<template>
     <!-- CAMBIAR IMPRIME AL HABILITAR AUTORIZACIONES -->
    <FormEdicion
        v-model="show"
        titulo="Comprobante"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :endPointImprimir="endPointImprimir"
        :endPointAuditoria="endPointAuditoria"
        :permiteModificar="permiteModificar"
        :permiteImprimir="tabla.autorizado"
        :permiteEnviarEmail="true"
        :imprime="true"
        :tabla="tabla"
        maxWidth="70%"
        @actualizoFormEdicion="actualizoFormEdicion"
        @imprimir="imprimir"
        @enviarEmail="enviarEmail">
        <template v-slot:Campos>
            <div class="d-flex pa-0">
                <SelectTiposTipoComprobante v-model="tabla.tipocomprobante.idtipotipocomprobante"
                    obligatorio
                    :clearable="false"
                    @valorSecundario="valorSecundarioTiposTipoComprobante"/>

                <SelectTiposComprobante v-model="tabla.tipocomprobante.id"
                    class="pl-1 pr-1"
                    obligatorio
                    seleccionaPrimero
                    :clearable="false"
                    :idsTiposTipoComprobante="tabla.tipocomprobante.idtipotipocomprobante"
                    :puntoVenta="puntoVenta"
                    :ivaEmisor="ivaEmisor"
                    :ivaReceptor="ivaReceptor"
                    @valorSecundario="valorSecundarioTiposComprobante"/>

                <InputFecha v-model="tabla.fecha"
                    obligatorio/> 

                <SelectPuntosVenta v-model="tabla.idpuntoventa"
                    v-show="tiposTipoComprobante.emisionPropia" 
                    class="pl-1"
                    soloActivos
                    :idTipoComprobante="tabla.tipocomprobante.id"
                    :readonly="!nuevoRegistro"
                    :obligatorio="tiposTipoComprobante.emisionPropia"
                    :clearable="false"
                    @valorSecundario="valorSecundarioPuntosVenta"/>
                
                <Input v-model="tabla.puntoventaexterno"
                    v-show="!tiposTipoComprobante.emisionPropia" 
                    class="pl-1"
                    label="P. de Venta"
                    tipo="number"
                    :obligatorio="!tiposTipoComprobante.emisionPropia"/>

                <Input v-model="tabla.numero"
                    v-show="!tiposTipoComprobante.emisionPropia || !nuevoRegistro"
                    class="pl-1"
                    label="Número"
                    tipo="number"
                    :obligatorio="numeroObligatorio"
                    :readonly="tiposTipoComprobante.emisionPropia"/>
            </div>
                    
            <div class="d-flex">
                <v-col cols="nuevoRegistro ? '6' : '4'" class="pa-0">
                    <SelectSectores v-model="tabla.idsector" obligatorio/>
                </v-col>
                <v-col cols="4" class="pa-0 pl-1" v-show="!nuevoRegistro">
                    <SelectUsuarios v-model="tabla.idusuariocreo" titulo="Creó" readonly/>
                </v-col>
                <v-col cols="nuevoRegistro ? '6' : '4'" class="pa-0 pl-1" v-show="tiposTipoComprobante.solicitaAutorizador">
                    <SelectUsuarios v-model="tabla.idusuarioautorizo" 
                        soloActivos 
                        soloAutorizadores
                        :titulo="tabla.autorizado ? 'Autorizó' : 'Autoriza'" 
                        :idSector="tabla.idsector"
                        :obligatorio="tiposTipoComprobante.solicitaAutorizador"/>
                </v-col>
            </div>
 
            <div class="d-flex">
                <v-col cols="6" class="pa-0">
                    <SelectPersonas v-model="tabla.idpersona" 
                        habilitaAgregar
                        habilitaModificar 
                        habilitaDetalle
                        showSlotDetalle
                        obligatorio
                        @valorSecundario="valorSecundarioPersona"
                        @clear="valorSecundarioPersona"/>
                </v-col>
                <v-col cols="6" class="pa-0 pl-1">
                    <SelectPersonas v-model="tabla.idsolicitante" 
                        titulo="Solicitante"
                        habilitaAgregar
                        habilitaModificar 
                        habilitaDetalle
                        showSlotDetalle/>
                </v-col>
            </div>

            <Tab v-model="tab" :tabs="tabs">
                <template v-slot:articulos>
                    <v-card>    
                        <div class="d-flex">
                            <v-col cols="6" class="pa-0">  
                                <v-col cols="6" class="pa-0">                   
                                    <Input v-model="codigoArticulo"
                                        label="Código"
                                        placeholder="Ingresá el código y presiona Enter"
                                        outlined
                                        dense
                                        @keydown.enter="buscarArticulo(codigoArticulo)"/>
                                </v-col>
                            </v-col>
                            <v-col cols="6" class="d-flex pa-0">  
                                <Button tipo="buscar" 
                                    :visible="tiposTipoComprobante.permiteArticulosSinComp"
                                    @click="mostrarArticulos(true)"/>
                                <Button tipo="comprobantes" 
                                    :visible="tiposTipoComprobante.tiposTipoComprobanteRelacionados.length !=0" 
                                    @click="mostrarDetalleComprobantes(true)"/>
                                <v-spacer/>
                                <Button tipo="eliminar" 
                                    :disabled="!articulosSeleccionados" 
                                    @click="eliminarArticulos"/>
                            </v-col>
                        </div>
                        <v-card-text class="pa-0">
                            <div style="max-height: 400px; overflow-y: auto;">
                                <DataTable v-model="selectArticulos"
                                    multiple
                                    dense
                                    hideDefaultFooter
                                    disablePagination
                                    itemKey="index"
                                    :items="tabla.detallecomprobantes"
                                    :headers="headersArticulos"
                                    @eliminar="eliminarArticulo"
                                    @cantidad="modificoCantidad"/>
                            </div>
                        </v-card-text>
                    </v-card>
                </template>
            </Tab>

            <div class="d-flex">
                <v-col cols="6" class="pa-0">
                    <InputTextArea v-model="tabla.observaciones"
                        label="Observaciones"/>
                </v-col>
                <v-col cols="6" class="pa-0 text-right">
                    <div class="text-h6">Total: {{tabla.total}}</div>
                </v-col>
            </div>
            
            <div class="d-flex">
                <SwitchCustom v-model="tabla.autorizado" label="Autorizado" 
                    v-show="tiposTipoComprobante.solicitaAutorizador"
                    :disabled="!usuarioHabilitado"/>
                <SwitchCustom v-model="tabla.anulado" label="Anulado"/>
            </div>
           
            <v-dialog v-model="showArticulos" max-width="100%" @click:outside="mostrarArticulos(false)">
                <v-card class="pa-2">
                    <Articulos :show="showArticulos"
                        modoSeleccion 
                        @input="agregarArticulos" 
                        @cerrar="mostrarArticulos(false)"/>
                </v-card>
            </v-dialog>     

            <v-dialog v-model="showDetalleComprobantes" max-width="100%" @click:outside="mostrarDetalleComprobantes(false)">
                <v-card class="pa-2">
                    <DetalleComprobantes :show="showDetalleComprobantes"
                        modoSeleccion
                        :idPersona="tabla.idpersona"
                        @input="agregarArticulos" 
                        @cerrar="mostrarDetalleComprobantes(false)"/>
                </v-card>
            </v-dialog>    

            <Confirm ref="confirm"></Confirm>
        </template>    
    </FormEdicion>
</template>

<script>

    import { mapState } from 'vuex';
    import { FormEdicionMixin } from '@/components/mixins/FormEdicionMixin';
    import { ImprimirComprobante } from '../../shared/ImpresionComprobante';
    import { comprobantesApi } from '../../api/ComprobantesApi'
    import { articulosApi } from '../../api/ArticulosApi'
    import { mailsApi } from '../../api/MailsApi'
    import { cerosIzquierda } from '../../utils';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Articulos from '../../views/Articulos/Articulos.vue';
    import DetalleComprobantes from './DetalleComprobantes.vue';
    import SelectPersonas from '../../components/select/SelectPersonas.vue';
    import SelectTiposTipoComprobante from '../../components/select/SelectTiposTipoComprobante.vue';
    import SelectTiposComprobante from '../../components/select/SelectTiposComprobante.vue';
    import Input from '@/components/input/Input.vue';
    import InputFecha from '../../components/input/InputFecha.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue';
    import InputTextArea from '../../components/input/InputTextArea.vue';
    import SelectUsuarios from '../../components/select/SelectUsuarios.vue';
    import Tab from '../../components/base/Tab.vue';
    import DataTable from '../../components/base/DataTable.vue';
    import Button from '../../components/base/button/Button.vue';
    import SelectPuntosVenta from '../../components/select/SelectPuntosVenta.vue';
    import SwitchCustom from '../../components/base/Switch.vue'    

    export default {

        mixins: [
            FormEdicionMixin,
        ],

        components: {
            FormEdicion,
            Articulos,
            DetalleComprobantes,
            SelectPersonas,
            Input,
            SelectTiposTipoComprobante,
            SelectTiposComprobante,
            InputFecha,
            SelectSectores,
            InputTextArea,
            SelectUsuarios,
            Tab,
            DataTable,
            Button,
            SelectPuntosVenta,
            SwitchCustom,
        },
                
        data: () => ({
            endPointBuscar: comprobantesApi.buscar,
            endPointGuardar: comprobantesApi.guardar,
            endPointEliminar: comprobantesApi.eliminarVarios,
            endPointImprimir: comprobantesApi.imprimir,
            endPointAuditoria: comprobantesApi.auditoria,
            tabla: {
                idpersona: null,
                idsolicitante: null,
                idempresa: null,
                tipocomprobante:{
                    id: null,
                    idtipotipocomprobante: null
                },
                fecha: null,
                idpuntoventa: null,
                puntoventaexterno: null,
                numero: null,
                idsector: null,
                idusuariocreo: null,
                idusuarioautorizo: null,
                observaciones: null,
                anulado: false,
                autorizado: false,
                detallecomprobantes: [],
                total: 0,
            },
            tab: 0,
            tabs:[{nombre: 'articulos', habilitado: true,  show: true, cantidad: 0}],
            headersArticulos:[{value: "eliminar", sortable: true, filterable: false, groupable: false},
                {text: "Código", value: "articulo.codigo", filterable: false, groupable: false},
                {text: "Descripción", value: "articulo.nombre", filterable: false, groupable: false},
                {text: "Cantidad", value: "cantidad", filterable: false, groupable: false, width: "10%", tipo: "numero", editable: true},
                {text: "P. Unitario", value: "unitario", filterable: false, groupable: false, width: "10%", tipo: "numero", editable: true},
                {text: "% Desc./Rec.", value: "descuentorecargo", filterable: false, groupable: false, width: "10%", tipo: "numero", editable: true},
                {text: "Importe", value: "total", filterable: false, groupable: false, width: "10%", tipo: "numero", editable: true},
            ],
            selectArticulos: [],
            tiposTipoComprobante: {
                solicitaAutorizador: false,
                tiposTipoComprobanteRelacionados: [],
                emisionPropia: false,
                permiteArticulosSinComp: false,
            },
            tiposComprobante: {
                puntosVenta: [],
            },  
            persona: {
                idCondicionIva: 0,
            },  
            showArticulos: false,
            showDetalleComprobantes: false,
            codigoArticulo: null,
            puntoVenta: null,
        }),

        computed: {
            ...mapState({
                idusuario: state => state.usuarioModule.id,
                administrador: state => state.usuarioModule.administrador,
                idempresa: state => state.empresasModule.id,
                idcondicioniva: state => state.empresasModule.condicioniva.id,
            }),     
            articulosIngresados(){
                return this.tabla.detallecomprobantes.length != 0
            },
            articulosSeleccionados(){
                return this.selectArticulos.length != 0
            },
            numeroObligatorio(){
                if (this.tiposTipoComprobante.emisionPropia) return false
                return !this.tiposComprobante.puntosVenta.includes(Number(this.tabla.puntoventaexterno))
            },
            ivaEmisor(){
                return this.tiposTipoComprobante.emisionPropia ? this.idcondicioniva : this.persona.idCondicionIva
            },
            ivaReceptor(){
                return this.tiposTipoComprobante.emisionPropia ? this.persona.idCondicionIva : this.idcondicioniva
            },
            usuarioHabilitado(){
                return true 
                //HABILITAR CUANDO SE IMPLEMENTEN AUTORIZACIONES
                //return this.tabla.idusuarioautorizo === this.idusuario || this.administrador
            },
            permiteModificar(){
                return this.articulosIngresados && 
                (this.tabla.id === 0 || 
                !this.tiposTipoComprobante.solicitaAutorizador || 
                !this.tabla.autorizado ||
                this.usuarioHabilitado)
            },
        },

        watch:{
            'tabla.detallecomprobantes': {
                deep: true,
                handler(){
                    this.calcularImportes()
                }
            },
            'tabla.puntoventaexterno': {
                handler(val){
                    this.puntoVenta = val
                }
            },
            'tabla.tipocomprobante.idtipotipocomprobante': {
                handler(val,oldval){
                    this.tabla.autorizado = true
                    //HABILITAR CUANDO SE IMPLEMENTEN AUTORIZACIONES
                    //if (oldval){
                    //    this.tabla.autorizado = !this.tiposTipoComprobante.solicitaAutorizador 
                    //}
                }
            },
        },

        created(){
            this.tabla.idempresa = this.idempresa
            this.tabla.idusuariocreo = this.idusuario
            this.tabla.fecha = this.$diaActual()
        },

        methods: {
            mostrarArticulos(val){
                this.showArticulos = val
            },  

            mostrarDetalleComprobantes(val){
                this.showDetalleComprobantes = val
            },  

            valorSecundarioTiposComprobante(val){
                this.tiposComprobante.puntosVenta = val.puntosventa.map(item => item.nombre);
            },

            valorSecundarioTiposTipoComprobante(val){
                this.tiposTipoComprobante.emisionPropia = val.emisionpropia
                this.tiposTipoComprobante.solicitaAutorizador = val.solicitaautorizador
                this.tiposTipoComprobante.tiposTipoComprobanteRelacionados = val.tipostipocomprobanterelacionados
                this.tiposTipoComprobante.permiteArticulosSinComp = val.permitearticulossincomp 
            },

            valorSecundarioPuntosVenta(val){    
                if (this.tiposTipoComprobante.emisionPropia){
                    this.puntoVenta = val.nombre
                }
            },

            valorSecundarioPersona(val){
                this.persona.idCondicionIva = val ? val.idcondicioniva : null
            },

            async buscarArticulo(val){
                if (!val) return
                const {data} = await articulosApi.buscar('',val)
                if (data.id){
                    const detalleComprobante = {
                        index: this.tabla.detallecomprobantes.length,
                        cantidad: 1,
                        unitario: 0,
                        descuentorecargo: 0,
                        articulo: {
                            id: data.id,
                            codigo: data.codigo,
                            nombre: data.nombre,
                        },
                    };
                    this.tabla.detallecomprobantes.push(detalleComprobante);
                    this.codigoArticulo = ''
                }else{
                    this.$refs.confirm.open('Atención', "No se encontró el artículo.", {tipo:'aceptacion',color:'red'})
                }
            },
            
            agregarArticulos(registros){
                this.showArticulos = false
                this.showDetalleComprobantes = false  
                for (let i = 0; i < registros.length; i++) {
                    const articulo = registros[i].articulo || registros[i]
                    const detalleComprobante = {
                        index: this.tabla.detallecomprobantes.length,
                        cantidad: registros[i].pendiente || registros[i].cantidad || 1,
                        unitario: registros[i].unitario || 0,
                        descuentorecargo: registros[i].descuentorecargo || 0,
                        articulo: {
                            id: articulo.id,
                            codigo: articulo.codigo,
                            nombre: articulo.nombre,
                        },
                    };

                    if (registros[i].comprobante) {
                        detalleComprobante.iddetalleorigen = registros[i].id
                        detalleComprobante.cantidadorigen = registros[i].pendiente 
                        detalleComprobante.cantidadmaxima = registros[i].pendiente
                    }

                    this.tabla.detallecomprobantes.push(detalleComprobante);
                }
            },

            eliminarArticulo(index){
                this.tabla.detallecomprobantes.splice(index,1);
            },

            eliminarArticulos(){
                // Obtener los índices de los elementos que deben ser eliminados
                let indicesEliminar = this.tabla.detallecomprobantes.reduce((indices, elemento, index) => {
                    if (this.selectArticulos.some(eliminar => eliminar.index === elemento.index)) {
                        indices.push(index);
                    }
                    return indices;
                }, []);

                // Eliminar los elementos del arrayPrincipal usando splice
                for (let i = indicesEliminar.length - 1; i >= 0; i--) {
                    this.tabla.detallecomprobantes.splice(indicesEliminar[i], 1);
                }

                this.selectArticulos = []
            },

            calcularImportes(){
                const detalle = this.tabla.detallecomprobantes;
                const cantidadDetalle = detalle.length;
                let sumaTotales = 0;
                for (let i = 0; i < cantidadDetalle; i++) {
                    const unitario = parseFloat(detalle[i].unitario)
                    const desrec = parseFloat(unitario * detalle[i].descuentorecargo / 100)
                    const cantidad = parseFloat(detalle[i].cantidad)
                    const total = parseFloat(((unitario + desrec) * cantidad).toFixed(2))
                    detalle[i].total = total
                    sumaTotales += total;
                }
                this.tabla.total = sumaTotales
                this.tabs[0].cantidad = cantidadDetalle
            },

            modificoCantidad(index){
                if (this.tabla.detallecomprobantes[index].iddetalleorigen){
                    const detalle = this.tabla.detallecomprobantes[index]
                    if (detalle.cantidad > detalle.cantidadmaxima){
                        const mensaje = 'Se ajustó ' + detalle.articulo.nombre + ' a la cantidad máxima permitida.'
                        this.$refs.confirm.open('Notificación', mensaje, {tipo:'aceptacion',color:'red'})
                        detalle.cantidad = detalle.cantidadorigen
                    }
                    detalle.cantidadorigen = detalle.cantidad
                }
            },

            async imprimir(data){
                ImprimirComprobante(data,'imprimir')
            },

            async enviarEmail(data){
                var pdfBase64WithPrefix = await ImprimirComprobante(data,'exportar')
                var pdfBase64 = pdfBase64WithPrefix.split(',')[1]; 

                const idEmpresa = data.idempresa
                const mails = [data.persona.contactos[0].email]

                let puntoventa = 0
                if (data.puntoventaexterno){
                    puntoventa = cerosIzquierda(data.puntoventaexterno,4)
                }
                if (data.puntoventa.nombre != null){
                    puntoventa = cerosIzquierda(data.puntoventa.nombre,4)
                }
                const numero = puntoventa + "-" + cerosIzquierda(data.numero,8)
                const asunto = data.tipocomprobante.nombre + " " + numero

                const cuerpo = `Hola <b>${data.persona.contactos[0].nombre}</b>, adjuntamos comprobante.
                <br><br>Muchas gracias.
                <br><br>${data.empresa.nombre}`;

                let adjuntos = []
                adjuntos.push({
                    filename: asunto  + '.pdf',
                    content: pdfBase64,
                })

                try {
                    await mailsApi.enviar(idEmpresa,mails,asunto,cuerpo,adjuntos)
                } catch (error) {
                    console.error(error);
                }

            },
        },
    }
</script>