import axios from 'axios'
const controller = "normas/"

class NormasApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    // async eliminar(id){
    //     const response = await axios.get(controller + id)
    //     if(response.data.direcciondocumentostorage){
    //         firebase.storage().refFromURL(response.data.direcciondocumentostorage).delete()
    //     }
    //     return axios.delete(controller + id);
    // }

    async eliminarVarios(ids){

        ///FALTA ELIMINAR STORAGE
        
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    async buscarVarios(parametros){
        let response = await axios.get(controller,{params:{
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsSectores: parametros.idsSectores,
            idTipo: parametros.idTipo,
            idEmisor: parametros.idEmisor,
            idEstado: parametros.idEstado,
            idsEtiquetas: parametros.idsEtiquetas}
        })
        for (let index=0; index < response.data.length; index++) {
            response.data[index].numero = response.data[index].numero + '-' + response.data[index].anio
        }
        return response
    }

    async select(parametros){
        let response = await axios.get(controller + "select",{params:{
            ids: parametros.ids,
            busqueda: parametros.busqueda,
            idExcluido: parametros.idExcluido} 
        })
        for (let index=0; index < response.data.length; index++) {
            response.data[index].nombre = response.data[index].tiponorma.nombre 
            + ' ' + response.data[index].numero
            + '-' + response.data[index].anio 
            + ' ' + response.data[index].titulo
        }
        return response
    }

    auditoria(id,rangoFecha){
        return axios.get("auditoria",{params:{
            desde: rangoFecha ? rangoFecha[0] : null,
            hasta: rangoFecha ? rangoFecha[1] : null,
            idTabla: id,
            tabla: "normas"
        }})
    }

    selectAcciones(){
        return axios.get("accionesnormas/select");
    }

    selectEmisores(){
        return axios.get("emisoresnorma/select");
    }

    selectEstados(){
        return axios.get("estadosnormas/select");
    }

    selectTipos(){
        return axios.get("tiposnorma/select");
    }

    selectEtiquetas(parametros){
        return axios.get("etiquetas/select",{params:{
            ids: parametros.ids,
            busqueda: parametros.busqueda} 
        })
    }
}

export const normasApi = new NormasApi();