<template>
    <FormEdicion
        v-model="show"
        titulo="Inscripción"
        maxWidth="40%"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :endPointImprimir="endPointImprimir"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion"
        @imprimir="imprimir">
        <template v-slot:Campos>
            <SelectSectoresInscripciones :sectoresSeleccionados.sync="tabla.inscripcion.idsector" 
                :eventosSeleccionados.sync="tabla.idinscripcion" 
                obligatorio
                :soloPermiteInscripciones="nuevoRegistro"/>
            
            <InputTextArea v-model="tabla.observacionesexterno"
                label="Información adicional"
                readonly/> 

            <div class="d-flex">  
                <v-col class="pa-0 pr-1" v-show="tabla.id != 0">   
                    <Input v-model="fechahoraEnLetras"
                        label="Fecha y Hora"
                        readonly/>
                </v-col> 
                <v-col class="pa-0">   
                    <SelectOrigenes v-model="tabla.idorigen"
                        readonly/>
                </v-col>
            </div>

            <SelectPersonas v-model="tabla.idpersona" 
                habilitaAgregar
                habilitaModificar 
                habilitaDetalle
                showSlotDetalle
                obligatorio/>

            <InputTextArea v-model="tabla.observaciones"
                label="Observaciones"/> 
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {inscripcionesApi} from '@/api/InscripcionesApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import InputTextArea from '../../components/input/InputTextArea.vue'
    import SelectSectoresInscripciones from '../../components/select/SelectSectoresInscripciones.vue';
    import Input from '../../components/input/Input.vue';
    import SelectOrigenes from '../../components/select/SelectOrigenes.vue'
    import { imprimirInscripcion } from '../../shared/ImpresionInscripcion';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            InputTextArea,
            SelectSectoresInscripciones,
            SelectPersonas: () => import('@/components/select/SelectPersonas.vue'),
            Input,
            SelectOrigenes,
        },

        data: () => ({
            endPointBuscar: inscripcionesApi.buscarDetalle,
            endPointGuardar: inscripcionesApi.guardarDetalle,
            endPointEliminar: inscripcionesApi.eliminarVariosDetalle,
            endPointImprimir: inscripcionesApi.imprimirDetalle,
            tabla: {
                idinscripcion: 0,
                idpersona: null,
                observaciones: "",
                observacionesexterno: "",
                fechahora: null,
                idorigen: 1,
                inscripcion:{
                    idsector: 0,
                },
            },
        }),

        computed: {
            fechahoraEnLetras:{
                get() {
                    if(this.tabla.fechahora != null){
                        return this.$convertirObjectDateString(new Date(this.tabla.fechahora),'dma') + ' ' + this.$obtenerHora(new Date(this.tabla.fechahora),'hml')
                    }else{
                        return null
                    }  
                },
                set() {
                    return null
                }
            },
        },    
        
        methods: {
            imprimir(data){
                imprimirInscripcion(data)
            }
        }
    }
</script>