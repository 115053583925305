import axios from 'axios'
const controller = "condicioniva/"
class CondicionesIvaApi {
    async select(parametros){

        return axios.get(controller + "select",{params:{
            personaFisica: parametros.personaFisica,
            personaJuridica: parametros.personaJuridica}
        });

    }
}

export const condicionesIvaApi = new CondicionesIvaApi();