<template>
    <FormEdicion
        v-model="show"
        titulo="Punto de Venta"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                label="Nombre"
                obligatorio
                tipo="number"/>
            <SwitchCustom v-model="tabla.activo" label="Activo"/>
        </template>    
    </FormEdicion>
</template>

<script>

    import { FormEdicionMixin } from '@/components/mixins/FormEdicionMixin';
    import { puntosVentaApi } from '../../api/PuntosVentaApi';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import SwitchCustom from '../../components/base/Switch.vue'
    import Input from '@/components/input/Input.vue';
    
    export default {

        mixins: [
            FormEdicionMixin,
        ],

        components: {
            FormEdicion,
            SwitchCustom,
            Input,
        },
                
        data: () => ({
            endPointBuscar: puntosVentaApi.buscar,
            endPointGuardar: puntosVentaApi.guardar,
            endPointEliminar: puntosVentaApi.eliminarVarios,

            tabla: {
                nombre: null,
                activo: true,
            },
        }),

    }
</script>