<template>
    <FormEdicion
        v-model="show"
        titulo="Norma relacionada"
        modoAlta
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <SelectAccionesNormas v-model="tabla.accion.id" obligatorio @valorSecundario="valorSecundarioAccion"/>
            <SelectNormas v-model="tabla.norma.id" obligatorio :idExcluido="idExcluido" @valorSecundario="valorSecundarioNorma"/>
        </template>
    </FormEdicion>
</template>

<script>
    
    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import SelectAccionesNormas from '../../components/select/SelectAccionesNormas.vue';
    import SelectNormas from '../../components/select/SelectNormas.vue';

    export default {
        
        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            SelectAccionesNormas,
            SelectNormas,
        },

        data: () => ({
            tabla: {
                accion:{
                    id: null,
                    nombre: '',
                },
                norma:{
                    id: null,
                    titulo: '',
                    numero: 0,
                    anio: 0,
                    fecha: null,
                    direcciondocumentostorage: '',
                    tiponorma:{
                        nombre: ''
                    },
                }, 
            },
        }),

        props: {
            idExcluido: {type: Number,
                default: null},
        },

        methods: {
            valorSecundarioNorma(val){
                this.tabla.norma.titulo = val.titulo
                this.tabla.norma.numero = val.numero
                this.tabla.norma.anio = val.anio
                this.tabla.norma.fecha = val.fecha
                this.tabla.norma.direcciondocumentostorage = val.direcciondocumentostorage
                this.tabla.norma.tiponorma.nombre = val.tiponorma.nombre
            },
            valorSecundarioAccion(val){
                this.tabla.accion.nombre = val.nombre
            },
            guardo() {
                this.$emit("guardo",this.tabla)
            },
        },

    }
</script>