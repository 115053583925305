<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Estado" 
        :tituloPlural="tituloPlural" 
        :endPointSelect="endPointSelect"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :filtro="filtro"
        :clearable="false"
        :readonly="readonly"
        @valorSecundario="valorSecundario">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {turnosApi} from '@/api/TurnosApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: turnosApi.selectEstados,
        }),

        props: {
            noSolicitaPersona: {type: Boolean,
                default: false},
            tituloPlural: {type: String,
                default: 'Estados'
            }
        },

        watch:{
            noSolicitaPersona:{
                immediate: true, //HACE QUE SE EJECUTE AL INICIO EL OBSERVADOR
                handler (val, oldVal) {
                    this.filtro.noSolicitaPersona = val 
                }
            } 
        },

    }    
</script>