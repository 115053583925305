import Vue from 'vue'
import Vuex from 'vuex'
import '../services/axios.interceptor';

import empresasModule from './modules/empresas.module';
import fechasModule from './modules/fechas.module.js'
import loadingModule from './modules/loading.module.js'
import usuarioModule from './modules/usuario.module.js'
import parametrosModule from './modules/parametros.module';
import sectoresModule from './modules/sectores.module'
import snackbarModule from './modules/snackbar.module.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    colorPrincipal: "blue darken-3",
    colorSecundario: "blue darken-4",
  },
 
  modules: {
    empresasModule,
    fechasModule,
    loadingModule,
    usuarioModule,
    parametrosModule,
    sectoresModule,
    snackbarModule, 
  }
})
