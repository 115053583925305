export const FormEdicionMixin = {

    data: () => ({
        tabla: {
            id: 0,
            actualizar: false,
        },
        valoresInicial: {},
        reset: true,
    }),

    props: {
        value: {type: Boolean,
            default: false},  
        id: {type: [Array,Number],
            default: 0},
        tablaExterna: {type: Object,
            default: () => ({}),},
        tablaValoresPorDefecto: {type: Object,
            default: () => ({}),},
        modoAlta: {type: Boolean,
            default: false},  
        modoModificacion: {type: Boolean,
            default: false},  
        habilitaEliminar: {type: Boolean,
            default: true},  
    },

    computed: {
        show: {
            get() {
                if (this.value) this.seteoShow()
                return this.value;
            },
            set(value) {
                if (value){
                    this.seteoShow() 
                }
                this.$emit("input",value);
            }
        },
        nuevoRegistro(){
            return !this.tabla.id || this.tabla.id === 0 
        },
    },

    mounted() {
        this.valoresInicial = JSON.parse(JSON.stringify(this.tabla));
    },

    methods: {
        seteoShow(){
            if(Object.keys(this.tablaExterna).length != 0){
                this.tabla = JSON.parse(JSON.stringify(this.tablaExterna));
            }
            this.tabla.id = this.id
        },
        actualizoFormEdicion(id){
            if (id) this.$emit("actualizoFormEdicion",id,true);
            if (this.reset) this.tabla = JSON.parse(JSON.stringify(this.valoresInicial)) 
        },
        eliminar(index){
            this.$emit("eliminar",index)
        },
    },

}    