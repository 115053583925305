<template>
    <FormEdicion
        v-model="show"
        titulo="Artículo"
        :endPointValoresPorDefecto="endPointValoresPorDefecto"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :endPointAuditoria="endPointAuditoria"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.codigo"
                autofocus
                label="Código"
                obligatorio
                :counter="30"/>

            <Input v-model="tabla.nombre"
                label="Nombre"
                obligatorio/>

            <SelectRubrosSubrubros :rubrosSeleccionados.sync="tabla.subrubro.idrubro" 
                :subrubrosSeleccionados.sync="tabla.subrubro.id" 
                obligatorio/>
            
            <div class="d-flex">
                <v-col cols="6" class="pa-0">
                    <SelectAlicuotasIva v-model="tabla.idalicuotaiva" 
                        obligatorio/> 
                </v-col>

                <v-col cols="6" class="pa-0 pl-1">
                    <Input v-model="tabla.costoneto"
                        label="Precio de Costo"
                        tipo="number"/>
                </v-col>
            </div>

            <SwitchCustom v-model="tabla.activo" label="Activo"/>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {articulosApi} from '../../api/ArticulosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue'
    import SelectRubrosSubrubros from '@/components/select/SelectRubrosSubrubros.vue';
    import SelectAlicuotasIva from '@/components/select/SelectAlicuotasIva';
    import SwitchCustom from '../../components/base/Switch.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectRubrosSubrubros,
            SelectAlicuotasIva,
            SwitchCustom,
        },

        data: () => ({
            endPointValoresPorDefecto: articulosApi.porDefecto,
            endPointBuscar: articulosApi.buscar,
            endPointGuardar: articulosApi.guardar,
            endPointEliminar: articulosApi.eliminarVarios,
            endPointAuditoria: articulosApi.auditoria,
            tabla:{
                codigo: "",
                nombre: "",
                subrubro:{
                    id: null,
                    idrubro: null
                }, 
                idalicuotaiva: null,
                costoneto: 0,
                activo: true,
            }
        }),

    }
</script>