import axios from 'axios'
const controller = "articulos/"

class ArticulosApi {
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id, codigo){
        return axios.get(`${controller}buscar`,{params:{
            id: id, 
            codigo: codigo,
        }});
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }
    
    buscarVarios(parametros){
        return axios.get(controller,{params:{
            activo: parametros.activo, 
            idsSubrubros: parametros.idsSubrubros,
            idsRubros: parametros.idsRubros,
        }});
    }

    
    select(parametros){
        return axios.get(controller + "select",{params:{
            ids: parametros.ids,
            busqueda: parametros.busqueda} 
        })
    }

    porDefecto(){
        return axios.get(controller + "pordefecto");
    }

    auditoria(id,rangoFecha){
        return axios.get("auditoria",{params:{
            desde: rangoFecha ? rangoFecha[0] : null,
            hasta: rangoFecha ? rangoFecha[1] : null,
            idTabla: id,
            tabla: "articulos"}})
    }
}

export const articulosApi = new ArticulosApi();