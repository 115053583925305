import axios from 'axios'
import {modificaFormatoFecha} from '@/utils'
const controller = "calendarios/"

class CalendariosApi {
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){    
        return axios.get(controller,{params: {
            idsSectores: parametros.idsSectores 
        }})
    }

    async buscarDetalle(id){
        let tabla = await axios.get(controller + 'buscardetalle' + id);
        tabla.data.rangofecha = []
        tabla.data.rangofecha.push(tabla.data.fechainicio)
        tabla.data.rangofecha.push(tabla.data.fechafin)
        return tabla
    }

    guardarDetalle(datos){
        const rango = Array.from(datos.rangofecha).sort()
        datos.fechainicio = rango ? rango[0] : null
        datos.fechafin = rango ? rango[1] : null
        if (datos.id){
            return axios.put(controller + 'actualizardetalle',datos);
        }else{
            return axios.post(controller + 'agregardetalle',datos);
        }
    }

    eliminarVariosDetalle(ids){
        return axios.delete(controller  + 'eliminardetalle',{params:{
            ids: ids
        }});
    }

    async buscarVariosDetalle(parametros){    
        let response = await axios.get(controller + "buscardetalle",{params: {
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsCalendarios: parametros.idsCalendarios,
            idsSectores: parametros.idsSectores,
        }})
        for (let index=0; index < response.data.length; index++) {
            response.data[index].rangofecha = modificaFormatoFecha(response.data[index].fechainicio) + ' ~ ' + modificaFormatoFecha(response.data[index].fechafin)  
        }
        return response
    }
}

export const calendariosApi = new CalendariosApi();