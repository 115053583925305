
import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import {createPDFWithImage, obtenerDiaEnLetras, obtenerHora} from '../utils'

export function imprimirTurno(data, title) {
    const titleReport = title || 'Constancia de Turno'

    var createPDF = function(imgData) {
        var doc = new jsPDF()
        const widthPage = doc.internal.pageSize.getWidth()
        let texto
        let textoformato
        let linea = 27
        const fontName = "helvetica"
        doc.setTextColor(40)

        // Evento addPage() para detectar cuando se agrega una nueva página
        doc.internal.events.subscribe('addPage', () => {
            linea = 27
        });
        
        if (data.fechahora){
            doc.setFontSize(12)
            doc.setFont(fontName,'bold')
            doc.text("Turno",10,linea)
            linea += 5

            doc.setFontSize(10)
            doc.text("Día:",10,linea)
            doc.text(obtenerDiaEnLetras(new Date(data.fechahora)),45,linea)
            
            if (data.intervalo != '00:00:00'){
                doc.text("Hora:",140,linea)
                doc.text(obtenerHora(new Date(data.fechahora),'hml'),150,linea)
            }
            linea += 5

            doc.setFont(fontName,"normal")
            doc.text("Lugar:",10,linea)
            doc.text(data.turno.sector.lugarturno,45,linea)
            linea += 5

            doc.text("Dirección:",10,linea)
            doc.text(data.turno.sector.domicilioturno,45,linea)
            linea += 5

            doc.text("Teléfono:",10,linea)
            doc.text(data.turno.sector.telefono,45,linea)
            linea += 5

            doc.text("Estado Turno:",10,linea)
            doc.text(data.estado.nombre,45,linea)
            linea += 5

            doc.text("Persona:",10,linea)
            const nombreCompleto = `${data.persona.apellido || ''} ${data.persona.nombre}`;
            doc.text(nombreCompleto,45,linea)
            linea += 5

            doc.text("Tipo y Nro. Doc.",10,linea)
            doc.text(data.persona.tipodocumento.nombre + " " + data.persona.nrodocumento,45,linea)
            linea += 5

            doc.text("Observaciones:",10,linea)
            doc.text(data.observacionesexterno,45,linea)
        }

        switch (data.turno.sector.idtiposector) {
            case 1: //Licencias
                if (data.fechahora){
                    linea += 5
                    texto = "Solicitamos presentarse a su turno 10 minutos antes, caso contrario, este será ocupado y deberá ser solicitado nuevamente."
                    doc.setFont(fontName,'bold')
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea)
                    linea += 8

                    texto = "Recomendamos que la presente constancia sea presentada, de forma digital o impresa, el día del turno del examen psicofísico."
                    doc.setFont(fontName,"normal")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea)
                    linea += 5
                } 

                //TIPOS TRAMITES
                //1-Renovacion
                //2-Primera vez
                //3-Nueva Categoría
                //4-Extravio

                if ([1, 2, 3].includes(data.tramite.idtipotramite)) {

                    if (data.fechahora){
                        doc.line(10,linea,200,linea)
                        linea += 5
                    }

                    doc.setFontSize(12)
                    doc.setFont(fontName,'bold')
                    doc.text("Examen Psicofísico",10,linea)
                    linea += 5

                    if (!data.fechahora){
                        doc.setFontSize(10)
                        doc.setFont(fontName,"normal")
                        texto = "Día y Horario: obtener turno ingresando a www.mtotoras.gob.ar con al menos 60 días de anticipación" + "\n" +
                        "Lugar: Municipalidad de Totoras - Oficina 1. Dirección: Arenales 984."
                        textoformato = doc.splitTextToSize(texto,180)
                        doc.text(textoformato,10,linea)
                        linea += 10
                    }

                } else{
                    doc.setFontSize(10)
                    doc.setFont(fontName,"normal")
                    doc.text("Diríjase a la oficina 1 en la Municipalidad, Arenales 984 Totoras, de lunes a viernes de 8 a 12 Hs.",10,linea)
                    linea += 5
                }
                
                doc.setFontSize(10)
                doc.setFont(fontName,"normal")

                doc.text("Documentación a presentar:",10,linea)
                linea += 5

                texto = "•  Boleta de pago de CENAT (Certificado Nacional de Antecedentes de Tránsito), valor $6.800. Una vez abonada tiene validez por 60 días. Constancia de Infracciones - SIJAI (es gratuita y tiene validez por 15 días). " +
                "Para generar ambos documentos, podrá ingresar a: https://www.mtotoras.gob.ar opción Licencias de Conducir. " +
                "Deberá completar los datos personales del solicitante y seleccionar el Centro de Emisión de Licencias correspondiente a su jurisdicción, en este caso: TOTORAS - ARENALES 984. " +
                "Una vez impresa la boleta de pago de CENAT, podrá abonarla en cualquiera de los centros de cobro detallados, dentro de los 7 días desde su emisión. Transcurrido dicho plazo, deberá generar una nueva. " +
                "Si se abona a través de la linea de cajas de Banco Nación, tener en cuenta que la acreditación puede demorar hasta 72 hs. " +
                "El comprobante de pago impreso es REQUISITO OBLIGATORIO para dar inicio al trámite (inclusive si se realizó a través de Mercado Pago). " + "\n" +
                "•  Constancia de CUIL, descargar ingresando desde: https://www.mtotoras.gob.ar en la opción Licencias de Conducir." + "\n" +
                "•  Documento Nacional de Identidad (D.N.I.) DOCUMENTO TARJETA (único documento válido para inicio de trámite)." + "\n" +
                "•  Fotocopia del D.N.I." + "\n" 

                if ([4].includes(data.tramite.idtipotramite)) {
                    texto = texto + "•  De haberse extraviado el DNI presentar DENUNCIA DE EXTRAVÍO DE DNI Y TIRILLA DE TRÁMITE " + 
                    "DE DNI (NUEVO DNI) CON LA MISMA DIRECCIÓN QUE FIGURABA EN LA LICENCIA EXTRAVIADA. EN CASO DE MODIFICAR LA DIRECCIÓN " + 
                    "DEBERÁ ESPERAR EL NUEVO PLÁSTICO DEL DNI." + "\n" +
                    "•  DENUNCIA DE EXTRAVÍO DE LICENCIA DE CONDUCIR." + "\n"
                }
                
                if ([1, 3].includes(data.tramite.idtipotramite)) {
                    texto = texto + "•  Licencia/s de conducir vencida/s o a vencer." + "\n" +
                    "•  Fotocopia de la/s licencia/s vencidas o a vencer." + "\n" 
                }
                
                if ([2].includes(data.tramite.idtipotramite)) {
                    texto = texto + "•  Original o fotocopia del carné de grupo sanguíneo autenticado por la/el bioquímica/o." + "\n" 
                }
                
                if ([1, 3, 4].includes(data.tramite.idtipotramite)) {
                    texto = texto + "•  Para el caso de licencia especial D1, presentar Certificado de Reincidencia." + "\n" 
                }

                if ([1, 2, 3].includes(data.tramite.idtipotramite)) {
                    texto = texto + "•  Se deberá abonar: Psicóloga $1.500, Médico $1.500, Estampillas Colegio de Médicos $10.000, " +
                    "Libre de Multas de Totoras $1.800, Tasa Provincial $100, "
                    if ([2].includes(data.tramite.idtipotramite)) {
                        texto = texto + "Original hasta 2 categorías $6.500, "
                    }
                    if ([1, 3].includes(data.tramite.idtipotramite)) {
                        texto = texto + "Renovación hasta 2 categorías $5.200, Renovación Anual $5.200, "
                    }
                    texto = texto + "categorías adicionales $3.400 c/u. " + "\n" 
                }

                if ([4].includes(data.tramite.idtipotramite)) {
                    texto = texto + "•  LOS PAGOS CORRESPONDIENTES Y EL PSICOFÍSICO DEPENDERÁ DEL TIPO DE TRÁMITE POR EXTRAVÍO." + "\n"
                }

                texto = texto + "•  Las personas domiciliadas en Lucio V. López y Salto Grande deberán presentar Libre de Multas de su respectiva localidad cuya validez " + 
                "será de 30 días a partir de su fecha de emisión."

                textoformato = doc.splitTextToSize(texto,180)
                doc.text(textoformato,15,linea)
                linea += textoformato.length * 4 + 2

                doc.text("Los importes están sujetos a modificaciones, sin previo aviso.",10,linea)
                linea += 5

                if ([1, 2, 3].includes(data.tramite.idtipotramite)) {
                    texto = "IMPORTANTE: Personas con diabetes tipo I o II deberán presentar: Análisis de Glicemia, Hemoglobina Glicosilada y Fondo de Ojos." + "\n" +
                    "Si usa lentes para ver de lejos o de cerca, traer los mismos al Examen Psicofísico como así también, a los Exámenes Teóricos y Prácticos (en caso de que correspondiera)."
                    doc.setFont(fontName,"bold")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea) 
                    linea += 18
                }

                if ([2, 3].includes(data.tramite.idtipotramite)) {
                    
                    doc.line(10,linea,200,linea)
                    linea += 5

                    doc.setFontSize(12)
                    doc.setFont(fontName,"bold")
                    doc.text("Cursos Nacionales de Educación Vial Digital",10,linea)
                    linea += 5

                    doc.setFontSize(10)
                    doc.setFont(fontName,"normal")
                    texto = 'Previo a la realización de los Cursos de Formación Vial presenciales ' +
                    '"General" y "Motovehículos" previstos por la APSV, los solicitantes deberán acreditar, con los certificados respectivos, ' +
                    'la realización de los CURSOS NACIONALES DE EDUCACIÓN VIAL DIGITAL: ' + "\n" + 
                    '• Mi primera licencia: curso teórico obligatorio para jóvenes de entre 18 y 21 años inclusive, ' + 
                    'que tramiten por primera vez la Licencia Nacional para conducir automóvil y/o motocicleta.' + "\n" + 
                    '• Curso nacional de educación vial: curso teórico obligatorio para aquellas personas que no se encuentren dentro ' + 
                    'del rango etario mencionado, que tramiten por primera vez la Licencia Nacional para conducir automóvil y/o motocicleta.' + "\n" +
                    'Primera vez auto (Clase B, original y ampliación): "Curso Auto"' + "\n" +
                    'Primera vez moto (Clase A, original y ampliación): "Curso Moto"' + "\n" +
                    'En ambos casos: Curso perspectiva de género y Curso de Estrellas Amarillas.'
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,) 
                    linea += textoformato.length * 4 + 4
                    
                    doc.setFont(fontName,"bold")
                    texto = 'Para realizar los cursos, el solicitante deberá acceder a traves del siguiente sitio web: https://www.mtotoras.gob.ar opción Licencias de Conducir.'
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,) 

                    doc.addPage()

                    doc.setFontSize(12)
                    doc.setFont(fontName,"bold")
                    doc.text("Curso de Educación Vial y Examen Teórico",10,linea)
                    linea += 5

                    doc.setFontSize(10)
                    doc.text("Solo serán exigidos para los siguientes casos y habiendo aprobado la aptitud psicofísica:",10,linea)
                    linea += 5

                    texto = "•  Para los tipos de trámite Original. " + "\n" +
                    "•  Para los tipos de trámite Renovación con Ampliación, son obligatorios para las clases ampliadas. " + "\n" +
                    "•  Para los mayores de 65 años que inicien trámites de Renovación de clase E1. " + "\n" +
                    "•  Para los trámites de Renovación cuyos solicitantes sean mayores de 65 años y que aún cuenten con Licencia Provincial (NO NACIONAL). " + "\n" +
                    "•  Para los trámites de Renovación, y el trámite tiene cargado un antecedente de otra provincia. " + "\n" +
                    "•  Licencias caducas (cambio de datos no denunciados dentro de los 90 días de la fecha de emisión del DNI con dicha modificación). " + "\n" +
                    "•  Para los trámites de Renovación cuyo solicitante registra al menos 2 (dos) antecedentes con sentencia firme por faltas graves en el SIJAI. " + "\n" 
                    doc.setFont(fontName,"normal")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,15,linea)
                    linea += textoformato.length * 4 

                    texto = "Curso de Capacitación Vial para Clases A - Motovehículos (Si es primera vez, es requisito haber cursado Capacitación Vial General):"
                    doc.setFontSize(10)
                    doc.setFont(fontName,"bold")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,)
                    linea += textoformato.length * 4

                    texto = "Día y Horario: Lunes 7:10 Hs. Lugar: Anexo de Cultura. Dirección: Laprida y San Lorenzo. "  + "\n" +
                    "Luego del curso el examinador le otorgará el turno para rendir el examen teórico correspondiente. " + "\n" +
                    "Previo a la realización de los Cursos de Formación Vial (original o ampliación a clases A), los solicitantes deberán acreditar, con el certificado respectivo, " + 
                    "la realización del CURSO NACIONAL DE EDUCACIÓN VIAL DIGITAL (ver página 3)."
                    doc.setFont(fontName,"normal")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,)
                    linea += textoformato.length * 4 + 5

                    texto = "Curso de Capacitación Vial General (para las renovaciones de solicitantes mayores de 65 años que aún cuenten con licencia provincial, altas de A y B, y licencias vencidas más de un año):"
                    doc.setFont(fontName,"bold")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,)
                    linea += textoformato.length * 4 

                    texto = "Día y Horario: Martes 7:10 Hs. Lugar: Anexo de Cultura. Dirección: Laprida y San Lorenzo. "  + "\n" +
                    "Luego del curso el examinador le otorgará el turno para rendir el examen teórico correspondiente. " + "\n" +
                    "Previo a la realización de los Cursos de Formación Vial (original o ampliación a clases B), los solicitantes deberán acreditar, con el certificado respectivo, " + 
                    "la realización del CURSO NACIONAL DE EDUCACIÓN VIAL DIGITAL (ver página 3)."
                    doc.setFont(fontName,"normal")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,)
                    linea += textoformato.length * 4 + 5

                    texto = "Curso de Capacitación Vial para Clases E, C y D – Especiales:"
                    doc.setFont(fontName,"bold")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,)
                    linea += textoformato.length * 4 

                    texto = "Día y Horario: Miércoles 7:10 Hs. Lugar: Anexo de Cultura. Dirección: Laprida y San Lorenzo. "  + "\n" +
                    "Luego del curso el examinador le otorgará el turno para rendir el examen teórico correspondiente. "
                    doc.setFont(fontName,"normal")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,)
                    linea += textoformato.length * 4 + 5
                    
                    texto = "IMPORTANTE: Los solicitantes detallados en los ítems deberán estudiar del Manual de Formación de Conductores y del folleto de señalización vial, " +
                    "como así tambien, podrán practicar en el Simulador de Examen Teórico el/los cuestionarios correspondientes a las clases involucradas, accediendo desde el siguiente " +
                    "sitio web: https://www.mtotoras.gob.ar opción Licencias de Conducir."
                    doc.setFont(fontName,"bold")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,)
                    linea += textoformato.length * 4

                    doc.line(10,linea,200,linea)
                    linea += 5

                    doc.setFontSize(12)
                    doc.setFont(fontName,"bold")
                    doc.text("Examen Práctico",10,linea)
                    linea += 5

                    texto = "Día y Horario:  a convenir con el instructor el día del Examen Teórico. Lugar: Circuito de manejo. " + "\n" +
                    "Dirección: Bv. Garay y Lavalle"
                    doc.setFontSize(10)
                    doc.setFont(fontName,"normal")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea,)
                    linea += textoformato.length * 4

                    doc.text("Requisitos legales del vehículo:",10,linea)                    
                    linea += 5

                    doc.rect(10,linea-4,95,68)
                    texto = "AUTOS, CAMIONES, TRACTORES, CAMIONETAS Y MAQUINARIA AGRÍCOLA"
                    textoformato = doc.splitTextToSize(texto,85)
                    doc.text(textoformato,12,linea,)
                    
                    doc.rect(105,linea-4,95,68)
                    doc.text("MOTOS Y CICLOMOTORES",107,linea)
                    linea += 10

                    texto = "• Revisión técnica del vehículo." + "\n" +
                    "• Cédula del automotor." + "\n" +
                    "• Póliza de Seguro." + "\n" +
                    "• Último recibo de Patente pago." + "\n" + "\n" +
                    "Elementos de seguridad:"  + "\n" +
                    "• Luces de giro funcionando." + "\n" +
                    "• Luces posición, bajas y altas funcionando." + "\n" +
                    "• Luz de freno." + "\n" +
                    "• Espejos retrovisores." + "\n" +
                    "• Chapa patente." + "\n" +
                    "• Cinturón de seguridad." + "\n" +
                    "• Balizas." + "\n" +
                    "• Matafuegos."
                    doc.text(texto,12,linea,)
                    
                    texto = "• Cédula de la moto/ciclomotor." + "\n" +
                    "• Póliza de Seguro." + "\n" +
                    "• Último recibo de Patente pago." + "\n" + "\n" +
                    "Elementos de Seguridad:" + "\n" +
                    "• Luces de giro, bajas y altas funcionando." + "\n" +
                    "• Luz de freno." + "\n" +
                    "• Espejos retrovisores." + "\n" +
                    "• Chapa patente."
                    doc.text(texto,107,linea,)
                    linea += 58

                    doc.rect(10,linea-4,190,9)
                    texto = "En caso de que el vehículo no cumpla con los requisitos obligatorios, el Examen Práctico será dado como NO APROBADO."
                    doc.setFont(fontName,"bold")
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,12,linea)

                    doc.addPage()
                    
                    texto = "De no aprobar el Examen Teórico o Práctico, dispondrá de 2 nuevas oportunidades separadas por 10 días corridos."  
                    doc.setFontSize(10)
                    textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,10,linea)
                    linea += textoformato.length * 4 + 5

                    doc.setFont(fontName,"bold")
                    doc.text("En caso de lluvia, no se podrá realizar el Examen Práctico.",10,linea)
                    linea += 7

                    doc.text("Vehículos con los que puede rendir Examen Práctico según clase de licencia a solicitar:",10,linea)
                    linea += 5

                    texto="A21: *Motocicleta de 50cc. Hasta 150cc." + "\n" + "\n" +
                    "A22: *Motocicleta mayor de 150cc hasta 300cc." + "\n" + "\n" +
                    "A3: *Motocicleta mayor a 300cc." + "\n" + "\n" +
                    "B1: *Automóvil, camioneta o casa rodante motorizada de hasta 3.500 kgs. de peso total." + "\n" +
                    "      *Automóvil, camioneta o casa rodante motorizada de hasta 3.500 kgs. de peso total con acoplado igual o mayor." + "\n" +
                    "      *Camión sin acoplado o casa rodante motorizada de más de 3.500 kgs. de peso total." + "\n" +
                    "      *Vehículo de transporte de pasajeros de hasta 8 plazas." + "\n" +
                    "      *Vehículo de transporte de pasajeros de más de 8 plazas." + "\n" +
                    "      *Camión articulado o con acoplado o semiacoplado." + "\n" + "\n" +
                    "B2: *Automóvil, camioneta o casa rodante motorizada de hasta 3.500 kgs. de peso total con acoplado igual o mayor."  + "\n" +
                    "      *Camión articulado o con acoplado o semiacloplado." + "\n" + "\n" +
                    "C: *Camión sin acoplado o casa rodante motorizada de más de 3.500 kgs de peso total."  + "\n" +
                    "     *Vehículo de transporte de pasajeros de más de 8 plazas." + "\n" +
                    "     *Camión articulado o con acoplado o semiacloplado." + "\n" + "\n" +
                    "D1: *Automóvil, camioneta o casa rodante motorizada de hasta 3.500 kgs. de peso total." + "\n" +
                    "      *Automóvil, camioneta o casa rodante motorizada de hasta 3.500 kgs. de peso total con acoplado igual o mayor." + "\n" +
                    "      *Camión sin acoplado o casa rodante motorizada de más de 3.500 kgs. de peso total." + "\n" +
                    "      *Vehículo de transporte de pasajeros de hasta 8 plazas." + "\n" +
                    "      *Camión articulado o con acoplado o semiacloplado." + "\n" + "\n" +
                    "E1: *Camión articulado o con acoplado o semiacloplado." + "\n" + "\n" +
                    "E2: *Camión sin acoplado o casa rodante motorizada de más de 3.500 kgs. de peso total."  + "\n" +
                    "      *Vehículo de transporte de pasajeros de más de 8 plazas." + "\n" +
                    "      *Camión articulado o con acoplado o semiacloplado." + "\n" +
                    "      *Máquina especial no agrícola." + "\n" + "\n" +
                    "G1: *Camión sin acoplado o casa rodante motorizada de más de 3.500 kgs. de peso total." + "\n" +
                    "      *Vehículo de transporte de pasajeros de más de 8 plazas." + "\n" +
                    "      *Camión articulado o con acoplado o semiacloplado." + "\n" +
                    "      *Tractor agrícola." + "\n" + "\n" +
                    "G2: *Camión sin acoplado o casa rodante motorizada de más de 3.500 kgs. de peso total." + "\n" +
                    "      *Vehículo de transporte de pasajeros de más de 8 plazas." + "\n" +
                    "      *Camión articulado o con acoplado o semiacloplado." + "\n" +
                    "      *Máquina agrícola especial."

                    doc.setFontSize(9)
                    doc.setFont(fontName,"normal")
                    doc.text(texto,10,linea)
                    
                }
                break;
            case 2: //Salud Animal   
                
                linea += 8
                doc.line(10,linea,200,linea)

                linea += 5
                doc.setFontSize(12)
                doc.setFont(fontName,"bold")
                doc.text("Preparación",10,linea)
                
                linea += 5
                doc.setFontSize(10)
                texto = "•  La noche previa a la operación deberá hacerle un ayuno de 12 hs (pasar toda la noche sin comer, ni tomar agua)." + "\n" +
                "•  Debe presentarse en el horario y lugar acordado, con el animal y una manta." + "\n" +
                "•  Los perros/as con collar y correa." + "\n" +
                "•  Los gatos/as en bolsa red de verdulería." + "\n" +
                "•  No es necesario darle medicación."
                doc.setFont(fontName,"normal")
                textoformato = doc.splitTextToSize(texto,180)
                doc.text(textoformato,15,linea)

                break;
        }

        const totalPages = doc.internal.getNumberOfPages(); // Obtener el número total de páginas
        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            //ENCABEZADO PAGINA
            linea = 16
            doc.setFont(fontName,"normal")
            doc.addImage(imgData, 'JPEG', 10, 8, imgData.width * 10 / 100,  imgData.height * 10 / 100);
            doc.setFontSize(14)
            doc.text(data.turno.sector.nombre,widthPage/2,linea,{align:'center'})
            linea += 4

            if (data.tramite){
                doc.setFontSize(10)
                doc.text("Trámite: " + data.tramite.nombre,widthPage/2,linea,{align:'center'})
            }
            linea += 2

            doc.line(10,linea,200,linea)

            //PIE PAGINA
            linea = 276
            doc.setPage(pageNumber)
            doc.line(10,linea,200,linea)
            linea += 4

            doc.setFont(fontName,"italic")
            doc.setFontSize(8)

            doc.text('Página ' + pageNumber + '/' + totalPages, 10, linea);
            doc.text('Impreso ' + dayjs().format("DD/MM/YYYY HH:mm [Hs.]"),200,linea,{align:'right'})
        }

        doc.output('dataurlnewwindow',{filename: titleReport})
    }

    createPDFWithImage(createPDF)
}