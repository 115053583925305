<template>
    <FormABM
        :show="show"
        titulo="Artículos"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"       
        :modoSeleccion="modoSeleccion"
        :multiple="multiple"
        :filtro="filtro"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar">

        <template v-slot:CompFiltros>
            <div class="d-flex">
                <v-col cols="11" class="pa-0">
                    <SelectRubrosSubrubros :rubrosSeleccionados.sync="filtro.idsRubros" :subrubrosSeleccionados.sync="filtro.idsSubrubros" multiple/>    
                </v-col>
                <v-col cols="1" class="pa-0 pl-1">
                    <SelectTriple v-model="filtro.activo" label="Activo"/>
               </v-col>
            </div>    
        </template>  
        
        <template v-slot:FormEdicion>
            <Articulo v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
        </template>    
    </FormABM>    
</template>

<script>

    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {articulosApi} from '@/api/ArticulosApi'
    import FormABM from '@/components/base/form/FormABM.vue';
    import Articulo from './Articulo.vue';
    import SelectRubrosSubrubros from '@/components/select/SelectRubrosSubrubros.vue';
    import SelectTriple from '@/components/base/SelectTriple.vue';

    export default {

        mixins: [FormABMMixin],

        components: {
            FormABM, 
            Articulo,
            SelectRubrosSubrubros,
            SelectTriple,
        },

        data: () => ({
            endPointBuscarVarios: articulosApi.buscarVarios,
            endPointEliminarVarios: articulosApi.eliminarVarios,
            headers: [
                {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
                {text: "Código", value: "codigo", groupable: false},
                {text: "Nombre", value: "nombre", groupable: false},
                {text: "Rubro", value: "subrubro.rubro.nombre", filterable: false},
                {text: "SubRubro", value: "subrubro.nombre", filterable: false},
                {text: "Activo", value: "activo", filterable: false, groupable: false, tipo: "boolean"}],    
            filtro:{
                activo: true,
                idsRubros: [],
                idsSubrubros: [],
            }
        }),
    }
</script>