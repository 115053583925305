<template>
    <FormEdicion
        v-model="show"
        titulo="Acción"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :endPointAuditoria="endPointAuditoria"
        :tabla="tabla"
        maxWidth="800px"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <div class="d-flex">    
                <InputFecha v-model="tabla.fecha" 
                    autofocus
                    obligatorio/>
                <SelectTiposAccionesPersonas v-model="tabla.idtipoaccion"
                    obligatorio
                    class="pl-1"/>
                <SelectUsuarios v-model="tabla.idusuario"
                    readonly
                    class="pl-1"/>
                <SelectSectores v-model="tabla.idsector"
                    obligatorio
                    class="pl-1"/>
            </div>
            <SelectPersonas v-model="tabla.idpersona"
                habilitaAgregar
                habilitaModificar 
                obligatorio/>
            <Input v-model="tabla.titulo"
                label="Título"
                obligatorio/>
            <InputTextArea v-model="tabla.detalle"
                label="Detalle"/>
        </template>    
    </FormEdicion>
</template>

<script>

    import {mapState} from 'vuex';
    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {accionesPersonasApi} from '../../api/AccionesPersonasApi';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import InputFecha from '../../components/input/InputFecha.vue';
    import Input from '@/components/input/Input.vue';
    import SelectPersonas from '../../components/select/SelectPersonas.vue';
    import InputTextArea from '../../components/input/InputTextArea.vue';
    import SelectUsuarios from '../../components/select/SelectUsuarios.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue';
    import SelectTiposAccionesPersonas from '../../components/select/SelectTiposAccionesPersonas.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            InputFecha,
            SelectPersonas,
            InputTextArea,
            SelectUsuarios,
            SelectSectores,
            SelectTiposAccionesPersonas,
        },

        data: () => ({
            endPointBuscar: accionesPersonasApi.buscar,
            endPointGuardar: accionesPersonasApi.guardar,
            endPointEliminar: accionesPersonasApi.eliminarVarios,
            endPointAuditoria: accionesPersonasApi.auditoria,
            tabla: {
                fecha: null,
                titulo: "",
                idpersona: null,
                detalle: "",
                idusuario: null,
                idsector: null,
                idtipoaccion: null,
            },
        }),

        computed: {
            ...mapState({
                idusuario: state => state.usuarioModule.id,
            }),       
        },

        created(){
            this.tabla.idusuario = this.idusuario
            this.tabla.fecha = this.$diaActual()
        }
    }
</script>