<template>
    <div v-show="show">
        <Alert v-model="showAlert" :text="mensajeError"/>          
        <div class="d-flex">
            <Calendar 
                :endPointBuscarVarios="endPointBuscarVariosCalendario"
                :actualizarBuscar="actualizar"
                :filtro="filtro"
                :start="filtro.desde"
                @changeCalendar="changeCalendar"
                @mostrarEvento="mostrarEvento">
                <template v-slot:Otros>
                    <SelectSectoresTramites 
                        :sectoresSeleccionados.sync="filtro.idSector" 
                        :tramitesSeleccionados.sync="filtro.idTramite"
                        :outlined="true"
                        :hidedetails="true"
                        :dense="true"
                        :showTramite="false"
                        @valorSecundarioSector="valorSecundarioSector"/>
                    <v-spacer/>
                </template>  
                <template v-slot:Otros2> 
                    <v-btn
                        outlined
                        depressed
                        :disabled="disabledProximo"
                        v-show="permiteModificar"
                        @click="proximoTurno"
                        class="ml-2">
                        Próximo Turno
                    </v-btn>     
                    <SelectCalendarios v-model="filtro.idsCalendarios" class="ml-2" 
                        :idsSectores="filtro.idSector" 
                        :outlined="true" 
                        :hidedetails="true"
                        :multiple="true" 
                        :seleccionarTodosAlSetear="true"
                        :dense="true"/>
                </template>
            </Calendar>
            <v-navigation-drawer
                v-show="mostrarGraficos"
                right
                :clipped="$vuetify.breakpoint.lgAndUp">
                <v-col class="pa-0 mb-2">
                    <div v-show="dataEstados.length != 0">
                        <ChartDonut :data="dataEstados"/>
                        <ChartRadialBar :data="dataOcupacionDisponibles" tipo="disponibles"/>
                        <ChartRadialBar :data="dataOcupacionReservados" tipo="reservados"/>
                        <ChartRadialBar :data="dataAsistencia" tipo="asistencia"/>
                        <ChartDonut v-show="dataOrigenes.length != 0" :data="dataOrigenes"/>
                    </div>    
                </v-col>
            </v-navigation-drawer>    
        </div>
    </div>    
</template>

<script>

    import {mapState} from 'vuex';
    import {turnosApi} from '../../api/TurnosApi';
    import Calendar from '../../components/base/Calendar.vue'
    import SelectSectoresTramites from '../../components/select/SelectSectoresTramites.vue';
    import ChartDonut from '../../components/graphics/ChartDonut.vue'
    import ChartRadialBar from '../../components/graphics/ChartRadialBar.vue';
    import SelectCalendarios from '../../components/select/SelectCalendarios.vue';

    export default {

        components: {
            Calendar,
            SelectSectoresTramites,
            ChartDonut,
            ChartRadialBar,
            SelectCalendarios,
        },

        data: () => ({
            endPointBuscarVariosCalendario: turnosApi.buscarVariosCalendario,
            headers: [],
            showAlert: false,
            filtro: {
                idSector: null,
                idTramite: null,
                incluirFeriados: new Boolean(true),
                idsCalendarios: null,
                desde: null,
                hasta: null,
            },
            mensajeError: "",
            dataEstados: [],
            dataOrigenes: [],
            dataOcupacionDisponibles: null,
            dataOcupacionReservados: null,
            dataAsistencia: null,
        }),

        props:{
            show: {type: Boolean,
                default: true},
            mostrarGraficos: {type:Boolean,
                default: false},
            actualizar: {type: Boolean,
                default: false}
        },

        computed: { 
            disabledProximo: {
                get(){
                    return (this.filtro.idSector ? false : true)
                },
                set(){}
            },
            ...mapState({
                permiteModificar: state => state.usuarioModule.permisos.turno.modificar,
            }),
        },

        created(){
            //SE SETAN VALORES PARA EVITAR QUE SE CONSULTE DATOS AL SERVIDOR 2 VECES 
            if (this.$store.state.fechasModule.desde){
                this.filtro.desde = this.$store.state.fechasModule.desde
                this.filtro.hasta = this.$store.state.fechasModule.hasta           
            }
            this.filtro.idSector = this.$store.state.sectoresModule.idsSectores[0]    
        },

        watch: {  
            mostrarGraficos(val){
                if (val) this.obtenerDatosTotales()
            },
        },

        methods: { 
            changeCalendar(val){
                this.filtro.desde = val.start.date
                this.filtro.hasta = val.end.date
                this.obtenerDatosTotales()
                this.$store.dispatch("setFechaDesde",this.filtro.desde)
                this.$store.dispatch("setFechaHasta",this.filtro.hasta)
            },

            mostrarEvento(event){
                if (event.id){
                    this.$emit("showAsignar",event.id);
                }else if(event.otros.idferiado){
                    this.$emit("showFeriado",event.otros.idferiado);
                }else{
                    this.$emit("showEvento",event.otros.iddetallecalendario);
                }
            },

            async proximoTurno(){
                try {
                    this.$store.commit("loadingModule/setIsLoading");
                    const response = await turnosApi.siguienteDisponible(this.filtro)
                    if (response.data){
                        this.mostrarEvento(response.data)
                    }else{
                        this.mensajeError = "No existen turnos disponibles."
                        this.showAlert = true
                    }
                } catch (error) {
                    console.log(error);
                } finally{
                    this.$store.commit("loadingModule/setIsLoading");
                }   
                
            },

            async obtenerDatosTotales() {
                if (this.mostrarGraficos){                    
                    try {
                        const response = await turnosApi.estadisticas({rangoFecha:[this.filtro.desde, this.filtro.hasta], idSector: this.filtro.idSector})
                        this.dataEstados = response.data.estados
                        this.dataOrigenes = response.data.origenes
                        this.dataOcupacionDisponibles = response.data.ocupaciondisponibles
                        this.dataOcupacionReservados = response.data.ocupacionreservados
                        this.dataAsistencia = response.data.asistencia

                    } catch (error) {
                        console.log(error);
                    }
                }
            },

            valorSecundarioSector(val){
                this.$emit("valorSecundarioSector",val);
            }
        }
    }

</script>