import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

import './filters.js';

import VueMask from 'v-mask'

import alert from './components/base/notifications/Alert.vue'
import confirm from './components/base/notifications/Confirm.vue'
import VueMeta from 'vue-meta'
import * as utils from './utils'

Vue.component('Alert', alert)
Vue.component('Confirm', confirm)

Vue.use(VueMask,{
  vuetify,
});

Vue.use(VueTelInputVuetify,{
  vuetify,
});

Vue.use(VueMeta)

Object.keys(utils).forEach(key => {
  Vue.prototype[`$${key}`] = utils[key]
})

require('./plugins/fontawesome');

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import firebase from "firebase/compat/app"
import 'firebase/compat/storage'
const configFirebase = {
  apiKey: "AIzaSyC4Aihd-oCmJvWtbPPVUd7GOOHRk2Zj5i8",
  authDomain: "munitotoras-f89a6.firebaseapp.com",
  projectId: "munitotoras-f89a6",
  storageBucket: "munitotoras-f89a6.appspot.com",
  messagingSenderId: "728071558027",
  appId: "1:728071558027:web:9e4a91f4f76cd2b225d58e",
  measurementId: "G-1D23KDXXMF"
};
firebase.initializeApp(configFirebase)

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
