<template>
    <Select 
        v-model="itemsSeleccionados"
        :titulo="titulo" 
        :tituloPlural="tituloPlural" 
        :endPointSelect="endPointSelect"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :readonly="readonly">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {origenesApi} from '@/api/OrigenesApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: origenesApi.select,
        }),

        props: {
            titulo: {type: String,
                default: "Origen"},
            tituloPlural: {type: String,
                default: "Orígenes"},
        }
    }    
</script>