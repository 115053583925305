<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Alícuota IVA" 
        tituloPlural="Alícuotas IVA" 
        :endPointSelect="endPointSelect"
        :obligatorio="obligatorio">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {alicuotasIvaApi} from '@/api/AlicuotasIvaApi'
    import Select from '@/components/base/Select.vue';
    import AlicuotasIva from '../../views/Alicuotas IVA/AlicuotasIva.vue';
    
    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            AlicuotasIva,
        },

        data: () => ({
            endPointSelect: alicuotasIvaApi.buscarVarios,
        }),
    }    
</script>