<template>
    <FormEdicion
        v-model="show"
        titulo="Sector"
        maxWidth="30%"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                autofocus
                label="Nombre"
                obligatorio/>
            <Tab v-model="tab" :tabs="tabs">               
                <template v-slot:datos>
                     <v-card>                    
                        <v-card-text class="px-0 pt-2">
                            <Input v-model="tabla.telefono"
                                label="Teléfono"/>
                            <Input v-model="tabla.email"
                                label="Email"
                                tipo="mail"/>
                            <Input v-model="tabla.direccion"
                                label="Dirección"/>
                            <SelectLocalidades v-model="tabla.idlocalidad"/>
                            <SelectTiposSector v-model="tabla.idtiposector"/>    
                        </v-card-text>
                     </v-card>
                </template>
                <template v-slot:turnos>
                     <v-card>                    
                        <v-card-text class="px-0 pt-1">
                            <div class="d-flex">
                                <SwitchCustom v-model="tabla.activoturnos" label="Activo"/> 
                                <v-spacer/>
                                <SwitchCustom v-model="tabla.activoturnosexterno" label="Activo externo"/> 
                            </div>
                            
                            <select-tipos-persona v-model="tabla.tipospersonasectores" multiple/>
                            <Input v-model="tabla.domicilioturno"
                                label="Dirección"/>
                            <Input v-model="tabla.lugarturno"
                                label="Lugar"/>
                            <InputTextArea v-model="tabla.leyendaturno"
                                label="Leyenda"/>
                            <SelectTramites v-model="tabla.tramitessectores" multiple seleccionarTodos/>

                            <SwitchCustom v-model="tabla.muestraobsturnosext" label="Observaciones habilitadas"/>   

                            <div class="d-flex" v-if="tabla.muestraobsturnosext"> 
                                <SwitchCustom class="pa-0" v-model="tabla.obsobligatoriaturnosext" label="Observaciones obligatorias"/>
                                <v-spacer/>
                                <Input v-model="tabla.cantidadminimacaracteresobs"
                                    v-show="tabla.obsobligatoriaturnosext"
                                    label="Cantidad mínima caracteres"
                                    tipo="number"/>
                            </div>

                        </v-card-text>
                    </v-card>
                </template>
                <template v-slot:consultas>
                     <v-card>                    
                        <v-card-text class="px-0 pt-1">
                            <SwitchCustom v-model="tabla.activoconsultas" label="Activo"/> 
                        </v-card-text>
                    </v-card>
                </template>
            </Tab>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {sectoresApi} from '../../api/SectoresApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';
    import InputTextArea from '../../components/input/InputTextArea.vue';
    import SelectTramites from '../../components/select/SelectTramites.vue';
    import SelectTiposSector from '../../components/select/SelectTiposSector.vue';
    import Tab from '../../components/base/Tab.vue';
    import SwitchCustom from '../../components/base/Switch.vue';
    import SelectLocalidades from '../../components/select/SelectLocalidades.vue';
    import SelectTiposPersona from '../../components/select/SelectTiposPersona.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            InputTextArea,
            SelectTramites,
            SelectTiposSector,
            Tab,
            SwitchCustom,
            SelectLocalidades,
            SelectTiposPersona,
        },

        data: () => ({
            endPointBuscar: sectoresApi.buscar,
            endPointGuardar: sectoresApi.guardar,
            endPointEliminar: sectoresApi.eliminarVarios,
            tabla: {
                nombre: "",
                direccion: "",
                idlocalidad: null,
                lugarturno: "",
                domicilioturno: "",
                telefono: "",
                leyendaturno: "",
                tramitessectores: [],
                tipospersonasectores: [],
                muestraobsturnosext: false,
                idtiposector: null,
                obsobligatoriaturnosext: false,
                cantidadminimacaracteresobs: 0,
                activoturnos: false,
                activoturnosexterno: false,
                activoconsultas: false,
            },
            tabs: [
                {nombre: 'datos', habilitado: true, show: true},
                {nombre: 'turnos', habilitado: true, show: true},
                {nombre: 'consultas', habilitado: true, show: true},
            ],
            tab:0,
        }),
        
        watch:{
            'tabla.muestraobsturnosext'(val){ 
                if(!val){
                    this.tabla.obsobligatoriaturnosext = false
                }
            },
            'tabla.obsobligatoriaturnosext'(val){ 
                if(!val){
                    this.tabla.cantidadminimacaracteresobs = 0
                }
            },
        },

    }
</script>