<template>
  <FormABM
    :show="show"
    titulo="Empresas"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion="modoSeleccion"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">
    
    <template v-slot:FormEdicion>
      <Empresa v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
    </template>    
    
  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {empresasApi} from '@/api/EmpresasApi';
  import FormABM from '@/components/base/form/FormABM.vue';
  import Empresa from './Empresa.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      Empresa,
    },

    data: () => ({
      endPointBuscarVarios: empresasApi.buscarVarios,
      endPointEliminarVarios: empresasApi.eliminarVarios,
      headers: [
        {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        {text: "Nombre", value: "nombre", groupable: false},
        {text: "Cond. IVA", value: "condicioniva.nombre", groupable: false}, 
        {text: "Documento", value: "documento", groupable: false}, 
      ],
    }),

  }
</script>