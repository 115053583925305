import axios from 'axios'
import { decimales } from '@/utils';
const controller = "alicuotasiva/"

class AlicuotasIvaApi {
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    async buscarVarios(){
        let response = await axios.get(controller);
        for (let index=0; index < response.data.length; index++) {
            response.data[index].nombre = decimales(response.data[index].nombre) + "%"
        }
        return response
    }
}

export const alicuotasIvaApi = new AlicuotasIvaApi();