<template>
  <FormABM
    :show="show"
    titulo=""
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :endPointImprimir="endPointImprimir"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion="modoSeleccion"
    :multiple="multiple"
    :filtro="filtro"
    :habilitaFiltrar="false"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar"
    @imprimirItem="imprimir">

    <template v-slot:FormEdicion>
      <Comprobante v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/> 
    </template>    
  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {ImprimirComprobante} from '../../shared/ImpresionComprobante';
  import {comprobantesApi} from '@/api/ComprobantesApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import Comprobante from './Comprobante.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      Comprobante,
    },

    data: () => ({
      endPointBuscarVarios: comprobantesApi.buscarVarios,
      endPointEliminarVarios: comprobantesApi.eliminarVarios,
      endPointImprimir: comprobantesApi.imprimir,
      headers: [
        {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        {text: "Operación", value: "tipocomprobante.tipotipocomprobante.nombre"},
        {text: "Fecha", value: "fecha", groupable: false, tipo:"fecha"},
        {text: "Comprobante", value: "comprobante", groupable: false},
        {text: "Importe", value: "total", groupable: false, tipo:"numero"},
        {text: "Persona", value: "persona.nombre"},
        {text: "Solicitante", value: "solicitante.nombre"},
        {text: "Sector", value: "sector.nombre"}],
    }),

    props: {
      filtro: {type: Object,
        default: () => ({})
      },
    },

    methods: {
      imprimir(data){
        ImprimirComprobante(data)
      }
    }

  }
</script>