<template>
  <FormABM
    :show="show"
    titulo="Feriados"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :permiteAgregar="permiteAgregar"
    :permiteModificar="permiteModificar"
    :permiteEliminar="permiteEliminar"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion="modoSeleccion"
    :multiple="multiple"
    :filtro="filtro"
    sortBy="fecha"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar"> 
    
    <template v-slot:CompFiltros>
      <InputFechaRango v-model="filtro.rangoFecha"/>  
    </template>  

    <template v-slot:FormEdicion>
      <Feriado v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
    </template>   
  </FormABM>    
</template>

<script>

  import {mapState} from 'vuex';
  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {feriadosApi} from '@/api/FeriadosApi';
  import FormABM from '@/components/base/form/FormABM.vue';
  import Feriado from './Feriado.vue';
  import InputFechaRango from '@/components/input/InputFechaRango.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      Feriado,
      InputFechaRango,
    },

    data: () => ({
      endPointBuscarVarios: feriadosApi.buscarVarios,
      endPointEliminarVarios: feriadosApi.eliminarVarios,
      headers: [
        {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        {text: "Fecha", value: "fecha", groupable: false, tipo:"fecha"},
        {text: "Detalle", value: "nombre", groupable: false}],
      filtro:{
        rangoFecha: []
      }
    }),

    computed: {
      ...mapState({
          permiteAgregar: state => state.usuarioModule.permisos.feriado.agregar,
          permiteModificar: state => state.usuarioModule.permisos.feriado.modificar,
          permiteEliminar: state => state.usuarioModule.permisos.feriado.eliminar,
      }),       
    },

    created() {
      this.filtro.rangoFecha = this.$rangoSeteado()
    },

  }
</script>