<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Tipo Operación" 
        tituloPlural="Tipos Operación" 
        :endPointSelect="endPointSelect"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :filtro="filtro"
        :clearable="clearable"
        @valorSecundario="valorSecundario">
    </Select>
</template>

<script>
    import { SelectMixin } from '@/components/mixins/SelectMixin';
    import { tiposTiposComprobantesApi } from '../../api/TiposTiposComprobantesApi';
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: tiposTiposComprobantesApi.select,
        }),
    }    
</script>