<template>
    <ComboBox
        v-model="itemsSeleccionados"
        titulo="Etiquetas" 
        :endPointSelect="endPointSelect"
        :filtro="filtro"
        @searchInput="searchInput">
    </ComboBox>
</template>

<script>

    import {normasApi} from '../../api/NormasApi'
    import ComboBox from '../base/ComboBox.vue'

    export default {
        
        components: {
            ComboBox,
        },

        data: () => ({
            endPointSelect: normasApi.selectEtiquetas,
            filtro:{
                busqueda:"",
                id:0,
            },    
            //usarSearchInput:false,
        }),
        
        props: {
            value:{type:[Array,Number],
                default(){return []}},
        },

        computed: {
            itemsSeleccionados: {
                get() {
                    
                    // if (this.value != null && this.value != 0){
                    //     this.filtro.id = this.value
                    //     this.usarSearchInput = false
                    // }
                    
                    return this.value
                },
                set(value) {  
                    this.$emit("input",value)
                }
            },
        },

        methods: {
            searchInput(val){
                //if (this.usarSearchInput){
                    this.filtro.busqueda = val    
                //}else{
                //    this.usarSearchInput = true
                //}    
            },


            // seleccionar(val){
            //     this.itemsSeleccionados.length = 0
            //     for (var i in val) {
            //         this.itemsSeleccionados.push(val[i].id)
            //     }
            // }
        },

    }
</script>