<template>
    <FormABM 
        :show="show"
        :titulo="titulo"    
        :permiteAgregar="permiteAgregarTurno"   
        :permiteEliminar="false"
        @actualizar="actualizar">
        
        <template v-slot:BotonesAdicionales>
            <Button tipo="calendario" :visible="!mostrarCalendario" @click="mostrar('calendario')"/>
            <v-menu offset-y>
                <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom :color="colorPrincipal">
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon
                                small 
                                v-bind="attrs"
                                v-on="{...tooltip,...menu }">
                                <v-icon class="material-icons-outlined">more_vert</v-icon>
                            </v-btn>
                        </template>
                        <span>Más</span>
                    </v-tooltip>
                </template>
                <v-list dense>
                    <v-list-item v-if="permiteAgregarEventoCalendario" @click="showEvento(0)">
                        <v-list-item-icon>
                            <v-icon>add</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Agregar Evento</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="permiteAgregarFeriado" @click="showFeriado(0)">
                        <v-list-item-icon>
                            <v-icon>add</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Agregar Feriado</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="!mostrarListado" @click="mostrar('listado')">
                        <v-list-item-icon>
                            <v-icon>list_alt</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Listado</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="permiteVerGraficosTurnos && mostrarCalendario" @click="mostrarGraficos=!mostrarGraficos">
                        <v-list-item-icon>
                            <v-icon>data_usage</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{descripcionMenuGraficos}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-show="permiteVerEstadisticasTurnos && !mostrarEstadisticas" @click="mostrar('estadisticas')">
                        <v-list-item-icon>
                            <v-icon>bar_chart</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>    
                            <v-list-item-title>Estadísticas</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider v-show="tipoSectorLicencias"></v-divider>
                    <v-list-item v-show="tipoSectorLicencias">
                        <v-list-item-icon>
                            <v-icon>paid</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <a  class="text-decoration-none text--primary"
                                    href="https://www.santafe.gov.ar/cenat/"
                                    target="_blank">
                                    Boleta de Pago de CENAT
                                </a>
                            </v-list-item-title>
                        </v-list-item-content>  
                    </v-list-item>
                    <v-list-item v-show="tipoSectorLicencias">
                        <v-list-item-icon>
                            <v-icon>article</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <a  class="text-decoration-none text--primary"
                                    href="https://www.anses.gob.ar/consulta/constancia-de-cuil"
                                    target="_blank">
                                    Constancia de CUIL - ANSES
                                </a>
                            </v-list-item-title>
                        </v-list-item-content>  
                    </v-list-item>

                    <v-divider v-show="tipoSectorLicencias"></v-divider>

                    <v-list-item v-show="tipoSectorLicencias">
                        <v-list-item-icon>
                            <v-icon>download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <a class="text-decoration-none text--primary" @click="impresionInstructivo(idTramiteRenovacion)">
                                    Instructivo Renovación
                                </a>
                            </v-list-item-title>
                        </v-list-item-content>  
                    </v-list-item>
                    <v-list-item v-show="tipoSectorLicencias">
                        <v-list-item-icon>
                            <v-icon>download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <a class="text-decoration-none text--primary" @click="impresionInstructivo(idTramitePrimeraVez)">
                                    Instructivo Primera vez
                                </a>
                            </v-list-item-title>
                        </v-list-item-content>  
                    </v-list-item>
                    <v-list-item v-show="tipoSectorLicencias">
                        <v-list-item-icon>
                            <v-icon>download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <a class="text-decoration-none text--primary" @click="impresionInstructivo(idTramiteNuevaCategoria)">
                                    Instructivo Renovación con nueva categoría
                                </a>
                            </v-list-item-title>
                        </v-list-item-content>  
                    </v-list-item>
                    <v-list-item v-show="tipoSectorLicencias">
                        <v-list-item-icon>
                            <v-icon>download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <a class="text-decoration-none text--primary" @click="impresionInstructivo(idTramiteExtravio)">
                                    Instructivo Extravío
                                </a>
                            </v-list-item-title>
                        </v-list-item-content>  
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>

        <template v-slot:FormEdicion>
            <TurnosCalendario :show="mostrarCalendario" 
                :actualizar="actualizarBuscar" 
                :mostrarGraficos="mostrarGraficos"
                @showAsignar="showAsignar"
                @showFeriado="showFeriado"
                @showEvento="showEvento"
                @valorSecundarioSector="valorSecundarioSector"/>
            <TurnoCrear v-model="showFormEdicion" :id=0 @actualizoFormEdicion="actualizoFormEdicion"/>   
            <TurnoAsignar v-model="mostrarAsignar" :id="idTurno" @actualizoFormEdicion="actualizoFormEdicion"/>
            <DetalleCalendario v-model="mostrarEvento" :id="idEvento" @actualizoFormEdicion="actualizoFormEdicion"/>
            <Feriado v-model="mostrarFeriado" :id="idFeriado" @actualizoFormEdicion="actualizoFormEdicion"/>
            <TurnosListado :show="mostrarListado"/> 
            <TurnosEstadisticas :show="mostrarEstadisticas"/>
        </template>    

    </FormABM>    
</template>

<script>

    import {mapState} from 'vuex';
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {sectoresApi} from '../../api/SectoresApi';
    import {tramitesApi} from '../../api/TramitesApi'
    import {imprimirTurno} from '@/shared/ImpresionTurno'
    import FormABM from '@/components/base/form/FormABM.vue';
    import Button from '../../components/base/button/Button.vue';
    import TurnoCrear from './TurnoCrear.vue'
    import TurnoAsignar from './TurnoAsignar.vue';
    import TurnosCalendario from './TurnosCalendario.vue'
    import TurnosListado from './TurnosListado.vue'
    import TurnosEstadisticas from './TurnosEstadisticas.vue'
    import Feriado from '../Feriados/Feriado.vue';
    import DetalleCalendario from '../Calendarios/DetalleCalendario.vue'
    import Calendario from '../Calendarios/Calendario.vue';

    export default {

        mixins: [FormABMMixin],

        components: {
            FormABM,
            TurnoCrear,
            TurnoAsignar,
            TurnosCalendario,
            TurnosListado, 
            TurnosEstadisticas,
            Button,
            Feriado,
            DetalleCalendario,
            Calendario,
        },

        data: () => ({
            titulo: '',
            mostrarCalendario: false,
            mostrarListado: false,
            mostrarGraficos: false,
            mostrarEstadisticas: false,
            mostrarAsignar: false,
            mostrarFeriado: false,
            mostrarEvento: false,
            idTurno: 0,
            idFeriado: 0,
            idEvento: 0,
            tipoSectorLicencias: false,
            idSector: 0,
            idTramiteRenovacion: 4,
            idTramitePrimeraVez: 14,
            idTramiteNuevaCategoria: 24,
            idTramiteExtravio: 34,
        }),

        computed: {
            ...mapState({
                permiteAgregarTurno: state => state.usuarioModule.permisos.turno.agregar,
                permiteAgregarFeriado: state => state.usuarioModule.permisos.feriado.agregar,
                permiteAgregarEventoCalendario: state => state.usuarioModule.permisos.eventoCalendario.agregar,
                permiteVerGraficosTurnos: state => state.usuarioModule.permisos.turno.graficos,
                permiteVerEstadisticasTurnos: state => state.usuarioModule.permisos.turno.estadisticas,
                colorPrincipal: state => state.colorPrincipal,
            }),
            descripcionMenuGraficos(){
                if (!this.mostrarGraficos) return "Mostrar totales"
                return "Ocultar totales"
            }
        },

        created(){
            this.mostrar('calendario')
        },

        methods: {
            mostrar(tipo){
                this.mostrarCalendario = false
                this.mostrarListado = false
                this.mostrarEstadisticas = false
                this.mostrarGraficos = false
                switch (tipo){
                    case 'calendario' : this.mostrarCalendario = true, this.titulo = "Calendario de Turnos"
                        break;
                    case 'listado' : this.mostrarListado = true, this.titulo = "Listado de Turnos"
                        break;
                    case 'estadisticas' : this.mostrarEstadisticas=true, this.titulo = "Estadísticas de Turnos"
                        break;
                }
            },
            showAsignar(id){
                this.idTurno = id
                this.mostrarAsignar = true
            },
            showEvento(id){
                this.idEvento = id
                this.mostrarEvento = true
            },
            showFeriado(id){
                this.idFeriado = id
                this.mostrarFeriado = true
            },
            async impresionInstructivo(idTipoTramite){
                const {data: sector} = await sectoresApi.buscar(this.idSector)
                const {data: tramite} = await tramitesApi.buscar(idTipoTramite)
                const data = {
                    turno: {
                        sector: {
                            nombre: sector.nombre,
                            idtiposector: sector.idtiposector
                        }
                    },
                    tramite: {
                        nombre: tramite.nombre,
                        idtipotramite: tramite.idtipotramite,
                    }
                };
                imprimirTurno(data,"Instructivo")
            },
            valorSecundarioSector(val){
                this.idSector = val.id
                if (val && val.idtiposector === 1){
                    this.tipoSectorLicencias = true
                }else{
                    this.tipoSectorLicencias = false
                }
            },
        },    
    }
</script>