import axios from 'axios'
import Decode from 'jwt-decode'
import Router from '../../router'

const state = {
    token: null,
    id: null,
    nombre: null,
    email: null,
    administrador: false,
    modulos: [],
    sectores: [],
    permisos: {
        turno: {
            agregar: false,
            modificar: false,
            eliminar: false,
            graficos: false,
            estadisticas: false,
            modificarEstado: false,
        },
        persona: {
            agregar: false,
            modificar: false,
            eliminar: false,
            visualizar: false,
        },
        feriado: {
            agregar: false,
            modificar: false,
            eliminar: false,
        },
        calendario: {
            agregar: false,
            modificar: false,
            eliminar: false,
            seleccionar: false,
        },
        eventoCalendario: {
            agregar: false,
            modificar: false,
            eliminar: false,
        }
    },
};

const actions = { 
    async login({commit},credenciales){
        // try {
        //     const response = await axios.post("login",{
        //         email:credenciales.email,
        //         password:credenciales.password
        //     })

        //     let token = response.data.access_token

        //     commit("setToken",token)  
        //     commit("setUsuario",Decode(token))
        //     localStorage.setItem('access_token',token);

        // } catch (error) {
        // }
        
        return new Promise((resolve, reject) => {
            axios.post("login",{
                email:credenciales.email,
                password:credenciales.password})
            .then((response) => {
                let token = response.data.access_token
                commit("setToken",token)  
                commit("setUsuario",Decode(token))
                commit("snackbarModule/setSnack",{accion:'Otra',message:'Acceso correcto.'})
                localStorage.setItem('access_token',token);
                resolve(response)
            })
            .catch((ex) => {
                reject(ex)
            })
        })

    },

    autoLogin({commit}){
      let token = localStorage.getItem("access_token")
      if (token){
        commit("setToken",token)  
        commit("setUsuario",Decode(token))
        Router.push({name: 'home'})
      }
    },

    logout({commit}){
      commit("setToken",null)  
      commit("setUsuario",null)
      localStorage.removeItem("access_token")
      Router.push({name:'login'})
    },
};

function tienePermiso(usuario,nroPermiso) {
    return usuario && (usuario.administrador || usuario.permisos.includes(nroPermiso));
}

const mutations = {
    setToken:(state,token)=>(
        state.token = token
    ),
    setUsuario:(state,usuario)=>(
        state.id = usuario ? usuario.id : null,
        state.nombre = usuario ? usuario.nombre : null,
        state.email =  usuario ? usuario.email : null,
        state.administrador = usuario ? usuario.administrador : null,
        state.modulos = usuario ? usuario.modulos : [],
        state.sectores =  usuario ? usuario.sectores : [],
        state.permisos.turno.agregar = tienePermiso(usuario,2),
        state.permisos.turno.modificar = tienePermiso(usuario,3),
        state.permisos.turno.eliminar = tienePermiso(usuario,4), 
        state.permisos.persona.agregar = tienePermiso(usuario,6),
        state.permisos.persona.modificar = tienePermiso(usuario,7),
        state.permisos.persona.eliminar = tienePermiso(usuario,8),
        state.permisos.feriado.agregar =  tienePermiso(usuario,10),
        state.permisos.feriado.modificar = tienePermiso(usuario,11),
        state.permisos.feriado.eliminar = tienePermiso(usuario,12),
        state.permisos.calendario.agregar = tienePermiso(usuario,14),
        state.permisos.calendario.modificar = tienePermiso(usuario,15),
        state.permisos.calendario.eliminar = tienePermiso(usuario,16),
        state.permisos.eventoCalendario.agregar = tienePermiso(usuario,17),
        state.permisos.eventoCalendario.modificar = tienePermiso(usuario,18),
        state.permisos.eventoCalendario.eliminar = tienePermiso(usuario,19),
        state.permisos.persona.visualizar = tienePermiso(usuario,20),
        state.permisos.turno.graficos = tienePermiso(usuario,21),
        state.permisos.turno.estadisticas = tienePermiso(usuario,22),
        state.permisos.turno.modificarEstado = tienePermiso(usuario,23), 
        state.permisos.calendario.seleccionar = tienePermiso(usuario,24)
    ),
};

export default {
    state,
    actions,
    mutations,
}