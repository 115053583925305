<template>
    <div class="d-flex">
        <div class="text-caption">
            Permisos
        </div>
        <v-treeview v-model="seleccionados" 
            v-if="mostrar" 
            selectable
            dense
            transition
            :items="items"/>
    </div>
</template>

<script>

    import {permisosApi} from '../../api/PermisosApi'
    export default {

        data: () => ({
            items: [],
            mostrar: false,
        }),

        props:{
            value:{type: Array},
        },

        computed: {
            seleccionados: {
                get() {
                    return this.value;
                },
                set(value) {    
                    this.$emit("input",value);
                }
            },
        },

        created() {
            this.obtenerDatos()
        },

        methods:{ 
            async obtenerDatos() {
                try {
                    const {data} = await permisosApi.select()
                    let items = [...data]
                    this.items.length = 0
                    for (let i = 0; i < items.length; i++) {
                        if(items[i].idpadre === 0){
                            this.items.push({
                                id: items[i].id,
                                name: items[i].nombre,
                                children: [],
                            })
                        }else{
                            this.items[this.items.findIndex((element) => element.id === items[i].idpadre)].children.push({
                                id: items[i].id,
                                name: items[i].nombre,
                            })
                        }
                    }
                    this.mostrar = true;
                } catch (error) {
                    console.log(error);
                }
            }
        }

    }
</script>