<template>
    <v-switch 
        v-model="valor"
        v-bind="$attrs" 
        class="pa-0 ma-0 pt-1 px-2" 
        dense/>
</template>

<script>
    export default {
        props: {
            value: Boolean,
        },
        computed: {
            valor: {
                get() {return this.value},
                set(value) {this.$emit("input",value)}
            },
        }
    }
</script>
