// import axios from 'axios'
// const controller = "feriados/"

// export function guardar(datos){
//     if (datos.id){
//         return axios.put(controller,datos);
//     }else{
//         return axios.post(controller,datos);
//     }
// }

// export function eliminar(id){
//     return axios.delete(controller + id);
// }

// export function buscar(id){
//     return axios.get(controller + id);
// }

// export function buscarVarios(parametros){ 
//     return axios.get(controller,{params: {
//         desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
//         hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null
//     }})
// }

import axios from 'axios'
const controller = "feriados/"

class FeriadosApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){ 
        return axios.get(controller,{params: {
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
        },
    })}

}

export const feriadosApi = new FeriadosApi();