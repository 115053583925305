<template>
    <apexchart ref="apexChart" type="bar" :options="chartOptions" :series="chartSeries"/>
</template>

<script>
    export default {

        data: () => ({
            chartSeries:[],
            chartOptions: {
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded',
                    }, 
                },
                chart: {
                    stacked: true
                },
                toolbar: {
                    show: true
                },
                zoom: {
                    enabled: true
                },
                fill: {
                    opacity: 1
                },
                xaxis: {
                    categories: []
                }
            }
        }),

        props:{
            data: {type: Object}
        },

        watch:{
            data: {
                deep: true,
                handler(){
                    this.update()
                }
            },
        },

        methods: {
            update(){            
                this.chartSeries = [];
                this.chartOptions.xaxis.categories = [];

                this.$refs.apexChart.updateOptions({
                    xaxis:{
                        categories: this.data.series
                    }
                })

                if (this.data.series.length === 1){
                    for (let x = 0; x < this.data.datos.length; x++) {
                        this.chartSeries.push({
                            name: this.data.datos[x].nombre,
                            data: [this.data.datos[x].cantidad],
                            color: this.data.datos[x].color
                        })
                    }
                }else{
                    this.chartSeries.push({
                        name: 'Valor',
                        data: this.data.cantidades
                    });
                }

            }
        }

    }

</script>