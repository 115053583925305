<template>
    <FormEdicion
        v-model="show"
        titulo="SubRubro"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                autofocus
                label="Nombre"
                obligatorio/>

            <SelectRubros v-model="tabla.idrubro" obligatorio/>

            <Input v-model="tabla.detalle" label="Detalle"/>
           
            <div class="d-flex">
                <v-col cols="6" class="pa-0">
                    <SwitchCustom v-model="tabla.activo" label="Activo"/> 
                </v-col>         
                <v-col cols="6" class="pa-0"> 
                    <SwitchCustom v-model="tabla.predeterminado" label="Predeterminado"/>
                </v-col>  
            </div>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {subrubrosApi} from '../../../api/SubrubrosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue'
    import SelectRubros from '@/components/select/SelectRubros.vue';
    import SwitchCustom from '../../../components/base/Switch.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectRubros,
            SwitchCustom,
        },

        data: () => ({
            endPointBuscar: subrubrosApi.buscar,
            endPointGuardar: subrubrosApi.guardar,
            endPointEliminar: subrubrosApi.eliminarVarios,
            tabla:{
                idrubro: 0,
                nombre: "",
                detalle: "",
                activo: true,
                predeterminado: false,
            }
        }),

    }
</script>