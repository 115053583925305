<template>
    <div class="d-flex">
        <v-col class="pa-0">
            <SelectSectores
                :value="sectoresSeleccionados"
                :multiple="multiple"
                @valorSecundario="valorSecundarioSector"
                @input="$emit('update:sectoresSeleccionados',$event)"
            />
        </v-col>    
        <v-col class="pa-0 pl-1">
            <SelectInscripciones
                :value="eventosSeleccionados"
                :idsSectores="sectoresSeleccionados"
                :activo="soloActivos"
                :activoInscripciones="soloActivosInscripciones"
                :permiteInscripciones="soloPermiteInscripciones"
                :obligatorio="obligatorio"
                :multiple="multiple"
                @valorSecundario="valorSecundarioEvento"
                @input="$emit('update:eventosSeleccionados',$event)"
            />
        </v-col>
    </div>
</template>

<script>

    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import SelectSectores from './SelectSectores.vue'
    import SelectInscripciones from './SelectInscripciones.vue'

    export default {

        mixins: [SelectMixin],

        components: { 
            SelectSectores,
            SelectInscripciones,
        },

        props: {
            sectoresSeleccionados: {type: [Array,Number],
                default(){return[ ]}},
            eventosSeleccionados: {type: [Array,Number],
                default(){return[ ]}},
            soloActivos:{type:Boolean,default:false},
            soloActivosInscripciones:{type:Boolean,default:false},
            soloPermiteInscripciones:{type:Boolean,default:false},
        },

        methods: {
            valorSecundarioSector(val){
                this.$emit("valorSecundarioSector",val);
            },
            valorSecundarioEvento(val){
                this.$emit("valorSecundarioEvento",val);
            }
        }

    }   

</script>
