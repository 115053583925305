import axios from 'axios'
const controller = "localidades/"

class LocalidadesApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){        
        return axios.get(controller,{params: {
            idsProvincias: parametros.idsProvincias
        }})
    }

    async select(parametros){
        let response = await axios.get(controller + "select",{params:{
            ids: parametros.ids,
            busqueda: parametros.busqueda} 
        })
        for (let index=0; index < response.data.length; index++) {
            response.data[index].nombre = response.data[index].nombre + ' (' + response.data[index].provincia.nombre + ')'
        }
        return response
    }

    predeterminado(){
        return axios.get(controller + "predeterminado");
    }
}

export const localidadesApi = new LocalidadesApi();