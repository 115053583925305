<template>
  <FormABM
        :show="show"
        :titulo="titulo"  
        :permiteAgregar="false"
        :permiteEliminar="false"
        showFiltro
        @filtrar="filtrar">   

        <template v-slot:BotonesAdicionales>
            <Button tipo="listado" :visible="!mostrarListado" 
                @click="mostrar('listado')"/>
            <Button tipo="estadisticas" :visible="!mostrarEstadisticas" 
                @click="mostrar('estadisticas')"/>
        </template>

        <template v-slot:CompFiltros>
            <div class="d-flex">
                <v-col cols="3" class="pa-0 mr-1">
                    <input-fecha-rango v-model="filtro.rangoFecha"/>
                </v-col>
                <v-col cols="6" class="pa-0 mr-1">
                    <select-sectores-inscripciones :sectoresSeleccionados.sync="filtro.idsSectores" 
                        :eventosSeleccionados.sync="filtro.idsEventos" 
                        multiple/>
                </v-col>
                <v-col cols="3" class="pa-0 mr-1">
                    <select-origenes v-model="filtro.idsOrigenes" multiple/>
                </v-col>
            </div>
        </template>  

        <template v-slot:FormEdicion>   
            <detalle-inscripciones-listado :show="mostrarListado" :actualizarBuscarExterno="actualizarListado" :filtro="filtro"/>
            <detalle-inscripciones-estadisticas :show="mostrarEstadisticas" :actualizarBuscarExterno="actualizarEstadisticas" :filtro="filtro"/>
        </template> 
    </FormABM>    
</template>

<script>

    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import FormABM from '@/components/base/form/FormABM.vue';
    import Button from '../../components/base/button/Button.vue';
    import InputFechaRango from '../../components/input/InputFechaRango.vue';
    import SelectSectoresInscripciones from '../../components/select/SelectSectoresInscripciones.vue';
    import SelectOrigenes from '../../components/select/SelectOrigenes.vue';
    import DetalleInscripcionesListado from './DetalleInscripcionesListado.vue';
    import DetalleInscripcionesEstadisticas from './DetalleInscripcionesEstadisticas.vue';

    export default {
    
        mixins: [FormABMMixin],

        components: {
            FormABM, 
            Button,
            SelectSectoresInscripciones,
            InputFechaRango,
            SelectOrigenes,
            DetalleInscripcionesListado,
            DetalleInscripcionesEstadisticas,
        },

        data: () => ({
            titulo: '',
            mostrarListado: false,
            mostrarEstadisticas: false,
            actualizarListado: false,
            actualizarEstadisticas: false,
            filtro:{
                rangoFecha: [],
                idsSectores: null,
                idsEventos: null,
                idsOrigenes: null
            }
        }),

        created() {
            this.filtro.rangoFecha = this.$rangoSeteado()
            this.mostrar('listado')
        },

        methods: {
            mostrar(tipo){
                switch (tipo){
                    case 'listado': 
                        this.mostrarEstadisticas = false
                        this.titulo = "Listado de Inscripciones"
                        this.mostrarListado = true 
                        break;
                    case 'estadisticas': 
                        this.mostrarListado = false
                        this.titulo = "Estadísticas de Inscripciones"
                        this.mostrarEstadisticas = true
                        break;
                }
            },

            filtrar(){
                if (this.mostrarListado) this.actualizarListado = !this.actualizarListado   
                if (this.mostrarEstadisticas) this.actualizarEstadisticas = !this.actualizarEstadisticas  
            },
        },    
    }
</script>