<template>
  <FormABM
    :show="show"
    titulo="Historial"
    showFiltro
    :headers="headers"       
    :permiteAgregar="false"
    :permiteEliminar="false"
    :modoVisualizacion="true"
    :multiple="false"
    :itemsPadre="itemsPadre"
    @cerrar="cerrar"
    @filtrar="buscar">
    <template v-slot:CompFiltros>
      <div class = "d-flex">
        <InputFechaRango v-model="rangoFecha"/> 
      </div>
    </template>
  </FormABM>    
</template>

<script>

  import dayjs from "dayjs"
  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import FormABM from '@/components/base/form/FormABM.vue';
  import InputFechaRango from '../../components/input/InputFechaRango.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      InputFechaRango,
    },

    data: () => ({
      headers: [
        {text: "Operación", value: "operacion"},
        {text: "Fecha y hora", value: "fechahora", tipo:"fechahoraminuto"},
        {text: "Usuario", value: "usuario"},
        {text: "Dato", value: "campo"},
        {text: "Valor antiguo", value: "oldvalue", groupable: false},
        {text: "Valor nuevo", value: "newvalue", groupable: false},
      ],
      rangoFecha: [],
      actualizarBuscar: false,
    }),

    watch: {
      show: {
        immediate: true,
        handler(val){
          if (val){
            this.seteoFechas()
            this.buscar()
          }
        }
      }
    },

    methods: {
      seteoFechas(){
        const fechaActual = dayjs();
        const fechaAntigua = fechaActual.subtract(7,'day')
        this.rangoFecha = []
        this.rangoFecha[0] = dayjs(fechaAntigua).format("YYYY-MM-DD") 
        this.rangoFecha[1] = this.$diaActual()
      },
      
      buscar(){
        this.$emit("actualizoRangoFecha",this.rangoFecha)
        this.$emit('buscar')
      },

      cerrar(){
        this.$emit('cerrar')
      },
    },

  }
</script>