<template>
    <FormABM
        :show="show"
        titulo="Puntos de Venta"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"       
        :multiple="multiple"
        :filtro="filtro"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar"> 
        
        <template v-slot:CompFiltros>
            <SelectTriple v-model="filtro.activo" label="Activo"/>
        </template>

        <template v-slot:FormEdicion>
            <punto-venta v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/> 
        </template>   
    </FormABM>    
  </template>
  
  <script>
  
    import { FormABMMixin } from '@/components/mixins/FormABMMixin';
    import { puntosVentaApi } from '../../api/PuntosVentaApi';
    import FormABM from '@/components/base/form/FormABM.vue';
    import PuntoVenta from './PuntoVenta.vue';
    import SelectTriple from '@/components/base/SelectTriple.vue';
  
    export default {
      
      mixins: [FormABMMixin],
  
      components: {
        FormABM, 
        SelectTriple,
        PuntoVenta,
      },
  
      data: () => ({
        endPointBuscarVarios: puntosVentaApi.buscarVarios,
        endPointEliminarVarios: puntosVentaApi.eliminarVarios,
        headers: [
          {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
          {text: "Nombre", value: "nombre"},
          {text: "Activo", value: "activo", filterable: false, groupable: false, tipo: "boolean"}],
        filtro:{
          activo: true,
        }
      }),
  
    }
  </script>