<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Rubro" 
        tituloPlural="Rubros" 
        :endPointSelect="endPointSelect"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :actualizarSelect="actualizarSelect"
        :autofocus="autofocus"
        :filtro="filtro"
        @showFormABM="mostrarFormABM=true">
        <template v-slot:FormABM>
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <Rubros :modoSeleccion="true" 
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                    </v-card>
            </v-dialog>
        </template>
    </Select>
</template>

<script>

    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {rubrosApi} from '@/api/RubrosApi'
    import Select from '@/components/base/Select.vue';
    import Rubros from '../../views/Articulos/Rubros/Rubros.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            Rubros,
        },

        data: () => ({
            endPointSelect: rubrosApi.buscarVarios,
        }),
    }    
</script>