<template>
    <FormEdicion
        v-model="show"
        titulo="Selección de Empresa"
        :tabla="tabla"
        modoSeleccion
        :habilitaEliminar="false"
        @actualizoFormEdicion="guardo">
        <template v-slot:Campos>
            <SelectEmpresas v-model="tabla.idEmpresa" obligatorio/>
        </template>    
    </FormEdicion>
</template>

<script>
    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import SelectEmpresas from '@/components/select/SelectEmpresas.vue';
    import {mapState} from 'vuex';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            SelectEmpresas,
        },

        data: () => ({
            tabla:{
                idEmpresa: 0,
            } 
        }),

        computed: {
            ...mapState({
                empresa: state => state.empresasModule,
            }),
        },

        watch:{
            show(val){
                if (val){
                    this.tabla.idEmpresa = this.empresa.id
                } 
            },   
        },

        methods: {
            guardo(){
                this.$store.dispatch('setEmpresa',this.tabla.idEmpresa)
            }
        }
    }
</script>
