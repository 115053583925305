<template>
    <v-tooltip bottom :color="colorPrincipal">
        <template v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="enviar">
                <font-awesome-icon :icon="['fab', 'whatsapp']" size="xl"/>
            </v-btn>
        </template>    
        <span>{{"Enviar Whatsapp"}}</span>
    </v-tooltip>
</template>

<script>
    import {mapState} from 'vuex';
    export default {
        
        props: {
            numero: { type: String, 
                    default: ''},
            texto: { type: String, 
                    default: ""},   
        },
        
        computed: {
            ...mapState({colorPrincipal: state => state.colorPrincipal}),
        },

        methods: {
            enviar() {
                //window.open(`https://wa.me/+54${number}`);
                //let comando = "https://wa.me/+549" + this.numero 
                //let comando = "https://api.whatsapp.com/send?phone=5493476558913"
                let comando = "https://wa.me/54" + this.numero + "?text=" + this.texto

                window.open(comando)

            },
         }

    }
</script>