import axios from 'axios'
const controller = "subrubros/"

class SubrubrosApi {
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){
        return axios.get(controller,{params: {
            activo: parametros.activo, 
            idsRubros: parametros.idsRubros
        }});
    }
}

export const subrubrosApi = new SubrubrosApi();