<template>
    <div v-if="show">    
        <div class="d-flex">
            <v-col class="d-flex pa-0">
                <v-col cols="3" class="pa-0 pt-0 mr-1">
                    <InputFechaRango v-model="filtro.rangoFecha"/>
                </v-col>
                <v-col cols="3" class="pa-0 pt-0 mr-1">
                    <SelectSectores v-model="filtro.idsSectores" multiple/>
                </v-col>
                <v-col cols="3" class="pa-0 pt-0 mr-1">
                    <SelectEstadosTurno v-model="filtro.idsEstados" multiple/>
                </v-col>
                <v-col cols="3" class="pa-0 pt-0 mr-1">
                    <SelectOrigenes v-model="filtro.idsOrigenesAsignacion" tituloPlural="Orígenes Asignación" multiple/>
                </v-col>
            </v-col>
            <div class="pa-0 pt-4 pl-3">
                <Button tipo="filtrar" @click="buscar"/>
            </div>
        </div>

        <div class="d-flex">
            <v-col cols="4">
                <ChartDonut :data="dataEstados"/>
            </v-col>
            <v-col cols="4">
                <ChartRadialBar :data="dataAsistencia" :tipo="'asistencia'"/>
            </v-col>
            <v-col cols="4">
                <ChartDonut :data="dataOrigenes"/>
            </v-col>
        </div>
        <div class="d-flex">
            <v-col cols="6">
                <ChartLine :data="dataEstadosPorMes" :xaxis="xaxisEstadosPorMes"/>
            </v-col>
            <v-col cols="3">
                <ChartColumn :data="dataEstadosPromedioDiario"/>
            </v-col>
            <v-col cols="3">
                <ChartColumn :data="dataEstadosPromedioMensual"/>
            </v-col>
        </div>    
    </div>
</template>

<script>

    import {turnosApi} from '../../api/TurnosApi';
    import InputFechaRango from '../../components/input/InputFechaRango.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue';
    import SelectEstadosTurno from '../../components/select/SelectEstadosTurno.vue';
    import SelectOrigenes from '../../components/select/SelectOrigenes.vue';
    import Button from '../../components/base/button/Button.vue';
    import ChartDonut from '../../components/graphics/ChartDonut.vue';
    import ChartRadialBar from '../../components/graphics/ChartRadialBar.vue';
    import ChartLine from '../../components/graphics/ChartLine.vue';
    import ChartColumn from '../../components/graphics/ChartColumn.vue';

    export default {

        components: {
            InputFechaRango,
            SelectSectores,
            SelectEstadosTurno,
            SelectOrigenes,
            Button,
            ChartDonut,
            ChartRadialBar,
            ChartLine,
            ChartColumn,
        },
   
        data: () => ({
            filtro:{
                rangoFecha: [],
                idSector: null,
                idsSectores: null,
                idsEstados: null,
                idsOrigenesAsignacion: null,          
            },
            dataEstados: [],
            dataOrigenes: [],
            dataAsistencia: null,
            seriesLineMensual: [],   
            dataEstadosPorMes: [],
            xaxisEstadosPorMes: [],
            dataEstadosPromedioDiario: {},
            dataEstadosPromedioMensual: {},
        }),
        
        props: {
            show: {type:Boolean,
                default: false},
        },

        watch:{
            show(val){
                if (val){
                    this.filtro.rangoFecha = this.$rangoSeteado()
                    this.filtro.idsSectores = this.$store.state.sectoresModule.idsSectores
                    this.buscar()
                }
            }
        },
        
        methods: { 
            async buscar(){
                try {
                    this.$store.commit("loadingModule/setIsLoading");
                    const response = await turnosApi.estadisticas(this.filtro)
                    this.dataEstados = response.data.estados
                    this.dataOrigenes = response.data.origenes
                    this.dataAsistencia = response.data.asistencia
                    this.dataEstadosPorMes = response.data.estadospormes
                    this.xaxisEstadosPorMes = response.data.mesesestados
                    this.dataEstadosPromedioDiario = response.data.estadospromediodiario
                    this.dataEstadosPromedioMensual = response.data.estadospromediomensual
                } catch (error) {
                    console.log(error);
                } finally {
                    this.$store.commit("loadingModule/setIsLoading");
                }
            }
        }

    }
</script>


