<template>
    <Select 
        v-model="itemsSeleccionados"
        :titulo="titulo"
        tituloPlural="P. de Venta" 
        :endPointSelect="endPointSelect"
        :obligatorio="obligatorio"
        :multiple="multiple"
        :filtro="filtro"
        :readonly="readonly"
        :clearable="clearable"
        :hidedetails="hidedetails"
        :dense="dense"
        @valorSecundario="valorSecundario">
    </Select>
</template>

<script>
    import { SelectMixin } from '@/components/mixins/SelectMixin';
    import { puntosVentaApi } from '../../api/PuntosVentaApi';
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: puntosVentaApi.select,
            filtro:{
                soloActivos: false,
                idTipoComprobante: null,
            }
        }),

        props: {
            soloActivos: {type: Boolean,
                default: false},
            idTipoComprobante: {type: Number},
            titulo: {type: String,
                default: "P. de Venta"}
        },
        
        watch:{
            soloActivos:{
                immediate: true, 
                handler (val) {
                    this.filtro.soloActivos = val
                }
            },
            idTipoComprobante:{
                immediate: true, 
                handler (val) {
                    this.filtro.idTipoComprobante = val
                }
            }
        },
    }    
</script>