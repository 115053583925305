import { empresasApi } from "../../api/EmpresasApi";

const state = {
    id: 0,
    nombre: '',
    nrodocumento: '',
    nombrefantasia: '',
    tipodocumento: {
        id: 0,
        nombre: '',
    },
    condicioniva: {
        id: 0,
        nombre: '',
        detalle: '',
        },
    direccion: '',
    localidad: {
        id: 0,
        nombre: '',
        provincia:{
            nombre: ''
        }
        },
    telefono: '',
    email: '',
    web: '',
    logobase64: '',
};

const actions = { 
    async setEmpresa({commit},id){
        const {data} = await empresasApi.set(id)
        if (data){
            commit("setEmpresa",data) 
        }
    },
};

const mutations = {
    setEmpresa:(state,data)=>(
        state.id = data.id,
        state.nombre = data.nombre,
        state.nrodocumento = data.nrodocumento,
        state.nombrefantasia = data.nombrefantasia,
        state.tipodocumento.id = data.tipodocumento.id,
        state.tipodocumento.nombre = data.tipodocumento.nombre,
        state.condicioniva.id = data.condicioniva.id,
        state.condicioniva.nombre = data.condicioniva.nombre,
        state.condicioniva.detalle = data.condicioniva.detalle,
        state.direccion = data.direccion,
        state.localidad.id = data.localidad.id,
        state.localidad.nombre = data.localidad.nombre,
        state.localidad.provincia.nombre = data.localidad.provincia.nombre,
        state.telefono = data.telefono,
        state.email = data.email,
        state.web = data.web,
        state.logobase64 = data.logobase64      
    ),
};

export default {
    state,
    actions,
    mutations,
}