<template>
    <FormEdicion
        v-model="show"
        titulo="Empresa"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                autofocus
                label="Nombre"
                obligatorio/>        
            
            <Tab v-model="tab" :tabs="tabs">               
                <template v-slot:datos>                

                    <div class="d-flex pt-2">
                        <select-condicion-iva v-model="tabla.condicioniva.id" obligatorio/>
                        <select-tipos-documento v-model="tabla.tipodocumento.id" 
                            obligatorio
                            class="pl-1 pr-1"
                            @valorSecundario="valorSecundarioTiposDocumento"/>
                        <Input v-model="tabla.nrodocumento"
                            class="pl-1"
                            label="Núm. Documento"
                            obligatorio
                            :tipo= "formatoCuit ? 'cuit' : 'dni'"/>
                    </div>
                    
                    <Input v-model="tabla.telefono"
                        label="Teléfono"/>
                    <Input v-model="tabla.email"
                        label="Email"
                        tipo="mail"/>
                    <Input v-model="tabla.web"
                        label="Web"/>
                    <Input v-model="tabla.direccion"
                        label="Dirección"/>
                    <SelectLocalidades v-model="tabla.localidad.id" 
                        obligatorio/>
                    <Input v-model="tabla.nombrefantasia"
                        label="Nombre de Fantasía"/>
                    <SwitchCustom v-model="tabla.activo" label="Activa"/>    
                </template>

                <template v-slot:mail>
                    <Input v-model="tabla.mailhost"
                        label="Host"
                        class="pt-2"/>
                    <Input v-model="tabla.mailport"
                        label="Puerto"
                        type="number"/>
                    <SwitchCustom v-model="tabla.mailsecure" 
                        label="Seguro"/>    
                    <Input v-model="tabla.mailuser"
                        label="Usuario"/>
                    <Input v-model="tabla.mailpassword"
                        label="Password"/>
                </template>
            </Tab>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {empresasApi} from '../../api/EmpresasApi';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';
    import SelectLocalidades from '../../components/select/SelectLocalidades.vue';
    import SwitchCustom from '../../components/base/Switch.vue';
    import Tab from '../../components/base/Tab.vue';
    import SelectTiposDocumento from '../../components/select/SelectTiposDocumento.vue';
    import SelectCondicionIva from '../../components/select/SelectCondicionIva.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectLocalidades,
            SwitchCustom,
            Tab,
            SelectTiposDocumento,
            SelectCondicionIva,
        },

        data: () => ({
            endPointBuscar: empresasApi.buscar,
            endPointGuardar: empresasApi.guardar,
            endPointEliminar: empresasApi.eliminarVarios,
            tabla: {
                nombre: "",
                telefono: "",
                activo: true,
                email: "",
                direccion: "",
                localidad: {
                    id: null
                },
                web: "",
                nombrefantasia: "",
                condicioniva: {
                    id: 0,
                },
                tipodocumento: {
                    id: 0
                },
                nrodocumento: "",
                mailhost: "",
                mailport: null,
                mailsecure: true,
                mailuser: "",
                mailpassword: "",
            },
            tabs:[
                {nombre: 'datos', habilitado: true, show: true},
                {nombre: 'mail', titulo: 'Envio de Mails', habilitado: true, show: true},
            ],
            tab:0,
            formatoCuit:false,
        }),

        methods: {
            valorSecundarioTiposDocumento(value){
                this.formatoCuit = value.formatocuit
            },
        }

    }
</script>