<template>
  <FormABM
    :show= "show"
    titulo="Actividades Económicas"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion= "modoSeleccion"
    :multiple="multiple"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">
    
    <template v-slot:FormEdicion>
      <ActividadEconomica v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
    </template>    

  </FormABM>   
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {actividadesEconomicasApi} from '@/api/ActividadesEconomicasApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import ActividadEconomica from './ActividadEconomica.vue'

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      ActividadEconomica,
    },

    data: () => ({
      endPointBuscarVarios: actividadesEconomicasApi.buscarVarios,
      endPointEliminarVarios: actividadesEconomicasApi.eliminarVarios,
      headers: [
        {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        {text: "Código", value: "codigo", groupable: false},
        {text: "Nombre", value: "nombre", groupable: false}],
    }),

  }
</script>