<template>
    <div class="d-flex">
        <v-col class="pa-0">
            <SelectSectores 
                :multiple="multiple"
                :value="sectoresSeleccionados"
                :obligatorio="sectoresObligatorio"
                :outlined="outlined"
                :hidedetails="hidedetails"
                :readonly="readonly"
                :dense="dense"
                guardarIdsSeleccionados
                activoTurnos
                @valorSecundario="valorSecundarioSector"
                @input="$emit('update:sectoresSeleccionados',$event)"
            />
        </v-col>       
        <SelectTramites
            v-show="showTramite && sectoresSeleccionados && itemsTramites != 0"
            class="pl-2"
            :multiple="multiple"
            :value="tramitesSeleccionados"
            :idsSectores="sectoresSeleccionados"
            :obligatorio="showTramite && tramitesObligatorio"
            :outlined="outlined"
            :hidedetails="hidedetails"
            :dense="dense"
            @valorSecundario="valorSecundarioTramite"
            @input="$emit('update:tramitesSeleccionados',$event)"
            @cantidadItems="cantidadItemsTramites"
        />
    </div>
</template>

<script>

    import SelectSectores from './SelectSectores.vue'
    import SelectTramites from './SelectTramites.vue'

    export default {
        components: { 
            SelectSectores,
            SelectTramites, 
        },

        data: () => ({
            itemsTramites: 0,
            actualizarSelect: false,
        }),

        props: {
            sectoresSeleccionados: {type: [Array,Number],
                default(){return[ ]}},
            tramitesSeleccionados: {type: [Array,Number],
                default(){return[ ]}},    
            multiple: {type: Boolean,
                default: false},
            sectoresObligatorio: {type: Boolean,
                default: false},   
            tramitesObligatorio: {type:Boolean,
                default:false},
            showTramite:{type: Boolean,
                default: true},       
            outlined: {type: Boolean,
                default: false},   
            hidedetails: {type: Boolean,
                default: false}, 
            readonly: {type: Boolean,
                default: false},
            dense: {type: Boolean,
                default: false},
        },

        methods: {
            cantidadItemsTramites(val){
                this.itemsTramites = val
                this.$emit("cantidadItemsTramites",val);
            },
            valorSecundarioSector(val){
                this.$emit("valorSecundarioSector",val);
            },
            valorSecundarioTramite(val){
                this.$emit("valorSecundarioTramite",val);
            }
        }
    }
</script>
