<template>
    <apexchart ref="apexChart" type="radialBar" :options="chartOptions" :series="chartSeries"/>
</template>

<script>
    
    export default {

        data: () => ({
            chartSeries: [],
            chartOptions: {
                plotOptions: {
                    radialBar: {
                        hollow: {
                            size: "60%"
                        },
                        startAngle: -135,
                        endAngle: 135,
                        track: {
                            startAngle: -135,
                            endAngle: 135,
                        },
                        dataLabels: {
                            name: {
                                show: true,
                                color: "#888",
                                fontSize: "12px",
                                offsetY: 70,
                            },
                            value: {
                                fontSize: "30px",
                                show: true
                            }
                        }
                    },
                },
                fill: {
                    colors: [],
                    opacity: 0.8,
                },
                stroke: {
                    lineCap: "butt"
                },
                labels: [],
            },
        }),

        props:{
            data:{type:Number,
                default:0},
            tipo:{type:String,
                default:''}
        },
        
        watch:{
            data(){
                this.update()
            }
        },

        mounted(){
            if(this.tipo === "disponibles"){
                this.$refs.apexChart.updateOptions({
                    fill: {
                        colors: [function({ value}) {
                            if(value < 70) {
                                return '#4CAF50'
                            } else if (value >= 70 && value < 85) {
                                return '#FFEB3B'
                            } else {
                                return '#F44336'
                            }
                        }],
                    },
                    labels:["Ocupación Disponibles"]
                })
            }else if(this.tipo === "reservados"){
                this.$refs.apexChart.updateOptions({
                    fill: {
                        colors: [function({ value}) {
                            if(value < 80) {
                                return '#4CAF50'
                            } else if (value >= 80 && value < 85) {
                                return '#FFEB3B'
                            } else {
                                return '#F44336'
                            }
                        }],
                    },
                    labels:["Ocupación Reservados"]
                })
            }else if(this.tipo === "asistencia"){
                this.$refs.apexChart.updateOptions({
                    fill: {
                        colors: [function({ value}) {
                            if(value < 70) {
                                return '#F44336' 
                            } else if (value >= 70 && value < 90) {
                                return '#FFEB3B'
                            } else {
                                return '#4CAF50'
                            }
                        }],
                    },
                    labels:["Asistencia"]
                })
            }
        },
        
        methods: {
            update(){  
                this.chartSeries = []
                this.chartSeries.push(this.data)
            }
        },

    }

</script>
