import axios from 'axios'
const controller = "tramites/"

class TramitesApi {
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(){ 
        return axios.get(controller)
    }

    select(parametros){
        return axios.get(controller + "select",{params: {
            idsSectores: parametros.idsSectores,
            seleccionarTodos: parametros.seleccionarTodos
        }});
    }

    selectTipos(){
        return axios.get("tipostramite/select");
    }
}

export const tramitesApi = new TramitesApi();