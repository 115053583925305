<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Tipo operación" 
        tituloPlural="Tipos operación" 
        :endPointSelect="endPointSelect"
        :multiple="multiple">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {auditoriaApi} from '@/api/AuditoriaApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: auditoriaApi.selectTiposOperacion,
        }),
    }    
</script>