<template>
    <div v-show="show">    
        <Alert v-model="showAlert" :text="mensajeError"/> 
        <div class="d-flex">
            <div class="text-h6">{{tituloForm}}</div>
            <v-spacer/>
            <InputBusqueda v-model="search" autofocus v-if="showDataTable"/>
            <v-spacer/>
            <div class="align-self-center">
                <Button tipo="agregar" :visible="permiteAgregar" @click="actualizar(0)"/>
                <Button tipo="eliminar" :visible="permiteEliminar && !modoSeleccion" :disabled="!itemsSeleccionados" @click="eliminar(selectItems)"/>
                <Button tipo="imprimir" :visible="permiteImprimir" @click="generarPdf"/>
                <Button tipo="cancelar" :visible="modoSeleccion || modoVisualizacion" @click="cerrar"/>
                <slot name="BotonesAdicionales"/>
            </div>
        </div>    
        <div v-if="(Object.keys(filtro).length != 0 || showFiltro) && habilitaFiltrar" class="d-flex">
            <v-col class="pa-0 pt-2">
                <slot name="CompFiltros"/>
            </v-col>
            <div class="d-flex ml-2 mt-4">
                <Button tipo="filtrar" @click="filtrar"/>
            </div>
        </div> 
        <div v-if="showDataTable">   
            <DataTable 
                v-model="selectItems"
                :headers="headers"
                :items="items"
                :search="search"
                :sortBy="sortBy"
                :dense="modoSeleccion"
                :seleccion="modoSeleccion"
                :multiple="multiple"
                :permiteModificar="permiteModificar"
                :permiteEliminar="permiteEliminar"
                :permiteVisualizar="permiteVisualizar"
                :permiteDescargar="permiteDescargar"
                :itemKey="itemKey"
                @actualizar="actualizar"
                @eliminar="eliminar"
                @seleccionar="seleccionarIndividual"
                @imprimir="imprimir"
            />
        </div>
        <div v-if="modoSeleccion" class="d-flex justify-center">
            <div v-if="multiple" class="pa-1">
                <Button tipo="seleccionar" @click="seleccionarMultiple"/>
            </div>
        </div>
        <slot name="FormEdicion" v-show="showSlotEdicion"/>
        <Confirm ref="confirm"></Confirm>
    </div> 
</template>

<script>

    import Button from '../button/Button.vue';
    import InputBusqueda from '@/components/input/InputBusqueda.vue';
    import DataTable from '@/components/base/DataTable.vue';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import dayjs from "dayjs";
    import jsPDF from 'jspdf'
    import autoTable from 'jspdf-autotable'

    export default {
        components: {
            Button,
            InputBusqueda,
            DataTable,
        },

        data: () => ({
            items: [],
            selectItems: [],
            search: '',
            showSlotEdicion: false,
            isLoading: false,
            showAlert: false,
            mensajeError: '',
        }),

        props: {
            show: {type: Boolean,
                default: true},
            titulo: {type: String,
                default: ''},
            endPointBuscarVarios: {type: Function},
            endPointEliminarVarios: {type: Function},
            endPointImprimir: {type: Function},
            permiteAgregar: {type: Boolean,
                default: true},
            permiteModificar:{type: Boolean,
                default: true},
            permiteEliminar: {type: Boolean,
                default: true},
            permiteVisualizar: {type: Boolean,
                default: false},
            permiteImprimir: {type: Boolean,
                default: false},
            permiteDescargar:{type: Boolean,
                default: false},
            modoSeleccion: {type: Boolean,
                default: false},
            modoVisualizacion: {type: Boolean,
                default: false},
            multiple: {type: Boolean,
                default: true},
            filtro: {type: Object,
                default: () => ({})},
            headers: {type: Array,
                default: () => ([])},
            sortBy: {type: String,
                default: "nombre"},
            itemsPadre: {type: Array,
                default: () => ([])}, ///PODRIA MEJORARSE HAY QUE ANALIZAR
            actualizarBuscar: {type: Boolean, ///ESTO SE PODRIA EVITAR USANDO FILTRO
                default: false}, 
            showFiltro: {type: Boolean, 
                default: false}, 
            habilitaFiltrar: {type: Boolean,
                default: true},  
            itemKey: {type: String,
                default: 'id'},
        },

        computed: {
            tituloForm(){
                if (this.modoSeleccion) return "Selección de " + this.titulo
                return this.titulo
            },
            showTitulo(){
                return this.titulo != ''
            },
            showDataTable(){
                return this.headers.length != 0
            },
            itemsSeleccionados(){
                return this.selectItems.length != 0
            },  
        },

        watch:{
            show(val){
                if (val){
                    this.buscar()
                } 
            },
            actualizarBuscar(){
                this.buscar()
            },
            itemsPadre: {
                immediate: true,
                deep: true,
                handler(val){
                    this.items = [...val]
                }
            },
        },
    
        mounted() {
            this.buscar()
        },

        methods: {
            
            actualizar(id) {
                this.$emit("actualizar",id);
                this.showSlotEdicion = true;
            },
            
            async eliminar(val) {  
                if (this.endPointEliminarVarios){
                    let ids = []
                    let mensaje
                    let masivo = Array.isArray(val)
                    if (masivo){
                        if (val.length === 1){
                            mensaje = '¿Confirma la eliminación?'
                            masivo = false
                        }else{
                            mensaje = '¿Confirma la eliminación de los ' + val.length + ' elementos seleccionados?'
                        }
                        const confirm = await this.$refs.confirm.open('Atención', mensaje, {tipo:'confirmacion',color:'red'})
                        if (confirm){
                            ids = val.map(val => val.id);
                        }else{
                            return
                        }
                    }else{
                        ids = [val]
                    } 

                    try {
                        this.$store.commit("loadingModule/setIsLoading");
                        await this.endPointEliminarVarios(ids)
                        this.$store.commit("snackbarModule/setSnack",{accion:'Elimino'})
                        if (masivo) this.borrarSeleccion()
                        this.buscar()
                    } catch (ex) {
                        if (!masivo){
                            this.mensajeError = "No es posible la eliminación, existen datos relacionados."
                        }else{
                            this.mensajeError = "No es posible la eliminación, al menos un elemento tiene datos relacionados."
                        }
                        this.showAlert = true
                    } finally {
                        this.$store.commit("loadingModule/setIsLoading");
                    }
                }   
            },

            async imprimir(id) {
                if (this.endPointImprimir){
                    try {
                        this.$store.commit("loadingModule/setIsLoading");
                        const response = await this.endPointImprimir(id)
                        this.$emit('imprimirItem',response.data);     
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.$store.commit("loadingModule/setIsLoading");
                    }
                }   
            },

            seleccionarIndividual(id){
                this.$emit('seleccionar',id);   
                this.borrarSeleccion()
            },

            seleccionarMultiple() {
                this.$emit('seleccionar',this.selectItems);      
                this.borrarSeleccion()
            },

            borrarSeleccion(){
                this.search = ''
                this.selectItems = []
            },

            filtrar(){
                this.$emit('filtrar');
                this.buscar()
            },

            async buscar() {
                if (this.show && this.endPointBuscarVarios){
                    try {
                        this.$store.commit("loadingModule/setIsLoading");
                        const response = await this.endPointBuscarVarios(this.filtro)
                        this.items = [...response.data]
                    } catch (error) {
                        console.log(error);
                    } finally{
                        this.$store.commit("loadingModule/setIsLoading");
                    }   
                }
            },

            async generarPdf(){
                
                let me = this

                var getImageFromUrl = function(url, callback) {
                    var img = new Image();
                    img.onError = function() {
                        alert('Cannot load image: "'+url+'"');
                    };

                    img.onload = function() {
                        callback(img);
                    };
                    
                    img.src = url;
                }
                
                var createPDF = function(imgData) {
                    var doc = new jsPDF('landscape'); // Especifica 'landscape' para orientación horizontal

                    const widthPage = doc.internal.pageSize.getWidth()
                    let headersImpresion = []
                    let itemsImpresion = []
                    let nColumna = 0

                    function obtenerValor(objeto, ruta) {
                        const propiedades = ruta.split('.');
                        let valor = objeto;
                        for (let i = 0; i < propiedades.length; i++) {
                            if (valor && valor.hasOwnProperty(propiedades[i])) {
                                valor = valor[propiedades[i]];
                            } else {
                                valor = "";
                                break;
                            }
                        }
                        return valor;
                    }

                    function procesarCampo(valor, tipo) {
                        if (tipo === 'fechahora') return dayjs(valor).format("DD/MM/YYYY HH:mm [Hs.]")
                        return valor;
                    }

                    me.headers.forEach(function(x) {
                        if (x.impresion) {
                            headersImpresion.push(x.text);
                            me.items.forEach(function(y, registro) {
                                let campo = obtenerValor(y, x.value);
                                campo = procesarCampo(campo, x.tipo);

                                if (nColumna === 0) {
                                    itemsImpresion.push([campo]);
                                } else {
                                    itemsImpresion[registro].push(campo);
                                }
                            });
                            nColumna++;
                        }
                    });
                    
                    doc.autoTable({
                        head: [headersImpresion],
                        body: itemsImpresion,
                        margin: {top:22},
                        didDrawPage(data) {
                            doc.addImage(imgData, 'JPEG', data.settings.margin.left, 8, imgData.width * 10 / 100,  imgData.height * 10 / 100);
                            doc.setFontSize(14)
                            doc.setTextColor(40)
                            doc.text(me.titulo,widthPage/2,12,{align:'center'})

                            // Footer - DE ESTA FORMA NO SE SABE EL TOTAL DE PAGINAS
                            // var str = "Página " + doc.internal.getNumberOfPages()
                            // doc.setFontSize(9)
                            // var pageSize = doc.internal.pageSize;
                            // var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                            // doc.text(str, data.settings.margin.left, pageHeight - 10);
                        },
                        headStyles: {
                            fillColor: [0, 0, 0],       // Color de fondo negro
                            textColor: [255, 255, 255], // Color de texto blanco
                            fontSize: 8,                // Tamaño de letra para los encabezados
                        },
                        styles: {
                            fontSize: 8                 // Tamaño de letra para el cuerpo de la tabla
                        }
                    })
    
                    const pageCount = doc.internal.getNumberOfPages()
                    for(var i = 1; i <= pageCount; i++) {
                        doc.setPage(i)
                        doc.setFont("helvetica")
                        doc.setFontSize(9)
                        doc.text('Página ' + String(i) + ' de ' + String(pageCount),widthPage/2,297-10,{align:'center'})
                    }

                    ///doc.save('listado.pdf') ///DESCARGA SOLAMENTE EL PDF
                    doc.output('dataurlnewwindow',{filename: 'Listado'})
                }
                
                getImageFromUrl(this.$store.state.empresasModule.logobase64,createPDF);

            },                 

            cerrar() {
                this.$emit('cerrar');
            },

        }
            
    }
</script>

