<template>
    <div class="d-flex">
        <v-combobox
            v-model="itemsSeleccionados"
            hide-selected
            multiple
            deletable-chips
            small-chips
            :items="items"
            :search-input.sync="search"
            :label="titulo">
            <template v-slot:no-data v-if="buscar">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            No hay resultados que coincidan con "<strong>{{ search }}</strong>". Presiona <kbd>enter</kbd> para crear uno nuevo
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-combobox>
    </div>
</template>

<script>
    export default {

        data: () => ({
            items: ["arbol"],
            loading: false,
            search: null,
        }),

        props: {
            value: {type: [Array,Number],
                default(){return[ ]}},
            titulo: {type: String},
            filtro: {type: Object},
            endPointSelect: {type: Function}, 
        },

        computed: {     
            itemsSeleccionados: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit("input", value)  
                }
            },
            buscar(){
                return this.search && this.search.length >= 3 ? true : false
            }
        },

        watch:{

            search(val) {
                if(this.buscar){
                    this.$emit("searchInput",val)
                }else{
                    this.items = []
                }   
            },

            filtro: {
                immediate: false,
                deep: true, 
                handler(){
                    this.select()    
                }
            }
        },

        methods: {
            async select() {
                let me = this
                try {
                    this.loading = true
                    const response = await this.endPointSelect(me.filtro)
                    if(response.data){
                        me.items = []
                        response.data.map(function(x) {
                        me.items.push({text:x.nombre,value:x.id});
                        })
                    }
                    this.loading = false
                } catch (error) {
                    console.log(error);
                }
            },
        }

    }
</script>