<template>
    <FormABM
        :show="show"
        titulo="Rubros"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"    
        :modoSeleccion="modoSeleccion"   
        :multiple="multiple"
        :filtro="filtro"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar">
    
        <template v-slot:CompFiltros>
            <SelectTriple v-model="filtro.activo" label="Activo"/>
        </template>  

        <template v-slot:FormEdicion>
            <Rubro v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
        </template>    
    </FormABM>    
</template>

<script>

    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {rubrosApi} from '@/api/RubrosApi'
    import FormABM from '@/components/base/form/FormABM.vue';
    import Rubro from './Rubro.vue';
    import SelectTriple from '@/components/base/SelectTriple.vue';

    export default {

        mixins: [FormABMMixin],

        components: {
            FormABM, 
            Rubro,
            SelectTriple,
        },

        data: () => ({
            endPointBuscarVarios: rubrosApi.buscarVarios,
            endPointEliminarVarios: rubrosApi.eliminarVarios,
            headers: [
                {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
                {text: "Nombre", value: "nombre", groupable: false},
                {text: "Detalle", value: "detalle", groupable: false},
                {text: "Activo", value: "activo", filterable: false, groupable: false, tipo: "boolean"}],    
            filtro:{
                activo: true
            }
        }),
        
    }

</script>