<template>
    <FormEdicion
        v-model="show"
        titulo="Localidad"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                autofocus
                label="Nombre"
                obligatorio/>
            <SelectProvincias v-model="tabla.idprovincia" obligatorio/>  
            <SwitchCustom v-model="tabla.predeterminado" label="Predeterminada"/>        
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {localidadesApi} from '../../api/LocalidadesApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue'
    import SelectProvincias from '@/components/select/SelectProvincias.vue';
    import SwitchCustom from '../../components/base/Switch.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectProvincias,
            SwitchCustom,
        },

        data: () => ({
            endPointBuscar: localidadesApi.buscar,
            endPointGuardar: localidadesApi.guardar,
            endPointEliminar: localidadesApi.eliminarVarios,
            tabla: {
                nombre: "",
                predeterminado: false,
                idprovincia: 0,
            },
        }),

    }
</script>