<template>
    <Select 
        v-model="itemsSeleccionados"
        :titulo="titulo" 
        :tituloPlural="tituloPlural" 
        :endPointSelect="endPointSelect"
        :obligatorio="obligatorio"
        @valorSecundario="valorSecundario">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {normasApi} from '@/api/NormasApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: normasApi.selectAcciones,
        }),

        props: {
            titulo: { type: String,
                default: "Acción"},
            tituloPlural: { type: String,
                default: "Acciones"},
        },
    }    
</script>