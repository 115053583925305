<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Localidad" 
        tituloPlural="Localidades" 
        :endPointSelect="endPointSelect"
        :habilitaDetalle="habilitaDetalle"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :actualizarSelect="actualizarSelect"
        :filtro="filtro"
        :busquedaSecuencial="busquedaSecuencial=true"
        @showFormABM="mostrarFormABM=true"
        @valorSecundario="valorSecundario"
        @searchInput="searchInput">
        <template v-slot:FormABM>
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <Localidades modoSeleccion 
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                </v-card>
            </v-dialog>
        </template>
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {localidadesApi} from '@/api/LocalidadesApi'
    import Select from '@/components/base/Select.vue';
    import Localidades from '../../views/Localidades/Localidades.vue'

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            Localidades,
        },

        data: () => ({
            endPointSelect: localidadesApi.select,
        }),

    }    
</script>