<template>
    <FormABM
        :show="show"
        titulo="Tipos de Comprobantes"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"       
        :multiple="multiple"
        :filtro="filtro"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar"> 
        
        <template v-slot:CompFiltros>
            <div class = "d-flex">
              <v-col cols="8" class="pa-0">
                <SelectTiposTipoComprobante v-model="filtro.idsTipoTipoComprobante" multiple/>
              </v-col>
              <v-col cols="4" class="pa-0 pl-1">
                <SelectTriple v-model="filtro.activo" label="Activo"/>
              </v-col>
            </div>
        </template>

        <template v-slot:FormEdicion>
            <TipoComprobante v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/> 
        </template>   
    </FormABM>    
  </template>
  
  <script>
  
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {tiposComprobantesApi} from '../../api/TiposComprobantesApi';
    import FormABM from '@/components/base/form/FormABM.vue';
    import TipoComprobante from './TipoComprobante.vue';
    import SelectTriple from '@/components/base/SelectTriple.vue';
    import SelectTiposTipoComprobante from '@/components/select/SelectTiposTipoComprobante.vue';
  
    export default {
      
      mixins: [FormABMMixin],
  
      components: {
        FormABM, 
        TipoComprobante,
        SelectTriple,
        SelectTiposTipoComprobante,
      },
  
      data: () => ({
        endPointBuscarVarios: tiposComprobantesApi.buscarVarios,
        endPointEliminarVarios: tiposComprobantesApi.eliminarVarios,
        headers: [
          {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
          {text: "Nombre", value: "nombrecorto"},  
          {text: "Letra", value: "letra"},
          {text: "Tipo", value: "tipotipocomprobante.nombre"},
          {text: "Detalle", value: "nombre"},
          {text: "Activo", value: "activo", filterable: false, groupable: false, tipo: "boolean"}],
        filtro:{
          idsTipoTipoComprobante: null,
          activo: true,
        }
      }),
  
    }
  </script>