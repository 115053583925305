<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Norma"
        tituloPlural="Normas"
        :endPointSelect="endPointSelect"
        :habilitaDetalle="habilitaDetalle"
        :obligatorio="obligatorio"
        :filtro="filtro"
        :busquedaSecuencial="busquedaSecuencial=true"
        @showFormABM="mostrarFormABM=true"
        @searchInput="searchInput"
        @valorSecundario="valorSecundario">

        <template v-slot:FormABM>
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <Normas modoSeleccion
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                    </v-card>
            </v-dialog>
        </template>

    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {normasApi} from '@/api/NormasApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            Normas: () => import('../../views/Normas/Normas.vue')
        },

        data: () => ({
            endPointSelect: normasApi.select,
        }),

        props: {
            idExcluido: {type: Number,
                default: null},
        },

        watch:{
            idExcluido:{
                immediate: true, 
                handler (val) {
                    this.filtro.idExcluido = val
                }
            } 
        },

    }    
</script>