<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Artículo" 
        tituloPlural="Artículo" 
        :endPointSelect="endPointSelect"
        :habilitaDetalle="habilitaDetalle"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :actualizarSelect="actualizarSelect"
        :filtro="filtro"
        :busquedaSecuencial="busquedaSecuencial=true"
        @showFormABM="mostrarFormABM=true"
        @valorSecundario="valorSecundario"
        @searchInput="searchInput">
        <template v-slot:FormABM>
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <articulos modoSeleccion 
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                </v-card>
            </v-dialog>
        </template>
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import { articulosApi } from '../../api/ArticulosApi';
    import Select from '@/components/base/Select.vue';
    import Articulos from '../../views/Articulos/Articulos.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            Articulos,
        },

        data: () => ({
            endPointSelect: articulosApi.select,
        }),

    }    
</script>