<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Evento" 
        tituloPlural="Eventos" 
        :endPointSelect="endPointSelect"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :filtro="filtro"
        @valorSecundario="valorSecundario">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {inscripcionesApi} from '@/api/InscripcionesApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: inscripcionesApi.select,
            filtro:{
                idsSectores: null, 
                activo: null,
                activoInscripciones: null,
                permiteInscripciones: null,
            }    
        }),

        props: {
            idsSectores:{type: [Array,Number],
                default(){return[ ]}
            },
            activo: {type: Boolean, default: false},
            activoInscripciones: {type: Boolean, default: false},
            permiteInscripciones: {type: Boolean, default: false},
        },

        watch:{
            idsSectores(val){
                if(typeof val === 'number'){
                    this.filtro.idsSectores = [val]
                }else{
                    this.filtro.idsSectores = val
                }
            },
            activo:{
                immediate: true,
                handler(val){
                    this.filtro.activo = val 
                }
            },
            activoInscripciones:{
                immediate: true,
                handler(val){
                    this.filtro.activoInscripciones = val 
                }
            },
            permiteInscripciones:{
                immediate: true,
                handler(val){
                    this.filtro.permiteInscripciones = val 
                }
            }
        },
    }    
</script>