<template>
    <FormEdicion
        v-model="show"
        titulo="Contacto"
        :tabla="tabla"
        :modoAlta="modoAlta"
        :modoModificacion="modoModificacion"
        :tablaValoresPorDefecto="tablaValoresPorDefecto"
        @actualizoFormEdicion="actualizoFormEdicion"
        @eliminar="eliminar">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                label="Nombre"
                :autofocus="focusNombre"
                obligatorio
                class="soloMayusculas"/>
            
            <Input v-model="tabla.telefono"
                label="Teléfono"
                tipo="telefono"
                placeholder="Ingresar sin el 0 y sin el 15, Ej. 3476551234"
                :autofocus="!focusNombre"/>

            <Input v-model="tabla.email"
                label="Email"
                tipo="mail"/>

            <Input v-model="tabla.observaciones"
                label="Observaciones"/>
            
            <div class="d-flex"> 
                <v-col class="pa-0">
                    <SwitchCustom v-model="tabla.principal" label="Principal"/>
                </v-col>

                <v-col class="pa-0">
                    <SelectOrigenes v-show="!modoAlta" v-model="tabla.idorigenalta"
                        titulo="Origen Alta" 
                        readonly/>
                </v-col>
            </div>

        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input';
    import SwitchCustom from '../../components/base/Switch.vue';
    import SelectOrigenes from '../../components/select/SelectOrigenes.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SwitchCustom,
            SelectOrigenes,
        },

        data: () => ({
            tabla: {
                nombre: "",
                telefono: "",
                email: "",
                observaciones: "",
                principal: false,
            },
        }),

        computed:{
            focusNombre() {
                return this.tabla.nombre === '' || !this.modoAlta   
            },
        }
    }    

</script>

<style>
    .soloMayusculas input{
        text-transform: uppercase
    }
</style>