import axios from 'axios'
const controller = "tiposcomprobante/"

class TiposComprobantesApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){ 
        return axios.get(controller,{params: {
            idsTipoTipoComprobante: parametros.idsTipoTipoComprobante,
            activo: parametros.activo,
        }})
    }

    async select(parametros){
        let response = await axios.get(controller + "select",{params: {
            idsTiposTipoComprobante: parametros.idsTiposTipoComprobante,
            puntoVenta: parametros.puntoVenta,
            ivaEmisor: parametros.ivaEmisor,
            ivaReceptor: parametros.ivaReceptor
        }});
        for (let index=0; index < response.data.length; index++) {
            response.data[index].nombre = response.data[index].nombre + ' ' + response.data[index].letra
        }
        return response
    }

}

export const tiposComprobantesApi = new TiposComprobantesApi();