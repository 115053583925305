import dayjs from "dayjs"

const state = {
    dias: [ 
        {"id":0,"nombre":"Domingo"},
        {"id":1,"nombre":"Lunes"},
        {"id":2,"nombre":"Martes"},
        {"id":3,"nombre":"Miércoles"},
        {"id":4,"nombre":"Jueves"},
        {"id":5,"nombre":"Viernes"},
        {"id":6,"nombre":"Sábado"}
    ],
    meses: [ 
        {"id":0,"nombre":"Enero"},
        {"id":1,"nombre":"Febrero"},
        {"id":2,"nombre":"Marzo"},
        {"id":3,"nombre":"Abril"},
        {"id":4,"nombre":"Mayo"},
        {"id":5,"nombre":"Junio"},
        {"id":6,"nombre":"Julio"},
        {"id":7,"nombre":"Agosto"},
        {"id":8,"nombre":"Septiembre"},
        {"id":9,"nombre":"Octubre"},
        {"id":10,"nombre":"Noviembre"},
        {"id":11,"nombre":"Diciembre"}
    ],
    desde: dayjs().startOf("month").format("YYYY-MM-DD"),
    hasta: dayjs().endOf("month").format("YYYY-MM-DD"),
};

const actions = { 
    async selectDias(){
        return this.state.fechasModule.dias
    },
    async selectMeses(){
        return this.state.fechasModule.meses
    },
    setFechaDesde({commit},fecha){
        if(!fecha){
            fecha = dayjs(new Date()).format('YYYY-MM-DD');
        }
        commit("setFechaDesde",fecha) 
    },
    setFechaHasta({commit},fecha){
        if(!fecha){
            fecha = dayjs(new Date()).format('YYYY-MM-DD');
        }
        commit("setFechaHasta",fecha) 
    },
};

const mutations = {
    setFechaDesde:(state,desde)=>(
        state.desde = desde
    ),
    setFechaHasta:(state,hasta)=>(
        state.hasta = hasta
    ),
};


export default {
    state,
    actions,
    mutations,
}