<template>
    <FormEdicion
        v-model="show"
        titulo="Persona"
        maxWidth="50%"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :endPointAuditoria="endPointAuditoria"
        :permiteModificar="permiteModificar"
        :permiteEliminar="permiteEliminar"
        :tabla="tabla"
        :camposPorDefecto="camposPorDefecto"
        @actualizoFormEdicion="actualizoFormEdicion">

        <template v-slot:Campos>
            <div class="d-flex">
                <select-tipos-persona v-model="tabla.idtipopersona" obligatorio/>
                <Input v-model="tabla.nombre"
                    :label="labelNombre"
                    autofocus
                    obligatorio
                    class="soloMayusculas pl-1"/>
                <Input v-model="tabla.apellido"
                    v-show="personaFisica"
                    label="Apellido"
                    :obligatorio="personaFisica"
                    class="soloMayusculas pl-1"/>
            </div>

            <div class="d-flex">
                <select-tipos-documento v-model="tabla.idtipodocumento" 
                    obligatorio
                    :personaFisica="personaFisica"
                    :personaJuridica="personaJuridica"
                    @valorSecundario="valorSecundarioTiposDocumento"/>
                
                <Input v-model="tabla.nrodocumento"
                    class="pl-1"
                    label="Núm. Documento"
                    obligatorio
                    :tipo= "formatoCuit ? 'cuit' : 'dni'"/>
                
                <select-condicion-iva v-model="tabla.idcondicioniva"
                    class="pl-1"
                    obligatorio
                    :personaFisica="personaFisica"
                    :personaJuridica="personaJuridica"/>

                <Input v-model="tabla.cuitcuil"
                    class="pl-1"
                    v-show="personaFisica"
                    label="Cuit/Cuil"
                    tipo="cuit"/>
            </div>

            <Tab v-model="selectedTabPrincipal" :tabs="tabPrincipal">
                <template v-slot:datos>
                    <v-card>
                        <v-card-text class="pa-0 pt-2">                                
                            <div class="d-flex">  
                                <v-col cols="7" class="pa-0 pr-1"> 
                                    <Input v-model="tabla.direccion" label="Dirección" obligatorio class="soloMayusculas"/>
                                </v-col>
                                <v-col cols="5" class="pa-0"> 
                                    <SelectLocalidades v-model="tabla.idlocalidad" obligatorio/>
                                </v-col>
                            </div>

                            <div class="d-flex">    
                                <SelectSexos v-model="tabla.idsexo" v-show="personaFisica" :obligatorio="personaFisica" class="pr-1"/>
                                <InputFecha v-model="tabla.fechanacimiento" v-if="personaFisica" label="Fecha de Nacimiento"/>
                                <Input v-model="edad" v-show="personaFisica" label="Edad" readonly class="pl-1 pr-1"/>
                                <SelectOrigenes v-model="tabla.idorigenalta" 
                                    v-show="tabla.id != 0" 
                                    titulo="Origen Alta" 
                                    readonly/>
                            </div>

                            <input-text-area v-model="tabla.observaciones" label="Observaciones"/>

                        </v-card-text>
                    </v-card>
                </template>

                <template v-slot:contactos>
                    <v-card>                    
                        <v-card-text class="pa-0">
                            <div class="d-flex justify-center">
                                <Button tipo="agregar" @click="agregarContacto"/>
                            </div>
                            <div class="d-flex justify-center text-h7" v-if="tabla.contactos.length === 0">
                                {{mensajeVacio}}
                            </div>
                            <v-row dense class="pa-1"> 
                                <v-col v-for="item in tabla.contactos"
                                    :key="item.index"
                                    :cols=6>
                                    <v-card>
                                        <v-card-title class="pa-1 text-subtitle-2">
                                            <v-icon>person</v-icon>
                                            {{item.nombre}}     
                                            <v-spacer/>
                                            <Button tipo="modificar" size="x-small" @click="editarContacto(item,tabla.contactos.indexOf(item))"/>
                                            <Button tipo="eliminar" size="x-small" @click="eliminarContacto(tabla.contactos.indexOf(item))"/>
                                        </v-card-title>

                                        <v-card-text class="pa-0 pb-2">
                                            <v-row align="center" class="mx-0">
                                                <ButtonWhatsapp :numero="item.telefono" :texto="'Hola ' + item.nombre  + ', ¿como estás?'" v-if="item.telefono"/>
                                                {{item.telefono}}
                                                <ButtonMail :para="item.email" :cuerpo="'Hola ' + item.nombre  + ', ¿como estás?'" v-if="item.email"/>
                                                {{item.email}}
                                            </v-row>
                                            <v-row align="center" class="ml-1" v-show="item.observaciones">
                                                {{item.observaciones}}
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <template v-slot:familiares>
                    <v-card>                    
                        <v-card-text class="pa-0">
                            <div class="d-flex justify-center">
                                <Button tipo="agregar" @click="agregarFamiliar"/>
                            </div>
                            <div class="d-flex justify-center text-h7" v-if="tabla.familiares.length === 0">
                                {{mensajeVacio}}
                            </div>
                            <v-row dense class="pa-1"> 
                                <v-col v-for="item in tabla.familiares"
                                    :key="item.index"
                                    :cols=6>
                                    <v-card>
                                        <v-card-title class="pa-1 text-subtitle-2">
                                            <v-icon>person</v-icon>
                                            {{item.vinculo.nombre}}     
                                            <v-spacer/>
                                            <Button tipo="modificar" size="x-small" @click="editarFamiliar(item,tabla.familiares.indexOf(item))"/>
                                            <Button tipo="eliminar" size="x-small" @click="eliminarFamiliar(tabla.familiares.indexOf(item))"/>
                                        </v-card-title>
                                        <v-card-text class="pa-1">
                                            <v-row class="ma-0">
                                                {{item.persona.nombre}}
                                                <v-spacer/>
                                                {{item.persona.tipodocumento.nombre + " " + item.persona.nrodocumento}}
                                                <v-spacer/>
                                                {{calcularEdad(item.persona.fechanacimiento)}}
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </template>

                <template v-slot:habilitaciones>
                    <v-card-text class="pa-0">
                        <div class="d-flex justify-center">
                            <Button tipo="agregar" @click="agregarActividad"/>
                        </div>
                        <div class="d-flex justify-center text-h7" v-if="tabla.actividadespersonas.length === 0">
                            {{mensajeVacio}}
                        </div>
                        <v-col v-for="item in tabla.actividadespersonas"
                            :key="item.index"
                            :cols=12
                            class="pa-1">
                            <v-card>
                                <v-card-title class="pa-1 text-subtitle-2">
                                    <v-col class="pa-0">
                                        {{item.actividadeconomica.codigo}} {{item.actividadeconomica.nombre}}                        
                                    </v-col>                                        
                                    <Button tipo="modificar" size="x-small" @click="editarActividad(item,tabla.actividadespersonas.indexOf(item))"/>
                                    <Button tipo="eliminar" size="x-small" @click="eliminarActividad(tabla.actividadespersonas.indexOf(item))"/>        
                                </v-card-title>

                                <v-card-text class="pa-1">
                                    <v-row class="ma-0">
                                        Dirección: {{item.direccion}} {{item.localidad.nombre}}
                                    </v-row>
                                    <v-row class="ma-0">
                                        Estado: {{item.estadoactividad.nombre}} 
                                        <v-spacer/>
                                        Fecha Habilitación: {{item.emision | fecha}}
                                    </v-row>
                                    <v-row class="ma-0" v-show="item.observaciones">
                                        {{item.observaciones}}
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-card-text>
                </template>
            </Tab>    

            <Tab v-model="selectedTabSecundario" 
                v-show="tabla.detalleturnos.length != 0 || tabla.detalleinscripciones.length != 0 || tabla.detalleacciones.length != 0  || tabla.detallecomprobantes.length != 0"
                :tabs="tabSecundario">
                <template v-slot:turnos>
                    <v-row dense> 
                        <v-col v-for="item in tabla.detalleturnos"
                            :key="item.index"
                            :cols=12>
                            <v-card :color="item.estado.color" dark>
                                <v-card-title class="pa-1 text-subtitle-2">
                                    <v-col class="d-flex pa-0">
                                        {{item.turno.sector.nombre}}                        
                                    </v-col>           
                                    <div v-if="usuario.administrador || (usuario.sectores.includes(item.turno.sector.id) && permiteModificarTurno)">
                                        <Button tipo="modificar" size="x-small" @click="editarTurno(item.id)" />                    
                                    </div>
                                </v-card-title>

                                <v-card-text class="d-flex pa-1">
                                        Día: {{$obtenerDiaEnLetras(new Date(item.fechahora))}}
                                        <v-spacer/>
                                        Hora: {{$obtenerHora(new Date(item.fechahora),'hml')}}
                                        <v-spacer/>
                                        Estado: {{item.estado.nombre}} 
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>

                <template v-slot:inscripciones>
                    <v-card>                    
                        <v-card-text class="pa-0">
                            <v-col v-for="item in tabla.detalleinscripciones"
                                :key="item.index"
                                :cols=12
                                class="pa-1">
                                <v-card>
                                    <v-card-title class="pa-1 text-subtitle-2">
                                        <v-col class="d-flex pa-0">
                                            {{item.fechahora | fecha}} {{item.inscripcion.sector.nombre}}: {{item.inscripcion.nombre}}                         
                                        </v-col>           
                                        <div>
                                            <Button tipo="modificar" size="x-small" @click="editarInscripcion(item.id)" />                    
                                        </div>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </template>

                <template v-slot:acciones>
                    <v-card>                    
                        <v-card-text class="pa-0">
                            <v-col v-for="item in tabla.detalleacciones"
                                :key="item.index"
                                :cols=12
                                class="pa-1">
                                <v-card>
                                    <v-card-title class="pa-1 text-subtitle-2">
                                        <v-col class="d-flex pa-0">
                                            {{item.fecha | fecha}}
                                            <v-spacer/>
                                            {{item.tipoaccion.nombre}}
                                            <v-spacer/>
                                            {{item.titulo}}  
                                            <v-spacer/>                       
                                        </v-col>           
                                        <div>
                                            <Button tipo="modificar" size="x-small" @click="editarAccion(item.id)" />                    
                                        </div>
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </template>

                <template v-slot:comprobantes>
                    <v-card>                    
                        <v-card-text class="pa-0">
                            <v-col v-for="item in tabla.detallecomprobantes"
                                :key="item.index"
                                :cols=12
                                class="pa-1">
                                <v-card>
                                    <v-card-title class="pa-1 text-subtitle-2">
                                        <v-col class="d-flex pa-0">
                                            {{item.fecha | fecha}}
                                            <v-spacer/>
                                            {{item.tipocomprobante.tipotipocomprobante.nombre + " " + item.tipocomprobante.nombrecorto + " " + 
                                            item.tipocomprobante.letra + " " + item.puntoventa.nombre +  "-" + item.numero}}  
                                            <v-spacer/>
                                            <Button tipo="modificar" size="x-small" @click="editarComprobante(item.id)" />      
                                        </v-col>           
                                    </v-card-title>
                                </v-card>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </template>

            </Tab>

            <Contacto v-model="showContacto"
                :modoAlta="altaContacto"
                :modoModificacion="!altaContacto"
                :tablaExterna="contacto"
                :tablaValoresPorDefecto="contactoPorDefecto"
                @actualizoFormEdicion="actualizarContacto"
                @eliminar="eliminarContacto"/>

            <ActividadPersona v-model="showActividad"
                :modoAlta="altaActividad"
                :modoModificacion="!altaActividad"
                :tablaExterna="actividad"
                :tablaValoresPorDefecto="actividadPorDefecto"
                @actualizoFormEdicion="actualizarActividad"
                @eliminar="eliminarActividad"/>
            
            <Familiar v-model="showFamiliar"
                :modoAlta="altaFamiliar"
                :modoModificacion="!altaFamiliar"
                :tablaExterna="familiar"
                :tablaValoresPorDefecto="familiarPorDefecto"
                :idsPersonasExcluidas="idsPersonasExcluidas"
                @actualizoFormEdicion="actualizarFamiliar"
                @eliminar="eliminarFamiliar"/>

            <TurnoAsignar v-model="showTurno" :id="idTurno" @actualizoFormEdicion="actualizarDatosPersona"/>   
            <DetalleInscripcion v-model="showInscripcion" :id="idInscripcion" @actualizoFormEdicion="actualizarDatosPersona"/>
            <AccionPersona v-model="showAccion" :id="idAccion" @actualizoFormEdicion="actualizarDatosPersona"/>
            <Comprobante v-model="showComprobante" :id="idComprobante" @actualizoFormEdicion="actualizarDatosPersona"/>
        </template>    
    </FormEdicion>
</template>

<script>

    import {mapState} from 'vuex';
    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {personasApi} from '../../api/PersonasApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Contacto from './Contacto.vue'
    import ActividadPersona from './ActividadPersona.vue'
    import SelectLocalidades from '@/components/select/SelectLocalidades';
    import Button from '../../components/base/button/Button.vue';
    import ButtonWhatsapp from '../../components/base/button/ButtonWhatsapp.vue';
    import ButtonMail from '../../components/base/button/ButtonMail.vue';
    import Input from '@/components/input/Input';
    import TurnoAsignar from '../Turnos/TurnoAsignar.vue';
    import SelectSexos from '../../components/select/SelectSexos.vue';
    import InputFecha from '../../components/input/InputFecha.vue';
    import DetalleInscripcion from '../Inscripciones/DetalleInscripcion.vue';
    import Tab from '../../components/base/Tab.vue';
    import SelectOrigenes from '../../components/select/SelectOrigenes.vue';
    import Familiar from './Familiar.vue';
    import AccionPersona from '../Acciones Personas/AccionPersona.vue';
    import Comprobante from '../Comprobantes/Comprobante.vue';
    import SelectTiposPersona from '../../components/select/SelectTiposPersona.vue';
    import InputTextArea from '../../components/input/InputTextArea.vue';
    import SelectTiposDocumento from '../../components/select/SelectTiposDocumento.vue';
    import SelectCondicionIva from '../../components/select/SelectCondicionIva.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            SelectLocalidades,
            Contacto,
            Button,
            ButtonMail,
            ButtonWhatsapp,
            Input,
            ActividadPersona,
            TurnoAsignar,
            SelectSexos,
            InputFecha,
            DetalleInscripcion,
            Tab,
            SelectOrigenes,
            Familiar,
            AccionPersona,
            Comprobante,
            SelectTiposPersona,
            InputTextArea,
            SelectTiposDocumento,
            SelectCondicionIva,
        },

        data: () => ({
            endPointValoresPorDefecto: personasApi.porDefecto,
            endPointBuscar: personasApi.buscar,
            endPointGuardar: personasApi.guardar,
            endPointEliminar: personasApi.eliminarVarios,
            endPointAuditoria: personasApi.auditoria,
            tabla: {
                nombre: "",
                apellido: "",
                direccion: "",
                idtipodocumento: 0,
                nrodocumento: "",
                cuitcuil:"",
                idlocalidad: 0,
                idcondicioniva: 0,     
                idsexo: null,
                fechanacimiento: null,
                idtipopersona: 0,
                observaciones: "",
                contactos: [],
                actividadespersonas: [],
                familiares :[],
                detalleturnos: [],
                detalleinscripciones: [],
                detalleacciones: [],
                detallecomprobantes: [],
            },
            camposPorDefecto:{
                idtipopersona: 1,
                idcondicioniva: 1,
            },
            contacto:{},
            contactoPorDefecto:{},
            actividad:{},
            actividadPorDefecto:{},
            familiar:{},
            familiarPorDefecto:{},
            showContacto:false,
            showActividad:false,
            showFamiliar:false,
            altaContacto:false,
            altaActividad:false,
            altaFamiliar:false,
            showTurno:false,
            showInscripcion:false,
            showAccion:false,
            showComprobante:false,
            idTurno:0,
            idInscripcion:0,
            idAccion:0,
            idComprobante:0,
            formatoCuit:false,
            mensajeVacio: "Nada que mostrar por aquí...",
            tabPrincipal:[
                {nombre: 'datos', habilitado: true, show: true},
                {nombre: 'contactos', habilitado: true, show: true, cantidad: 0},
                {nombre: 'habilitaciones', show: true, cantidad: 0},
                {nombre: 'familiares', show: true, cantidad: 0},
            ],
            tabSecundario: [
                {nombre: 'turnos', habilitado: true, cantidad: 0},
                {nombre: 'inscripciones', habilitado: true, cantidad: 0},
                {nombre: 'acciones', habilitado: true, cantidad: 0},
                {nombre: 'comprobantes', habilitado: true, cantidad: 0},
            ],
            selectedTabPrincipal:0,
            selectedTabSecundario:0,
            idsPersonasExcluidas:[],
        }),

        computed: {
            edad(){
                return this.calcularEdad(this.tabla.fechanacimiento)
            },
            personaFisica(){
                return this.tabla.idtipopersona === 1
            },  
            personaJuridica(){
                return this.tabla.idtipopersona === 2
            },  
            labelNombre(){
                return this.personaFisica ? "Nombre" : "Razón Social"
            },
            ...mapState({
                usuario: state => state.usuarioModule,
                permiteModificar: state => state.usuarioModule.permisos.persona.modificar,
                permiteEliminar: state => state.usuarioModule.permisos.persona.eliminar,
                permiteModificarTurno: state => state.usuarioModule.permisos.turno.modificar,
            }),       
        },

        watch:{
            show(val){
                if (val) this.selectedTab = 0
            },
            'tabla.idtipopersona'(val){
                if(val === 2){
                    this.tabla.apellido = null
                    this.tabla.fechanacimiento = null
                    this.tabla.idsexo = null
                }
            },
            'tabla.contactos.length'(val){
                this.tabPrincipal[1].cantidad = val
            },
            'tabla.actividadespersonas.length'(val){
                this.tabPrincipal[2].cantidad = val
            },
            'tabla.familiares.length'(val){
                this.tabPrincipal[3].cantidad = val
            },
            'tabla.detalleturnos.length'(val){
                this.tabSecundario[0].cantidad = val
            },
            'tabla.detalleinscripciones.length'(val){
                this.tabSecundario[1].cantidad = val
            },
            'tabla.detalleacciones.length'(val){
                this.tabSecundario[2].cantidad = val
            },
            'tabla.detallecomprobantes.length'(val){
                this.tabSecundario[3].cantidad = val
            },
        },

        created(){
            this.tabPrincipal[2].habilitado = this.usuario && (this.usuario.administrador || this.usuario.modulos.includes('habilitaciones'))
            this.tabPrincipal[3].habilitado = this.usuario && (this.usuario.administrador || this.usuario.modulos.includes('familiares'))
        },

        methods: {

            calcularEdad(fechanacimiento){
                const edad = this.$edad(fechanacimiento)
                return edad != null ? `${edad} Años` : ''
            },

            editarTurno(id){
                this.idTurno = id
                this.showTurno = true
            },
  
            editarInscripcion(id){
                this.idInscripcion = id
                this.showInscripcion = true
            },

            editarAccion(id){
                this.idAccion = id
                this.showAccion = true
            },

            editarComprobante(id){
                this.idComprobante = id
                this.showComprobante = true
            },

            actualizarDatosPersona(){
                this.tabla.actualizar = !this.tabla.actualizar
            },

            agregarContacto(){   
                this.altaContacto = true
                this.contacto = {}
                if(this.tabla.contactos.length === 0){
                    this.contactoPorDefecto.nombre = this.tabla.nombre
                    this.contactoPorDefecto.principal = true
                }else{
                    this.contactoPorDefecto = {}
                }
                this.showContacto = true
            },

            editarContacto(registro,index){
                this.altaContacto = false
                registro.index = index
                this.contacto = registro
                this.showContacto = true
            },

            eliminarContacto(index){
                this.tabla.contactos.splice(index,1);
            },
            
            actualizarContacto(tablaActualizada){
                if (this.altaContacto){
                    this.tabla.contactos.push({
                        nombre:tablaActualizada.nombre.toUpperCase(),
                        telefono:tablaActualizada.telefono,
                        email:tablaActualizada.email,
                        observaciones:tablaActualizada.observaciones,
                        principal:tablaActualizada.principal,
                        idorigenalta:1})
                }else{
                    this.tabla.contactos[tablaActualizada.index].nombre = tablaActualizada.nombre.toUpperCase()
                    this.tabla.contactos[tablaActualizada.index].telefono = tablaActualizada.telefono
                    this.tabla.contactos[tablaActualizada.index].email = tablaActualizada.email
                    this.tabla.contactos[tablaActualizada.index].observaciones = tablaActualizada.observaciones
                    this.tabla.contactos[tablaActualizada.index].principal = tablaActualizada.principal
                }
                if (tablaActualizada.principal === true &&  this.tabla.contactos.length > 1){
                    this.tabla.contactos.forEach(function(elemento,indice) {
                        if(indice != tablaActualizada.index){
                            elemento.principal = false
                        }
                    })
                }                
            },

            agregarActividad(){
                this.altaActividad = true
                this.actividad = {}
                 if(this.tabla.actividadespersonas.length === 0){
                    this.actividadPorDefecto.principal = true
                }else{
                    this.actividadPorDefecto = {}
                }
                this.showActividad = true
            },

            editarActividad(registro,index){
                this.altaActividad = false
                registro.index = index
                this.actividad = registro
                this.showActividad = true
            },

            eliminarActividad(index){
                this.tabla.actividadespersonas.splice(index,1);
            },

            actualizarActividad(tablaActualizada){
                if (this.altaActividad){
                    this.tabla.actividadespersonas.push({
                        actividadeconomica:tablaActualizada.actividadeconomica,
                        direccion:tablaActualizada.direccion.toUpperCase(),
                        localidad:tablaActualizada.localidad,
                        estadoactividad:tablaActualizada.estadoactividad,
                        emision:tablaActualizada.emision,
                        vencimiento:tablaActualizada.vencimiento,
                        principal:tablaActualizada.principal,
                        observaciones:tablaActualizada.observaciones})
                }else{
                    this.tabla.actividadespersonas[tablaActualizada.index].actividadeconomica = tablaActualizada.actividadeconomica
                    this.tabla.actividadespersonas[tablaActualizada.index].direccion = tablaActualizada.direccion.toUpperCase()
                    this.tabla.actividadespersonas[tablaActualizada.index].localidadd = tablaActualizada.localidad
                    this.tabla.actividadespersonas[tablaActualizada.index].estadoactividad = tablaActualizada.estadoactividad
                    this.tabla.actividadespersonas[tablaActualizada.index].emision = tablaActualizada.emision
                    this.tabla.actividadespersonas[tablaActualizada.index].vencimiento = tablaActualizada.vencimiento
                    this.tabla.actividadespersonas[tablaActualizada.index].principal = tablaActualizada.principal
                    this.tabla.actividadespersonas[tablaActualizada.index].observaciones = tablaActualizada.observaciones
                }
                if (tablaActualizada.principal === true &&  this.tabla.actividadespersonas.length > 1){
                    this.tabla.actividadespersonas.forEach(function(elemento,indice) {
                        if(indice != tablaActualizada.index){
                            elemento.principal = false
                        }
                    })
                }          
            },

            agregarFamiliar(){
                this.altaFamiliar = true
                this.familiar = {}
                this.familiarPorDefecto = {}
                this.excluirIdsPersonas()
                this.showFamiliar = true
            },

            editarFamiliar(registro,index){
                this.altaFamiliar = false
                registro.index = index
                this.familiar = registro
                this.excluirIdsPersonas(registro.persona.id)
                this.showFamiliar = true
            },

            excluirIdsPersonas(idAIncluir){
                this.idsPersonasExcluidas.length = 0
                if (this.tabla.id != 0 && this.tabla.id != null){
                    this.idsPersonasExcluidas.push(this.tabla.id)
                }
                for (let i=0; i < this.tabla.familiares.length; i++){
                    const idFamiliar = this.tabla.familiares[i].persona.id
                    if (idFamiliar != idAIncluir){
                        this.idsPersonasExcluidas.push(idFamiliar)
                    }
                }       
            },

            eliminarFamiliar(index){
                this.tabla.familiares.splice(index,1);
            },

            actualizarFamiliar(tablaActualizada){
                if (this.altaFamiliar){
                    this.tabla.familiares.push({
                        vinculo: tablaActualizada.vinculo,
                        persona: tablaActualizada.persona})
                }else{
                    this.tabla.familiares[tablaActualizada.index].vinculo = tablaActualizada.vinculo
                    this.tabla.familiares[tablaActualizada.index].persona = tablaActualizada.persona
                }
            },

            valorSecundarioTiposDocumento(value){
                this.formatoCuit = value.formatocuit
            },

        }
    }
</script>

<style>
    .soloMayusculas input{
        text-transform: uppercase
    }
</style>