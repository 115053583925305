<template>
    <v-date-picker
        v-model="valor"
        v-bind="$attrs" 
        locale="es"
        :color="colorPrincipal"
        :min="fechaMinima"
        :max="fechaMaxima"
        :disabled="disabled"
        :allowed-dates="allowedDates"
        :picker-date.sync="pickerDate"
        :full-width="fullwidth">
    </v-date-picker>
</template>

<script>
    import {mapState} from 'vuex';
    
    export default {

        data: () => ({
            fechaMinima:"",
            fechaMaxima:"",
            fechasDisponibles:[],
            disabled:false,
            pickerDate:"",
        }),

        props:{
            value:{ type: [String,Number,Array]},
            accionFechas: { type: String,
                default:""},
            filtro: { type: Object },
            fullwidth:{ type: Boolean,
                default: false},  
            seleccionaPrimero:{ type: Boolean,
                default: false},  
            limpiar:{ type: Boolean,
                default: false},
        },
        
        watch:{
            filtro: {
                deep: true,
                handler(){
                    this.buscarFechas()
                }
            },
            limpiar(){
                this.fechasDisponibles = []
                this.disabled = true
            }
        },

        computed: {
            ...mapState({colorPrincipal: state => state.colorPrincipal}),
            valor: {
                get() {
                    return this.value;
                },
                set(value) {    
                    this.$emit("input",value);
                }
            },
        },

        methods: {

            allowedDates(value){
                if(this.accionFechas){
                    return this.fechasDisponibles.includes(value)
                }else{
                    return true
                }
            },

            async buscarFechas(){
                this.fechasDisponibles = []
                if(this.accionFechas){
                    this.$store.commit("loadingModule/setIsLoading");
                    try {
                        let me = this

                        const response = await this.$store.dispatch(this.accionFechas,this.filtro)

                        if (response){
                            response.data.fechasdisponibles.map(function(x) {
                                me.fechasDisponibles.push(x.fecha);
                            })

                            if(this.fechasDisponibles[0]){
                                this.fechaMinima = this.fechasDisponibles[0]
                                this.fechaMaxima = this.fechasDisponibles[this.fechasDisponibles.length - 1]
                                this.pickerDate = this.fechaMinima
                                this.disabled = false

                                if(this.fechasDisponibles.length === 1 || this.seleccionaPrimero){
                                    this.valor = this.fechaMinima
                                }else{
                                    this.valor = ""
                                }
                                this.$emit('existenFechasDisponibles')
                            }else{
                                this.disabled = true
                                if(response.data.existenfechasocupadas) {
                                    this.$emit('existenSoloFechasOcupadas')
                                }else{
                                    this.$emit('noExistenFechas')
                                }
                            }
                        }
                       
                    } catch (error) {
                        console.log(error);
                    }
                    this.$store.commit("loadingModule/setIsLoading");
                }else{
                    this.disabled = false
                }
            }
        }
    }
</script>