<template>
    <apexchart ref="apexChart" type="line" :options="chartOptions" :series="chartSeries"/>
</template>

<script>
    export default {

        data: () => ({
            chartSeries: [],
            chartOptions:{
                stroke: {
                    curve: 'smooth',
                },
                xaxis: {
                    categories: [],
                }
            },   
        }),

        props:{
            data:{type:Array},
            xaxis:{type:Array}
        },

        watch:{
            data: {
                deep: true,
                handler(){
                    this.update()
                }
            },
        },

        methods: {
            update(){            

                this.chartSeries = []

                for (let i = 0; i < this.data.length; i++) {
                    this.chartSeries.push({
                        name: this.data[i].nombre,
                        data: this.data[i].datos,
                        color: this.data[i].color,
                    })

                }

                this.$refs.apexChart.updateOptions({
                    xaxis:{
                        categories: Object.keys(this.xaxis).map((key) => {return this.xaxis[key].nombre})
                    }
                })
            }
        }
    }
</script>
