<template>
    <FormEdicion
        v-model="show"
        titulo="Evento"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :permiteModificar="permiteModificar"
        :permiteEliminar="permiteEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <SelectCalendarios v-model="tabla.idcalendario" 
                habilitaAgregar 
                habilitaModificar 
                habilitaDetalle/>
            <Input v-model="tabla.detalle" label="Detalle"/>
            <InputFechaRango v-model="tabla.rangofecha"/>
        </template>    
    </FormEdicion>
</template>
<script>

    import {mapState} from 'vuex';
    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {calendariosApi} from '@/api/CalendariosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '../../components/input/Input.vue';
    import SelectCalendarios from '../../components/select/SelectCalendarios.vue';
    import InputFechaRango from '../../components/input/InputFechaRango.vue';
   
    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectCalendarios,
            InputFechaRango,
        },

        data: () => ({
            endPointBuscar: calendariosApi.buscarDetalle,
            endPointGuardar: calendariosApi.guardarDetalle,
            endPointEliminar: calendariosApi.eliminarVariosDetalle,
            tabla: {
                detalle: '',
                idcalendario: 0,
                rangofecha: [],
            },
        }),

        computed: {
            ...mapState({
                permiteModificar: state => state.usuarioModule.permisos.eventoCalendario.modificar,
                permiteEliminar: state => state.usuarioModule.permisos.eventoCalendario.eliminar,
            }),       
        },
    }
</script>