<template>
    <FormEdicion
        v-model="show"
        titulo="Tipo de Operación"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                label="Nombre"
                obligatorio/>
            <SwitchCustom v-model="tabla.activo" label="Activo"/>
            <SwitchCustom v-model="tabla.emisionpropia" label="Emisión propia"/>
            <SwitchCustom v-model="tabla.solicitaautorizador" label="Solicita autorizador"/>
            <SwitchCustom v-model="tabla.registrapendientes" label="Registra pendientes de artículos"/>
            <SwitchCustom v-model="tabla.permitearticulossincomp" label="Permite ingresar artículos sin comprobantes de origen"/>
            <SwitchCustom v-model="tabla.generacontabilidad" label="Genera contabilidad"/>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import { tiposTiposComprobantesApi } from '../../api/TiposTiposComprobantesApi';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import SwitchCustom from '../../components/base/Switch.vue'
    import Input from '@/components/input/Input.vue';
    
    export default {

        mixins: [
            FormEdicionMixin,
        ],

        components: {
            FormEdicion,
            SwitchCustom,
            Input,
        },
                
        data: () => ({
            endPointBuscar: tiposTiposComprobantesApi.buscar,
            endPointGuardar: tiposTiposComprobantesApi.guardar,
            endPointEliminar: tiposTiposComprobantesApi.eliminarVarios,

            tabla: {
                nombre: '',
                activo: true,
                emisionpropia: true,
                solicitaautorizador: false,
                registrapendientes: false,
                permitearticulossincomp: false,
                generacontabilidad: false,
            },
        }),

    }
</script>