<template>
    <v-row class="fill-height">
        <v-col>
            <v-sheet>
                <v-toolbar flat>
                    <slot name="Otros"/>
                    <v-toolbar-title v-if="$refs.calendar">
                        {{titulo}}
                    </v-toolbar-title> 
                    <v-btn fab text small @click="prev">
                        <v-icon small>
                            arrow_back_ios
                        </v-icon>
                    </v-btn>
                    <v-btn fab text small @click="next">
                        <v-icon small>
                            arrow_forward_ios
                        </v-icon>
                    </v-btn>
                    <v-btn
                        outlined
                        depressed
                        class="mr-2"
                        @click="setToday">
                        Hoy
                    </v-btn>
                    <v-spacer/>
                    <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                outlined
                                color="grey darken-1"
                                v-bind="attrs"
                                v-on="on">
                                <span>{{ typeToLabel[type] }}</span>
                                <v-icon right>
                                    expand_more
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="type = 'day'">
                                <v-list-item-title>Día</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'week'">
                                <v-list-item-title>Semana</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = 'month'">
                                <v-list-item-title>Mes</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="type = '4day'">
                                <v-list-item-title>4 días</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <slot name="Otros2"/>

                </v-toolbar>
            </v-sheet>
            <v-sheet>
                <v-calendar
                    v-model="value"
                    ref="calendar"
                    locale="es"
                    :type="type"
                    :weekdays="weekday"
                    :events="events"
                    :event-overlap-mode="mode"
                    :event-overlap-threshold="30"
                    :event-color="getEventColor"
                    :short-intervals="false"
                    :interval-minutes="intervalo"
                    :interval-count="cantidadIntervalos"
                    :first-interval="primerIntervalo"
                    :start="start"
                    @change="changeCalendar"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    @click:event="showEvent">

                    <template v-slot:day-body="{date, week}">
                        <div
                            class="v-current-time"
                            :class="{first: date === week[0].date}"
                            :style="{top: nowY}">
                        </div>
                    </template>

                </v-calendar>

            </v-sheet>
        </v-col>
    </v-row>
</template>

<script>

    export default {

        data: () => ({
            value: '',
            ready: false,
            type: 'month',
            typeToLabel: {
                month: 'Mes',
                week: 'Semana',
                day: 'Día',
                '4day': '4 Días',
            },
            weekday: [0, 1, 2, 3, 4, 5, 6],
            events: [],
            mode: 'stack',
            intervalo: 15,
        }),

        props:{
            endPointBuscarVarios: {type: Function},
            actualizarBuscar: { type: Boolean,
                default: false}, 
            filtro: { type: Object },
            start: {type: String,
                default:""},
        },

        computed: {
            titulo(){
                return this.$primerLetraMayuscula(this.$refs.calendar.title)
            },
            cal(){
                return this.ready ? this.$refs.calendar : null
            },
            nowY(){
                return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
            },
            cantidadIntervalos(){
                var totalIntervalos = 24 * (60 / this.intervalo)
                return totalIntervalos - this.primerIntervalo
            },
            primerIntervalo(){
                if (!this.events || this.events.length === 0) return 0
                if (this.events[0].timed === 0) return 0
                var primerHora = this.events[0].start.getHours()
                return primerHora * (60/this.intervalo)        
            },
        },

        mounted(){
            this.ready = true
            this.scrollToTime()
            this.updateTime()
        },

        watch:{
            actualizarBuscar(){
                this.buscar()
            },
            filtro: {
                deep: true,
                handler(){
                    this.buscar()
                }
            },
        },

        methods:{

            viewDay({date}){
                this.value = date
                this.type = 'day'
            },
            setToday(){
                this.value = ''
            },
            prev(){
                this.$refs.calendar.prev()
            },
            next() {
                this.$refs.calendar.next()
            },
            getCurrentTime(){
                return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
            },
            scrollToTime(){
                const time = this.getCurrentTime()
                const first = Math.max(0, time - (time % 30) - 30)
                this.cal.scrollToTime(first)
            },
            updateTime(){
                setInterval(() => this.cal.updateTimes(), 60 * 1000)
            },
            changeCalendar({start,end}) {
                this.$emit("changeCalendar",{start,end})
            },

            async buscar() {
                if (this.endPointBuscarVarios){
                    try {
                        this.$store.commit("loadingModule/setIsLoading");
                        const {data} = await this.endPointBuscarVarios(this.filtro)
                        
                        this.events = []
                        this.weekday = []

                        for (let i = 0; i < data.length; i++) {

                            this.events.push({
                                id: data[i].id,
                                name: data[i].detalle,
                                color: data[i].color,
                                start: data[i].definehora ? new Date(data[i].fechahorainicio) : new Date(data[i].fechahorainicio).toJSON().substring(0,10),
                                end: data[i].definehora ? new Date(data[i].fechahorafin) :  new Date(data[i].fechahorafin).toJSON().substring(0,10),
                                timed: data[i].definehora ? 1 : 0,
                                otros: data[i],
                            })

                            const dia = new Date(data[i].fechahorainicio).getUTCDay()
                            if (!data[i].idferiado && !this.weekday.includes(dia)){
                                this.weekday.push(dia)
                            }   
                        }
                        this.weekday = this.weekday.sort()

                        if (this.weekday.length == 0){
                            this.weekday = [0, 1, 2, 3, 4, 5, 6]
                        }

                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.$store.commit("loadingModule/setIsLoading");
                    }
                    
                }
            },

            showEvent ({nativeEvent, event }) {
                this.$emit("mostrarEvento",event)
            },
            
            getEventColor (event) {
                 return event.color
            },
        }    
    }
</script>

<style lang="scss">
    .v-current-time {
        height: 2px;
        background-color: #ea4335;
        position: absolute;
        left: -1px;
        right: 0;
        pointer-events: none;

        &.first::before {
            content: '';
            position: absolute;
            background-color: #ea4335;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-top: -5px;
            margin-left: -6.5px;
        }
    }
</style>