export const SelectMixin = {

    data: () => ({
        actualizarSelect: false,
        mostrarFormEdicion: false,
        mostrarFormABM: false,
        busquedaSecuencial: false,
        usarSearchInput: false,
        filtro:{
            ids: null,
            busqueda: null,
        },    
        adicionales: null,
        valoresPorDefecto: [],
    }),

    props: {
        value: {type: [Array,Number],
            default(){return[]}},
        habilitaAgregar: {type: Boolean,
            default: false},  
        habilitaModificar: {type: Boolean,
            default: false},  
        habilitaDetalle: {type: Boolean,
            default: false}, 
        obligatorio: {type: Boolean,
            default: false},       
        multiple: {type: Boolean,
            default: false},
        autofocus: {type: Boolean,
            default: false},         
        clearable:{type:Boolean,
            default: true},    
        readonly: {type:Boolean,
            default: false},   
        seleccionaPrimero: {type: Boolean,
            default: false},  
        seleccionaUltimo:{type:Boolean,
            default:false}, 
        showSlotDetalle:{type:Boolean,
            default:false},   
        dense:{type:Boolean,
            default:false}, 
        outlined:{type:Boolean,
            default:false}, 
        hidedetails:{type:Boolean,
            default:false}, 
        guardarIdsSeleccionados: { type: Boolean,
            default:false},
        seleccionarTodosAlSetear:{type:Boolean,
            default:false},
        maxWidthFormABM: {type: String, 
            default: '50%'},
        hidedetails:{type:Boolean,
            default: false}, 
    },

    computed: {
        itemsSeleccionados: {
            get() {                
                if (this.value != null && this.value != 0 && this.busquedaSecuencial){
                    this.filtro.ids = [this.value]  
                    this.filtro.busqueda = null
                    this.usarSearchInput = false
                }
                return this.value
            },
            set(value) {  
                this.$emit("input",value)
            }
        },
    },
    
    methods: {
        
        searchInput(val){
            if (this.usarSearchInput){
                this.filtro.ids = null,
                this.filtro.busqueda = val    
            }else{
                this.usarSearchInput = true
            }    
        },

        valorSecundario(registro){
            this.adicionales = registro
            this.$emit("valorSecundario",registro)
        },

        resetValorSecundario(){
            this.$emit("resetValorSecundario");
        },

        clear(){
            this.$emit("valorSecundario")
        },

        seleccionar(val){
            if (!this.multiple){
                this.filtro.ids = [val.id]
                this.itemsSeleccionados = val.id
            }else{
                this.itemsSeleccionados.length = 0
                for (var i in val) {
                    this.itemsSeleccionados.push(val[i].id)
                }
                this.filtro.ids = this.itemsSeleccionados
            }
            this.cerrar()
        },
        
        actualizoFormEdicion(id,alta){
            if (!this.multiple && alta){
                this.filtro.busqueda = null
                this.filtro.ids = [id]
                this.itemsSeleccionados = id
            } 
        },

        cerrar(){
            this.mostrarFormABM = false
            this.$emit("cerrar")
        },      
        
        cantidadItems(val){
            this.$emit("cantidadItems",val) 
        },

    }
}

