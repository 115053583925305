import axios from 'axios'
const controller = "puntosventa/"

class PuntosVentaApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){ 
        return axios.get(controller,{params: {
            activo: parametros.activo,
        }})
    }

    async select(parametros){
        let response = await axios.get(controller + "select",{params:{
            soloActivos: parametros.soloActivos,
            idTipoComprobante: parametros.idTipoComprobante}   
        });

        for (let index=0; index < response.data.length; index++) {
            response.data[index].nombre = response.data[index].nombre.toString()
        }
        return response
    }
}
export const puntosVentaApi = new PuntosVentaApi();