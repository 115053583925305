<template>
  <FormABM
    :show="show"
    titulo="Detalle de Comprobantes"
    :endPointBuscarVarios="endPointBuscarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion="modoSeleccion"
    :multiple="multiple"
    :filtro="filtro"
    :permiteAgregar="false"
    @cerrar="cerrar"
    @seleccionar="seleccionar">
    
    <template v-slot:CompFiltros>
      <div class = "d-flex">
        <v-col cols="2" class="pa-0">
          <InputFechaRango v-model="filtro.rangoFecha"/> 
        </v-col>
        <v-col cols="2" class="pa-0 pl-1">
          <SelectTiposTipoComprobante v-model="filtro.idsTipoTipoComprobante" multiple/>
        </v-col>
        <v-col cols="2" class="pa-0 pl-1">
          <SelectTiposComprobante v-model="filtro.idsTipoComprobante"
            :idsTiposTipoComprobante="filtro.idsTipoTipoComprobante"
            multiple/>
        </v-col>
        <v-col cols="2" class="pa-0 pl-1">
          <SelectPersonas v-model="filtro.idPersona"/>
        </v-col>
        <v-col cols="2" class="pa-0 pl-1">
          <SelectPersonas v-model="filtro.idSolicitante" titulo="Solicitante"/>
        </v-col>
        <v-col cols="1" class="pa-0 pl-1">
          <SelectSectores v-model="filtro.idsSectores" multiple/>
        </v-col>
        <v-col cols="1" class="pa-0 pl-1">
          <SwitchCustom v-model="filtro.pendiente" label="Pendientes"/>
        </v-col>
      </div>
    </template>
  
  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {comprobantesApi} from '@/api/ComprobantesApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import InputFechaRango from '@/components/input/InputFechaRango.vue';
  import SelectTriple from '@/components/base/SelectTriple.vue';
  import SelectTiposTipoComprobante from '@/components/select/SelectTiposTipoComprobante.vue';
  import SelectTiposComprobante from '@/components/select/SelectTiposComprobante.vue';
  import SelectPersonas from '@/components/select/SelectPersonas.vue';
  import SelectSectores from '@/components/select/SelectSectores.vue';
  import SwitchCustom from '../../components/base/Switch.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      InputFechaRango,
      SelectTriple,
      SelectTiposTipoComprobante,
      SelectTiposComprobante,
      SelectPersonas,
      SelectSectores,
      SwitchCustom,
    },

    data: () => ({
      endPointBuscarVarios: comprobantesApi.buscarVariosDetalle,
      headers: [
        {text: "Fecha", value: "comprobante.fecha", groupable: false},
        {text: "Comprobante", value: "comprobante.detalle"},
        {text: "Código", value: "articulo.codigo", groupable: false},
        {text: "Detalle", value: "articulo.nombre"},
        {text: "Cantidad", value: "cantidad", groupable: false, width: "6%"},
        {text: "Unitario", value: "unitario", groupable: false, width: "7%"},
        {text: "Total", value: "total", groupable: false, width: "7%"},
        {text: "Pendiente", value: "pendiente", groupable: false, width: "6%"},
        {text: "Persona", value: "comprobante.persona.nombre"},
        {text: "Solicitante", value: "comprobante.solicitante.nombre"},
        {text: "Sector", value: "comprobante.sector.nombre"}],
      filtro:{
        rangoFecha: [],
        idsTipoTipoComprobante: null,
        idsTipoComprobante: null,
        idPersona: null,
        idSolicitante: null,
        idsSectores: null,
        pendiente: true,
      }
    }),

    props: {
      idPersona: {type: Number},    
    },

    watch: {
      idPersona: {
        immediate: true,
        handler(val){
          this.filtro.idPersona = val
        }
      },  
    },

  }
</script>