<template>
  <FormABM
        :show="show"
        titulo="Eventos de Calendarios"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :permiteAgregar="permiteAgregar"
        :permiteModificar="permiteModificar"
        :permiteEliminar="permiteEliminar"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"       
        :modoSeleccion="modoSeleccion"
        :multiple="multiple"
        :filtro="filtro"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar">

        <template v-slot:CompFiltros>
            <div class="d-flex">
                <v-col cols="6" class="pa-0 mr-1">
                    <InputFechaRango v-model="filtro.rangoFecha"/>
                </v-col>
                <v-col cols="6" class="pa-0 mr-1">
                    <SelectCalendarios v-model="filtro.idsCalendarios" habilitaDetalle multiple/>
                </v-col>
            </div>
        </template>  

        <template v-slot:FormEdicion>
            <DetalleCalendario v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
        </template>    
    </FormABM>    
</template>

<script>

    import {mapState} from 'vuex';
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {calendariosApi} from '@/api/CalendariosApi'
    import FormABM from '@/components/base/form/FormABM.vue';
    import DetalleCalendario from './DetalleCalendario.vue'
    import InputFechaRango from '@/components/input/InputFechaRango.vue';
    import SelectCalendarios from '@/components/select/SelectCalendarios.vue';

    export default {
    
        mixins: [FormABMMixin],

        components: {
            FormABM, 
            DetalleCalendario,
            InputFechaRango,
            SelectCalendarios,
        },

        data: () => ({
            endPointBuscarVarios: calendariosApi.buscarVariosDetalle,
            endPointEliminarVarios: calendariosApi.eliminarVariosDetalle,
            headers: [
                {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
                {text: "Rango de fechas", value: "rangofecha", groupable: false},
                {text: "Calendario", value: "calendario.nombre"},
                {text: "Detalle", value: "detalle", groupable: false},
            ],
            filtro:{
                idsCalendarios: null,
                rangoFecha: [],
            },
        }),

        computed: {
            ...mapState({
                permiteAgregar: state => state.usuarioModule.permisos.eventoCalendario.agregar,
                permiteModificar: state => state.usuarioModule.permisos.eventoCalendario.modificar,
                permiteEliminar: state => state.usuarioModule.permisos.eventoCalendario.eliminar,
            }),       
        },

        created(){
            this.filtro.rangoFecha = this.$rangoSeteado()
        },
    }
</script>