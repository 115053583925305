<template>
    <FormABM 
        :show="show"
        :titulo="titulo"    
        :permiteAgregar="false"
        :permiteEliminar="false"
        showFiltro
        @filtrar="filtrar">
        
        <template v-slot:BotonesAdicionales>
            <Button tipo="listado" :visible="!mostrarListado" 
                @click="mostrar('listado')"/>
            <Button tipo="estadisticas" :visible="!mostrarEstadisticas" 
                @click="mostrar('estadisticas')"/>
        </template>

        <template v-slot:CompFiltros>
            <div class = "d-flex">
                <v-col cols="2" class="pa-0">
                    <input-fecha-rango v-model="filtro.rangoFecha"/> 
                </v-col>
                <v-col cols="2" class="pa-0 pl-1">
                    <select-tipos-tipo-comprobante v-model="filtro.idsTipoTipoComprobante" multiple/>
                </v-col>
                <v-col cols="2" class="pa-0 pl-1">
                    <select-tipos-comprobante v-model="filtro.idsTipoComprobante"
                        :idsTiposTipoComprobante="filtro.idsTipoTipoComprobante"
                        multiple/>
                </v-col>
                <v-col cols="2" class="pa-0 pl-1">
                    <select-personas v-model="filtro.idPersona"/>
                </v-col>
                <v-col cols="2" class="pa-0 pl-1">
                    <select-personas v-model="filtro.idSolicitante" titulo="Solicitante"/>
                </v-col>
                <v-col cols="2" class="pa-0 pl-1">
                    <select-sectores v-model="filtro.idsSectores" multiple/>
                </v-col>
            </div>
            <div class = "d-flex">
                <v-col cols="2" class="pa-0">
                    <select-articulos v-model="filtro.idArticulo"/>
                </v-col>
                <v-col cols="4" class="pa-0 pl-1">
                    <select-rubros-subrubros :rubrosSeleccionados.sync="filtro.idsRubros" :subrubrosSeleccionados.sync="filtro.idsSubrubros" multiple/>
                </v-col>
                <v-col cols="2" class="pa-0 pl-1">
                    <select-usuarios v-model="filtro.idsCreadores" multiple tituloPlural="Creadores"/>
                </v-col>
                <v-col cols="2" class="pa-0 pl-1">
                    <select-usuarios v-model="filtro.idsAutorizadores" multiple soloAutorizadores tituloPlural="Autorizadores"/>
                </v-col>
                <v-col cols="1" class="pa-0 pl-1">
                    <select-triple v-model="filtro.autorizado" label="Autorizado"/>
                </v-col>
                <v-col cols="1" class="pa-0 pl-1">
                    <select-triple v-model="filtro.anulado" label="Anulado"/>
                </v-col>
            </div>
        </template>

        <template v-slot:FormEdicion>
            <comprobantes-listado :show="mostrarListado" :actualizarBuscarExterno="actualizarListado" :filtro="filtro"/>
            <comprobantes-estadisticas :show="mostrarEstadisticas" :actualizarBuscarExterno="actualizarEstadisticas" :filtro="filtro"/>
        </template>    

    </FormABM>    
</template>

<script>

    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import FormABM from '@/components/base/form/FormABM.vue';
    import Button from '../../components/base/button/Button.vue';
    import ComprobantesListado from './ComprobantesListado.vue';
    import ComprobantesEstadisticas from './ComprobantesEstadisticas.vue';
    import InputFechaRango from '@/components/input/InputFechaRango.vue';
    import SelectTriple from '@/components/base/SelectTriple.vue';
    import SelectTiposTipoComprobante from '@/components/select/SelectTiposTipoComprobante.vue';
    import SelectTiposComprobante from '@/components/select/SelectTiposComprobante.vue';
    import SelectPersonas from '@/components/select/SelectPersonas.vue';
    import SelectSectores from '@/components/select/SelectSectores.vue';
    import SelectUsuarios from '../../components/select/SelectUsuarios.vue';
    import SelectRubrosSubrubros from '../../components/select/SelectRubrosSubrubros.vue';
    import SelectArticulos from '../../components/select/SelectArticulos.vue';

    export default {

        mixins: [FormABMMixin],

        components: {
            FormABM,
            ComprobantesListado,
            ComprobantesEstadisticas,
            Button,
            InputFechaRango,
            SelectTriple,
            SelectTiposTipoComprobante,
            SelectTiposComprobante,
            SelectPersonas,
            SelectSectores,
            SelectUsuarios,
            SelectRubrosSubrubros,
            SelectArticulos,
        },

        data: () => ({
            titulo: '',
            mostrarListado: false,
            mostrarEstadisticas: false,
            actualizarListado: false,
            actualizarEstadisticas: false,
            filtro:{
                rangoFecha: [],
                idsTipoTipoComprobante: null,
                idsTipoComprobante: null,
                idArticulo: null,
                idPersona: null,
                idSolicitante: null,
                idsSectores: null,
                idsCreadores: null,
                idsAutorizadores: null,
                idsRubros: null,
                idsSubrubros: null,
                autorizado: null,
                anulado: false,
            },
        }),

        created(){
            this.filtro.rangoFecha[0] = this.$diaActual()
            this.filtro.rangoFecha[1] = this.$diaActual()
            this.mostrar('listado')
        },

        methods: {
            mostrar(tipo){
                this.mostrarListado = false
                this.mostrarEstadisticas = false
                switch (tipo){
                    case 'listado' : this.mostrarListado = true, this.titulo = "Listado de Comprobantes"
                        break;
                    case 'estadisticas' : this.mostrarEstadisticas = true, this.titulo = "Estadísticas de Comprobantes"
                        break;
                }
            },

            filtrar(){
                if (this.mostrarListado) this.actualizarListado = !this.actualizarListado   
                if (this.mostrarEstadisticas) this.actualizarEstadisticas = !this.actualizarEstadisticas  
            },
        },    
    }
</script>