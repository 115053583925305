<template>
    <div>
        <v-tabs v-model="selectedTab" center-active grow dark :background-color="colorPrincipal" :height="27">
            <v-tab v-for="(tab, index) in tabs" :key="index" v-show="tab.habilitado && (tab.show || tab.cantidad != 0)">
                {{tab.titulo || tab.nombre}}
                <v-avatar size="20" :color="colorSecundario" class="ml-1" v-show="tab.cantidad && tab.cantidad != 0">
                    <span>{{tab.cantidad}}</span>
                </v-avatar>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
            <v-tab-item v-for="(tab, index) in tabs" :key="index">
                <slot :name="tab.nombre"></slot>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {

        props: {
            value: {type: Number,
                default: 0},
            tabs: {type: Array},
        },

        computed: {
            selectedTab: {
              get() {return this.value},
                set(value) {this.$emit("input", value)}
            },
            ...mapState({
                colorPrincipal: state => state.colorPrincipal,
                colorSecundario: state => state.colorSecundario,
            }),
        },

    }

</script>