<template>
  <FormABM
    :show="show"
    titulo="Alícuotas de IVA"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion= "modoSeleccion"
    :multiple="multiple"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">
    
    <template v-slot:FormEdicion>
        <AlicuotaIva v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
    </template>    

  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {alicuotasIvaApi} from '@/api/AlicuotasIvaApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import AlicuotaIva from './AlicuotaIva.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      AlicuotaIva,
    },

    data: () => ({
      endPointBuscarVarios: alicuotasIvaApi.buscarVarios,
      endPointEliminarVarios: alicuotasIvaApi.eliminarVarios,
      headers: [
        {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        {text: "Alícuota", value: "nombre", groupable: false},
        {text: "Activo", value: "activo", filterable: false, groupable: false, tipo: "boolean"},
        {text: "Predeterminado", value: "predeterminado", filterable: false, groupable: false, tipo: "boolean" }],   
    }),
  }
</script>