<template>
    <FormEdicion
        v-model="show"
        titulo="Calendario"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :permiteModificar="permiteModificar"
        :permiteEliminar="permiteEliminar"
        :tabla="tabla"
        :habilitaEliminar="habilitaEliminar"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input
                v-model="tabla.nombre"
                label="Nombre"
                obligatorio
            />
            <SelectSectores v-model="tabla.calendariossectores" multiple activoTurnos/>  
            <div class="text-caption pa-0">
                Color
            </div>
            <v-row justify="space-around">
                <v-color-picker
                    v-model="tabla.color"
                    hide-inputs
                ></v-color-picker>
            </v-row>
        </template>    
    </FormEdicion>
</template>

<script>

    import {mapState} from 'vuex';
    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {calendariosApi} from '@/api/CalendariosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue'
    
    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectSectores,
        },

        data: () => ({
            endPointBuscar: calendariosApi.buscar,
            endPointGuardar: calendariosApi.guardar,
            endPointEliminar: calendariosApi.eliminarVarios,
            tabla: {
                nombre: "",
                calendariossectores: [],
                color: "",
            },
        }),
        
        computed: {
            ...mapState({
                permiteModificar: state => state.usuarioModule.permisos.calendario.modificar,
                permiteEliminar: state => state.usuarioModule.permisos.calendario.eliminar,
            }),       
        },
    }
</script>