import Vue from 'vue'
import VueRouter from 'vue-router'

import AccionesPersonas from '../views/Acciones Personas/AccionesPersonas.vue'
import ActividadesEconomicas from '../views/Actividades Economicas/ActividadesEconomicas.vue'
import AlicuotasIva from '../views/Alicuotas IVA/AlicuotasIva.vue'
import Articulos from '../views/Articulos/Articulos.vue'
import Auditoria from '../views/Auditoria/Auditoria.vue'
import Calendarios from '../views/Calendarios/Calendarios.vue'
import Comprobantes from '../views/Comprobantes/Comprobantes.vue'
import DetalleCalendarios from '../views/Calendarios/DetalleCalendarios.vue'
import DetalleInscripciones from '../views/Inscripciones/DetalleInscripciones.vue'
import Empresas from '../views/Empresas/Empresas.vue'
import Feriados from '../views/Feriados/Feriados.vue'
import Home from '../views/Home.vue'
import Inscripciones from '../views/Inscripciones/Inscripciones.vue'
import Localidades from '../views/Localidades/Localidades.vue'
import Login from '../views/Usuarios/Login.vue'
import Modulos from '../views/Modulos/Modulos.vue'
import Normas from '../views/Normas/Normas.vue'
import Personas from '../views/Personas/Personas.vue'
import PuntosVenta from '../views/Comprobantes/PuntosVenta.vue'
import Rubros from '../views/Articulos/Rubros/Rubros.vue'
import Sectores from '../views/Sectores/Sectores.vue'
import Subrubros from '../views/Articulos/Subrubros/Subrubros.vue'
import TiposComprobantes from '../views/Comprobantes/TiposComprobantes.vue'
import TiposTipoComprobantes from '../views/Comprobantes/TiposTipoComprobantes.vue'
import Tramites from '../views/Sectores/Tramites/Tramites.vue'
import Turnos from '../views/Turnos/Turnos.vue'
import Usuarios from '../views/Usuarios/Usuarios.vue'
import Vinculos from '../views/Vinculos/Vinculos.vue'

import store from '../store'

Vue.use(VueRouter)

Vue.prototype.$ColorPrincipal = "blue darken-3"

const routes = [
  {
    path: '',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {libre: true}
  },
  {
    path: '/turnos',
    name: 'turnos',
    component: Turnos,
  },
  {
    path: '/personas',
    name: 'personas',
    component: Personas
  },
  {
    path: '/feriados',
    name: 'feriados',
    component: Feriados
  },
  {
    path: '/sectores',
    name: 'sectores',
    component: Sectores
  },
  {
    path: '/localidades',
    name: 'localidades',
    component: Localidades,
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component: Usuarios
  },
  {
    path: '/actividadeseconomicas',
    name: 'actividades economicas',
    component: ActividadesEconomicas
  },
  {
    path: '/modulos',
    name: 'modulos',
    component: Modulos
  },
  {
    path: '/auditoria',
    name: 'auditoria',
    component: Auditoria
  },
  {
    path: '/tramites',
    name: 'tramites',
    component: Tramites
  },
  {
    path: '/normas',
    name: 'normas',
    component: Normas
  },
  {
    path: '/eventosinscripciones',
    name: 'eventos inscripciones',
    component: Inscripciones,
  },
  {
    path: '/inscripciones',
    name: 'inscripciones',
    component: DetalleInscripciones
  },
  {
    path: '/calendarios',
    name: 'calendarios',
    component: Calendarios
  },
  {
    path: '/eventoscalendarios',
    name : 'eventos calendarios',
    component: DetalleCalendarios,
  },
  {
    path: '/vinculos',
    name: 'vinculos',
    component: Vinculos
  },
  {
    path: '/acciones',
    name: 'acciones personas',
    component: AccionesPersonas
  },
  {
    path: '/articulos',
    name: 'articulos',
    component: Articulos
  },
  {
    path: '/rubros',
    name: 'rubros',
    component: Rubros
  },
  {
    path: '/subrubros',
    name: 'subrubros',
    component: Subrubros
  },
  {
    path: '/alicuotasiva',
    name: 'alicuotas iva',
    component: AlicuotasIva
  },
  {
    path: '/comprobantes',
    name: 'comprobantes',
    component: Comprobantes
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: Empresas
  },
  {
    path: '/tiposcompobantes',
    name: 'tipos comprobantes',
    component: TiposComprobantes
  },
  {
    path: '/tipostiposcompobantes',
    name: 'tipos tipos comprobantes',
    component: TiposTipoComprobantes
  },
  {
    path: '/puntosventa',
    name: 'puntos venta',
    component: PuntosVenta
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next) => {
  if (to.matched.some(record => record.meta.libre)){
    next()
  } else if (store.state.usuarioModule.id){
    if (store.state.usuarioModule.administrador || to.name === 'home' || store.state.usuarioModule.modulos.includes(to.name)) {
      next()
    }
  } else {
    next({
      name: 'login'
    })
  }
})

export default router