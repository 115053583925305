import axios from 'axios'
const controller = "parametros/"

class ParametrosApi {
    
    buscar(){
        return axios.get(controller);
    }

}

export const parametrosApi = new ParametrosApi();