<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Día" 
        tituloPlural="Días" 
        :multiple="multiple"
        :obligatorio="obligatorio"
        :showBotonDetalle="false"
        endPointStore="selectDias">
        <template v-slot:ItemsExtras>
            <v-list-item @click="seleccionarTodos">
                <v-list-item-action>
                    <v-icon :color="itemsSeleccionados.length > 0 ? $ColorPrincipal : ''">{{ icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-title>Solo hábiles</v-list-item-title>
            </v-list-item>
        </template>    
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            habiles:[1,2,3,4,5]
        }),

        computed: {     
            todosSeleccionados () {
                return this.habiles.every(r => this.itemsSeleccionados.indexOf(r) !== -1)
            },

            algunosSeleccionados () {
                return this.habiles.some(r => this.itemsSeleccionados.indexOf(r) !== -1)
            },

            icon () {
                if (this.todosSeleccionados) return 'check_box'
                if (this.algunosSeleccionados) return 'indeterminate_check_box'
                return 'check_box_outline_blank'
            },
            
            itemsSeleccionados: {
                get() {
                   return this.value
                },
                set(value) {
                    this.$emit("input", value)
                }
            },
        },    

        methods: {
            seleccionarTodos () {
                this.$nextTick(() => {
                    if (this.todosSeleccionados) {
                        for(var i = 0; i < this.habiles.length; i++) {
                            this.itemsSeleccionados.splice(this.itemsSeleccionados.indexOf(this.habiles[i]),1)
                        }
                    } else {
                        this.itemsSeleccionados = this.habiles.map((x) => (x));
                    }
                })
            },
         }

    }    
</script>