<template>
    <form-abm 
        :show="show"
        :titulo="titulo"  
        :permiteAgregar="permiteAgregar"
        :permiteEliminar="false"
        showFiltro
        @filtrar="filtrar"
        @actualizar="actualizar">   

        <template v-slot:BotonesAdicionales>
            <Button tipo="listado" 
                :toolTipExt="tipo === 'listado' ? 'Personas con Actividades' : 'Personas'"
                @click="mostrar"/>
        </template>

        <template v-slot:CompFiltros>
            <div class="d-flex">
                <v-col cols="3" class="pa-0"> 
                    <select-localidades v-model="filtro.idsLocalidades" habilitaDetalle multiple/> 
                </v-col> 
                <v-col cols="3" class="pa-0"> 
                    <select-actividades-economicas v-model="filtro.idsActividades" habilitaDetalle multiple />
                </v-col>
                <v-col cols="3" class="pa-0">
                    <select-origenes v-model="filtro.idsOrigenesAlta" multiple/>
                </v-col>
                <v-col cols="3" class="pa-0 pl-1">
                    <select-tipos-persona v-model="filtro.idsTiposPersona" multiple/>
                </v-col>
            </div>
        </template>  

        <template v-slot:FormEdicion>   
            <persona  v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>
            <personas-listado :show="tipo==='listado'" :actualizarBuscarExterno="actualizarListado" :filtro="filtro"/>
            <personas-actividades :show="tipo==='actividades'" :actualizarBuscarExterno="actualizarConActividades" :filtro="filtro"/>
        </template> 

    </form-abm>
</template>

<script>
    
    import {mapState} from 'vuex';
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import FormAbm from '../../components/base/form/FormABM.vue';
    import Button from '../../components/base/button/Button.vue';
    import PersonasListado from './PersonasListado.vue';
    import PersonasActividades from './PersonasActividades.vue';
    import SelectLocalidades from '../../components/select/SelectLocalidades.vue';
    import SelectActividadesEconomicas from '../../components/select/SelectActividadesEconomicas.vue';
    import SelectOrigenes from '../../components/select/SelectOrigenes.vue';
    import SelectTiposPersona from '../../components/select/SelectTiposPersona.vue';
    import Persona from './Persona.vue';

    export default {

        mixins: [FormABMMixin],

        components: { 
            FormAbm,
            Button,
            PersonasListado,
            PersonasActividades,
            SelectLocalidades,
            SelectActividadesEconomicas,
            SelectOrigenes,
            SelectTiposPersona,
            Persona,
        },
        
        data: () => ({
            tipo: '',
            actualizarListado: false,
            actualizarConActividades: false,
            filtro:{
                idsLocalidades: null,
                idsActividades: null,
                idsOrigenesAlta: null,
                idsTiposPersona: null,
            }
        }),

        computed: {
            ...mapState({
                permiteAgregar: state => state.usuarioModule.permisos.persona.agregar,
                permiteModificar: state => state.usuarioModule.permisos.persona.modificar,
                permiteEliminar: state => state.usuarioModule.permisos.persona.eliminar,
                permiteVisualizar: state => state.usuarioModule.permisos.persona.visualizar,
            }),
            titulo(){
                return this.tipo === 'listado' ? "Personas" : "Personas con Actividades"
            },
        },

        created() {
            this.tipo = 'listado'
            this.filtrar()
        },

        methods: {
            mostrar(){
                this.tipo = this.tipo === 'actividades' ? 'listado' : 'actividades'
            },

            filtrar(){
                switch (this.tipo){
                    case 'listado': 
                        this.actualizarListado = !this.actualizarListado   
                        break;
                    case 'actividades': 
                        this.actualizarConActividades = !this.actualizarConActividades  
                        break;
                }
            },
        },    

    }
</script>
