<template>
    <Select 
        v-model="itemsSeleccionados"
        :titulo="titulo" 
        :tituloPlural="tituloPlural"
        :endPointSelect="endPointSelect" 
        :multiple="multiple"
        :filtro="filtro"
        :readonly="readonly"
        :obligatorio="obligatorio">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {usuariosApi} from '@/api/UsuariosApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: usuariosApi.select,
            filtro:{
                soloActivos: false,
                soloAutorizadores: false,
                incluirExterno: false,
                idSector: 0,
            }    
        }),

        props: {
            titulo: {type: String,
                default: "Usuario"},
            tituloPlural: {type: String,
                default: "Usuarios"},
            soloActivos: {type: Boolean,
                default: false},
            soloAutorizadores: {type: Boolean,
                default: false},
            incluirExterno: {type: Boolean,
                default: false},
            idSector: {type: Number,
                default: 0
            }
        },
        
        watch:{
            soloActivos:{
                immediate: true, 
                handler (val) {
                    this.filtro.soloActivos = val
                }
            },
            soloAutorizadores:{
                immediate: true, 
                handler (val) {
                    this.filtro.soloAutorizadores = val
                }
            },
            incluirExterno:{
                immediate: true, 
                handler (val) {
                    this.filtro.incluirExterno = val
                }
            },    
            idSector:{
                immediate: true, 
                handler (val) {
                    this.filtro.id = null
                    this.filtro.idSector = val
                }
            }
        },
    }    
</script>