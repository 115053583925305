<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Tipo Trámite" 
        :endPointSelect="endPointSelect">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {tramitesApi} from '@/api/TramitesApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: tramitesApi.selectTipos,
        }),

    }    
</script>