<template>
    <v-alert
      v-model="show"
      border="top"
      close-text="'Cerrar'"
      dark
      dismissible
      :type="type"
      :close-icon="'close'"
    >
    {{text}}
    </v-alert>
</template>

<script>
    export default {
        
        props:{  
            value: {type: Boolean,
                default: false},
            type: {type: String,
                default: 'error'},
            text: {type: String,
                default: ''},
        },

        computed: {
            show: {
                get() {return this.value},
                set(value) {this.$emit("input",value)}
            },
        }

    }
</script>