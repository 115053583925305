<template>
    <FormEdicion
        v-model="show"
        titulo="Contraseña"
        :endPointGuardar="endPointGuardar"
        :tabla="tabla"
        :habilitaEliminar="false"
        modoModificacion>
        <template v-slot:Campos>

            <Input v-model="tabla.password"
                label="Contraseña Actual"
                tipo="password"
                autofocus
                obligatorio/>

            <Input v-model="tabla.passwordnuevo"
                label="Contraseña Nueva"
                tipo="password"
                obligatorio/>
            
            <v-text-field v-model="tabla.passwordnuevo2"
                label="Reingreso Contraseña Nueva"
                tipo="password"
                :rules="[valid]"
                required/>

        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {usuariosApi} from '../../api/UsuariosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
        },

        data: () => ({
            endPointGuardar: usuariosApi.contrasenia,
            tabla: {
                password:"",
                passwordnuevo:"",
                passwordnuevo2:"",
            },
        }),

        methods: {
            valid(value){
                if (!value){
                    return 'El dato es obligatorio.'
                }
                if (value != this.tabla.passwordnuevo){
                    return 'La contraseña nueva no coincide.'
                }   
                return true
            },
        }
    }
</script>