<template>
    <FormABM
        :show="show"
        titulo="Usuarios"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :headers="headers"
        :items="items"
        :actualizarBuscar="actualizarBuscar"       
        :modoSeleccion="modoSeleccion"
        :multiple="multiple"
        :filtro="filtro"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar">   

        <template v-slot:CompFiltros>
            <div class="d-flex">
                <v-col cols="1" class="pa-0">
                    <SelectTriple v-model="filtro.activo" label="Activo"/>
                </v-col>
                <v-col cols="1" class="pa-0 pl-1">
                    <SelectTriple v-model="filtro.administrador" label="Administrador" class="pl-1"/>
                </v-col>
                <v-col cols="1" class="pa-0 pl-1">
                    <SelectTriple v-model="filtro.autorizador" label="Autorizador" class="pl-1"/>
                </v-col>
                <v-col cols="3" class="pa-0 pl-1">
                    <SelectSectores v-model="filtro.idsSectores" multiple class="pl-1"/>
                </v-col>
                <v-col cols="3" class="pa-0 pl-1">
                    <SelectModulos v-model="filtro.idsModulos" multiple class="pl-1"/>
                </v-col>
                <v-col cols="3" class="pa-0 pl-1">
                    <SelectEstadosTurno v-model="filtro.idsEstados" multiple class="pl-1"/>
                </v-col>
            </div>
        </template>

        <template v-slot:FormEdicion>
            <Usuario v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
        </template> 
    </FormABM>    
</template>

<script>
    
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {usuariosApi} from '@/api/UsuariosApi'
    import FormABM from '@/components/base/form/FormABM.vue';
    import Usuario from './Usuario.vue'
    import SelectTriple from '@/components/base/SelectTriple.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue';
    import SelectModulos from '../../components/select/SelectModulos.vue';
    import SelectEstadosTurno from '../../components/select/SelectEstadosTurno.vue';

    export default {

        mixins: [FormABMMixin],

        components: {
            FormABM, 
            Usuario,
            SelectTriple,
            SelectSectores,
            SelectModulos,
            SelectEstadosTurno,
        },

        data: () => ({
            endPointBuscarVarios: usuariosApi.buscarVarios,
            endPointEliminarVarios: usuariosApi.eliminarVarios,
            headers: [
                {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
                {text: "Nombre", value: "nombre", groupable: false },
                {text: "Email", value: "email", groupable: false },
                {text: "Activo", value: "activo", filterable: false, groupable: false, tipo: "boolean"},
                {text: "Administrador", value: "administrador", filterable: false, groupable: false, tipo: "boolean"},
                {text: "Autorizador", value: "autorizador", filterable: false, groupable: false, tipo: "boolean"},
                {text: "Último ingreso", value: "fechahoralogin", filterable: false, groupable: false, tipo: "fechahoraminuto"},
            ],  
            filtro:{
                activo: true,
                administrador: null,
                autorizador: null,
                idsSectores: [],
                idsModulos: [],
                idsEstados: [],
            },   
        }), 
    }

</script>