<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Calendario" 
        tituloPlural="Calendarios" 
        :endPointSelect="endPointSelect"
        :habilitaAgregar="habilitaAgregar"
        :habilitaModificar="habilitaModificar"
        :habilitaDetalle="habilitaDetalle"
        :permiteSeleccionar="permiteSeleccionar"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :actualizarSelect="actualizarSelect"
        :outlined="outlined"
        :hidedetails="hidedetails"
        :readonly="readonly"
        :filtro="filtro"
        :seleccionarTodosAlSetear="seleccionarTodosAlSetear"
        :dense="dense"
        @showFormEdicion="mostrarFormEdicion=true"
        @showFormABM="mostrarFormABM=true"
        @valorSecundario="valorSecundario">

        <template v-slot:FormEdicion> 
            <Calendario v-model="mostrarFormEdicion" 
                :id="itemsSeleccionados" 
                :habilitaEliminar="false" 
                @actualizoFormEdicion="actualizoFormEdicion"/>
        </template>

        <template v-slot:FormABM> 
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <Calendarios :modoSeleccion="true" 
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                    </v-card>
            </v-dialog>
        </template>
    </Select>
</template>

<script>

    import {mapState} from 'vuex';
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {calendariosApi} from '@/api/CalendariosApi'
    import Select from '@/components/base/Select.vue';
    import Calendario from '../../views/Calendarios/Calendario.vue';
    import Calendarios from '../../views/Calendarios/Calendarios.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            Calendario,
            Calendarios,
        },

        data: () => ({
            endPointSelect: calendariosApi.buscarVarios,
            filtro:{
                idsSectores: null,
            }    
        }),

        props: {
            idsSectores:{type: [Array,Number],
                default(){return[ ]}
            },
        },
        
        computed: {
            ...mapState({
                permiteSeleccionar: state => state.usuarioModule.permisos.calendario.seleccionar,
            }),
        },

        watch:{
            idsSectores(val){
                if(typeof val === 'number'){
                    this.filtro.idsSectores = [val]
                }else{
                    this.filtro.idsSectores = val
                }
            }
        },
        
    }    
</script>