<template>
    <FormEdicion
        v-model="show"
        titulo="Tipo de Comprobante"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <div class="d-flex">
                <Input v-model="tabla.nombrecorto"
                    label="Nombre"
                    obligatorio/>

                <Input v-model="tabla.letra"
                    label="Letra"
                    class="pl-1"
                    obligatorio/>
            </div>

            <Tab v-model="tab" :tabs="tabs">               
                <template v-slot:datos>
                    <v-card>                    
                        <v-card-text class="px-0 pt-2">
                            <SelectTiposTipoComprobante v-model="tabla.idtipotipocomprobante"
                                obligatorio
                                :clearable="false"/>

                            <Input v-model="tabla.nombre"
                                label="Detalle"
                                obligatorio/>
                            
                            <Input v-model="tabla.formatoimpresion"
                                label="Formato de Impresión"/>

                            <SwitchCustom v-model="tabla.activo" label="Activo"/>
                        </v-card-text>
                    </v-card>
                </template>

                <template v-slot:numeración>
                    <v-card>                    
                        <v-card-text class="px-0 pt-1">
                            <div class="d-flex justify-center">
                                <Button tipo="agregar" @click="agregarNumeracion"/>
                            </div>
                            <data-table v-model="selectItems"
                                dense
                                hideDefaultFooter
                                disablePagination
                                :headers="headersNumeracion"
                                :items="tabla.numeracion"
                                @eliminar="eliminarNumeracion"/>
                        
                        </v-card-text>
                    </v-card>
                </template>
            </Tab>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {tiposComprobantesApi} from '../../api/TiposComprobantesApi';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import SelectTiposTipoComprobante from '../../components/select/SelectTiposTipoComprobante.vue';
    import SwitchCustom from '../../components/base/Switch.vue'
    import Input from '@/components/input/Input.vue';
    import Tab from '../../components/base/Tab.vue';
    import Button from '../../components/base/button/Button.vue';
    import DataTable from '../../components/base/DataTable.vue';
    
    export default {

        mixins: [
            FormEdicionMixin,
        ],

        components: {
            FormEdicion,
            SelectTiposTipoComprobante,
            SwitchCustom,
            Input,
            Tab,
            Button,
            DataTable,
        },
                
        data: () => ({
            endPointBuscar: tiposComprobantesApi.buscar,
            endPointGuardar: tiposComprobantesApi.guardar,
            endPointEliminar: tiposComprobantesApi.eliminarVarios,

            tabla: {
                idtipotipocomprobante: null,
                nombre: '',
                nombrecorto: '',
                activo: true,
                formatoimpresion: '',
                numeracion: [],
            },
            tabs: [
                {nombre: 'datos', habilitado: true, show: true},
                {nombre: 'numeración', habilitado: true, show: true},
            ],
            tab: 0,
            selectItems: [],
            headersNumeracion: [
                {value: "eliminar", sortable: false, filterable: false, groupable: false},
                {text: "Punto de venta", value: "idpuntoventa", groupable: false, tipo: "selectpuntosventa"},
                {text: "Próximo número", value: "proximonumero", groupable: false, tipo: "numero", editable: true}
            ],

        }),

        methods: {
            eliminarNumeracion(index){
                this.tabla.numeracion.splice(index,1);
            },
            agregarNumeracion(){
                this.tabla.numeracion.push({proximonumero: 1})
            },

        }

      

    }
</script>