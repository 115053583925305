import axios from 'axios'
const controller = "vinculos/"

class VinculosApi {
    
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(){
        return axios.get(controller)
    }

    select(parametros){
        return axios.get(controller + "select",{params: {
            idExcluido: parametros.idExcluido,
            idSexo: parametros.idSexo
        }});
    }
}

export const vinculosApi = new VinculosApi();