<template>
    <div v-show="permiteSeleccionar">
        <div class="d-flex pb-1">
            <v-autocomplete
                v-model="itemsSeleccionados"
                class="pa-0 mt-1"
                deletable-chips  
                ref="autocomplete"
                :label="multiple ? tituloPlural : titulo"
                :items="items"
                :multiple="multiple"
                :clearable="(multiple || clearable) && !readonly"
                :chips="multiple"
                :autofocus="autofocus"
                :rules="[valid]"
                :outlined="outlined"
                :hide-details="hidedetails"
                :search-input.sync="search"
                :loading="loading"
                :hide-no-data="busquedaSecuencial"
                :cache-items="busquedaSecuencial"
                :readonly="readonly"
                :dense="dense"
                @click:clear="clear">
                
                <!-- Add a tile with Select All as Label and binded on a method that add or remove all items -->
                <v-list-item
                    v-if="multiple && !busquedaSecuencial && items.length > 1"
                    slot="prepend-item"
                    ripple
                    @click="seleccionarTodos">
                    <v-list-item-action>
                        <v-icon :color="itemsSeleccionados && itemsSeleccionados.length > 0 ? $ColorPrincipal : ''">{{ icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>Seleccionar todos</v-list-item-title>
                </v-list-item> 

                <slot name="ItemsExtras" ripple slot="prepend-item"/>
                <v-divider v-if="multiple && items.length > 1" slot="prepend-item" class="mt-2"/> 

                <template v-if="multiple" v-slot:selection="{attrs,item,select,selected }">
                    <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        small chip
                        @click="select"
                        @click:close="removeChip(item.value)">
                        {{item.text}}
                    </v-chip>
                </template>

                <template v-slot:item="data" v-if="!multiple">
                    <v-list-item-avatar v-if="data.item.adicionales.color" :color="data.item.adicionales.color" size="36" ></v-list-item-avatar>
                    <v-list-item-content> 
                       {{data.item.text}}
                    </v-list-item-content>
                </template>
                
                <!-- <template v-slot:selection="{item}">
                    <div class="d-flex flex-column"> 
                        {{item.text}}
                    </div>
                </template>     -->
            </v-autocomplete>

            <div class="d-flex align-center" data-html2canvas-ignore="true">
                <Button tipo="agregar" :visible="muestraBotonAgregar" @click="mostrarFormEdicion()"/>
                <Button tipo="modificar" :visible="muestraBotonModificar" @click="mostrarFormEdicion()" />
                <Button tipo="mas" :visible="habilitaDetalle" @click="mostrarFormABM()"/>
            </div>
            
            <slot name="FormEdicion"/>
            <slot name="FormABM"/> 
        </div>
        <div>
            <slot name="Detalle" v-if="showSlotDetalle"/>
        </div>
    </div>
</template>

<script>
    
    import Button from './button/Button.vue';

    export default {
    
        components: {
            Button,
        },

        data: () => ({
            items: [],
            loading: false,
            search: null,
        }),

        props: {
            value: {type: [Array,Number],
                default(){return []}},
            titulo: {type: String},
            tituloPlural: {type: String},  
            endPointSelect: {type: Function},    
            endPointStore: {type: String},    
            habilitaAgregar: {type: Boolean,
                default: false},  
            habilitaModificar: {type: Boolean,
                default: false},  
            habilitaDetalle: {type: Boolean,
                default: false}, 
            permiteAgregar: {type:Boolean,
                default: true},
            permiteModificar: {type:Boolean,
                default: true},
            permiteSeleccionar: {type:Boolean,
                default: true},
            obligatorio: {type: Boolean,
                default: false}, 
            multiple: {type: Boolean,
                default: false},
            autofocus: {type: Boolean,
                default: false},        
            filtro: {type: Object},
            actualizarSelect:{type:Boolean,
                default: false}, 
            showSlotDetalle: {type: Boolean,
                default: false},   
            clearable: {type:Boolean,
                default: true}, 
            readonly: {type: Boolean,
                default: false},   
            outlined:{type:Boolean,
                default: false}, 
            hidedetails:{type:Boolean,
                default: false}, 
            busquedaSecuencial:{type:Boolean,
                default: false}, 
            seleccionaPrimero:{type:Boolean,
                default: false}, 
            seleccionaUltimo:{type:Boolean,
                default: false},  
            guardarIdsSeleccionados:{type:Boolean,
                default: false},
            accionGuardarIdsSeleccionados:{type:String},
                default: "",  
            valoresPorDefecto:{type:[Array,Number],
                default: null},
            seleccionarTodosAlSetear:{type:Boolean,
                default: false},
            dense:{type:Boolean,
                default: false},
        },

        computed: {     
            itemsSeleccionados: {
                get() {
                    this.valorSecundario(this.value)  
                    return this.value
                },
                set(value) {
                    this.valorSecundario(value) 
                    this.$emit("input", value)
                    if (this.guardarIdsSeleccionados && this.accionGuardarIdsSeleccionados && !this.readonly && value != null){
                        this.$store.dispatch(this.accionGuardarIdsSeleccionados,value)
                    }
                }
            },

            todosSeleccionados () {
                return this.itemsSeleccionados && this.itemsSeleccionados.length === this.items.length
            },

            algunosSeleccionados () {
                return this.itemsSeleccionados && this.itemsSeleccionados.length > 0 && !this.todosSeleccionados    
            },

            icon () {
                if (this.todosSeleccionados) return 'check_box'
                if (this.algunosSeleccionados) return 'indeterminate_check_box'
                return 'check_box_outline_blank'
            },
            
            muestraBotonAgregar(){
                return this.habilitaAgregar && this.permiteAgregar && (this.itemsSeleccionados === null || this.itemsSeleccionados === 0)
            },
            
            muestraBotonModificar(){
                return this.habilitaModificar && this.permiteModificar && this.itemsSeleccionados != null && this.itemsSeleccionados != 0
            },
        },

        watch:{
            search(val) {
                if (this.busquedaSecuencial && val){
                    if(val.length >= 3){
                        this.$emit("searchInput",val)
                    }else{
                        this.items = []
                    }   
                }
            },
            
            filtro: {
                deep: true, 
                handler(){
                    this.select()
                }
            },

            async actualizarSelect(){
                this.$refs.autocomplete.cachedItems = []; //Es necesario blanquear CACHE porque se modificaron elementos
                await this.select()
            },
        },

        mounted() {
            if (!this.busquedaSecuencial) this.select()
            if (this.busquedaSecuencial && this.value && this.value != 0) this.select() //NECESARIO PARA QUE SETEE AL INICIO
            if (this.valoresPorDefecto && this.valoresPorDefecto.length != 0){
                if (this.multiple){
                    this.itemsSeleccionados = [...this.valoresPorDefecto]
                }else{
                    this.itemsSeleccionados = this.valoresPorDefecto[0]
                }
            }
        },

        methods: {
            
            removeChip(value){
                this.itemsSeleccionados.splice(this.itemsSeleccionados.indexOf(value),1)
            },

            async select() {
                if (!this.permiteSeleccionar) return
                try {
                    let me = this
                    this.loading = true
                    if (this.endPointSelect){
                        const response = await this.endPointSelect(this.filtro)
                        this.items = [] //NO MOVER ESTO DE ACA, SINO QUEDA EN BLANCO EL SELECT
                        if(response.data){
                            response.data.map(function(x) {
                                me.items.push({text: x.nombre, value: x.id, adicionales: x});
                            })
                        }
                    }else{
                        const response = await this.$store.dispatch(this.endPointStore)
                        this.items = [] //NO MOVER ESTO DE ACA, SINO QUEDA EN BLANCO EL SELECT
                        response.map(function(x) {
                            me.items.push({text: x.nombre, value: x.id, adicionales: x});
                        })
                    }
                    this.$emit("cantidadItems",me.items.length) 

                    if (this.items.length === 0){
                        this.itemsSeleccionados = null //NECESARIO PARA LOS SELECTS QUE DEPENDEN DE OTROS POR EJ. SELECTSECTORESTRAMITES
                    }else{    
                        this.setearValor()
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    this.loading = false
                }
            },

            setearValor(){
                if (this.multiple){
                    if(this.seleccionarTodosAlSetear && !this.todosSeleccionados){
                        this.seleccionarTodos()
                    }
                }else{
                    if (this.items.length === 0){
                        this.itemsSeleccionados = null
                    }else if(this.items.length == 1){
                        this.itemsSeleccionados = this.items[0].value
                    }else if(this.items.find(array => array.value === this.value)){
                        this.itemsSeleccionados = this.value //BUSCO SI EL ID ACTUAL, ESTA DENTRO DE LOS ITEMS
                    }else if(this.seleccionaPrimero){
                        this.itemsSeleccionados = this.items[0].value
                    }else if(this.seleccionaUltimo) {
                        const nUltimoItem = this.items.length - 1
                        this.itemsSeleccionados = this.items[nUltimoItem].value
                    }      
                }
            },

            valorSecundario(val){
                if (!Array.isArray(val) && val != undefined && val != 0 ){
                    const valor = this.items.find(array => array.value === val)
                    if (valor){
                        this.$emit("valorSecundario",valor.adicionales) 
                    }
                }else{
                    this.$emit("resetValorSecundario") 
                }  
            },

            seleccionarTodos(){
                this.$nextTick(() => {
                    if (this.todosSeleccionados) {
                        this.itemsSeleccionados = []
                    }else {
                        this.itemsSeleccionados = []
                        this.itemsSeleccionados = this.items.map((x) => (x.value));
                    }
                })
            },

            mostrarFormEdicion(){
                this.$emit("showFormEdicion")
            },

            mostrarFormABM(){
                this.$emit("showFormABM",true)
            },

            valid(){
                if(this.items.length > 0 && this.obligatorio && (!this.itemsSeleccionados || (this.itemsSeleccionados && this.itemsSeleccionados.length === 0))){
                    return 'El dato es obligatorio.'
                }else{
                    return true
                }
            },
            
            clear(){
                this.$emit("clear")
            }
        }
    }; 
</script>

<style lang="css" scoped>
    .v-select.v-select--chips >>> .v-select__selections{
        min-height: 32px !important;
    }
</style>