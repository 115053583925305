<template>
    <FormEdicion
        v-model="show"
        titulo="Usuario"
        maxWidth="800px"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :endPointAuditoria="endPointAuditoria"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>

            <div class="d-flex">        
                <Input v-model="tabla.nombre"
                    label="Nombre y Apellido"
                    autofocus
                    obligatorio/>
                <Input v-model="login"
                    v-show="tabla.id"
                    label="Último ingreso"
                    readonly
                    class="pl-1"/>
            </div>

            <div class="d-flex">  
                <Input v-model="tabla.email"
                    label="Email"
                    tipo="mail"
                    obligatorio/>
                <Input v-model="tabla.password"
                    label="Contraseña"
                    tipo="password"
                    obligatorio
                    class="pl-1"/>
            </div>

            <div class="d-flex">
                <SwitchCustom v-model="tabla.activo" label="Activo"/>     
                <v-spacer/>
                <SwitchCustom v-model="tabla.autorizador" label="Autorizador"/>
                <v-spacer/>
                <SwitchCustom v-model="tabla.administrador" label="Administrador"/>
            </div>

            <SelectModulos v-model="tabla.modulosusuarios" multiple/>
            <SelectSectores v-model="tabla.sectoresusuarios" multiple/>
            <SelectEstadosTurno v-model="tabla.estadosturnosusuarios" multiple tituloPlural="Estados de Turnos"/>
            <TreeviewPermisos v-model="tabla.permisosusuarios"/> 
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {usuariosApi} from '../../api/UsuariosApi';
    import dayjs from "dayjs";
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue'
    import SelectModulos from '../../components/select/SelectModulos.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue';
    import TreeviewPermisos from '../../components/base/TreeviewPermisos.vue';
    import SwitchCustom from '../../components/base/Switch.vue';
    import SelectEstadosTurno from '../../components/select/SelectEstadosTurno.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectModulos,
            SelectSectores,
            TreeviewPermisos,
            SwitchCustom,
            SelectEstadosTurno,
        },

        data: () => ({
            endPointBuscar: usuariosApi.buscar,
            endPointGuardar: usuariosApi.guardar,
            endPointEliminar: usuariosApi.eliminarVarios,
            endPointAuditoria: usuariosApi.auditoria,
            tabla: {
                nombre: '',
                email: '',
                password: '',
                activo: true,
                administrador: false,
                autorizador: false,
                fechahoralogin: '',
                modulosusuarios: [],
                sectoresusuarios: [],
                permisosusuarios: [],
                estadosturnosusuarios:[],
            },            
        }),

        computed: {
            login(){
                return this.tabla.fechahoralogin ? dayjs(this.tabla.fechahoralogin).format("DD/MM/YYYY HH:mm:ss [Hs.]") : ''
            }
        }
        
    }
</script>