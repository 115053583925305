<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Vínculo"
        tituloPlural="Vínculos"
        :endPointSelect="endPointSelect" 
        :obligatorio="obligatorio"
        :filtro="filtro"
        @valorSecundario="valorSecundario"/>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {vinculosApi} from '@/api/VinculosApi'
    import Select from '../base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: vinculosApi.select,
            filtro:{
                idExcluido:null,
                idSexo:null,
            }    
        }),

        props: {
            idExcluido: {type: Number,
                default: null},
            idSexo: {type: Number,
                default: null},
        },

        watch:{
            idExcluido:{
                immediate: true, 
                handler(val){
                    this.filtro.idExcluido = val
                }
            },
            idSexo(val){
                this.filtro.idSexo = val
            }
        },

    }    
</script>