<template>
    <FormEdicion
        v-model="show"
        titulo="Feriado"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :permiteModificar="permiteModificar"
        :permiteEliminar="permiteEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <InputFecha v-model="tabla.fecha" 
                autofocus
                obligatorio/>
            
            <Input v-model="tabla.nombre"
                label="Detalle"
                obligatorio/>
        </template>    
    </FormEdicion>
</template>

<script>

    import {mapState} from 'vuex';
    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {feriadosApi} from '../../api/FeriadosApi';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import InputFecha from '../../components/input/InputFecha.vue';
    import Input from '@/components/input/Input.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            InputFecha,
        },

        data: () => ({
            endPointBuscar: feriadosApi.buscar,
            endPointGuardar: feriadosApi.guardar,
            endPointEliminar: feriadosApi.eliminarVarios,
            tabla: {
                fecha: null,
                nombre: "",
            },
        }),

        computed: {
            ...mapState({
                permiteModificar: state => state.usuarioModule.permisos.feriado.modificar,
                permiteEliminar: state => state.usuarioModule.permisos.feriado.eliminar,
            }),       
        },

    }
</script>