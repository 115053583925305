<template>
    <div class = "d-flex">
        <v-col class="pa-0">
            <SelectRubros 
                :value="rubrosSeleccionados"
                :multiple="multiple"
                :obligatorio="obligatorio"
                @input="$emit('update:rubrosSeleccionados',$event)"
            />                    
        </v-col>
        <v-col class="pa-0 pl-1">
            <SelectSubrubros 
                :value="subrubrosSeleccionados"
                :multiple="multiple"
                :obligatorio="obligatorio"
                :idsRubros="rubrosSeleccionados"
                @input="$emit('update:subrubrosSeleccionados',$event)"
                @valorSecundario="valorSecundarioSubrubro"
            />
        </v-col>    
    </div>
</template>
<script>

    import SelectRubros from '@/components/select/SelectRubros.vue';
    import SelectSubrubros from '@/components/select/SelectSubrubros.vue';

    export default {

        components: {
            SelectRubros,
            SelectSubrubros,
        },

        props: {
            multiple:{type: Boolean,
                default: false },
            rubrosSeleccionados:{type: [Array,Number],
                default(){return[ ]}},
            subrubrosSeleccionados:{type: [Array,Number],
                default(){return[ ]}},    
            obligatorio: {type: Boolean,
                default: false },   
        },
        
        methods: {
            valorSecundarioSubrubro(value){
                this.$emit('update:rubrosSeleccionados',value.idrubro)
            },
        }
    }

</script>