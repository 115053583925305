<template>
    <FormABM
        :show="show"
        titulo="Tipos de Operaciones"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"       
        :multiple="multiple"
        :filtro="filtro"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar"> 
        
        <template v-slot:CompFiltros>
            <div class = "d-flex">
              <SelectTriple v-model="filtro.activo" label="Activo"/>
            </div>
        </template>

        <template v-slot:FormEdicion>
            <tipo-tipo-comprobante v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>
        </template>   
    </FormABM>    
  </template>
  
  <script>
  
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import { tiposTiposComprobantesApi } from '../../api/TiposTiposComprobantesApi';
    import FormABM from '@/components/base/form/FormABM.vue';
    import TipoTipoComprobante from './TipoTipoComprobante.vue';
    import SelectTriple from '@/components/base/SelectTriple.vue';
  
    export default {
      
      mixins: [FormABMMixin],
  
      components: {
        FormABM, 
        TipoTipoComprobante,
        SelectTriple,
      },
  
      data: () => ({
        endPointBuscarVarios: tiposTiposComprobantesApi.buscarVarios,
        endPointEliminarVarios: tiposTiposComprobantesApi.eliminarVarios,
        headers: [
          {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
          {text: "Nombre", value: "nombre"},  
          {text: "Activo", value: "activo", filterable: false, groupable: false, tipo: "boolean"}],
        filtro:{
          activo: true,
        }
      }),
  
    }
  </script>