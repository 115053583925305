<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Tipo Sector" 
        :endPointSelect="endPointSelect">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {sectoresApi} from '@/api/SectoresApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: sectoresApi.selectTipos,
        }),

        props: {
            titulo: { type: String,
                default: "Tipo Sector"},
            tituloPlural: { type: String,
                default: "Tipos Sector"},
        }
    }    
</script>