<template>
    <FormEdicion
        v-model="show"
        titulo="Evento"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                label="Nombre"
                obligatorio/>
            <div class="d-flex">
                <v-col cols="6" class="pa-0 pt-2 mr-1">
                    <SelectSectores v-model="tabla.idsector"/>  
                </v-col>
                <v-col cols="6" class="pa-0 pt-2">
                    <SelectTiposInscripciones v-model="tabla.idtipo"/>
                </v-col>
            </div>
            <InputTextArea v-model="tabla.detalle" label="Detalle"/>
            <Input v-model="tabla.destinatarios" label="Destinatarios"/>
            <Input v-model="tabla.horario" label="Día y Horario"/>
            <Input v-model="tabla.inicio" label="Inicio"/>
            <Input v-model="tabla.lugar" label="Lugar"/>
            <Input v-model="tabla.direccion" label="Dirección"/>
            <Input v-model="tabla.duracion" label="Duración"/>
            <Input v-model="tabla.instructor" label="Instructor/a"/>
            <Input v-model="tabla.cargahoraria" label="Carga horaria"/>
            <Input v-model="tabla.modalidad" label="Modalidad"/>
            <Input v-model="tabla.linkqr" label="Link pago QR"/>
            <SwitchCustom v-model="tabla.permiteobservaciones" label="Permite inf. adicional"/>
            <InputTextArea v-model="tabla.leyendaobservaciones" 
                v-show="tabla.permiteobservaciones"
                label="Leyenda inf. adicional"/>
            <SwitchCustom v-model="tabla.activo" label="Activo"/>
            <div class="d-flex">
                <SwitchCustom v-model="tabla.activoinscripciones" label="Activo Inscripciones"/>
                <v-spacer/>   
                <SwitchCustom v-model="tabla.permiteinscripciones" label="Permite Inscripciones"/>
            </div>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {inscripcionesApi} from '@/api/InscripcionesApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue';
    import SelectTiposInscripciones from '../../components/select/SelectTiposInscripciones.vue';
    import InputTextArea from '../../components/input/InputTextArea.vue'
    import SwitchCustom from '../../components/base/Switch.vue';
    
    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectSectores,
            SelectTiposInscripciones,
            InputTextArea,
            SwitchCustom,
        },

        data: () => ({
            endPointBuscar: inscripcionesApi.buscar,
            endPointGuardar: inscripcionesApi.guardar,
            endPointEliminar: inscripcionesApi.eliminarVarios,
            tabla: {
                nombre: "",
                idsector: null,
                idtipo: null,
                detalle: "",
                destinatarios: "",
                instructor: "",
                inicio: "",
                horario: "",
                lugar: "",
                direccion: "",
                duracion: "",
                modalidad: "",
                cargahoraria: "",
                activo: true,
                activoinscripciones: true,
                permiteinscripciones: true,
                linkqr: "",
                permiteobservaciones: false,
                leyendaobservaciones: "",
            },
        }),
        
        watch:{
            'tabla.activo'(val){
                if(!val){
                    this.tabla.activoinscripciones = false
                    this.tabla.permiteinscripciones = false
                }
            },
            'tabla.activoinscripciones'(val){
                if(!val){
                    this.tabla.permiteinscripciones = false
                }
            },
        },

    }
</script>