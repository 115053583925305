<template>
  <FormABM
    :show="show"
    titulo="Eventos para Inscripciones"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"     
    :modoSeleccion="modoSeleccion"  
    :multiple="multiple"
    :filtro="filtro"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">  

    <template v-slot:CompFiltros>
      <div class="d-flex">
        <v-col cols="6" class="pa-0 mr-1">
          <SelectSectores v-model="filtro.idsSectores" multiple/>
        </v-col>
        <v-col cols="2" class="pa-0 mr-1">
          <SelectTriple v-model="filtro.activo" label="Activo"/> 
        </v-col>
        <v-col cols="2" class="pa-0 mr-1">
          <SelectTriple v-model="filtro.activoInscripciones" label="Activo Inscripciones"/> 
        </v-col>
        <v-col cols="2" class="pa-0 mr-1">
          <SelectTriple v-model="filtro.permiteInscripciones" label="Permite Inscripciones"/> 
        </v-col>
      </div>
    </template>  

    <template v-slot:FormEdicion>
      <Inscripcion v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>   
    </template>  
  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {inscripcionesApi} from '@/api/InscripcionesApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import Inscripcion from './Inscripcion.vue'
  import SelectSectores from '@/components/select/SelectSectores.vue';
  import SelectTriple from '@/components/base/SelectTriple.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      Inscripcion,
      SelectSectores,
      SelectTriple,
    },

    data: () => ({
      endPointBuscarVarios: inscripcionesApi.buscarVarios,
      endPointEliminarVarios: inscripcionesApi.eliminarVarios,
      headers: [
        {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        {text: "Nombre", value: "nombre", groupable: false},
        {text: "Sector", value: "sector.nombre"},
        {text: "Activo", value: "activo", filterable: false, groupable: false, tipo: "boolean"},
        {text: "Activo Inscr.", value: "activoinscripciones", filterable: false, groupable: false, tipo: "boolean"},
        {text: "Permite Inscr.", value: "permiteinscripciones", filterable: false, groupable: false, tipo: "boolean"}
      ],
      filtro:{
        idsSectores: null,
        activo: true,
        activoInscripciones: true,
        permiteInscripciones: null,
      }
    }),

  }
</script>