<template>
    <FormABM
        :show="show"
        titulo="Normas"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"  
        :modoSeleccion="modoSeleccion"
        :multiple="multiple"
        :filtro="filtro"
        permiteDescargar
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar">   

        <template v-slot:CompFiltros>
            <div class="d-flex">
                <v-col cols="2" class="pa-0"> 
                    <InputFechaRango v-model="filtro.rangoFecha"/>
                </v-col>
                <v-col cols="2" class="pa-0 pl-2"> 
                    <SelectSectores v-model="filtro.idsSectores" multiple/>
                </v-col>
                <v-col cols="1" class="pa-0 pl-2"> 
                    <SelectTiposNormas v-model="filtro.idTipo"/>
                </v-col>
                <v-col cols="1" class="pa-0 pl-2"> 
                    <SelectEmisoresNorma v-model="filtro.idEmisor"/>
                </v-col>
                <v-col cols="1" class="pa-0 pl-2"> 
                    <SelectEstadosNormas v-model="filtro.idEstado"/>
                </v-col>
                <v-col cols="5" class="pa-0 pl-2"> 
                    <SelectEtiquetas v-model="filtro.idsEtiquetas"/>
                </v-col>
            </div>
        </template>  

        <template v-slot:FormEdicion>
            <Norma v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
        </template> 
    </FormABM>    
</template>

<script>

    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {normasApi} from '@/api/NormasApi'
    import FormABM from '@/components/base/form/FormABM.vue';
    import Norma from './Norma.vue'
    import SelectSectores from '@/components/select/SelectSectores.vue';
    import SelectEstadosNormas from '@/components/select/SelectEstadosNormas.vue';
    import SelectTiposNormas from '@/components/select/SelectTiposNormas.vue';
    import InputFechaRango from '@/components/input/InputFechaRango.vue';
    import SelectEtiquetas from '@/components/select/SelectEtiquetas.vue';
    import SelectEmisoresNorma from '@/components/select/SelectEmisoresNorma.vue';

    export default {

        mixins: [FormABMMixin],

        components: {
            FormABM,
            Norma,
            SelectSectores,
            SelectEstadosNormas,
            SelectTiposNormas,
            InputFechaRango,
            SelectEtiquetas,
            SelectEmisoresNorma,
        },

        data: () => ({
            endPointBuscarVarios: normasApi.buscarVarios,
            endPointEliminarVarios: normasApi.eliminarVarios,
            headers: [
                {text: "Opciones", value: "opciones", sortable:false, filterable:false, groupable:false},
                {text: "Fecha", value: "fecha", tipo:"fecha"},
                {text: "Tipo", value: "tiponorma.nombre"},
                {text: "Emisor", value: "emisornorma.nombre"},
                {text: "Número", value: "numero", groupable:false},
                {text: "Título", value: "titulo", groupable:false},
                {text: "Estado", value: "estadonorma.nombre"},],
            filtro:{
                rangoFecha: null,
                idsSectores: null,
                idTipo: null,
                idEmisor: null,
                idEstado: null,
                idsEtiquetas: null,
            },
            imageData: null,
            pathDocumento: null,
            uploadValue: 0 
        }),

        created() {
            this.filtro.rangoFecha = this.$rangoSeteado()
        },
    }
</script>