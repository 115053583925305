<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Módulo" 
        tituloPlural="Módulos" 
        :endPointSelect="endPointSelect"
        :habilitaDetalle="habilitaDetalle"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :actualizarSelect="actualizarSelect"
        @showFormABM="mostrarFormABM=true">
        <template v-slot:FormABM>
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <Modulos modoSeleccion 
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                    </v-card>
            </v-dialog>
        </template>
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {modulosApi} from '@/api/ModulosApi'
    import Select from '@/components/base/Select.vue';
    import Modulos from '../../views/Modulos/Modulos.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            Modulos,
        },

        data: () => ({
            endPointSelect: modulosApi.select,
        }),
    }    
</script>