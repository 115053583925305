<template>
  <v-menu
    v-model="menu"
    ref="menu"
    :disabled="readonly"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px">
    <template v-slot:activator="{on,attrs}">
      <v-text-field
        v-model="computedDateFormatted"
        class="pa-0 pt-1 ma-0"
        :label="label"
        :rules="[valid]"
        :autofocus="autofocus"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <DatePicker
      v-model="itemsSeleccionados"
      :no-title="notitle"
      @input="menu=false">
    </DatePicker>
  </v-menu>
</template>

<script>

  import DatePicker from '@/components/base/DatePicker.vue'

  export default {

    components: {
      DatePicker,
    },

    data: () => ({
      menu: false,
    }),

    props:{
      value: {type: String,
        default:''},
      label: {type: String,
        default: 'Fecha'},  
      notitle: {type: Boolean,
        default: true},
      obligatorio: {type:Boolean,
        default: false},    
      readonly: {type: Boolean,
        default: false},  
      autofocus: {type: Boolean,
        default: false},
    },

    computed: {
      
      itemsSeleccionados: {
        get() {
          return this.value;
        },
        set(value) {    
          this.$emit("input",value);
        }
      },

      computedDateFormatted: {
        get(){
          return this.formatDate(this.itemsSeleccionados)
        },
        set(value){      
          if(!value){
            this.itemsSeleccionados = null
          }
        }
      },
    },

    methods: {

      valid(value){
        if (this.obligatorio && !value){
          return 'El dato es obligatorio.'
        }
        return true
      },

      formatDate (date) {
        if(date && date != '1899-11-30'){
          const [year, month, day] = date.split('-')
          return `${day}/${month}/${year}`
        }
      },
      
    }

  }
</script>