import Vue from "vue";
import dayjs from "dayjs";

Vue.filter("fecha", value => {
    if (value != '') return dayjs(value).format("DD/MM/YYYY");
});

Vue.filter("fechahora", value => {
    if (value != '') return dayjs(value).format("DD/MM/YYYY HH:mm [hs.]");
});

Vue.filter("fechahoraminuto", value => {
    if (value) return dayjs(value).format("DD/MM/YYYY HH:mm:ss [hs.]");
});

Vue.filter("decimales", (value, decimals=2) => {
    return value.toFixed(decimals).toLocaleString();
});

Vue.filter("moneda", value => {
    return "$ " + value;
});

Vue.filter("porcentaje", value => {
    return value + "%";
});

Vue.filter("telefono", value => {
    if(value){
        return "+54 " + value.substring(0,4) + " " + value.substring(4,6) + "-" + value.substring(6,11)
    }
});

