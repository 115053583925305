<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Sexo" 
        :endPointSelect="endPointSelect"
        :obligatorio="obligatorio"
        :clearable="false"
        @valorSecundario="valorSecundario">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {personasApi} from '@/api/PersonasApi'
    import Select from '@/components/base/Select.vue';

    export default {
        mixins: [SelectMixin],

        components: {Select},

        data: () => ({
            endPointSelect: personasApi.selectSexos,
        }),
    }    
</script>