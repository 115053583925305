import axios from 'axios'
const controller = "sectores/"

class SectoresApi {
    
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){
        return axios.get(controller,{params:{
            activoTurnos: parametros.activoTurnos,
            activoTurnosExterno: parametros.activoTurnosExterno,
            activoConsultas: parametros.activoConsultas
        }});
    }

    select(parametros){
        return axios.get(controller + "select",{params: {
            activoTurnos: parametros.activoTurnos
        }});
    }

    selectTipos(){
        return axios.get("tipossector/select");
    }
}

export const sectoresApi = new SectoresApi();