<template>
  <FormABM
    :show="show"
    titulo="Sectores"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion="modoSeleccion"
    :multiple="multiple"
    :filtro="filtro"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">
    
    <template v-slot:CompFiltros>
      <div class="d-flex">
        <select-triple v-model="filtro.activoTurnos" label="Activo Turnos"/>
        <select-triple v-model="filtro.activoTurnosExterno" label="Activo Turnos Externo"  class="pl-1"/>
        <select-triple v-model="filtro.activoConsultas" label="Activo Consultas" class="pl-1"/>
      </div>
    </template>

    <template v-slot:FormEdicion>
      <Sector v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>   
    </template>    
  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {sectoresApi} from '@/api/SectoresApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import Sector from './Sector.vue';
  import SelectTriple from '../../components/base/SelectTriple.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      Sector,
      SelectTriple,
    },

    data: () => ({
      endPointBuscarVarios: sectoresApi.buscarVarios,
      endPointEliminarVarios: sectoresApi.eliminarVarios,
      headers: [
        {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        {text: "Nombre", value: "nombre", groupable: false},
        {text: "Turnos", value: "activoturnos", filterable: false, groupable: false, tipo: "boolean"},
        {text: "Turnos externo", value: "activoturnosexterno", filterable: false, groupable: false, tipo: "boolean"},
        {text: "Consultas", value: "activoconsultas", filterable: false, groupable: false, tipo: "boolean"},
      ],
      filtro: {
        activoTurnos: null,
        activoTurnosExterno: null,
        activoConsultas: null,
      }
    }),
  }
</script>