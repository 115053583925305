<template>
  <FormABM
    :show="show"
    titulo="Localidades"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion="modoSeleccion"
    :multiple="multiple"
    :filtro="filtro"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">   
    
    <template v-slot:CompFiltros>
        <SelectProvincias multiple v-model="filtro.idsProvincias"/>  
    </template>  

    <template v-slot:FormEdicion>
        <Localidad v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
    </template> 
  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {localidadesApi} from '@/api/LocalidadesApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import Localidad from './Localidad.vue';
  import SelectProvincias from '@/components/select/SelectProvincias.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      Localidad,
      SelectProvincias,
    },

    data: () => ({
      endPointBuscarVarios: localidadesApi.buscarVarios,
      endPointEliminarVarios: localidadesApi.eliminarVarios,
      headers: [
        {text: "Opciones", value:"opciones", sortable:false, filterable:false, groupable:false},
        {text: "Nombre", value:"nombre", groupable:false},
        {text: "Provincia", value:"provincia.nombre", filterable:false}],
      filtro:{
        idsProvincias:[]
      },
    }),

  }
</script>