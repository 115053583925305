<template>
  <FormABM
    :show="show"
    titulo="Vínculos"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion="modoSeleccion"
    :multiple="multiple"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">
    
    <template v-slot:FormEdicion>
      <Vinculo v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
    </template>    
  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {vinculosApi} from '@/api/VinculosApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import Vinculo from './Vinculo.vue'

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      Vinculo,
    },

    data: () => ({
      endPointBuscarVarios: vinculosApi.buscarVarios,
      endPointEliminarVarios: vinculosApi.eliminarVarios,
      headers: [
        { text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        { text: "Nombre", value: "nombre", groupable: false},
        { text: "Sexo", value: "sexo.nombre"}],
    }),

  }
</script>