<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="SubRubro" 
        tituloPlural="SubRubros" 
        :endPointSelect="endPointSelect"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :autofocus="autofocus"
        :filtro="filtro"
        @showFormABM="mostrarFormABM=true"
        @valorSecundario="valorSecundario">
        <template v-slot:FormABM>
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <Subrubros modoSeleccion 
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                    </v-card>
            </v-dialog>
        </template>
    </Select>
</template>

<script>
    
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {subrubrosApi} from '@/api/SubrubrosApi'
    import Select from '@/components/base/Select.vue';
    import Subrubros from '../../views/Articulos/Subrubros/Subrubros.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            Subrubros,
        },
        
        data: () => ({
            endPointSelect: subrubrosApi.buscarVarios,
            filtro:{
                idsRubros:[]
            }    
        }),

        props: {
            idsRubros:{type: [Array,Number],
                default(){return[ ]}
            },
        },

        watch:{
            idsRubros(val){
                if(typeof val === 'number'){
                    this.filtro.idsRubros = [val]
                }else{
                    this.filtro.idsRubros = val
                }
            }
        },
    }    
</script>