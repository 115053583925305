<template>
    <v-text-field
        :value="value"
        v-bind="$attrs"
        append-icon="search"
        label="Búsqueda"
        single-line
        hide-details
        clearable
        class="pa-0 mt-0"
        @input="$emit('input', $event)">
    </v-text-field>
</template>

<script>
    export default {
        props:{
            value: {type: String},
        },
    }
</script>