<template>
    <v-menu
        v-model="menu"
        ref="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="itemsSeleccionados"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px">

        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="itemsSeleccionados"
                class="pa-0 pt-1 ma-0"
                :label="label"
                :rules="[valid]"
                readonly
                v-bind="attrs"
                v-on="on">
            </v-text-field>
        </template>

        <v-card>
            <v-time-picker
                v-model="itemsSeleccionados"
                scrollable
                :format="'24hr'">
            </v-time-picker>
    
            <div class="d-flex justify-center">
                <div class="pa-1">
                    <Button tipo="cancelar" @click="menu = false"/>
                </div>
                <div class="pa-1">
                    <Button tipo="seleccionar" @click="$refs.menu.save(itemsSeleccionados)"/>
                </div>
            </div>
        </v-card>
    </v-menu>

</template>

<script>
    
    import Button from '../base/button/Button.vue';
   
    export default {

        components: {
            Button,
        },

        data: () => ({
            menu: false,
        }),

        props:{
            value:{type: String,
                default: '00:00'},
            label:{type: String,
                default: 'Intervalo'},  
            notitle:{type: Boolean,
                default: true},
            obligatorio:{ type: Boolean,
                default: false},  
        },

        computed: {
            itemsSeleccionados: {
                get() {
                    return this.value
                },
                set(value) {    
                    this.$emit("input",value);
                }
            },         
        },

        methods: {

            valid(value){
                if (this.obligatorio && !value){
                    return 'El dato es obligatorio.'
                }
                return true
            },
        }

    }
</script>