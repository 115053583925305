<template>
  <FormABM
        :show="show"
        permiteImprimir
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"   
        :modoSeleccion="modoSeleccion"    
        :multiple="multiple"
        :filtro="filtro"
        :habilitaFiltrar="false"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar">   

        <template v-slot:FormEdicion>
            <DetalleInscripcion v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
        </template> 
    </FormABM>    
</template>

<script>

    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {inscripcionesApi} from '@/api/InscripcionesApi'
    import FormABM from '@/components/base/form/FormABM.vue';
    import DetalleInscripcion from './DetalleInscripcion.vue'

    export default {
    
        mixins: [FormABMMixin],

        components: {
            FormABM, 
            DetalleInscripcion,
        },

        data: () => ({
            endPointBuscarVarios: inscripcionesApi.buscarVariosDetalle,
            endPointEliminarVarios: inscripcionesApi.eliminarVariosDetalle,
            headers: [
                {text:"Opciones", value:"opciones", sortable:false, filterable:false, groupable:false},
                {text:"Persona", value:"persona.nombre", groupable:false, impresion:true},
                {text:"Documento", value: "documento", groupable:false, impresion:true},
                {text:"Teléfono", value:"telefono", groupable:false, impresion:true},
                {text:"Fecha Nac.", value:"persona.fechanacimiento", groupable:false, tipo:"fecha"},
                {text:"Edad", value:"edad", impresion:true},
                {text:"Sexo", value:"persona.sexo.nombre"},
                {text:"Evento", value:"inscripcion.nombre", impresion:true},
                {text:"Inscripto/a", value:"fechahora", groupable:false, tipo:"fechahora"},
                {text:"Origen", value:"origen.nombre"},
                {text:"Inf. adicional", value:"observacionesexterno", groupable:false, impresion:true},
                {text:"Observaciones", value:"observaciones", groupable:false},
            ],
        }),

        props: {
            filtro: {type: Object,
                default: () => ({})
            },
        },

    }
</script>