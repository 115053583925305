<template>
    <form-abm
        :show="show"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :permiteModificar="permiteModificar"
        :permiteEliminar="permiteEliminar"
        :permiteVisualizar="permiteVisualizar"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"  
        :modoSeleccion="modoSeleccion"
        :multiple="multiple"
        :filtro="filtro"
        :permiteAgregar="false"
        :habilitaFiltrar="false"
        sortBy="persona"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar">   

        <template v-slot:FormEdicion>
            <Persona v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
        </template> 
    </form-abm>
</template>

<script>

    import {mapState} from 'vuex';
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {personasApi} from '@/api/PersonasApi'
    import FormAbm from '../../components/base/form/FormABM.vue';
    import Persona from './Persona.vue';

    export default {

        mixins: [FormABMMixin],

        components: {
            FormAbm, 
            Persona,
        },

        data: () => ({
            endPointBuscarVarios: personasApi.buscarVarios,
            endPointEliminarVarios: personasApi.eliminarVarios,
            headers: [
                {text: "Opciones", value: "opciones", sortable:false, filterable:false, groupable:false},
                {text: "Persona", value: "persona", groupable:false},
                {text: "Documento", value: "documento", groupable:false},
                {text: "Dirección", value: "direccion", groupable:false},
                {text: "Localidad", value: "localidad"}],   
        }),

        computed: {
            ...mapState({
                permiteModificar: state => state.usuarioModule.permisos.persona.modificar,
                permiteEliminar: state => state.usuarioModule.permisos.persona.eliminar,
                permiteVisualizar: state => state.usuarioModule.permisos.persona.visualizar,
            }),
        },
        
        props: {
            filtro: {type: Object,
                default: () => ({})
            },
        },

    }

</script>