<template>
    <FormEdicion 
        v-model="show"
        titulo="Vínculo relacionado"
        modoAlta
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <SelectSexos v-model="tabla.sexo.id" obligatorio @valorSecundario="valorSecundarioSexo"/>
            <SelectVinculos v-model="tabla.vinculorelacionado.id" obligatorio :idSexo="tabla.sexo.id" @valorSecundario="valorSecundarioVinculo"/>
        </template>
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import FormEdicion from '../../components/base/form/FormEdicion.vue';
    import SelectSexos from '../../components/select/SelectSexos.vue';
    import SelectVinculos from '../../components/select/SelectVinculos.vue';

    export default {
        
        mixins: [FormEdicionMixin],

        components:{
            FormEdicion,
            SelectSexos,
            SelectVinculos
        },

        data: () => ({
            tabla: {
                sexo:{
                    id: null,
                    nombre: '',
                },
                vinculorelacionado:{
                    id: null,
                    nombre: '',
                }, 
            },
        }),

        props: {
            idExcluido: {type: Number,
                default: null},
        },

        methods: {
            valorSecundarioSexo(val){
                this.tabla.sexo.nombre = val.nombre
            },
            valorSecundarioVinculo(val){
                this.tabla.vinculorelacionado.nombre = val.nombre
            },
        },
    }

</script>
