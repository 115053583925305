<template>
    <v-menu
        v-model="menu"
        ref="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="pa-0 pt-1 ma-0"
            v-model="computedDateFormatted"
            :label="label"
            :rules="[valid]"
            clearable
            readonly
            v-bind="attrs"
            v-on="on"
            @click:clear="itemsSeleccionados = []"
          ></v-text-field>
        </template>
        <v-card>
          <DatePicker
            v-model="itemsSeleccionados"
            range
            :no-title="notitle"
            :type="type">
          </DatePicker>
          <div class="d-flex justify-center">
            <div class="pa-1">
              <Button tipo="cancelar" @click="cancelar"/>
            </div>
            <div class="pa-1">
              <Button tipo="seleccionar" @click="seleccionar"/>
            </div>
          </div>
        </v-card>
    </v-menu>
</template>

<script>

  import dayjs from "dayjs"
  import DatePicker from '@/components/base/DatePicker.vue'
  import Button from "../base/button/Button.vue"

  export default {

    components: {
      DatePicker,
      Button,
    },

    data: () => ({
      menu: false,
    }),

    props:{
      value:{type: Array,
        default(){return []}},
      label:{type: String,
        default: 'Rango de fechas'},  
      notitle:{type: Boolean,
        default: true},
      obligatorio:{ type: Boolean,
        default: false},  
      type:{ type: String,
        default: 'date'},  
    },

    computed: {
      itemsSeleccionados: {
        get() {
          return this.value;
        },
        set(value) {    
          this.$emit("input",value);
          if(value[0]){
            this.$store.dispatch("setFechaDesde",value[0])
            if(value[1]){
              this.$store.dispatch("setFechaHasta",value[1])
            }
          }else{
            this.$store.dispatch("setFechaDesde")
            this.$store.dispatch("setFechaHasta")
          }
        }
      },
      computedDateFormatted: {
        get(){
          return this.formatDate(this.itemsSeleccionados)
        },
        set(value){
          this.$emit("input",value);
        }
      },
    },

    methods: {

      valid(value){
        if (this.obligatorio && !value){
            return 'El dato es obligatorio.'
        }
        return true
      },

      cancelar(){
        this.itemsSeleccionados = []
        this.menu = false
      },

      seleccionar(){
        if (this.itemsSeleccionados.length === 1){
          this.itemsSeleccionados.push(this.itemsSeleccionados[0])
        }
        this.$refs.menu.save(this.itemsSeleccionados)
      },

      formatDate(date){
        if (date && date.length > 0){
          const dateOrdenado = Array.from(date).sort()

          const [yeard, monthd, dayd] = dateOrdenado[0].split('-')
          if (this.type === 'date'){
            
            const desde = `${dayd}/${monthd}/${yeard}`

            if (dateOrdenado[1]){
              const [yearh, monthh, dayh] = dateOrdenado[1].split('-')
              const rango = `${desde} ~ ${dayh}/${monthh}/${yearh}`
              return rango
            }else{
              return desde
            }
          }else{
            const desde = `01/${monthd}/${yeard}`
            if (dateOrdenado[1]){
              const [yearh, monthh] = dateOrdenado[1].split('-')
              const hasta = `${yearh}/${monthh}/01`
              const rango = `${desde} ~ ${dayjs(hasta).endOf('month').format('DD/MM/YYYY')}`
              return rango
            }else{
              return desde
            }
          }
        }else{
          return   
        }
      },
    }
  }
</script>



