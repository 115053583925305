<template>
    <Select 
        v-model="itemsSeleccionados"
        :titulo="titulo" 
        :endPointSelect="endPointSelect"
        :habilitaAgregar="habilitaAgregar"
        :habilitaModificar="habilitaModificar"
        :habilitaDetalle="habilitaDetalle"
        :permiteAgregar="permiteAgregar"
        :permiteModificar="permiteModificar"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :actualizarSelect="actualizarSelect"
        :showSlotDetalle="showSlotDetalle"
        :clearable="clearable"
        :filtro="filtro"
        :busquedaSecuencial="busquedaSecuencial=true"
        :readonly="readonly"
        :hidedetails="personaSeleccionada && showSlotDetalle"
        @showFormEdicion="mostrarFormEdicion=true"
        @showFormABM="mostrarFormABM=true"
        @valorSecundario="valorSecundario"
        @searchInput="searchInput"
        @clear="clear">

        <template v-slot:FormEdicion> 
            <Persona v-model="mostrarFormEdicion" 
                :id="itemsSeleccionados" 
                :permiteEliminar="false" 
                @actualizoFormEdicion="actualizoFormEdicion"/>
        </template>

        <template v-slot:FormABM> 
            <v-dialog v-model="mostrarFormABM" :max-width="'80%'" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <PersonasListado :modoSeleccion="true" 
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                </v-card>
            </v-dialog>
        </template>
        
        <template v-slot:Detalle v-if="!soloConActividades"> 
            <div v-if="personaSeleccionada" class="d-flex justify-space-between pb-1 text-caption">
                <div v-if="adicionales.direccion" class="flex-shrink-0">
                    <v-icon medium class="pa-1">
                        house
                    </v-icon>
                    {{adicionales.direccion}} - {{adicionales.localidad.nombre}}
                </div>
                <div v-if="adicionales.telefono" class="flex-shrink-0">
                    <ButtonWhatsapp :numero="adicionales.telefono"/>
                    {{adicionales.telefono | telefono}}
                </div>
                <div v-if="adicionales.email" class="flex-shrink-0">
                    <ButtonMail :para="adicionales.email" />
                    {{adicionales.email}}
                </div>
            </div>
        </template>    
    </Select>
</template>

<script>

    import {mapState} from 'vuex';
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {personasApi} from '@/api/PersonasApi'
    import Select from '@/components/base/Select.vue';
    import ButtonWhatsapp from '../base/button/ButtonWhatsapp.vue';
    import ButtonMail from '../base/button/ButtonMail.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            ButtonWhatsapp,
            ButtonMail,
            Persona: () => import('../../views/Personas/Persona.vue'),
            PersonasListado: () => import('../../views/Personas/PersonasListado.vue'),
        },

        data: () => ({
            endPointSelect: personasApi.select,
            filtro: {
                soloConActividades: false,
                idsExcluidos: [],
            },
            adicionales: {
                direccion:  "",
                telefono: "",
                email: "",
                localidad: {
                    nombre: ""
                }
            },
        }),

        props: {
            soloConActividades: {type: Boolean,
                default: false},
            titulo: {type: String,
                default: "Persona"},
            idsExcluidos: {type: Array,
                default(){return []}},
        },

        computed: {
            ...mapState({
                permiteAgregar: state => state.usuarioModule.permisos.persona.agregar,
                permiteModificar: state => state.usuarioModule.permisos.persona.modificar,
            }),
            personaSeleccionada(){
                return this.itemsSeleccionados != 0 && this.itemsSeleccionados != null
            }
        },
        
        watch:{
            idsExcluidos:{
                immediate: true, 
                handler (val) {
                    this.filtro.idsExcluidos = val
                }
            } 
        },

        created() {
            this.filtro.soloConActividades = this.soloConActividades
        },

    }    
</script>