import axios from 'axios'
const controller = "auditoria/"

class AuditoriaApi {
    buscarVarios(parametros){
        return axios.get(controller,{params: {
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsUsuarios: parametros.idsUsuarios,
            idsTiposOperacion: parametros.idsTiposOperacion,
        }})
    }

    selectTiposOperacion(){
        return axios.get("tiposoperacion/select");
    }
}

export const auditoriaApi = new AuditoriaApi();