<template>
    <FormABM
        :show="show"
        :endPointBuscarVarios="endPointBuscarVarios"        
        :permiteAgregar="false"
        :permiteModificar="permiteModificar"
        :permiteEliminar="false"
        permiteImprimir
        :actualizarBuscar="actualizarBuscar"       
        :filtro="filtro"
        :headers="headers"
        sortBy="fechahora"
        @actualizar="actualizar"
        @cerrar="$emit('cerrar')"
        @seleccionar="seleccionar">
        
        <template v-slot:CompFiltros>
            <div class="d-flex">
                <v-col cols="2" class="pa-0 mr-1">
                    <InputFechaRango v-model="filtro.rangoFecha"/>
                </v-col>
                <v-col cols="1" class="pa-0 mr-1">
                    <InputHoraRango :desde.sync="filtro.horaDesde" :hasta.sync="filtro.horaHasta"/>
                </v-col>
                <v-col cols="1" class="pa-0 mr-1">
                    <SelectSectores v-model="filtro.idsSectores" multiple/>
                </v-col>
                <v-col cols="1" class="pa-0 mr-1">
                    <SelectTramites v-model="filtro.idsTramites" multiple/>
                </v-col>
                <v-col cols="1" class="pa-0 mr-1">
                    <SelectEstadosTurno v-model="filtro.idsEstados" multiple/>
                </v-col>
                <v-col cols="1" class="pa-0 mr-1">
                    <SelectOrigenes v-model="filtro.idsOrigenesAsignacion" tituloPlural="Orígenes Asignación" multiple/>
                </v-col>
                <v-col cols="3" class="pa-0 pr-2">
                    <SelectPersonas v-model="filtro.idPersona" habilitaDetalle/>
                </v-col>
                <v-col cols="2" class="pa-0 pr-2">
                    <SelectLocalidades v-model="filtro.idsLocalidades" multiple/>
                </v-col>
            </div>
        </template>  

        <template v-slot:FormEdicion>
            <TurnoAsignar v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
        </template>    
    </FormABM>    
</template>

<script>

    import {mapState} from 'vuex';
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {turnosApi} from '../../api/TurnosApi';
    import FormABM from '@/components/base/form/FormABM.vue';
    import TurnoAsignar from './TurnoAsignar.vue';
    import InputFechaRango from '../../components/input/InputFechaRango.vue';
    import SelectPersonas from '../../components/select/SelectPersonas.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue';
    import SelectEstadosTurno from '../../components/select/SelectEstadosTurno.vue';
    import SelectOrigenes from '../../components/select/SelectOrigenes.vue';
    import SelectTramites from '../../components/select/SelectTramites.vue';
    import InputHoraRango from '../../components/input/InputHoraRango.vue';
    import SelectLocalidades from '../../components/select/SelectLocalidades.vue';

    export default {

        mixins: [FormABMMixin],

        components: {
            FormABM, 
            TurnoAsignar,
            InputFechaRango,
            SelectPersonas,
            SelectSectores,
            SelectEstadosTurno,
            SelectOrigenes,
            SelectTramites,
            InputHoraRango,
            SelectLocalidades,
        },

        data: () => ({
            endPointBuscarVarios: turnosApi.buscarVarios,
            headers: [
                {text:"Opciones", value:"opciones", sortable:false, filterable:false, groupable:false},
                {text:"Persona", value:"persona.nombre", groupable:false, impresion:true},
                {text:"Documento", value:"documento", groupable:false, impresion:true},
                {text:"Teléfono", value:"telefono", groupable:false, impresion:true},
                {text:"Dirección", value:"persona.direccion", groupable:false, impresion:true},
                {text:"Localidad", value:"persona.localidad.nombre", impresion:true},
                {text:"Fecha y Hora", value:"fechahora", groupable:false, tipo:"fechahora", impresion:true},
                {text:"Estado", value:"estado.nombre"},
                {text:"Inf. adicional", value:"observacionesexterno", groupable:false, impresion:true},
                {text:"Observaciones", value:"observaciones", groupable:false, impresion:true}],    
            filtro:{
                rangoFecha: [],
                horaDesde: null,
                horaHasta: null,
                idsSectores: null,
                idsTramites: null,
                idPersona: null,
                idsEstados: null,
                idsOrigenesAsignacion: null,     
                idsLocalidades: null,     
            }
        }),
        
        computed: {
            ...mapState({
                permiteModificar: state => state.usuarioModule.permisos.turno.modificar,
            }),
        },

        watch:{
            show(val){
                if (val){
                    this.filtro.rangoFecha = this.$rangoSeteado()
                    this.filtro.idSectores = this.$store.state.sectoresModule.idsSectores
                    this.actualizarBuscar = !this.actualizarBuscar
                }
             }
        },

    }

</script>