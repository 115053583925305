import jsPDF from 'jspdf'
import dayjs from "dayjs"
import store from '../store'
import { cerosIzquierda, createPDFWithImage } from '../utils'

export function ImprimirComprobante(data, action) {

    return new Promise((resolve, reject) => {

        if (!data.tipocomprobante.formatoimpresion) return
        
        var createPDF = function(imgData) {
            var doc = new jsPDF();
            doc.setTextColor(40)
            let linea = 4
            const widthPage = doc.internal.pageSize.getWidth()
            const mediaPagina = widthPage / 2
            const interlinea = 3

            function encabezado() {
                linea = 4
                doc.addImage(imgData, 'JPEG',3,linea, imgData.width * 10 / 100,imgData.height * 10 / 100);
            
                linea += 8
                doc.setFontSize(14)
                doc.text(data.tipocomprobante.nombre,widthPage/2,linea,{align:'center'})

                linea += 4
                doc.setFontSize(12)
                doc.text(data.sector.nombre,widthPage/2,linea,{align:'center'})
                
                doc.setFontSize(9)
                doc.text("Fecha: " + dayjs(data.fecha).format("DD/MM/YYYY"),205,linea - 3,{align:'right'})

                let puntoventa = 0
                if (data.puntoventaexterno){
                    puntoventa = cerosIzquierda(data.puntoventaexterno,4)
                }
                if (data.puntoventa.nombre != null){
                    puntoventa = cerosIzquierda(data.puntoventa.nombre,4)
                }
                const numero = cerosIzquierda(data.numero,8)
                doc.text("Número: " + puntoventa + "-" + numero,205,linea,{align:'right'})

                linea += 2
                doc.line(4,linea,206,linea)
        
                linea += 4
                doc.text("Proveedor:",5,linea)
                const nombreCompleto = `${data.persona.apellido || ''} ${data.persona.nombre}`;
                doc.text(nombreCompleto,23,linea)

                if (data.solicitante){
                    doc.line(mediaPagina,linea-4,mediaPagina,linea + 11);
                    doc.text("Solicitante:",mediaPagina + 1,linea)
                    const nombreCompletoSolicitante = `${data.solicitante.apellido || ''} ${data.solicitante.nombre}`;
                    doc.text(nombreCompletoSolicitante,mediaPagina + 19,linea)
                }
                
                linea += interlinea
                doc.text("Documento:",5,linea)
                doc.text(data.persona.tipodocumento.nombre + " " + data.persona.nrodocumento,23,linea)
        
                if (data.solicitante){
                    doc.text("Documento:",mediaPagina + 1,linea)
                    doc.text(data.solicitante.tipodocumento.nombre + " " + data.solicitante.nrodocumento,mediaPagina + 19,linea)
                }

                linea += interlinea
                doc.text("Domicilio:",5,linea)
                doc.text(data.persona.direccion + " - " + data.persona.localidad.nombre,23,linea)
        
                linea += interlinea
                if (data.persona.contactos[0]){
                    doc.text("Teléfono:",5,linea)
                    doc.text(data.persona.contactos[0].telefono,23,linea)
                }
        
                linea = 33
                doc.line(4,linea,206,linea)
        
                linea += interlinea
                doc.text("Detalle",5,linea)
                doc.text("Cantidad",145,linea,{align:'right'})
                doc.text("Unitario",165,linea,{align:'right'})
                doc.text("Desc./Rec.",185,linea,{align:'right'})
                doc.text("Importe",205,linea,{align:'right'})
                
                linea += 1
                doc.line(4,linea,206,linea)
            }

            function cuerpo(){
                for (let i=0; i < data.detallecomprobantes.length; i++) {
                    linea += interlinea
                    doc.text(data.detallecomprobantes[i].articulo.nombre,5,linea)
                    doc.text(data.detallecomprobantes[i].cantidad.toFixed(2),145,linea,{align:'right'})
                    if (data.detallecomprobantes[i].unitario){
                        doc.text(data.detallecomprobantes[i].unitario.toFixed(2),165,linea,{align:'right'})
                        doc.text(data.detallecomprobantes[i].total.toFixed(2),205,linea,{align:'right'})
                    }
                    if (data.detallecomprobantes[i].descuentorecargo){
                        doc.text(data.detallecomprobantes[i].descuentorecargo.toFixed(2) + '%',185,linea,{align:'right'})
                    }
                }    
            }

            function pie(){
                linea = 115
                doc.line(4,linea,206,linea)

                linea += interlinea + 1
                if (data.observaciones){
                    const texto = "Observaciones: " + data.observaciones
                    const textoformato = doc.splitTextToSize(texto,180)
                    doc.text(textoformato,5,linea)
                }
                
                doc.setFontSize(12)
                if (data.total){
                    doc.text('TOTAL ' + data.total.toFixed(2),205,linea,{align:'right'})
                }

                doc.setFontSize(7)
                linea = 130
                doc.text("Usuario: " + data.usuariocreo.nombre,5,linea)
                if (data.usuarioautorizo){
                    doc.text("Autorizó: " + data.usuarioautorizo.nombre,widthPage/2,linea,{align:'center'})
                }
                doc.text("Impreso: " + dayjs().format("DD/MM/YYYY HH:mm:ss [Hs.]"),205,linea,{align:'right'})

                linea += 2.5
                let textoEmpresa = store.state.empresasModule.nombre + " - IVA " + store.state.empresasModule.condicioniva.detalle +
                " " + store.state.empresasModule.tipodocumento.nombre + " " + store.state.empresasModule.nrodocumento +
                " - " + store.state.empresasModule.direccion + " " + store.state.empresasModule.localidad.nombre +
                " " + store.state.empresasModule.localidad.provincia.nombre
                
                if (store.state.empresasModule.telefono){
                    textoEmpresa = textoEmpresa + " - " + store.state.empresasModule.telefono
                }
                if (store.state.empresasModule.email){
                    textoEmpresa = textoEmpresa + " - " + store.state.empresasModule.email
                }
                doc.text(doc.splitTextToSize(textoEmpresa,180),5,linea)

                if (data.sector){
                    linea += 2.5
                    let textoSector = "Sector " + data.sector.nombre
                    
                    if (data.sector.direccion){
                        textoSector = textoSector + " - " + data.sector.direccion + " " + data.sector.localidad.nombre 
                    }
                    if (data.sector.telefono){
                        textoSector = textoSector + " - " + data.sector.telefono 
                    }
                    if (data.sector.email){
                        textoSector = textoSector + " - " + data.sector.email
                    }
                    doc.text(doc.splitTextToSize(textoSector,180),5,linea)
                }
            }

            encabezado()
            cuerpo()
            pie()
            
            switch (action) {
                case 'imprimir': resolve(doc.output('dataurlnewwindow',{filename:'Comprobante'}));
                case 'exportar': resolve(doc.output('datauristring'))                    
                default: reject(new Error('Acción no válida'));
            }

        }

        createPDFWithImage(createPDF)
    });
}