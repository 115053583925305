export default {
    namespaced: true,
    
    state : {
        namespaced: true,
        isLoading: false,
    },

    mutations : {
        setIsLoading:(state)=>(
            state.isLoading = !state.isLoading
        ),
    }
}

