<template>
    <apexchart ref="apexChart" type="donut" :options="chartOptions" :series="chartSeries"/>
</template>

<script>

    export default {

        data: () => ({
            chartSeries: [],
            chartOptions: {
                labels: [],
                colors: [],
                legend: {
                    show: true,
                    position: 'bottom',
                    itemMargin: {
                        horizontal: 1,
                        vertical: 0
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true,
                                    fontSize: "16px",
                                },
                                value: {
                                    fontSize: "30px",
                                }
                            }
                        }
                    }
                },
                dataLabels: {
                    style: {
                        fontSize: '11px',
                        colors: ['#424242'],
                    },
                    dropShadow: {
                        enabled: false,
                    }
                },
                title: {
                    align: 'center',
                },
            },     
        }),

        props:{
            data:{type:Array},
            title:{type:String},
        },

        watch:{
            data: {
                deep: true,
                handler(){
                    this.update()
                }
            },
        },

        mounted(){
            this.$refs.apexChart.updateOptions({
                title:{
                    text: this.title
                }
            })
        },

        methods: {
            update(){            
                let series = []
                let labels = []
                let colors = []

                for (let i = 0; i < this.data.length; i++) {
                    series.push(this.data[i].cantidad)
                    labels.push(this.data[i].nombre)
                    if (this.data[i].color) colors.push(this.data[i].color)
                }

                this.$refs.apexChart.updateOptions({
                    series: series,
                    labels: labels,
                    colors: colors,
                })
            }
        }

    }
</script>

