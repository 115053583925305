export const FormABMMixin = {
    data: () => ({
        id: 0,
        items: [],
        actualizarBuscar: false,
        showFormEdicion: false,
        alta: false,
    }),
    
    props: { 
        show:{type: Boolean,
            default: true},
        modoSeleccion: {type: Boolean,
            default: false},
        multiple: {type: Boolean,
            default: true},
        itemsPadre: {type: Array},
        actualizarBuscarExterno: {type: Boolean,
            default: false}, 
    },

    watch:{
        actualizarBuscarExterno(){
            this.actualizarBuscar = !this.actualizarBuscar
        },
    },

    methods: { 

        actualizoFormEdicion(id){
            this.$emit("actualizoFormEdicion",id,this.alta)
            this.actualizarBuscar = !this.actualizarBuscar
            if (this.alta && this.seleccion && !this.multiple){
                this.cerrar()
            }  
       },

        actualizar(id) {
            this.id = id
            this.showFormEdicion = true
            if(id === 0){
                this.alta = true
            }else{
                this.alta = false
            }
        },

        seleccionar(val) {
            this.$emit("input",val);
        },

        cerrar(){
            this.$emit('cerrar')
        },

        valorSecundarioItemSeleccionado(){
        },

    }    
}    