const getDefaultState = () => {
    return {};
};
  
const state = getDefaultState();

export default {
    namespaced: true,
    name: "snackbar",
    
    state: {
        snack: {}
    },

    mutations: {
        setSnack(state, payload) {
            state.snack = {...payload};
        },
        resetState(state) {
            Object.assign(state, getDefaultState());
        }
    }
};