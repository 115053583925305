<template>
    <div class="d-flex">
        <Button tipo="anterior" :disabled="disabledAnterior" :visible="visible" @click="buscar('ant')"/>
        <Button tipo="siguiente" :disabled="disabledSiguiente" :visible="visible" @click="buscar('sig')"/>
    </div>
</template>

<script>
    import Button from './button/Button.vue'

    export default {
        components:{
            Button,
        },

        data: () => ({
            idLocal:0,
            anteriores:[],
            siguientes:[],
            cantidadBuscarAnterior:1,
            cantidadBuscarSiguiente:1,
        }),

        props: {
            parametros:{type:Object},
            endPointBuscarVarios: {type: Function},
            visible:{type:Boolean},
                default:true
        },

        computed: {
            disabledAnterior() {
                return (this.anteriores.length >= this.cantidadBuscarAnterior ? false : true)
            },
            disabledSiguiente() {
                return (this.siguientes.length >= this.cantidadBuscarSiguiente ? false : true)
            },
        },

        watch:{
            'parametros.id': {
                immediate: true,
                handler(val){
                    if (val != this.idLocal && val != 0) this.buscar()
                }
            },
        },

        methods:{
            async buscar(tipo){
                if (this.endPointBuscarVarios){
                    try{
                        const response = await this.endPointBuscarVarios(this.parametros)
                        this.anteriores=[]
                        this.siguientes=[]
                        if(tipo){
                            //this.cantidadBuscar = 2       
                            if(tipo==="ant" && response.data.anteriores.length > 0){
                                this.cantidadBuscarAnterior = 2
                                this.cantidadBuscarSiguiente = 0
                                this.idLocal = response.data.anteriores[0].id    
                                this.$emit("cambioId",this.idLocal)
                            }else if (tipo==="sig" && response.data.siguientes.length > 0){
                                this.cantidadBuscarAnterior = 0
                                this.cantidadBuscarSiguiente = 2
                                this.idLocal = response.data.siguientes[0].id  
                                this.$emit("cambioId",this.idLocal)
                            }
                        }else{
                            this.cantidadBuscarAnterior = 1      
                            this.cantidadBuscarSiguiente = 1
                        }
                        Object.assign(this.anteriores,response.data.anteriores)
                        Object.assign(this.siguientes,response.data.siguientes)
                    }catch(error){
                        console.log(error)
                    }
                }   
            },
        }

    }
</script>


 