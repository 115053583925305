const state = {
    idsSectores: [],
};

const actions = { 
    setIdsSectores({commit},ids){
        if(typeof(ids)==='number'){
            ids = [ids]
        }
        commit("setIdsSectores",ids) 
    },
};

const mutations = {
    setIdsSectores:(state,ids)=>(
        state.idsSectores = [...ids]         
    )
};

export default {
    state,
    actions,
    mutations,
}