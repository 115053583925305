import axios from 'axios'
import dayjs from "dayjs"
import { cerosIzquierda } from '@/utils';
const controller = "comprobantes/"

class ComprobantesApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }
    
    async buscarVarios(parametros){       
        let response = await axios.get(controller,{params: {
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsTiposTipoComprobante: parametros.idsTipoTipoComprobante ? [parametros.idsTipoTipoComprobante] : null,
            idsTiposComprobante: parametros.idsTipoComprobante,
            idsArticulos: parametros.idArticulo ? [parametros.idArticulo] : null,
            idsPersonas: parametros.idPersona ? [parametros.idPersona] : null,
            idsSolicitantes: parametros.idSolicitante ? [parametros.idSolicitante] : null,
            idsSectores: parametros.idsSectores,
            idsCreadores: parametros.idsCreadores,
            idsAutorizadores: parametros.idsAutorizadores,
            idsRubros: parametros.idsRubros,
            idsSubrubros: parametros.idsSubrubros,
            autorizado: parametros.autorizado,
            anulado: parametros.anulado,
            articulosPendientes: parametros.articulosPendientes,
            importePendiente: parametros.importePendiente,
            tesoreria: parametros.tesoreria,
            imputacionesSeleccionadas: parametros.imputacionesSeleccionadas,
        }})

        for (let index=0; index < response.data.length; index++) {
            const pventa = response.data[index].puntoventa ? response.data[index].puntoventa.nombre : response.data[index].puntoventaexterno
            response.data[index].comprobante = response.data[index].tipocomprobante.nombrecorto + ' ' + response.data[index].tipocomprobante.letra + ' ' +
            cerosIzquierda(pventa,4) + '-' + cerosIzquierda(response.data[index].numero,8)

            const apellido = response.data[index].persona.apellido
            const nombre = response.data[index].persona.nombre
            response.data[index].persona.nombre = apellido ? apellido + ' ' + nombre : nombre 

            if (response.data[index].solicitante){
                const apellido_solicitante = response.data[index].solicitante.apellido
                const nombre_solicitante = response.data[index].solicitante.nombre
                response.data[index].solicitante.nombre = apellido_solicitante ? apellido_solicitante + ' ' + nombre_solicitante : nombre_solicitante 
            }
        }
        return response
    }

    imprimir(id){
        return axios.get(controller + "imprimir" + id);
    }

    auditoria(id,rangoFecha){
        return axios.get("auditoria",{params:{
            desde: rangoFecha ? rangoFecha[0] : null,
            hasta: rangoFecha ? rangoFecha[1] : null,
            idTabla: id,
            tabla: "comprobantes"}})
    }

    async buscarVariosDetalle(parametros){      
        let response = await axios.get(controller + "buscarvariosdetalle",{params: {
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsTipoTipoComprobante: parametros.idsTipoTipoComprobante,
            idsTipoComprobante: parametros.idsTipoComprobante,
            idPersona: parametros.idPersona,
            idSolicitante: parametros.idSolicitante,
            idsSectores: parametros.idsSectores,
            pendiente: parametros.pendiente,
        }})

        for (let index=0; index < response.data.length; index++) {
            response.data[index].comprobante.fecha = dayjs(response.data[index].comprobante.fecha).format("DD/MM/YYYY")
            
            const pventa = (response.data[index].comprobante.puntoventa?.nombre !== undefined && response.data[index].comprobante.puntoventa.nombre !== null)
            ? response.data[index].comprobante.puntoventa.nombre
            : response.data[index].comprobante.puntoventaexterno;

            response.data[index].comprobante.detalle = response.data[index].comprobante.tipocomprobante.nombrecorto + ' ' + response.data[index].comprobante.tipocomprobante.letra + ' ' +
            cerosIzquierda(pventa,4) + '-' + cerosIzquierda(response.data[index].comprobante.numero,8)
        }
        
        return response
    }

    async estadisticas(parametros){
        let response = await axios.get(controller + "estadisticas",{params:{
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsTipoTipoComprobante: parametros.idsTipoTipoComprobante,
            idsTipoComprobante: parametros.idsTipoComprobante,
            idArticulo: parametros.idArticulo,
            idPersona: parametros.idPersona,
            idSolicitante: parametros.idSolicitante,
            idsSectores: parametros.idSector ? [parametros.idSector] : parametros.idsSectores,
            idsCreadores: parametros.idsCreadores,
            idsAutorizadores: parametros.idsAutorizadores,
            idsRubros: parametros.idsRubros,
            idsSubrubros: parametros.idsSubrubros,
            autorizado: parametros.autorizado,
            anulado: parametros.anulado,
        }})

        for (let index=0; index < response.data.personas.length; index++) {
            let detalle = ""
            if (response.data.personas[index].apellido) detalle = response.data.personas[index].apellido
            if (response.data.personas[index].nombre){
                if (detalle){
                    detalle = detalle + " " + response.data.personas[index].nombre 
                }else{
                    detalle = response.data.personas[index].nombre 
                }
            }
            response.data.personas[index].nombre = detalle
        }

        for (let index=0; index < response.data.solicitantes.length; index++) {
            let detalle = ""
            if (response.data.solicitantes[index].apellido) detalle = response.data.solicitantes[index].apellido
            if (response.data.solicitantes[index].nombre){
                if (detalle){
                    detalle = detalle + " " + response.data.solicitantes[index].nombre 
                }else{
                    detalle = response.data.solicitantes[index].nombre 
                }
            }
            response.data.solicitantes[index].nombre = detalle
        }

        return response
    }
}

export const comprobantesApi = new ComprobantesApi();