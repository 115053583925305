import axios from 'axios';
import store from '../store/index'

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = localStorage.getItem('access_token');
    if (token) {
        config.headers.common = {
            "Authorization": `Bearer ${token}`,
        }
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  })

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status==401 && store.state.usuarioModule.token){
      store.commit("snackbarModule/setSnack",{accion:'SesionExpirada'})
      store.dispatch("logout");
    }
    return Promise.reject(error);
  })

