import axios from 'axios'
const controller = "accionespersonas/"

class AccionesPersonasApi {
    
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    async buscarVarios(parametros){ 
        let response = await axios.get(controller,{params: {
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idPersona: parametros.idPersona,
            idsUsuarios: parametros.idsUsuarios,
            idsSectores: parametros.idsSectores,
            idsTipos: parametros.idsTipos,
        }})
        for (let index=0; index < response.data.length; index++) {
            const apellido = response.data[index].persona.apellido
            const nombre = response.data[index].persona.nombre
            response.data[index].persona.nombre = apellido ? apellido + ' ' + nombre : nombre 
        }
        return response
    }   

    selectTipos(){
        return axios.get("tiposaccionespersonas/select");
    }

    auditoria(id,rangoFecha){
        return axios.get("auditoria",{params:{
            desde: rangoFecha ? rangoFecha[0] : null,
            hasta: rangoFecha ? rangoFecha[1] : null,
            idTabla: id,
            tabla: "accionespersonas"}})
    }
}

export const accionesPersonasApi = new AccionesPersonasApi();