<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Empresa" 
        tituloPlural="Empresas" 
        :endPointSelect="endPointSelect"
        :obligatorio="obligatorio">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {empresasApi} from '../../api/EmpresasApi';
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: empresasApi.select,
        }),

    }
</script>