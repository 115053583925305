<template>
    <FormEdicion
        v-model="show"
        titulo="Trámite"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                label="Nombre"
                obligatorio
            />
            <SelectTiposTramite v-model="tabla.idtipotramite"/>
            <SwitchCustom v-model="tabla.otorgaturno" label="Otorga Turno"/>  
            <InputTextArea v-model="tabla.leyendaturno" label="Leyenda Turnos"/>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {tramitesApi} from '../../../api/TramitesApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';
    import SelectTiposTramite from '../../../components/select/SelectTiposTramite.vue';
    import SwitchCustom from '../../../components/base/Switch.vue';
    import InputTextArea from '../../../components/input/InputTextArea.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectTiposTramite,
            SwitchCustom,
            InputTextArea,
        },

        data: () => ({
            endPointBuscar: tramitesApi.buscar,
            endPointGuardar: tramitesApi.guardar,
            endPointEliminar: tramitesApi.eliminarVarios,
            tabla: {
                nombre: "",
                idtipotramite: null,
                otorgaturno: true,
                leyendaturno: "",
            },
        }),

    }
</script>