import axios from 'axios'
const controller = "actividadeseconomicas/"

class ActividadesEconomicasApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(){ 
        return axios.get(controller)
    }

    async select(parametros){
        let response = await axios.get(controller + "select",{params:{
            ids: parametros.ids,
            busqueda: parametros.busqueda} 
        })
        for (let index=0; index < response.data.length; index++) {
            response.data[index].descripcion = response.data[index].nombre 
            response.data[index].nombre = response.data[index].codigo + ' ' + response.data[index].nombre 
        }
        return response
    }

    selectEstados(){
        return axios.get(controller + "selectestados")
    }
}

export const actividadesEconomicasApi = new ActividadesEconomicasApi();