<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Actividad Económica" 
        tituloPlural="Actividades Económicas" 
        :endPointSelect="endPointSelect"
        :habilitaDetalle="habilitaDetalle"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :actualizarSelect="actualizarSelect"
        :filtro="filtro"
        :busquedaSecuencial="busquedaSecuencial=true"
        @showFormABM="mostrarFormABM=true"
        @valorSecundario="valorSecundario"
        @searchInput="searchInput"
        @clear="clear">
        <template v-slot:FormABM> 
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <ActividadesEconomicas modoSeleccion 
                        :multiple="multiple"
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                </v-card>
            </v-dialog>
        </template>
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {actividadesEconomicasApi} from '@/api/ActividadesEconomicasApi'
    import Select from '@/components/base/Select.vue';
    import ActividadesEconomicas from '../../views/Actividades Economicas/ActividadesEconomicas.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            ActividadesEconomicas,
        },

        data: () => ({
            endPointSelect: actividadesEconomicasApi.select,
        }),
    }    
</script>
