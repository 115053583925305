<template>
  <FormABM
    :show="show"
    titulo="Acciones"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :multiple="multiple"
    :filtro="filtro"
    sortBy="fecha"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">   
    
    <template v-slot:CompFiltros>
      <div class="d-flex">
        <v-col cols="3" class="pa-0">
          <InputFechaRango v-model="filtro.rangoFecha"/>  
        </v-col>
        <v-col cols="3" class="pa-0 pl-1">
          <SelectPersonas v-model="filtro.idPersona"/>
        </v-col>
        <v-col cols="2" class="pa-0 pl-1">
          <SelectTiposAccionesPersonas v-model="filtro.idsTipos" multiple/>
        </v-col>
        <v-col cols="2" class="pa-0 pl-1">
          <SelectUsuarios v-model="filtro.idsUsuarios" multiple/>
        </v-col>
        <v-col cols="2" class="pa-0 pl-1">
          <SelectSectores v-model="filtro.idsSectores" multiple/>
        </v-col>
      </div>
    </template>  

    <template v-slot:FormEdicion>
      <AccionPersona v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>  
    </template> 
  </FormABM>    
</template>

<script>

  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {accionesPersonasApi} from '@/api/AccionesPersonasApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import AccionPersona from './AccionPersona.vue';
  import InputFechaRango from '@/components/input/InputFechaRango.vue';
  import SelectPersonas from '@/components/select/SelectPersonas.vue';
  import SelectUsuarios from '@/components/select/SelectUsuarios.vue';
  import SelectSectores from '../../components/select/SelectSectores.vue';
  import SelectTiposAccionesPersonas from '../../components/select/SelectTiposAccionesPersonas.vue';

  export default {
    
    mixins: [FormABMMixin],

    components: {
      FormABM, 
      AccionPersona,
      InputFechaRango,
      SelectPersonas,
      SelectUsuarios, 
      SelectSectores,
      SelectTiposAccionesPersonas,
    },

    data: () => ({
      endPointBuscarVarios: accionesPersonasApi.buscarVarios,
      endPointEliminarVarios: accionesPersonasApi.eliminarVarios,
      headers: [
        {text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        {text: "Fecha", value: "fecha", groupable: false, tipo:"fecha"},
        {text: "Persona", value: "persona.nombre"},
        {text: "Título", value: "titulo", groupable: false},
        {text: "Tipo", value: "tipoaccion.nombre"},
        {text: "Usuario", value: "usuario.nombre"},
        {text: "Sector", value: "sector.nombre"}],
      filtro:{
        rangoFecha: [],
        idPersona: null,
        idsUsuarios: null,
        idsSectores: null,
        idsTipos: null,
      }
    }),

    created() {
      this.filtro.rangoFecha[0] = this.$diaActual()
      this.filtro.rangoFecha[1] = this.$diaActual()
    }

  }
</script>