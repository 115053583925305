<template>
    <div v-if="show">    
        <div class="d-flex">
            <v-col cols="4" class="pa-0">
                <v-card>
                    <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                        Artículos
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <data-table dense
                            :headers="headersCantidades"
                            :items="articulos"
                            class="pa-0"/>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4" class="pa-0">
                <v-card v-show="!filtro.idPersona">
                    <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                        Personas
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <data-table dense
                            :headers="headersComprobantes"
                            :items="personas"
                            class="pa-0"/>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4" class="pa-0">
                <v-card v-show="!filtro.idSolicitante">
                    <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                        Solicitantes
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <data-table dense
                            :headers="headersComprobantes"
                            :items="solicitantes"
                            class="pa-0"/>
                    </v-card-text>
                </v-card>
            </v-col>
        </div>
        <div class="d-flex">
            <v-card v-show="!filtro.idPersona" class="flex-grow-1">
                <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                    Personas
                </v-card-title>
                <v-card-text class="pa-0">
                    <chart-donut :data="personas"/>
                </v-card-text>
            </v-card>
            <v-card v-show="!filtro.idsSectores || filtro.idsSectores.length != 1" class="flex-grow-1">
                <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                    Sectores
                </v-card-title>
                <v-card-text class="pa-0">
                    <chart-donut :data="sectores"/>
                </v-card-text>
            </v-card>
            <v-card v-show="!filtro.idsCreadores || filtro.idsCreadores.length != 1" class="flex-grow-1">
                <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                    Creadores
                </v-card-title>
                <v-card-text class="pa-0">
                    <chart-donut :data="creadores"/>
                </v-card-text>
            </v-card>
            <v-card v-show="!filtro.idsAutorizadores || filtro.idsAutorizadores.length != 1" class="flex-grow-1">
                <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                    Autorizadores
                </v-card-title>
                <v-card-text class="pa-0">
                    <chart-donut :data="autorizadores"/>
                </v-card-text>
            </v-card> 
            <v-card v-show="!filtro.idsRubros || filtro.idsRubros.length != 1" class="flex-grow-1">
                <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                    Rubros
                </v-card-title>
                <v-card-text class="pa-0">
                    <chart-donut :data="rubros"/>
                </v-card-text>
            </v-card>
            <v-card v-show="!filtro.idsSubrubros || filtro.idsSubrubros.length != 1" class="flex-grow-1">
                <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                    Subrubros
                </v-card-title>
                <v-card-text class="pa-0">
                    <chart-donut :data="subrubros"/>
                </v-card-text>
            </v-card>
        </div>
        <div class="d-flex">
            <v-col cols="9">
                <chart-line :data="cantidadesPorMes" :xaxis="xaxisCantidadesPorMes"/>
            </v-col>
        </div>

    </div>
</template>

<script>

    import { comprobantesApi } from '../../api/ComprobantesApi';
    import DataTable from '../../components/base/DataTable.vue';
    import ChartDonut from '../../components/graphics/ChartDonut.vue';
    import ChartLine from '../../components/graphics/ChartLine.vue';

    export default {

        components: {
            DataTable,
            ChartDonut,
            ChartLine,
        },

        data: () => ({
            articulos: [],
            rubros: [],
            subrubros: [],
            personas: [],
            solicitantes: [],
            sectores: [],
            creadores: [],
            autorizadores: [],
            cantidadesPorMes: [],
            xaxisCantidadesPorMes: [],
            headersCantidades: [
                {text: "Detalle", value: "nombre", groupable: false},
                {text: "Cantidad", value: "cantidad", groupable: false}],
            headersComprobantes: [
                {text: "Detalle", value: "nombre", groupable: false},
                {text: "Comprobantes", value: "cantidad", groupable: false}],
        }),

        props: {
            show: {type:Boolean,
                default: false},
            filtro: {type: Object,
                default: () => ({})},
            actualizarBuscarExterno: {type: Boolean,
                default: false}, 
        },

        watch:{
            show(val){
                if (val){
                    this.buscar()
                }
            },
            actualizarBuscarExterno(){
                this.buscar()
            },
        },

        methods: { 
            async buscar(){
                try {
                    this.$store.commit("loadingModule/setIsLoading");
                    const response = await comprobantesApi.estadisticas(this.filtro)
                    this.articulos = response.data.articulos
                    this.rubros = response.data.rubros
                    this.subrubros = response.data.subrubros
                    this.personas = response.data.personas
                    this.solicitantes = response.data.solicitantes
                    this.sectores = response.data.sectores
                    this.creadores = response.data.creadores
                    this.autorizadores = response.data.autorizadores
                    this.xaxisCantidadesPorMes = response.data.cantidadespormes.map(item => ({ nombre: item.nombre }));
                    this.cantidadesPorMes.length = 0
                    this.cantidadesPorMes.push({
                        nombre: "Comprobantes",
                        datos: response.data.cantidadespormes.map(item => item.comprobantes),
                    })
                    this.cantidadesPorMes.push({
                        nombre: "Solicitantes",
                        datos: response.data.cantidadespormes.map(item => item.solicitantes),
                    })

                } catch (error) {
                    console.log(error);
                } finally {
                    this.$store.commit("loadingModule/setIsLoading");
                }
            }
        }

    }

</script>