<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Trámite" 
        tituloPlural="Trámites" 
        :endPointSelect="endPointSelect"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :actualizarSelect="actualizarSelect"
        :filtro="filtro"
        :outlined="outlined"
        :hidedetails="hidedetails"
        :readonly="readonly"
        :dense="dense"  
        @showFormABM="mostrarFormABM=true"
        @valorSecundario="valorSecundario"
        @cantidadItems="cantidadItems">
        <template v-slot:FormABM>
            <v-dialog v-model="mostrarFormABM" :max-width="maxWidthFormABM" @click:outside="mostrarFormABM=false">
                <v-card class="pa-2">
                    <Tramites modoSeleccion 
                        :multiple="multiple" 
                        @input="seleccionar" 
                        @cerrar="cerrar" 
                        @actualizoFormEdicion="actualizoFormEdicion"/>
                    </v-card>
            </v-dialog>
        </template>
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {tramitesApi} from '@/api/TramitesApi'
    import Select from '@/components/base/Select.vue';
    import Tramites from '../../views/Sectores/Tramites/Tramites.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
            Tramites,
        },

        data: () => ({
            endPointSelect: tramitesApi.select,
            filtro:{
                idsSectores:[]
            }    
        }),

        props: {
            idsSectores:{type: [Array,Number],
                default(){return[ ]}
            },
            seleccionarTodos:{type: Boolean,
                default: false}
        },

        watch:{
            idsSectores(val){
                if(typeof val === 'number'){
                    this.filtro.idsSectores = [val]
                }else{
                    this.filtro.idsSectores = val
                }
            },  
            seleccionarTodos(val){
                this.filtro.seleccionarTodos = val
            }
        },

        created() {
            if(this.$store.state.sectoresModule.idsSectores[0]){
                this.filtro.idsSectores = this.$store.state.sectoresModule.idsSectores[0]
            }
        },

    }    
</script>