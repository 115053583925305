<template>
    <v-select
        v-model="itemSeleccionado" 
        v-bind="$attrs" 
        class="pa-0 mt-1"
        clearable 
        :items="options">
    </v-select>
</template>

<script>

    export default {
        data: () => ({
            options: [
                {text: 'SI', value: true},
                {text: 'NO', value: false},
            ],
        }),

        props: {
            value: {type: Boolean,
                default: null},
        },

        computed: {     
            itemSeleccionado: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit("input", value)
                }
            },
        },  
    }

</script>