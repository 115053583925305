<template>
    <FormEdicion
        v-model="show"
        titulo="Alícuota de IVA"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        
        <template v-slot:Campos>

            <Input v-model="tabla.nombre"
                autofocus
                label="Alícuota"
                tipo="number"
                obligatorio/>

            <div class="d-flex">
                <v-col cols="6" class="pa-0">    
                    <SwitchCustom v-model="tabla.activo" label="Activo"/>
                </v-col>

                <v-col cols="6" class="pa-0">  
                    <SwitchCustom v-model="tabla.predeterminado" label="Predeterminado"/>     
                </v-col>
            </div>
          
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {alicuotasIvaApi} from '../../api/AlicuotasIvaApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue'
    import SwitchCustom from '../../components/base/Switch.vue';
    
    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SwitchCustom,
        },

        data: () => ({
            endPointBuscar: alicuotasIvaApi.buscar,
            endPointGuardar: alicuotasIvaApi.guardar,
            endPointEliminar: alicuotasIvaApi.eliminarVarios,
            tabla: {
                nombre: 0,
                activo: false,
                predeterminado: false,
            },
        }),

    }
</script>