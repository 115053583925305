<template>
  <v-app id="inspire">

    <v-navigation-drawer v-model="drawer"
      temporary
      app>
      
      <v-list flat dense>  
        
        <template v-for="(item, index) in itemsMenu">
          <v-list-item v-bind:key="index" :to="{name: item.route}" v-if="usuarioTieneAcceso(item.module)" >
            <v-list-item-icon>
              <v-icon :size="item.sizeIcon">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </template>

        <template v-if="usuario.administrador || usuario.modulos.some(modulo => itemsMenuParametros.map(item => item.module).includes(modulo))">            
          <v-list-group prepend-icon="settings">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Parámetros</v-list-item-title>  
              </v-list-item-content>
            </template>

            <template v-for="(item, index) in itemsMenuParametros">
              <v-list-item v-bind:key="index" :to="{name: item.route}" v-if="usuarioTieneAcceso(item.module)" >
                <v-list-item-title>{{item.title}}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>  
        </template>   

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :color="colorPrincipal"
      app
      dark
      dense>
      
      <template v-if="usuario.id">
        <v-app-bar-nav-icon
          @mouseover.stop="isPC && (drawer = !drawer)" 
          @click.stop="!isPC && (drawer = !drawer)"/>

        <v-spacer/>

        <v-btn
          text
          class="text-capitalize"
          @click="clickEmpresa">
          {{empresa.nombrefantasia}}
        </v-btn>
        
        <v-spacer/>

        <div>
          <v-menu offset-y>
            <template v-slot:activator="{on}">
              <v-btn v-on="on"
                text
                class="text-capitalize">
                {{usuario.nombre}}
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="showContrasenia=true">
                <v-list-item-icon>
                  <v-icon>vpn_key</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Contraseña</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>  
          <Button tipo='logout' size='' @click="salir"/>
        </div> 
      </template>
    </v-app-bar>

    <v-main>
      <Snackbar/>
      <loading :active.sync="isLoading"></loading>
      <v-container class="align-start pa-1 ma-0" fluid>
        <v-slide-y-transition mode="out-in">
          <router-view/>
        </v-slide-y-transition>    
      </v-container>
    </v-main>

    <v-footer v-if="!usuario.id" padless>
      <v-layout justify-center>
        <v-flex text-xs-center>
          <v-card flat tile class="white--text" :color="colorPrincipal">
            <v-card-text class="pa-0 white--text text-center"> 
              Pampasoft &copy; {{anio()}}
              <!-- Alan Córdoba - {{anio()}} -->
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-footer>  

    <Contrasenia v-model="showContrasenia"/>  
    <SeleccionEmpresa v-model="showSeleccionEmpresa"/>
  </v-app>

</template>

<script>

  import {mapState} from 'vuex';
  import Button from './components/base/button/Button.vue';
  import Snackbar from '@/components/base/notifications/SnackBar.vue';
  import Loading from 'vue-loading-overlay';
  import Contrasenia from './views/Usuarios/Contraseña.vue'
  import SeleccionEmpresa from './views/Empresas/SeleccionEmpresa.vue';

  export default {
    name: 'App',
    
    components: {
      Button,
      Snackbar,
      Contrasenia,
      Loading,
      SeleccionEmpresa,
    },

    data (){
      return {
        drawer: false,
        showContrasenia: false,
        showSeleccionEmpresa: false,
        isPC: false,
        itemsMenu: [
          {title: 'Inicio', route: 'home', module: 'home', icon: 'home'},
          {title: 'Turnos', route: 'turnos', module: 'turnos', icon: 'today'},
          {title: 'Inscripciones', route: 'inscripciones', module: 'inscripciones', icon: 'fa-person-chalkboard', sizeIcon: 20},
          {title: 'Personas', route: 'personas', module: 'personas', icon: 'group'},
          {title: 'Acciones', route: 'acciones personas', module: 'acciones personas', icon: 'support'},
          {title: 'Comprobantes', route: 'comprobantes', module: 'comprobantes', icon: 'description'},
          {title: 'Artículos', route: 'articulos', module: 'articulos', icon: 'sell'},
          {title: 'Normas', route: 'normas', module: 'normas', icon: 'fa-balance-scale', sizeIcon: 20},
          {title: 'Auditoría', route: 'auditoria', module: 'auditoria', icon: 'history'},
        ],
        itemsMenuParametros: [
          {title: 'Calendarios', route: 'calendarios', module: 'calendarios'},
          {title: 'Eventos de Calendarios', route: 'eventos calendarios', module: 'eventos calendarios'},
          {title: 'Eventos de Inscripciones', route: 'eventos inscripciones', module: 'eventos inscripciones'},
          {title: 'Feriados', route: 'feriados', module: 'feriados'},
          {title: 'Localidades', route: 'localidades', module: 'localidades'},
          {title: 'Rubros de Artículos', route: 'rubros', module: 'rubros'},
          {title: 'Subrubros de Artículos', route: 'subrubros', module: 'subrubros'},
          {title: 'Trámites', route: 'tramites', module: 'tramites'},
          {title: 'Vínculos', route: 'vinculos', module: 'vinculos'},
          {title: 'Act. Económicas', route: 'actividades economicas', module: 'actividades economicas'},
          {title: 'Alícuotas IVA', route: 'alicuotas iva', module: 'alicuotas iva'},
          {title: 'Módulos', route: 'modulos', module: 'modulos'},
          {title: 'Tipos de Comprobantes', route: 'tipos comprobantes', module: 'tipos comprobantes'},
          {title: 'Tipos de Operaciones', route: 'tipos tipos comprobantes', module: 'tipos operaciones'},
          {title: 'Puntos de Venta', route: 'puntos venta', module: 'puntos venta'},
          {title: 'Usuarios', route: 'usuarios', module: 'usuarios'},
          {title: 'Sectores', route: 'sectores', module: 'sectores'},
          {title: 'Empresas', route: 'empresas', module: 'empresas'},
        ]
      }
    },
    
    computed: {
      ...mapState('loadingModule',['isLoading']),
      ...mapState({
        colorPrincipal: state => state.colorPrincipal,
        usuario: state => state.usuarioModule,
        empresa: state => state.empresasModule,
        parametros: state => state.parametrosModule,
      }),
      routeName() {
        return this.$route.name;
      }
    },

    created(){
      this.$store.dispatch("autoLogin");
      window.addEventListener('beforeunload',this.cierreNavegador)
    },

    mounted() {
      this.isPC = this.checkIsPC(); // Llamada a la función para determinar si es una PC o no
    },

    methods: {
      anio(){
        return (new Date().getFullYear());
      },
      clickHome(){
        this.drawer = false
      },
      ingresar(){
        this.drawer = true
        this.$router.push({name:'login'},() => {})
      },
      salir(){
        this.$store.dispatch("logout");
      },
      cierreNavegador(){
        if (process.env.NODE_ENV != "development"){
          this.salir()
        }
      },
      usuarioTieneAcceso(modulo) {
        return this.usuario.administrador || this.usuario.modulos.includes(modulo) || modulo === 'home';
      },
      checkIsPC() {
        const userAgent = navigator.userAgent.toLowerCase();
        return !/mobile|android|iphone|ipad|iemobile|wpdesktop/i.test(userAgent);
      },
      clickEmpresa(){
        if (this.parametros.multiempresa){
          this.showSeleccionEmpresa = true
        }
        
      },
    }
  };
</script>

<style>
  .v-toolbar__content {
    padding: 8px !important;
  }
  html{ overflow-y: auto }
</style>
