<template>
    <FormEdicion
        v-model="show"
        titulo="Familiar"
        :tabla="tabla"
        :modoAlta="modoAlta"
        :modoModificacion="modoModificacion"
        :tablaValoresPorDefecto="tablaValoresPorDefecto"
        @actualizoFormEdicion="actualizoFormEdicion"
        @eliminar="eliminar">
        <template v-slot:Campos>
            <SelectVinculos v-model="tabla.vinculo.id" @valorSecundario="valorSecundarioVinculo"/>
            <SelectPersonas v-model="tabla.persona.id"
                habilitaAgregar
                habilitaModificar 
                showSlotDetalle
                obligatorio
                :idsExcluidos="idsPersonasExcluidas"
                @valorSecundario="valorSecundarioPersona"/>
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import SelectPersonas from '../../components/select/SelectPersonas.vue';
    import SelectVinculos from '../../components/select/SelectVinculos.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            SelectPersonas,
            SelectVinculos,
        },

        data: () => ({
            tabla: {
                vinculo:{
                    id: 0,
                    nombre: "",
                },
                persona:{
                    id: 0,
                    nombre: ""
                }
            },
        }),

        props: {
            idsPersonasExcluidas: {type: Array,
                default(){return []}},
        },

        methods: {
            valorSecundarioVinculo(val){
                this.tabla.vinculo.nombre = val.nombre
            },
            valorSecundarioPersona(val){
                this.tabla.persona.nombre = val._nombre
                this.tabla.persona.nrodocumento = val.nrodocumento
                this.tabla.persona.tipodocumento = val.tipodocumento
                this.tabla.persona.fechanacimiento = val.fechanacimiento
            }
        }
    }    

</script>

