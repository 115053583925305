<template>
    <FormEdicion
        v-model="show"
        titulo="Rubro"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.nombre"
                autofocus
                label="Nombre"
                obligatorio/>

            <Input v-model="tabla.detalle" label="Detalle"/>
            
            <SwitchCustom v-model="tabla.activo" label="Activo"/>          
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {rubrosApi} from '../../../api/RubrosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue'
    import SwitchCustom from '../../../components/base/Switch.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SwitchCustom,
        },

        data: () => ({
            endPointBuscar: rubrosApi.buscar,
            endPointGuardar: rubrosApi.guardar,
            endPointEliminar: rubrosApi.eliminarVarios,
            tabla:{
                nombre:"",
                detalle:"",
                activo:true,
            }
        }),
        
    }
</script>