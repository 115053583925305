import jsPDF from 'jspdf'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import QRCode from 'qrcode'
import { createPDFWithImage } from '../utils'

dayjs.extend(utc)

export function imprimirInscripcion(data) {
            
    var createPDF = function(imgData) {
        var doc = new jsPDF();
        const widthPage = doc.internal.pageSize.getWidth()
        const fontName = "helvetica"
        let texto
        let textoformato

        doc.addImage(imgData, 'JPEG', 10, 8, imgData.width * 10 / 100,  imgData.height * 10 / 100);

        doc.setFontSize(14)
        doc.setTextColor(40)
        doc.text("Constancia de Inscripción",widthPage/2,16,{align:'center'})
        
        doc.line(10,23,200,23)

        let linea = 28

        doc.setFontSize(10)

        doc.text("Inscripción:",10,linea)
        doc.text(dayjs(data.fechahora).utcOffset(-6 * 60).format("DD/MM/YYYY HH:mm [Hs.]"),45,linea)
        
        linea += 5
        doc.text("Apellido y Nombre:",10,linea)
        const nombreCompleto = `${data.persona.apellido || ''} ${data.persona.nombre}`;
        doc.text(nombreCompleto,45,linea)

        
        linea += 5
        doc.text("Tipo y Nro. Doc.",10,linea)
        doc.text(data.persona.tipodocumento.nombre + ' ' + data.persona.nrodocumento,45,linea) 
        
        linea += 5
        doc.text("Evento:",10,linea)
        doc.text(data.inscripcion.nombre,45,linea)
        
        if (data.inscripcion.detalle){
            linea += 5
            doc.text("Detalle:",10,linea)
            doc.text(data.inscripcion.detalle,45,linea)
        }
        
        if (data.inscripcion.destinatarios){
            linea += 5
            doc.text("Destinatarios:",10,linea)
            doc.text(data.inscripcion.destinatarios,45,linea)
        }

        if (data.inscripcion.horario){
            linea += 5
            doc.text("Horario:",10,linea)      
            doc.text(data.inscripcion.horario,45,linea)
        }

        if (data.inscripcion.inicio){
            linea += 5
            doc.text("Inicio:",10,linea)      
            doc.text(data.inscripcion.inicio,45,linea)
        }
        
        if (data.inscripcion.lugar){
            linea += 5
            doc.text("Lugar:",10,linea)
            doc.text(data.inscripcion.lugar,45,linea)
        }
        
        if (data.inscripcion.direccion){
            linea += 5
            doc.text("Dirección:",10,linea)
            doc.text(data.inscripcion.direccion,45,linea)
        }
        
        if (data.inscripcion.instructor){
            linea += 5
            doc.text("Instructor/a:",10,linea)
            doc.text(data.inscripcion.instructor,45,linea)
        }

        switch (data.inscripcion.idtipo) {
            case 14: //Colonia de vacaciones

                linea += 5
                doc.line(10,linea,200,linea)

                linea += 5
                doc.setFont(fontName,'bold')
                doc.text("ATENCION:",10,linea)

                linea += 5
                texto = "Presentar de forma obligatoria certificado médico y odontológico en oficina de Deportes en Albergue, Bv. Colon al 500."
                textoformato = doc.splitTextToSize(texto,180)
                doc.text(textoformato,10,linea)

                break
        }

        if (data.inscripcion.linkqr){
            linea += 5
            doc.line(10,linea,200,linea)
            linea += 5
            doc.text("Pagá con el siguiente código QR:",10,linea)  
            linea += 1
            // Generar el código QR a partir de la URL
            QRCode.toDataURL(data.inscripcion.linkqr)
            .then(function (qrDataURL) {
                // Agregar el código QR al documento PDF
                doc.addImage(qrDataURL, 'JPEG', 50, linea, 100, linea);
                doc.output('dataurlnewwindow',{filename:'Constancia de Inscripción'});
            })
            .catch(function (error) {
                console.error(error);
            });
        }
        else {
            doc.output('dataurlnewwindow',{filename:'Constancia de Inscripción'});
        }
    }

    createPDFWithImage(createPDF)
}