<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Tipo Comprobante" 
        tituloPlural="Tipos Comprobante" 
        :endPointSelect="endPointSelect"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :filtro="filtro"
        :clearable="clearable"
        :seleccionaPrimero="seleccionaPrimero"
        @valorSecundario="valorSecundario">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {tiposComprobantesApi} from '../../api/TiposComprobantesApi';
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: tiposComprobantesApi.select,
            filtro:{
                idsTiposTipoComprobante: null, 
                puntoVenta: null,
                ivaEmisor: null,
                ivaReceptor: null,
            }    
        }),

        props: {
            idsTiposTipoComprobante: {type: [Array,Number],
                default(){return[ ]}
            },
            puntoVenta: {type: String},
            ivaEmisor: {type: Number},
            ivaReceptor: {type: Number},
        },

        watch:{
            idsTiposTipoComprobante(val){
                if(typeof val === 'number'){
                    this.filtro.idsTiposTipoComprobante = [val]
                }else{
                    this.filtro.idsTiposTipoComprobante = val
                }
            },
            puntoVenta(val){
                this.filtro.puntoVenta = val
            },
            ivaEmisor(val){
                this.filtro.ivaEmisor = val
            },
            ivaReceptor: {
                immediate: true,
                handler(val){
                    this.filtro.ivaReceptor = val
                }
            },
        },
    }    
</script>