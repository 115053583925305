import axios from 'axios'
import {formatoDocumento} from '@/utils';
const controller = "personas/"

class PersonasApi {
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    async buscarVarios(parametros){  
        let response = await axios.get(controller,{params:{
            idsLocalidades: parametros.idsLocalidades,
            idsActividades: parametros.idsActividades,
            idsOrigenesAlta: parametros.idsOrigenesAlta,
            idsTiposPersona: parametros.idsTiposPersona}
        })

        for (let index=0; index < response.data.length; index++) {
            const apellido = response.data[index].apellido
            const nombre = response.data[index].nombre
            response.data[index].persona = apellido ? `${apellido} ${nombre}` : nombre;
            response.data[index].documento = response.data[index].tipodocumento.nombre + ' ' + formatoDocumento(response.data[index].nrodocumento,response.data[index].tipodocumento.formatocuit) 
            if (response.data[index].cuitcuil){
                response.data[index].documento = response.data[index].documento + ' / ' + formatoDocumento(response.data[index].cuitcuil,true) 
            }
            response.data[index].localidad = response.data[index].localidad.nombre + ' (' + response.data[index].localidad.provincia.nombre + ')' 
        }
        return response
    }

    async buscarVariosConActividad(parametros){  
        let response = await axios.get(controller + "buscarvariosconactividad",{params:{
            idsLocalidades: parametros.idsLocalidades,
            idsActividades: parametros.idsActividades,
            idsOrigenesAlta: parametros.idsOrigenesAlta,
            idsTiposPersona: parametros.idsTiposPersona}
        })

        for (let index=0; index < response.data.length; index++) {
            const apellido = response.data[index].idpersona.apellido
            const nombre = response.data[index].idpersona.nombre
            response.data[index].persona = apellido ? `${apellido} ${nombre}` : nombre;
            response.data[index].documento = response.data[index].idpersona.tipodocumento.nombre + ' ' + formatoDocumento(response.data[index].idpersona.nrodocumento,response.data[index].idpersona.tipodocumento.formatocuit) 
            response.data[index].id = response.data[index].idpersona.id
        }
        return response
    }

    async select(parametros){
        let response 
        if(!parametros.soloConActividades){
            response = await axios.get(controller + "select",{params:{
                ids: parametros.ids,
                busqueda: parametros.busqueda ? parametros.busqueda.replace(/\./g, '') : null,
                idsExcluidos: parametros.idsExcluidos} 
            })
        }else{
            response = await axios.get(controller + "selectPersonasConActividadesExterno",{params:{
                id: parametros.id,
                busqueda: parametros.busqueda ? parametros.busqueda.replace(/\./g, '') : null} 
            })
        }

        for (let index=0; index < response.data.length; index++) {
            const apellido = response.data[index].apellido
            const nombre = response.data[index].nombre
            const nombreCompleto = apellido ? apellido + ' ' + nombre : nombre

            response.data[index]._nombre = nombreCompleto
            response.data[index]._documento = response.data[index].tipodocumento.nombre + ' ' + formatoDocumento(response.data[index].nrodocumento,response.data[index].tipodocumento.formatocuit)
            response.data[index].nombre = nombreCompleto + ' (' + response.data[index]._documento + ')'
            
            if(!parametros.soloConActividades){ 
                // Buscar el contacto principal en el array de contactos
                const contactos = response.data[index].contactos;

                const contactoPrincipal = contactos.find(contacto => contacto.principal === true);
                // Asignar el teléfono del contacto principal, si existe
                response.data[index].telefono = contactoPrincipal ? contactoPrincipal.telefono : '';
                response.data[index].email = contactoPrincipal ? contactoPrincipal.email : '';
            }
        }
        return response
    }

    porDefecto(){
        return axios.get(controller + "pordefecto");
    }

    auditoria(id,rangoFecha){
        return axios.get("auditoria",{params:{
            desde: rangoFecha ? rangoFecha[0] : null,
            hasta: rangoFecha ? rangoFecha[1] : null,
            idTabla: id,
            tabla: "personas"
        }})
    }

    selectTipos(){
        return axios.get("tipospersona/select");
    }

    selectTiposDocumento(parametros){
        return axios.get("tiposdocumento/select",{params:{
            personaFisica: parametros.personaFisica,
            personaJuridica: parametros.personaJuridica
        }});
    }

    selectSexos(){
        return axios.get("sexos/select");
    }
}

export const personasApi = new PersonasApi();