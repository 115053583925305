import axios from 'axios'
import {modificaFormatoFecha, formatoDocumento, edad} from '@/utils'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const controller = "inscripciones/"

class InscripcionesApi {
    
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){ 
        return axios.get(controller,{params: {
            idsSectores: parametros.idsSectores,
            activo: parametros.activo,
            activoInscripciones: parametros.activoInscripciones,
            permiteInscripciones: parametros.permiteInscripciones,
        }})
    }
        
    select(parametros){
        return axios.get(controller + "select",{params: {
            idsSectores: parametros.idsSectores,
            activo: parametros.activo,
            activoInscripciones: parametros.activoInscripciones,
            permiteInscripciones: parametros.permiteInscripciones,
        }});
    }

    guardarDetalle(datos){
        if (datos.id){
            return axios.put(controller + 'actualizardetalle',datos);
        }else{
            return axios.post(controller + 'agregardetalle',datos);
        }
    }

    async buscarDetalle(id){
        let response = await axios.get(controller + 'buscardetalle' + id);
        response.data.fechahora = dayjs.utc(response.data.fechahora).subtract(3, 'hour'); 
        return response
    }

    eliminarVariosDetalle(ids){
        return axios.delete(controller + 'eliminardetalle',{params:{
            ids: ids
        }});
    }
    
    async buscarVariosDetalle(parametros){    
        let response = await axios.get(controller + "buscarvariosdetalle",{params: {
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsSectores: parametros.idsSectores,
            idsEventos: parametros.idsEventos,
            idsOrigenes: parametros.idsOrigenes,
        }})
        for (let index=0; index < response.data.length; index++) {
            const apellido = response.data[index].persona.apellido
            const nombre = response.data[index].persona.nombre
            response.data[index].persona.nombre = apellido ? apellido + ' ' + nombre : nombre 
            response.data[index].documento = response.data[index].persona.tipodocumento.nombre + ' ' + formatoDocumento(response.data[index].persona.nrodocumento,response.data[index].persona.tipodocumento.formatocuit)  
            response.data[index].persona.fechanacimiento = modificaFormatoFecha(response.data[index].persona.fechanacimiento)
            response.data[index].edad = edad(response.data[index].persona.fechanacimiento)
            
            // Buscar el contacto principal en el array de contactos
            const contactos = response.data[index].persona.contactos;
            const contactoPrincipal = contactos.find(contacto => contacto.principal === true);
            // Asignar el teléfono del contacto principal, si existe
            response.data[index].telefono = contactoPrincipal ? contactoPrincipal.telefono : '';
            response.data[index].fechahora = dayjs.utc(response.data[index].fechahora).subtract(6, 'hour'); 
        }
        return response
    }

    async estadisticas(parametros){
        let response = await axios.get(controller + "estadisticas",{params:{
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsSectores: parametros.idsSectores,
            idsEventos: parametros.idsEventos,
            idsOrigenes: parametros.idsOrigenes,
        }})

        for (let index=0; index < response.data.personas.length; index++) {
            let detalle = ""
            if (response.data.personas[index].apellido) detalle = response.data.personas[index].apellido
            if (response.data.personas[index].nombre){
                if (detalle){
                    detalle = detalle + " " + response.data.personas[index].nombre 
                }else{
                    detalle = response.data.personas[index].nombre 
                }
            }
            response.data.personas[index].nombre = detalle
        }

        return response
    }

    imprimirDetalle(id){
        return axios.get(controller + "imprimirdetalle" + id);
    }

    selectTipos(){
        return axios.get("tiposinscripcion/select");
    }
}

export const inscripcionesApi = new InscripcionesApi();