<template>
    <v-dialog v-model="show" :width="options.width" @keydown.esc="cancel">
      <v-card>
        <v-card-text class="pa-0">
          <v-toolbar dark :color="options.color" dense flat>
            <v-toolbar-title class="white--text">{{title}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text v-show="!!message" class="pa-4 text-center subtitle-2">{{ message }}</v-card-text>
          <v-card-actions class="justify-center">
            <div v-if = "options.tipo === 'confirmacion'">
              <v-btn text @click.native="ok"> 
                <v-icon left>
                  check
                </v-icon>
                SI
              </v-btn>
              <v-btn text @click.native="cancel">
                <v-icon left>
                  close
                </v-icon>
                NO
              </v-btn>
            </div>
            <div v-else-if = "options.tipo === 'aceptacion'">
              <v-btn text @click.native="ok"> 
                <v-icon left>
                  check
                </v-icon>
                ACEPTAR
              </v-btn>
            </div>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
    export default {
      data: () => ({
        dialog: false,
        resolve: null,
        reject: null,
        message: null,
        title: null,
        options: {
          tipo: 'confirmacion',
          color: 'primary',
          width: 290,
        }
      }),

      computed: {
        show: {
          get() {
            return this.dialog
          },
          set(value) {
            this.dialog = value
            if (value === false) {
              this.cancel()
            }
          }
        }
      },

      methods: {
        open(title, message, options) {
          this.dialog = true
          this.title = title
          this.message = message
          this.options = Object.assign(this.options, options)
          return new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
          })
        },
        ok() {
          this.resolve(true)
          this.dialog = false
        },
        cancel() {
          this.resolve(false)
          this.dialog = false
        }
      }

    }
</script>