<template>
    <FormEdicion
        v-model="show"
        titulo="Modulo"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input
                v-model="tabla.nombre"
                label="Nombre"
                autofocus
                obligatorio
            />
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {modulosApi} from '../../api/ModulosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
        },

        data: () => ({
            endPointBuscar: modulosApi.buscar,
            endPointGuardar: modulosApi.guardar,
            endPointEliminar: modulosApi.eliminarVarios,
            tabla: {
                nombre: "",
            },
        }),

    }
</script>