<template>
    <FormEdicion
        v-model="show"
        titulo="Turno"
        maxWidth="40%"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :endPointImprimir="endPointImprimir"
        :endPointAuditoria="endPointAuditoria"
        :permiteModificar="permiteModificar"
        :permiteEliminar="permiteEliminar && (estadoTurno.disponible || estadoTurno.reservado)"
        :permiteImprimir="!estadoTurno.disponible && !estadoTurno.reservado"
        :tabla="tabla"
        :imprime="imprimeConstancia"
        @mostroValores="mostroValores"
        @actualizoFormEdicion="actualizoFormEdicion"
        @imprimir="imprimir">
        <template v-slot:Campos>
            
            <SelectSectoresTramites :sectoresSeleccionados.sync="tabla.turno.idsector"
                readonly 
                :tramitesSeleccionados.sync="tabla.idtramite" 
                :tramitesObligatorio="tramitesObligatorio"/>

            <InputTextArea v-model="tabla.observacionesexterno"
                v-show="tabla.observacionesexterno"
                label="Información adicional"
                readonly/>
            <div class="d-flex">
                <v-col :cols="colFecha" class="pa-0 pt-1 pr-1">
                    <Input v-model="fecha"
                        label="Día"
                        readonly/>
                </v-col>
                <v-col v-if="tabla.intervalo != '00:00:00'" class="pa-0 pt-1">
                    <Input v-model="hora"
                        label="Hora"
                        readonly/>
                </v-col>
                <Navegation :parametros="tabla"
                    :endPointBuscarVarios="endPointAnterioresSiguientesTurnos"
                    :visible="muestraNavegation"
                    @cambioId="cambioTurno"
                    class="pt-3"/>
            </div>

            <div class="d-flex">  
                <v-col class="pa-0">  
                    <SelectEstadosTurno v-model="tabla.idestado"
                        obligatorio
                        :readonly="!permiteModificar || !permiteModificarEstado"
                        @valorSecundario="cambioEstado"/>
                </v-col>
                <v-col class="pa-0 pl-1" v-show="tabla.idorigenasignacion">   
                    <SelectOrigenes v-model="tabla.idorigenasignacion"
                        readonly/>
                </v-col>
            </div>
        
            <SelectPersonas v-model="tabla.idpersona" 
                habilitaAgregar
                habilitaModificar 
                habilitaDetalle
                showSlotDetalle
                :obligatorio="estadoTurno.asignado || estadoTurno.atendido || estadoTurno.cancelado"
                :readonly="readOnlyPersonas"/>

            <InputTextArea
                v-model="tabla.observaciones"
                label="Observaciones"
                :readonly="!permiteModificar"/>
       
        </template>    
       
    </FormEdicion>
</template>

<script>

    import {mapState} from 'vuex';
    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {turnosApi} from '../../api/TurnosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';
    import InputTextArea from '@/components/input/InputTextArea.vue'
    import SelectEstadosTurno from '@/components/select/SelectEstadosTurno.vue'
    import Navegation from '../../components/base/Navegation.vue';
    import SelectOrigenes from '../../components/select/SelectOrigenes.vue'
    import SelectSectoresTramites from '../../components/select/SelectSectoresTramites.vue';
    import { imprimirTurno } from '@/shared/ImpresionTurno'

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            InputTextArea,
            SelectEstadosTurno,
            Navegation,
            SelectOrigenes,
            SelectSectoresTramites,
            SelectPersonas: () => import('@/components/select/SelectPersonas.vue'),
        },
        
        data: () => ({
            endPointBuscar: turnosApi.buscar,
            endPointGuardar: turnosApi.guardarDetalle, 
            endPointEliminar: turnosApi.eliminarVarios,
            endPointImprimir: turnosApi.imprimir,
            endPointAuditoria: turnosApi.auditoria,
            endPointAnterioresSiguientesTurnos: turnosApi.anterioresSiguientes,
            tabla:{
                idturno:0,
                idtramite:null,
                fechahora:null,
                idestado:0,
                idpersona:null,
                observacionesexterno:"",
                observaciones:"",
                idorigenasignacion:0,
                inicialidestado:0,
                oldidpersona:0,
                oldobservaciones:"",
                oldidorigenasignacion:0,
                origenInterno:true,
                turno:{
                    idsector:0,
                    idtramite:0,
                }
            },
            estadoTurno:{
                disponible:false,
                asignado:false,
                reservado:false,
                atendido:false,
                cancelado:false
            },
            imprimeConstancia:false,
            tramitesObligatorio:false,
        }),

        computed: {
            ...mapState({
                permiteModificar: state => state.usuarioModule.permisos.turno.modificar,
                permiteEliminar: state => state.usuarioModule.permisos.turno.eliminar,
                permiteModificarEstado: state => state.usuarioModule.permisos.turno.modificarEstado,
            }),
            fecha: {
                get() {
                    return this.$obtenerDiaEnLetras(new Date(this.tabla.fechahora))
                },
                set() {}
            },
            hora: {
                get() {
                    return this.$obtenerHora(new Date(this.tabla.fechahora),'hml')
                },
                set() {}
            },
            readOnlyPersonas(){
                if(this.permiteModificar && this.tabla.oldidpersona === null) return false
                return true
            },
            muestraNavegation(){
                return this.estadoTurno.disponible || this.estadoTurno.reservado
            },
            colFecha(){
                if(this.muestraNavegation) return 9 
                return 10    
            },
        },

        watch:{
            'tabla.idpersona'(val){
                if (val && (this.estadoTurno.disponible === true || this.estadoTurno.reservado === true)){ 
                    this.tabla.idestado = 2 //ESTO DEBERIA MEJORARSE        
                }
            },
        },

        methods: {
            mostroValores(){
                this.tabla.inicialidestado = this.tabla.idestado //SE USA AL MOMENTO DE GRABAR EN EL BACKEND PARA VALIDAR QUE EL TURNO ESTE DISPONIBLE
                this.imprimeConstancia = this.tabla.idpersona == null ? true : false //SOLO IMPRIME AL GUARDAR, SI SE ASIGNO POR PRIMERA VEZ LA PERSONA AL TURNO
                if (this.tabla.oldidpersona != 0){
                    this.tabla.idpersona = this.tabla.oldidpersona
                    this.tabla.observaciones = this.tabla.oldobservaciones
                    this.tabla.idestado = this.tabla.oldidestado
                }
                this.tabla.oldidpersona = this.tabla.idpersona
                this.tabla.oldidorigenasignacion = this.tabla.idorigenasignacion
                this.tabla.oldidestado = this.tabla.idestado
            },
            cambioEstado(val){
                this.estadoTurno.asignado = (val.asignado === true) 
                this.estadoTurno.reservado = (val.reservado === true)
                this.estadoTurno.disponible = (val.disponible === true)
                this.estadoTurno.atendido = (val.atendido === true)
                this.estadoTurno.cancelado = (val.cancelado === true)
                this.tramitesObligatorio = !this.estadoTurno.disponible && !this.estadoTurno.reservado
                if (this.estadoTurno.disponible === true || this.estadoTurno.reservado === true) { 
                    this.tabla.idpersona = null
                    this.tabla.idorigenasignacion = null
                    this.tabla.idtramite = null
                    this.tabla.observaciones = ""
                    this.tabla.observacionesexterno = ""
                }else if((this.estadoTurno.asignado === true || this.estadoTurno.cancelado  === true || this.estadoTurno.atendido  === true) 
                        && this.tabla.idpersona == null && this.tabla.oldidpersona != 0){
                    this.tabla.idpersona = this.tabla.oldidpersona
                    this.tabla.idorigenasignacion = this.tabla.oldidorigenasignacion
                }
                if (this.estadoTurno.asignado === true && this.tabla.idorigenasignacion === null) this.tabla.idorigenasignacion = 1           
                if (this.estadoTurno.asignado === true){
                    this.imprimeConstancia = true
                }else{
                    this.imprimeConstancia = false
                }
            },
            cambioTurno(val){
                this.tabla.oldidpersona = this.tabla.idpersona
                this.tabla.oldobservaciones = this.tabla.observaciones
                this.tabla.oldidestado = this.tabla.idestado
                this.tabla.id = val
                this.tabla.actualizar = !this.tabla.actualizar
            },

            imprimir(data){
                imprimirTurno(data)
            }
        }
    }
</script>