<template>
    <v-textarea
        v-bind="$attrs" 
        auto-grow
        rows="1"
        class="ma-0"
        :value="value"
        :readonly="readonly"
        :clearable="clearable"
        :rules="[valid]"
        :counter="cantidadminimacaracteres != 0"
        @input="$emit('input', $event)">
    </v-textarea>
</template>
<script>
    export default {
        
        props:{
            value:{ type: [String,Number]},
            obligatorio:{ type: Boolean,
                default: false},    
            cantidadminimacaracteres:{type: Number,
                default: 0},
            readonly: {type: Boolean,
                default: false},  
        },

        computed: {
            clearable(){
                return !this.readonly
            },      
        },

        methods: {
            valid(value){
                if (this.obligatorio && !value){
                    return 'El dato es obligatorio.'
                }
                if (this.cantidadminimacaracteres != 0 && value.length < this.cantidadminimacaracteres){
                    return 'La cantidad mínima de caracteres es ' + this.cantidadminimacaracteres
                }
                return true
            },
        }

    }
</script>