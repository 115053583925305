<template>
    <div v-if="show">
        <div class="d-flex">
            <v-col cols="11">
                <chart-column :data="eventos"/>
            </v-col>
        </div>
        
        <div class="d-flex">
            <v-card class="flex-grow-1">
                <v-card-title class="pa-1 text-subtitle-1 justify-center">    
                    Personas
                </v-card-title>
                <v-card-text class="pa-1">
                    <data-table dense
                        :headers="headersPersonas"
                        :items="personas"
                        class="pa-0"/>
                </v-card-text>
            </v-card>
            <v-card class="flex-grow-1">
                <v-card-text>
                    <chart-donut :data="origenes"/>
                </v-card-text>
            </v-card>
        </div>

    </div>
    
</template>

<script>

    import { inscripcionesApi } from '../../api/InscripcionesApi';
    import DataTable from '../../components/base/DataTable.vue';
    import ChartColumn from '../../components/graphics/ChartColumn.vue';
    import ChartDonut from '../../components/graphics/ChartDonut.vue';

    export default {

        components: {
            DataTable,
            ChartDonut,
            ChartColumn,
        },

        data: () => ({
            eventos: {},
            personas: [],
            origenes: [],
            headersPersonas: [
                {text: "Detalle", value: "nombre", groupable: false},
                {text: "Eventos", value: "cantidad", groupable: false}],
        }),

        props: {
            show: {type:Boolean,
                default: false},
            filtro: {type: Object,
                default: () => ({})},
            actualizarBuscarExterno: {type: Boolean,
                default: false}, 
        },

        watch:{
            show(val){
                if (val){
                    this.buscar()
                }
            },
            actualizarBuscarExterno(){
                this.buscar()
            },
        },

        methods: { 
            async buscar(){
                try {
                    this.$store.commit("loadingModule/setIsLoading");
                    const response = await inscripcionesApi.estadisticas(this.filtro)

                    this.eventos = response.data.eventos
                    this.personas = response.data.personas
                    this.origenes = response.data.origenes
                } catch (error) {
                    console.log(error);
                } finally {
                    this.$store.commit("loadingModule/setIsLoading");
                }
            }
        }

}
</script>

