<template>
  <v-snackbar 
    v-model="show" 
    :top="top" 
    :timeout="timeout"
    :color="color">
    <v-icon>{{icono}}</v-icon>
    {{message}}
    <template v-slot:action="{attrs}">
        <v-btn
            v-bind="attrs"
            icon
            @click="show = false">
            <v-icon>close</v-icon>
        </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

  export default {
    
    data() {
      return {
        show: false,
        top: true,
        message: "",
        timeout: 2000,
        color: "",
        icono: "",
      };
    },

    created() {
      this.$store.watch(
        state => state.snackbarModule.snack,
        () => {
          const accion = this.$store.state.snackbarModule.snack.accion;

          if (accion) {
            
            this.color = "success"
            this.icono = "check_circle_outline"

            if(accion === 'Guardo'){
              this.message = 'Cambios guardados con éxito.'
            }else if(accion === 'Elimino'){
              this.message = 'Eliminación realizada con éxito.'
            }else if(accion === 'SesionExpirada'){
              this.color = "red"
              this.icono = "highlight_off"
              this.message = 'Su sesión ha expirado.'
            }else if(accion === 'Otra'){
              this.message = this.$store.state.snackbarModule.snack.message
            }

            this.show = true;
            this.$store.commit("snackbarModule/setSnack", {});
            
          }
        }
      );
    },

  };
</script>