<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Condición IVA" 
        tituloPlural="Condiciones IVA" 
        :endPointSelect="endPointSelect"
        :obligatorio="obligatorio"
        :clearable="false"
        :filtro="filtro"
        :readonly="readonly"
        @valorSecundario="valorSecundario"
        @resetValorSecundario="resetValorSecundario">
    </Select>
</template>


<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {condicionesIvaApi} from '@/api/CondicionesIvaApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: condicionesIvaApi.select,
            filtro: {
                personaFisica: null,
                personaJuridica: null,
            }    
        }),

        props: {
            personaFisica:{type: Boolean,
                default: false},
            personaJuridica:{type: Boolean,
                default: false},
        },

        watch:{
            personaFisica(val){
                this.filtro.personaFisica = val    
            },
            personaJuridica(val){
                this.filtro.personaJuridica = val    
            },
        },
    }    
</script>