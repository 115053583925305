<template>
    <form-abm
        :show="show"
        :endPointBuscarVarios="endPointBuscarVarios"
        :endPointEliminarVarios="endPointEliminarVarios"
        :permiteModificar="permiteModificar"
        :permiteVisualizar="permiteVisualizar"
        :headers="headers"
        :actualizarBuscar="actualizarBuscar"  
        :modoSeleccion="modoSeleccion"
        :multiple="multiple"
        :filtro="filtro"
        :permiteAgregar="false"
        :permiteEliminar="false"
        :habilitaFiltrar="false"
        permiteImprimir
        sortBy="actividadeconomica.nombre"
        itemKey="index"
        @actualizar="actualizar"
        @cerrar="cerrar"
        @seleccionar="seleccionar">   

        <template v-slot:FormEdicion>
            <persona v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/>
        </template>
    </form-abm>
</template>

<script>

    import {mapState} from 'vuex';
    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {personasApi} from '@/api/PersonasApi'
    import FormAbm from '../../components/base/form/FormABM.vue'
    import Persona from './Persona.vue'
    
    export default {
        
        mixins: [FormABMMixin],

        components: { 
            FormAbm,
            Persona,
        },
        
        data: () => ({
            endPointBuscarVarios: personasApi.buscarVariosConActividad,
            endPointEliminarVarios: personasApi.eliminarVarios,
            headers: [
                {text: "Opciones", value: "modificar", sortable:false, filterable:false, groupable:false},
                {text: "Persona", value: "persona", groupable:false, impresion:true},
                {text: "Documento", value: "documento", groupable:false, impresion:true},
                {text: "Código", value: "actividadeconomica.codigo", impresion:true},
                {text: "Actividad", value: "actividadeconomica.nombre", impresion:true},
                {text: "Dirección", value: "direccion", groupable:false, impresion:true},
                {text: "Localidad", value: "localidad.nombre", impresion:true},
                {text: "Fecha", value: "emision", tipo: "fecha", impresion:true},
                {text: "Estado", value: "estadoactividad.nombre", impresion:true},
                {text: "Act. Principal", value: "principal", tipo: "boolean", impresion:true},
                {text: "Observaciones", value: "observaciones", groupable:false, impresion:true},
            ],   
        }),

        computed: {
            ...mapState({
                permiteModificar: state => state.usuarioModule.permisos.persona.modificar,
                permiteVisualizar: state => state.usuarioModule.permisos.persona.visualizar,
            }),
        },
        
        props: {
            filtro: {type: Object,
                default: () => ({})
            },
        },
    }
            
</script>