<template>
    <v-text-field
        v-bind="$attrs" 
        v-on="$listeners"
        class="pa-0 mt-1"
        :value="value"
        :readonly="readonly"
        :clearable="clearable"
        :type="type"
        :rules="[valid]"
        :counter="counter || cantidadminimacaracteres != 0"
        @input="input"/>
</template>

<script>

    //v-mask="mask"
    ///[/\d?/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/]

    ///^\d{1,3}(,\d{3})*(\.\d+)?$/

    //v-mask="mask" 
    // :value="value"
    // v-bind="$attrs" 
    // v-on="myListeners" 

    import createNumberMask from 'text-mask-addons/dist/createNumberMask';

    export default {
        // data: () => ({
        //     numeroSinDecimal: createNumberMask({
        //         prefix: '',
        //         includeThousandsSeparator: true,
        //         thousandsSeparatorSymbol: '.',
        //     }),
        //     dni: createNumberMask({
        //         prefix: '',
        //         includeThousandsSeparator: true,
        //         thousandsSeparatorSymbol: '.',
        //         integerLimit: 8,
        //     }),
        //     monedaMask: createNumberMask({
        //         prefix: '$',
        //         includeThousandsSeparator: true,
        //         thousandsSeparatorSymbol: '.',
        //     }),
        // }),

        props:{
            value: {type: [String,Number]},  
            tipo: {type: String,
                default: '',
                validator: function(value) {
                    return ['','number','password','mail','dni','cuit','telefono'].includes(value);
                }},
            obligatorio: {type: Boolean,
                default: false},    
            mascara: {type: String,
                default: ''},  
            counter: {type: [Number,Boolean],
                default: false},
            tipovalidacion: {type: String,
                default: ''},
            cantidadminimacaracteres: {type: Number,
                default: 0},
            permiteValorCero: {type: Boolean,
                default: true},
            permiteValorNegativo: {type: Boolean,
                default: false},
            readonly: {type: Boolean,
                default: false},    
        },

        computed: {
            type() {
                switch (this.tipo) {
                    case "number": return "number"
                    case "password": return "password"
                    case "dni": return "number"
                    default: return ''
                }
            },
            clearable(){
                return !this.readonly
            },      
        },

        methods: {

            input(val){
                if (val){
                    switch (this.tipo) {
                    case "cuit":
                        // Elimina caracteres no numéricos
                        let soloNumeros = val.replace(/[^\d]/g, '');

                        // Aplica la máscara
                        if (soloNumeros.length >= 2) {
                            val = soloNumeros.replace(/^(\d{2})/, '$1-');
                        }
                        if (soloNumeros.length >= 10) {
                            val = soloNumeros.replace(/^(\d{2})(\d{8})/, '$1-$2-');
                        }
                        break;
                    }
                }
                this.$emit('input', val)
            },

            valid(val){
                if (!val) {
                    return this.obligatorio ? 'El dato es obligatorio.' : true;
                }
                
                const numValue = parseInt(val);

                if (!this.permiteValorCero && numValue === 0){
                    return 'El número debe ser distinto de 0.'
                }
                if (!this.permiteValorNegativo && numValue < 0){
                    return 'El número debe ser positivo.'
                }
                if (this.counter && val.length > this.counter){
                    return 'El dato puede contener hasta ' + this.counter + ' carácteres.'
                } 
                if (this.cantidadminimacaracteres != 0 && val.length < this.cantidadminimacaracteres){
                    return 'La cantidad mínima de caracteres es ' + this.cantidadminimacaracteres
                }  
                if (this.tipo === "dni" && (numValue < 1000000 || numValue > 99999999)){
                    return 'El número es incorrecto.'
                }
                if (this.tipo==="cuit"){
                    let cuit = val.replace(/[-]/gi,'')    

                    if(cuit.length != 11) {
                        return "La cantidad de dígitos es incorrecta.";
                    }
            
                    var acumulado   = 0;
                    var digitos     = cuit.split("");
                    var digito      = digitos.pop();
            
                    for(var i = 0; i < digitos.length; i++) {
                        acumulado += digitos[9 - i] * (2 + (i % 6));
                    }
            
                    var verif = 11 - (acumulado % 11);
                    if(verif == 11) {
                        verif = 0;
                    } else if(verif == 10) {
                        verif = 9;
                    }
            
                    if (digito == verif){
                        return true
                    }else{
                        return "El número es incorrecto."
                    } 
                }
                if(this.tipo==="mail") {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(val) || 'El formato es incorrecto.';
                }
                if(this.tipo==="telefono") {
                    const num = val.replace(/\D+/g, '');
                    const pattern = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/
                    return pattern.test(num) || 'El número es incorrecto.';
                }

                // if (this.mascara==="numero_sindecimal" && (val.includes('.') || val.includes(','))){
                //     return 'El número debe ser entero.'
                // }
                return true
            },
        }

    }
</script>