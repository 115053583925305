<template>
    <FormEdicion
        v-model="show"
        titulo="Norma"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :endPointAuditoria="endPointAuditoria"
        :tabla="tabla"
        rutaStorage="digestojuridico/"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input v-model="tabla.titulo"
                label="Titulo"
                autofocus
                obligatorio/>
            
            <div class="d-flex">
                <div class="pt-2">
                    <InputFecha v-model="tabla.fecha" 
                        obligatorio/>
                </div>
                <Input v-model="tabla.numero" label="Número" tipo="number" obligatorio :permite-valor-cero="false" class="px-2 pt-2"/>
                <Input v-model="tabla.anio" label="Año" tipo="number" obligatorio :permite-valor-cero="false" class="pt-2"/>
            </div>
            
            <div class="d-flex">
                <SelectEmisoresNorma v-model="tabla.idemisornorma" obligatorio/>
                <SelectTiposNormas v-model="tabla.idtiponorma" class="px-2" obligatorio/>
                <SelectEstadosNormas v-model="tabla.idestadonorma" obligatorio/>
            </div>

            <div v-if="tabla.direcciondocumentostorage == ''">
                <v-file-input
                    v-model="tabla.documentostorage"
                    accept=".pdf"
                    label="Copia de norma"
                    class="pt-2"
                    dense
                    show-size
                ></v-file-input>
            </div>
            <div v-else>
                <v-card @click="abrirDocumento(tabla.direcciondocumentostorage)">
                    <v-card-title class="pa-0 text-subtitle-1">
                        <v-icon>article</v-icon>
                        Copia de norma
                        <v-spacer/>
                        <div @click.stop="eliminarDocumento()">
                            <Button/>
                        </div>
                    </v-card-title>
                </v-card>
            </div>

            <SelectSectores v-model="tabla.sectoresnormas" multiple tituloPlural="Sectores relacionados" class="pt-3"/>
            <ComboEtiquetas v-model="tabla.etiquetasnormas"/>

            <div class="text-caption d-flex ma-0">
                Normas relacionadas
                <v-spacer/>
                <Button tipo="agregar" @click="showNormaRelacionada=true"/>
            </div>
            
            <v-col
                v-for="item in tabla.relacionesnormas"
                :key="item.index"
                :cols=12
                class="pa-1"
                >
                <v-card @click="abrirDocumento(item.normarelacionada.direcciondocumentostorage)">
                    <v-card-title class="pa-0 text-caption">
                        {{item.accion.nombre}}                        
                        <v-spacer/>
                        <div @click.stop="eliminarNormaRelacionada(tabla.relacionesnormas.indexOf(item))">
                            <Button/>                                               
                        </div>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-row class="ma-0">
                            {{item.normarelacionada.tiponorma.nombre}} {{item.normarelacionada.numero}}-{{item.normarelacionada.anio}} del {{item.normarelacionada.fecha | fecha}}
                        </v-row>
                        <v-row class="ma-0">
                            {{item.normarelacionada.titulo}}   
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <v-col
                v-for="item in tabla.relacionesnormas2"
                :key="item.index"
                :cols=12
                class="pa-1"
                >
                <v-card @click="abrirDocumento(item.idnorma.direcciondocumentostorage)">
                    <v-card-title class="pa-0 text-caption">
                        {{item.accion.nombre2}}                                                                
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-row class="ma-0">
                            {{item.idnorma.tiponorma.nombre}} {{item.idnorma.numero}}-{{item.idnorma.anio}} del {{item.idnorma.fecha | fecha}}
                        </v-row>
                        <v-row class="ma-0">
                            {{item.idnorma.titulo}}   
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            
            <NormaRelacionada v-model="showNormaRelacionada" 
                modoAlta
                :idExcluido= tabla.id
                @actualizoFormEdicion= "actualizarNormaRelacionada"
                @eliminar= "eliminarNormaRelacionada"/>

        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {normasApi} from '@/api/NormasApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';
    import SelectEstadosNormas from '../../components/select/SelectEstadosNormas.vue';
    import InputFecha from '../../components/input/InputFecha.vue';
    import SelectSectores from '../../components/select/SelectSectores.vue';
    import SelectTiposNormas from '../../components/select/SelectTiposNormas.vue';
    import ComboEtiquetas from '../../components/combo/ComboEtiquetas.vue';
    import Button from '../../components/base/button/Button.vue';
    import NormaRelacionada from './NormaRelacionada.vue';
    import SelectEmisoresNorma from '../../components/select/SelectEmisoresNorma.vue'

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectEstadosNormas,
            InputFecha,
            SelectSectores,
            SelectTiposNormas,
            ComboEtiquetas,
            Button,
            NormaRelacionada,
            SelectEmisoresNorma,
        },

        data: () => ({
            endPointBuscar: normasApi.buscar,
            endPointGuardar: normasApi.guardar,
            endPointEliminar: normasApi.eliminarVarios,
            endPointAuditoria: normasApi.auditoria,
            tabla: {
                titulo: "",
                numero: null,
                anio: null,
                fecha: null,
                idestadonorma: 0,
                idtiponorma: 0,
                idemisornorma: 0,
                sectoresnormas:[],
                documentostorage:null,
                direcciondocumentostorage: "",
                olddirecciondocumentostorage: "",
                etiquetasnormas: [],
                relacionesnormas: [],
                relacionesnormas2: [],
            },
            showNormaRelacionada: false,
            normaRelacionada: {},
        }),

        methods: {

            setearValores(){
                this.tabla.olddirecciondocumentostorage = this.tabla.direcciondocumentostorage
            },

            abrirDocumento(link){
                if (link){
                    const fileLink = document.createElement('a');
                    fileLink.href = link;
                    fileLink.setAttribute('target','_blank');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            },

            eliminarDocumento(){
                this.tabla.direcciondocumentostorage = ""
                this.tabla.documentostorage = null
            },

            actualizarNormaRelacionada(tabla){
                this.tabla.relacionesnormas.push({
                    normarelacionada:{
                        id:tabla.norma.id,
                        titulo:tabla.norma.titulo,
                        numero:tabla.norma.numero,
                        anio:tabla.norma.anio,
                        fecha:tabla.norma.fecha,
                        direcciondocumentostorage:tabla.norma.direcciondocumentostorage,
                        tiponorma:{
                            nombre:tabla.norma.nombretiponorma
                        },
                    },
                    accion:{
                        id:tabla.accion.id,
                        nombre:tabla.accion.nombre,
                    },
                })
            },

            eliminarNormaRelacionada(index){
                this.tabla.relacionesnormas.splice(index,1);
            },
        }
    }
</script>