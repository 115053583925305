import axios from 'axios'
const controller = "modulos/"

class ModulosApi {
    
    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(){ 
        return axios.get(controller)
    }

    select(){
        return axios.get(controller + "select")
    }
}

export const modulosApi = new ModulosApi();