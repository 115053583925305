<template>
    <v-dialog v-model="show" :max-width="maxWidth" @click:outside="actualizoFormEdicion(null,true)">
        <v-card>
            <Alert v-model="showAlert" :text="mensajeError"/>
            <v-card-title class="pa-2">
                <span>{{tituloCompleto}}</span>
                <v-spacer/>
                <Button tipo="cancelar" @click="actualizoFormEdicion(null,true)"/>
            </v-card-title>
            <v-card-text class="pa-2">  
                <v-form ref="form"> 
                    <slot name="Campos"/> <!--Realiza validación y reseteo de inputs-->
                </v-form>
                <slot name="Otros"/> <!--NO Realiza validación ni reseteo de inputs-->
            </v-card-text>
            <v-card-actions class="pa-2">
                <v-col cols="4" class="pa-0 py-3">
                    <Button tipo="eliminar" :visible="muestraBotonEliminar" @click="eliminar()"/>
                    <Button tipo="imprimir" :visible="muestraBotonImprimir" @click="imprimir('imprimir')"/>
                    <Button tipo="email" :visible="muestraBotonEmail" @click="imprimir('enviarEmail')"/>
                    <Button tipo="historial" :visible="muestraBotonHistorial" @click="showHistorial = true"/>
                </v-col>
                <v-col cols="4" class="d-flex justify-center">
                    <Button tipo="guardar" :visible="muestraBotonGuardar" @click="guardar(true)"/>
                    <Button tipo="aplicar" :visible="muestraBotonAplicar" @click="guardar(false)" />
                </v-col>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="showHistorial" max-width="1100px">
            <v-card class="pa-2">
                <Historial :show="showHistorial"
                    :itemsPadre="tablaHistorial" 
                    @actualizoRangoFecha="actualizoRangoFecha"
                    @buscar="auditoria"
                    @cerrar="showHistorial = false"/>
            </v-card>
        </v-dialog>
        <Confirm ref="confirm"></Confirm>
    </v-dialog>
</template>

<script>

    import firebase from 'firebase/compat/app';
    import Button from '../button/Button.vue';
    import Historial from '../../../views/Auditoria/Historial.vue';
    
    export default {
        components: {
            Button,
            Historial,
        },

        data: () => ({
            showAlert: false,
            showHistorial: false,
            mensajeError: "",
            tablaHistorial: [],
            rangoFecha: [],
        }), 

        props: {
            value: {type: Boolean},
            modoAlta: {type: Boolean,
                default: false},  
            modoModificacion: {type: Boolean,
                default: false},  
            modoSeleccion: {type: Boolean,
                default: false},  
            endPointValoresPorDefecto: {type: Function},  
            endPointBuscar: {type: Function},    
            endPointGuardar: {type: Function}, 
            endPointEliminar: {type: Function}, 
            endPointImprimir: {type: Function}, 
            endPointAuditoria: {type: Function},
            habilitaEliminar: {type: Boolean,
                default: true},    
            permiteModificar: {type: Boolean,
                default: true},
            permiteEliminar: {type: Boolean,
                default: true},      
            permiteImprimir: {type: Boolean,
                default: true}, 
            permiteEnviarEmail: {type: Boolean,
                default: false}, 
            titulo: {type: String,
                default: ''},
            tabla: {type: Object,
                required: true},
            tablaValoresPorDefecto: {type: Object,
                default: () => ({}),},
            camposPorDefecto: {type: Object,
                default: () => ({}),},
            rutaStorage: {type: String,
                default: ""},
            maxWidth: {type: String, 
                default: '500px'},
            imprime: {type: Boolean,
                default: false},
        },

        computed: {
            show: {
                get() {return this.value},
                set(value) {this.$emit("input", value)}
            },
            nuevoRegistro(){
                if (this.modoSeleccion || this.modoModificacion) return false
                return this.modoAlta || this.tabla.id == 0 || this.tabla.id == null
            },
            modificacionRegistro(){
                if (this.modoSeleccion || !this.permiteModificar) return false
                return this.modoModificacion || (this.tabla.id !== 0 && this.tabla.id != null) 
            },
            tituloCompleto() {
                if (this.nuevoRegistro) return "Agregar " + this.titulo
                if (this.modificacionRegistro) return "Modificar " + this.titulo
                return this.titulo
            },
            muestraBotonGuardar(){
                return this.permiteModificar
            },
            muestraBotonAplicar(){
                return this.nuevoRegistro && this.permiteModificar
            },
            muestraBotonEliminar(){
                return this.modificacionRegistro && this.habilitaEliminar && this.permiteEliminar  
            },
            muestraBotonImprimir(){
                return !this.nuevoRegistro && this.permiteImprimir && this.endPointImprimir != undefined
            },
            muestraBotonEmail(){
                return !this.nuevoRegistro && this.permiteEnviarEmail && this.endPointImprimir != undefined
            },
            muestraBotonHistorial(){
                return !this.nuevoRegistro && this.endPointAuditoria != undefined
            },
        },
        
        watch:{
            show(val){
                if (val){
                    this.nuevoRegistro ? this.valoresPorDefecto() : this.buscar()
                } 
            },   
            'tabla.actualizar'(){
                if (this.show) this.buscar()
            },
        },

        methods: {

            async valoresPorDefecto(){
                if (this.endPointValoresPorDefecto != undefined){
                    try {
                        const {data} = await this.endPointValoresPorDefecto()
                        Object.assign(this.tabla,data)
                    } catch (error) {
                        console.log(error)
                    }
                }else if(Object.keys(this.tablaValoresPorDefecto).length != 0){
                    Object.assign(this.tabla,this.tablaValoresPorDefecto)
                }        
                if(Object.keys(this.camposPorDefecto).length != 0){
                    Object.assign(this.tabla,this.camposPorDefecto)
                }               
                this.$nextTick(() => {
                    this.$refs.form.resetValidation()
                })
            },

            async buscar(){
                this.showAlert = false
                if (this.endPointBuscar != undefined){
                    try {
                        this.$store.commit("loadingModule/setIsLoading");
                        const {data} = await this.endPointBuscar(this.tabla.id)
                        Object.assign(this.tabla,data)
                        this.$refs.form.resetValidation()
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.$store.commit("loadingModule/setIsLoading");
                    }
                }
                this.$emit("mostroValores")          
            },
            
            async guardar(ocultar){
                if (!this.$refs.form.validate()) return
                if (this.endPointGuardar != undefined){
                    try {
                        this.$store.commit("loadingModule/setIsLoading");
                        const {data} = await this.endPointGuardar(this.tabla)
                        this.tabla.id = data

                        if (this.tabla.olddirecciondocumentostorage && this.tabla.direcciondocumentostorage != this.tabla.olddirecciondocumentostorage){
                            firebase.storage().refFromURL(this.tabla.olddirecciondocumentostorage).delete()
                        }
                        if (this.tabla.documentostorage){
                            //const storageRef = await firebase.storage().ref(this.rutaStorage + Date.now() + "_" + this.tabla.documentostorage.name).put(this.tabla.documentostorage);                    
                            const storageRef = await firebase.storage().ref(this.rutaStorage + response.data + "_" + this.tabla.documentostorage.name).put(this.tabla.documentostorage);                    
                            await storageRef.ref.getDownloadURL().then((url)=>{
                                this.tabla.direcciondocumentostorage = url;
                            });
                            response = await this.endPointGuardar(this.tabla)
                        }
                        this.actualizoFormEdicion(data,ocultar)

                        this.$store.commit("snackbarModule/setSnack",{accion:'Guardo'})
                        if (this.imprime) this.imprimir('imprimir')
                        
                    } catch(ex) {
                        console.log(ex)
                        if (ex.response.data){
                            this.mensajeError = ex.response.data.message.toString()
                            this.showAlert = true
                        } 
                    } finally {
                        this.$store.commit("loadingModule/setIsLoading");
                    }      
                }else{
                    this.actualizoFormEdicion(this.tabla,ocultar)
                }       
            },

            async eliminar(){
                if (this.endPointEliminar != undefined){
                    let confirm = await this.$refs.confirm.open('Atención', '¿Confirma la eliminación?', {tipo:'confirmacion',color:'red'})
                    if(confirm){
                        try {
                            this.$store.commit("loadingModule/setIsLoading");
                            await this.endPointEliminar([this.tabla.id])
                            this.$store.commit("snackbarModule/setSnack",{accion:'Elimino'})
                            this.actualizoFormEdicion(this.tabla.id,true)
                        } catch(ex) {
                            this.mensajeError = "No es posible la eliminación, existen datos relacionados."
                            this.showAlert = true
                        } finally {
                            this.$store.commit("loadingModule/setIsLoading");
                        }                          
                    }
                }else{
                    this.$emit("eliminar",this.tabla.index)
                    this.actualizoFormEdicion(null,true)
                }
            },
            
            async imprimir(accion){
                try {
                    this.$store.commit("loadingModule/setIsLoading");
                    const {data} = await this.endPointImprimir(this.tabla.id)
                    this.$emit(accion,data)
                } catch (error) {
                    console.log(error)
                } finally {
                    this.$store.commit("loadingModule/setIsLoading");
                }
            },

            async auditoria(){
                try {
                    this.tablaHistorial = []
                    this.$store.commit("loadingModule/setIsLoading");
                    const {data} = await this.endPointAuditoria(this.tabla.id,this.rangoFecha)
                    this.tablaHistorial = [...data]
                } catch(ex) {
                    this.mensajeError = ex.response.data.message.toString()
                    this.showAlert = true
                    return
                } finally {
                    this.$store.commit("loadingModule/setIsLoading");
                }    
            },

            actualizoFormEdicion(dato,ocultar){
                this.$emit("actualizoFormEdicion",dato)
                if (ocultar){
                    this.show = false
                    this.showAlert = false
                }else{
                    this.valoresPorDefecto()
                }
            },

            actualizoRangoFecha(dato){
                this.rangoFecha = dato
            },
           
        }
    };
</script>