<template>
    <Select 
        v-model="itemsSeleccionados"
        titulo="Sector" 
        :tituloPlural="tituloPlural" 
        :endPointSelect="endPointSelect"
        :guardarIdsSeleccionados="guardarIdsSeleccionados"
        :accionGuardarIdsSeleccionados="'setIdsSectores'"
        :multiple="multiple"
        :obligatorio="obligatorio"
        :outlined="outlined"
        :hidedetails="hidedetails"
        :valoresPorDefecto="valoresPorDefecto"
        :readonly="readonly"
        :filtro="filtro"
        :dense="dense"
        @valorSecundario="valorSecundario"
        @clear="clear">
    </Select>
</template>

<script>
    import {SelectMixin} from '@/components/mixins/SelectMixin';
    import {sectoresApi} from '@/api/SectoresApi'
    import Select from '@/components/base/Select.vue';

    export default {

        mixins: [SelectMixin],

        components: {
            Select,
        },

        data: () => ({
            endPointSelect: sectoresApi.select,
        }),

        props: {
            tituloPlural: {type: String,
                default: "Sectores"},
            activoTurnos: {type:Boolean,
                default: null},
        },

        watch:{
            activoTurnos:{
                immediate: true,
                handler (val) {this.filtro.activoTurnos = val}
            },
        },

        created(){
            this.valoresPorDefecto = this.$store.state.sectoresModule.idsSectores
        }

    }    
</script>