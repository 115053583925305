import axios from 'axios'
import {formatoDocumento} from '@/utils';
import dayjs from 'dayjs';

const controller = "turnos/"

class TurnosApi {
    
    guardar(registro){
        return axios.post(controller,registro);
    }

    guardarDetalle(registro){
        if (registro.id){
            return axios.put(controller + "actualizardetalle",registro);
        }else{
            return axios.post(controller + "agregardetalle",registro);
        }
    }

    buscar(id){
        return axios.get(controller + id)
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }
    
    async buscarVarios(params){

        const fechaDesde = params.rangoFecha?.length > 0 ? dayjs(params.rangoFecha[0]).format("YYYY-MM-DD") : null
        const fechaHasta = params.rangoFecha?.length > 0 ? dayjs(params.rangoFecha[1]).format("YYYY-MM-DD") : null

        const horaDesde = params.rangoHora?.length > 0 ? params.rangoHora[0] : null
        const horaHasta = params.rangoHora?.length > 0 ? params.rangoHora[1] : null

        let response = await axios.get(controller,{params: {
            fechaDesde: fechaDesde,
            fechaHasta: fechaHasta,
            horaDesde: horaDesde,
            horaHasta: horaHasta,
            idsSectores: params.idsSectores,
            idsTramites: params.idsTramites,
            idsEstados: params.idsEstados,
            idsPersonas: params.idPersona ? [params.idPersona] : null,
            idsOrigenesAsignacion: params.idsOrigenesAsignacion,
            idsLocalidades: params.idsLocalidades,
        }})

        for (let index=0; index < response.data.length; index++) {
            if(response.data[index].persona){
                const apellido = response.data[index].persona.apellido
                const nombre = response.data[index].persona.nombre
                response.data[index].persona.nombre = apellido ? apellido + ' ' + nombre : nombre  
                response.data[index].documento = response.data[index].persona.tipodocumento.nombre + ' ' + formatoDocumento(response.data[index].persona.nrodocumento,response.data[index].persona.tipodocumento.formatocuit) 
                response.data[index].telefono = response.data[index].persona.contactos[0] ? response.data[index].persona.contactos[0].telefono : '';   
            }else{
                response.data[index].persona = ''
                response.data[index].documento = ''
                response.data[index].telefono = ''
            }
        }
        return response
    }

    selectEstados(parametros){
        return axios.get(controller + "selectestados",{params: {
            noSolicitaPersona:parametros.noSolicitaPersona}})
    }

    auditoria(id,rangoFecha){
        return axios.get("auditoria",{params:{
            desde: rangoFecha ? rangoFecha[0] : null,
            hasta: rangoFecha ? rangoFecha[1] : null,
            idTabla: id,
            tabla: "detalleturnos"}})
    }

    imprimir(id){
        return axios.get(controller + "imprimir" + id);
    }

    buscarVariosCalendario(parametros){
        return axios.get(controller + "buscarvarioscalendario",{params: {
            desde: parametros.desde || new Date(1000,1,1),
            hasta: parametros.hasta || new Date(3000,1,1),
            idsSectores:parametros.idSector ? [parametros.idSector]: null,
            idsTramites:parametros.idTramite ? [parametros.idTramite]: null,
            idsCalendarios:parametros.idsCalendarios,
        }})
    }

    idAsignado(){
        return axios.get(controller + "idasignado",)
    }

    idDisponible(){
        return axios.get(controller + "iddisponible",)
    }

    anterioresSiguientes(parametros){
        return axios.get(controller + "anterioressiguientes",{params:{
            id:parametros.id}
        })
    }

    siguienteDisponible(parametros){
        return axios.get(controller + "siguientedisponible",{params:{
            idSector:parametros.idSector,
            idTramite:parametros.idTramite
        }})
    }

    estadisticas(parametros){
        return axios.get(controller + "estadisticas",{params:{
            desde: parametros.rangoFecha ? parametros.rangoFecha[0] : null,
            hasta: parametros.rangoFecha ? parametros.rangoFecha[1] : null,
            idsSectores: parametros.idSector ? [parametros.idSector] : parametros.idsSectores,
            idsEstados: parametros.idsEstados,
            idsOrigenesAsignacion: parametros.idsOrigenesAsignacion
        }})
    }
}

export const turnosApi = new TurnosApi();