<template>
    <v-tooltip bottom :color="colorPrincipal">
        <template v-slot:activator="{on,attrs}">
            <v-btn 
                v-on="on"
                v-bind="attrs"
                v-show="visible"
                icon
                :x-small="size === 'x-small'"
                :small="size === 'small'" 
                :disabled="disabled"
                @click="$emit('click')">
                <v-icon class="material-icons-outlined">{{icon}}</v-icon>
            </v-btn>
        </template>    
        <span>{{tooltip}}</span>
    </v-tooltip>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        props: {
            tipo: {type: String,
                default: '',
                validator: function(value) {
                    return ['','agregar','eliminar','modificar','buscar','filtrar','cancelar','seleccionar','guardar',
                    'aplicar','historial','imprimir','anterior','siguiente','calendario','login','logout',
                    'comprobantes','mas','ver','descargar','listado','estadisticas','email'].includes(value);
                }
            },
            size: {type: String,
                default: 'small',
                validator: function(value) {
                    return ['','small','x-small']
                }
            },
            disabled: {type: Boolean,
                default: false},
            visible: {type: Boolean,
                default: true},
            toolTipExt: {type: String,
                default: ''}
        },
        computed: {
            ...mapState({colorPrincipal: state => state.colorPrincipal}),
            iconAndTooltip() {
                const mappings = {
                    agregar: {icon: 'add', tooltip: 'Agregar'},
                    eliminar: {icon: 'delete', tooltip: 'Eliminar'},
                    modificar: {icon: 'edit', tooltip: 'Modificar'},
                    buscar: {icon: 'search', tooltip: 'Buscar'},
                    filtrar: {icon: 'filter_alt', tooltip: 'Filtrar'},
                    cancelar: {icon: 'close', tooltip: 'Cancelar'},
                    seleccionar: {icon: 'check', tooltip: 'Seleccionar'},
                    guardar: {icon: 'done', tooltip: 'Guardar'},
                    aplicar: {icon: 'add_task', tooltip: 'Guardar y agregar otro'},
                    historial: {icon: 'history', tooltip: 'Historial'},
                    imprimir: {icon: 'print', tooltip: 'Imprimir'},
                    anterior: {icon: 'chevron_left', tooltip: 'Anterior'},
                    siguiente: {icon: 'chevron_right', tooltip: 'Siguiente'},
                    calendario: {icon: 'today', tooltip: 'Calendario'},
                    login: {icon: 'login', tooltip: 'Ingresar'},
                    logout: {icon: 'logout', tooltip: 'Salir'},
                    comprobantes: {icon: 'note_add', tooltip: 'Comprobantes'},
                    mas: {icon: 'more_vert', tooltip: 'Más'},
                    ver: {icon: 'visibility', tooltip: 'Ver'},
                    descargar: {icon: 'download', tooltip: 'Descargar'},
                    listado: {icon: 'list_alt', tooltip: 'Listado'},
                    estadisticas: {icon: 'bar_chart', tooltip: 'Estadísticas'},
                    email: {icon: 'mail', tooltip: 'Enviar por email'}
                };
                return mappings[this.tipo] || { icon: '', tooltip: '' };
            },
            icon() {
                return this.iconAndTooltip.icon;
            },
            tooltip() {
                return this.toolTipExt !== '' ? this.toolTipExt : this.iconAndTooltip.tooltip;
            },
        },
    }
</script>

