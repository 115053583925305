<template>
    <v-form ref="form">    
        <v-card  class="mx-auto" max-width="600">
            
            <Alert v-model="showAlert" :text="mensajeError"/>
            
            <v-toolbar dark :color="colorPrincipal">
                <v-spacer></v-spacer>
                <v-toolbar-title>         
                    Bienvenido!
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
           
            <v-card-text>
                
                <Input v-model="credenciales.email"
                    label="Email"
                    tipo="mail"
                    obligatorio
                    prepend-icon="person"
                />

                <v-text-field v-model="credenciales.password"
                    class="pa-0 pt-2"
                    color="accent" 
                    label="Password" 
                    prepend-icon="lock"
                    required
                    :append-icon="show1 ? 'visibility' : 'visibility_off'"
                    :type="show1 ? 'text' : 'password'"
                    :rules="[() => !!credenciales.password || 'El dato es obligatorio.']"
                    @click:append="show1 = !show1"
                    @keyup.enter="ingresar"
                >
                </v-text-field>
                
            </v-card-text>
            <v-card-actions class="justify-center">
                <Button tipo="login" :disabled="!disabledLogin" @click="ingresar"/>
            </v-card-actions>
            
        </v-card>
    </v-form>
</template>
<script>

    import {mapState} from 'vuex';
    import Button from '../../components/base/button/Button.vue';
    import Input from '@/components/input/Input.vue';

    export default {

        components: {
            Button,
            Input,
        },

        data:() => ({
            show1: false,
            credenciales: {
                email:"",
                password:""
            },
            mensajeError: "",
            showAlert: false,

        }),

        computed: {
            ...mapState({colorPrincipal: state => state.colorPrincipal}),
            disabledLogin() {
                return this.credenciales.email != '' && this.credenciales.password != '';
            },
        },


        methods:{
            // ingresar(){
            //     this.mensajeError = null;
            //     axios.post('api/usuarios/Login', {email: this.email, password: this.password})
            //     .then(respuesta => {
            //         return respuesta.data
            //     })
            //     .then(data => {        
            //         this.$store.dispatch("guardarToken", data.token)
            //         this.$router.push({name:'home'},() => {});
            //     })
            //     .catch(err => {
            //         if (err.response.status==400){
            //             this.mensajeError="Ingrese un email válido.";
            //         } else if (err.response.status==404){
            //             this.mensajeError="Los datos son incorrectos.";
            //         }else{
            //             this.mensajeError="Ocurrió un error.";
            //         }
            //         console.log(err)
            //     })
            // }
            
            async ingresar(){
                // try {
                //     const response = await this.$store.dispatch('login',this.credenciales)
                //     this.$router.push({name:'home'},() => {});
                // } catch (ex) {
                //     this.mensajeError = "Credenciales incorrectas."
                //     this.showAlert = true
                // }

                if (this.$refs.form.validate()) {
                    // await this.$store.dispatch('login',this.credenciales)
                    // .then((res) => {
                    //     this.$router.push({name:'home'},() => {})
                    // })
                    // .catch((ex) => {
                    //     if (ex.response.status == 401){
                    //         this.mensajeError = "Credenciales incorrectas."
                    //     }else if (ex.response.status == 404){
                    //         this.mensajeError = "Usuario inactivo."
                    //     }else{
                    //         this.mensajeError = ex.response.data.message.toString()
                    //     }    
                    //     this.showAlert = true
                    // })

                    try {
                        this.$store.commit("loadingModule/setIsLoading");
                        await this.$store.dispatch('login',this.credenciales)
                        await this.$store.dispatch('setParametros')
                        this.$store.dispatch('setEmpresa')
                        this.$store.commit("loadingModule/setIsLoading");
                        this.$router.push({name:'home'},() => {})
                    } catch (ex) {
                        this.$store.commit("loadingModule/setIsLoading");
                        if (ex.response && ex.response.status == 401){
                            this.mensajeError = "Credenciales incorrectas."
                            this.showAlert = true
                        }else if (ex.response && ex.response.status == 400){
                            this.mensajeError = "Usuario inactivo."
                            this.showAlert = true
                        }else{
                            console.log(ex)
                        }    
                    }
                    
                }
            }
        }
    }
</script>