<template>
    <FormEdicion
        v-model="show"
        titulo="Vínculo"
        :endPointBuscar="endPointBuscar"
        :endPointGuardar="endPointGuardar"
        :endPointEliminar="endPointEliminar"
        :tabla="tabla"
        @actualizoFormEdicion="actualizoFormEdicion">
        <template v-slot:Campos>
            <Input
                v-model="tabla.nombre"
                label="Nombre"
                autofocus
                obligatorio
            />
            <SelectSexos v-model="tabla.idsexo" obligatorio/>
            
            <div class="text-caption d-flex ma-0">
                Vínculos relacionados
                <v-spacer/>
                <Button tipo="agregar" @click="showVinculoRelacionado=true"/>
            </div>
            <DataTable v-model="selectItems"
                dense
                hideDefaultFooter
                :headers="headersDetalleVinculos"
                :items="tabla.detallevinculos"
                @eliminar="eliminarVinculoRelacionado"
            />
            <VinculoRelacionado v-model="showVinculoRelacionado"
                modoAlta
                :idExcluido= tabla.id
                @actualizoFormEdicion= "actualizarVinculoRelacionado"
            />
        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import {vinculosApi} from '../../api/VinculosApi'
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input.vue';
    import SelectSexos from '../../components/select/SelectSexos.vue';
    import Button from '../../components/base/button/Button.vue';
    import DataTable from '../../components/base/DataTable.vue';
    import VinculoRelacionado from './VinculoRelacionado.vue'

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectSexos,
            Button,
            DataTable,
            VinculoRelacionado,
        },

        data: () => ({
            endPointBuscar: vinculosApi.buscar,
            endPointGuardar: vinculosApi.guardar,
            endPointEliminar: vinculosApi.eliminarVarios, 
            tabla: {
                nombre: "",
                idsexo: null,
                detallevinculos: []
            },
            showVinculoRelacionado: false,
            selectItems: [],
            headersDetalleVinculos: [
                {value: "eliminar", sortable: false, filterable: false, groupable: false},
                {text: "Sexo", value: "sexo.nombre", groupable: false},
                {text: "Vínculo", value: "vinculorelacionado.nombre", groupable: false}],
        }),

        methods: {

            actualizarVinculoRelacionado(tabla){
                this.tabla.detallevinculos.push({
                    sexo:{
                        id: tabla.sexo.id,
                        nombre: tabla.sexo.nombre,
                    },
                    vinculorelacionado:{
                        id: tabla.vinculorelacionado.id,
                        nombre: tabla.vinculorelacionado.nombre,
                    },
                })
            },

            eliminarVinculoRelacionado(index){
                this.tabla.detallevinculos.splice(index,1);
            },
        }

    }
</script>