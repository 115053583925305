import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n';
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);
Vue.use(VueI18n);

const messages = {
  es: {
    $vuetify: {
      close: 'Cerrar',
      noDataText: 'No se encontraron registros',
      dataTable: {
        ariaLabel:{
          activateAscending:'Activar orden ascendente',
          activateDescending:'Activar orden descendente',
          activateNone:'Desactivar',
          sortAscending:'Orden ascendente',
          sortDescending:'Orden descendente',
          sortNone:'Sin orden'    
        }, 
        itemsPerPageText: 'Registros por página:',
        sortBy: 'Orden'
      },
      dataFooter:{
        itemsPerPageAll: 'Todos',
        pageText: '{0}-{1} de {2}',
        prevPage: 'Página anterior',
        nextPage: 'Página siguiente'
      },
      dataIterator:{
        noResultsText: 'No se encontraron registros',    
      },
      datePicker:{
        itemsSelected:'Seleccionados',
        prevMonthAriaLabel:'Anterior mes',
        nextMonthAriaLabel:'Próximo mes',
        prevYearAriaLabel:'Anterior año',
        nextYearAriaLabel:'Próximo año',
      },
      timePicker:{
        am:'am',
        pm:'pm',
      },
      calendar:{
        moreEvents:'Ver más'
      },



    },
  }
}

const i18n = new VueI18n({
  locale: 'es', // set locale
  messages, // set locale messages
})

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
  },
  icons: {
    iconfont: 'md'
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  }
});
