<template>
    <v-tooltip bottom :color="colorPrincipal">
        <template v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="enviar">
                <v-icon>mail_outline</v-icon>
            </v-btn>
        </template>    
        <span>Enviar email</span>
    </v-tooltip>
</template>

<script>
    import {mapState} from 'vuex';
    export default {

        props: {
            para: { type: String, 
                    default: ""},
            asunto: { type: String, 
                    default: ""},    
            cuerpo: { type: String, 
                    default: ""},       
        },

        computed: {
            ...mapState({colorPrincipal: state => state.colorPrincipal}),
        },
        
         methods: {
            enviar() {
                //window.open('mailto:test@example.com?subject=subject&body=body');
                let comando = "mailto:" + this.para + "?subject=" + this.asunto + "&body=" + this.cuerpo
                window.open(comando)
            },
         }

    }
</script>