
<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="itemsSeleccionados"
        transition="scale-transition"
        offset-y
        max-width="600px"
        min-width="600px">

        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="itemsSeleccionados"
                class="pa-0 pt-1 ma-0"
                :label="label"
                :rules="[valid]"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="borrar">
            </v-text-field>
        </template>

        <v-card v-if="menu">
            <div  class="d-flex justify-center">
                <v-time-picker
                    :value="desde"
                    :max="hasta"
                    full-width
                    scrollable
                    @input="$emit('update:desde',$event)">
                </v-time-picker>
                <v-time-picker
                    :value="hasta"
                    :min="desde"
                    full-width
                    scrollable
                    @input="$emit('update:hasta',$event)">
                </v-time-picker>
            </div>
            <div class="d-flex justify-center">
                <div class="pa-1">
                    <Button tipo="cancelar" @click="menu = false"/>
                </div>
                <div class="pa-1">
                    <Button tipo="seleccionar" @click="seleccionar"/>
                </div>
            </div>
        </v-card>
    </v-menu>
</template>

<script>
    
    import Button from '../base/button/Button.vue';

    export default {

        components: {
            Button,
        },

        data: () => ({
            menu: false,
        }),

        props:{
            desde:{type: String,
                default: ''},
            hasta:{type: String,
                default: ''},
            label:{type: String,
                default: 'Rango de horas'},  
            notitle:{type: Boolean,
                default: true},
            obligatorio:{ type: Boolean,
                default: false},  
        },

        computed: {
            itemsSeleccionados: {
                get() {
                    if (this.hasta){
                        return this.desde.concat(' ~ ',this.hasta);
                    }else{
                        return this.desde
                    }
                },
                set(value) {    
                }
            },         
        },

        methods: {

            valid(value){
                if (this.obligatorio && !value){
                    return 'El dato es obligatorio.'
                }
                return true
            },

            seleccionar(){
                if (!this.hasta){
                    this.$emit('update:hasta',this.desde)
                }
                this.$refs.menu.save(this.itemsSeleccionados)
            },

            borrar(){
                this.$emit('update:desde','')
                this.$emit('update:hasta','')
            },

        }

    }
</script>