import axios from 'axios'
import {formatoDocumento} from '@/utils';
const controller = "empresas/"

class EmpresasApi{

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    set(id){
        return axios.get(controller + 'set',{params: {
            id: id,
            activo: 'true',
        }})
    }

    async buscarVarios(){ 
        let response = await axios.get(controller)
        for (let index=0; index < response.data.length; index++) {
            response.data[index].documento = response.data[index].tipodocumento.nombre + ' ' + formatoDocumento(response.data[index].nrodocumento,response.data[index].tipodocumento.formatocuit) 
        }
        return response
    }
    
    select(){
        return axios.get(controller + "select")
    }

}

export const empresasApi = new EmpresasApi();