import store from './store'
import dayjs from "dayjs"
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export function convertirStringDate(string){
    var parts = string.split('-');
    var valor = new Date(parts[0],parts[1] - 1,parts[2]); 
    return valor
}

export function convertirStringHora(string){
    var parts = string.split(':');
    var valor = new Date(2000,1,1,parts[0],parts[1])
    return valor
}

export function convertirObjectDateString(object,formato,separador){
    if(!separador) separador = "/"
    let dia = cerosIzquierda(object.getDate(),2)
    let mes = cerosIzquierda(object.getMonth() + 1,2)
    let anio = object.getFullYear()
    if (formato === 'dma') return dia + separador + mes + separador + anio
    if (formato === 'amd') return anio + separador + mes + separador + dia
}

export function obtenerDia(object){
    var valor = store.state.fechasModule.dias[object.getDay()].nombre;
    return valor
}

export function obtenerMes(object){
    var valor = store.state.fechasModule.meses[object.getMonth()].nombre;
    return valor
}

export function obtenerHora(object,formato){
    let hora = cerosIzquierda(object.getHours(),2)
    let minutos = cerosIzquierda(object.getMinutes(),2)
    let segundos = cerosIzquierda(object.getSeconds(),2)
    if (formato === 'hml') return hora + ':' + minutos + ' hs.'
    if (formato === 'hms') return hora + ':' + minutos + ':' + segundos
    return hora + ':' + minutos + ' hs.'  
}

export function  obtenerDiaEnLetras(fecha){
    let dia = obtenerDia(fecha)
    let numero = fecha.getDate()
    let mes = obtenerMes(fecha)
    let anio = fecha.getFullYear()

    return dia + ', ' + numero + ' de ' + mes + ' de ' + anio
}

export function primerLetraMayuscula(valor){
    return valor[0].toUpperCase() + valor.slice(1);
}

export function seteaHorasMinutos(fecha,horas,minutos){
            
    if (!minutos){
        minutos = new Date(horas)
    }

    let valor = new Date(fecha)
    valor = new Date(valor.setHours(horas.getHours()))
    valor = new Date(valor.setMinutes(minutos.getMinutes()))
    
    return valor
}

export function rangoSeteado(){
    const desde = store.state.fechasModule.desde
    const hasta = store.state.fechasModule.hasta
    return [desde,hasta]
}

export function modificaFormatoFecha(fecha){
    if (fecha === '1899-11-30') return ''
    return dayjs(fecha).format("DD/MM/YYYY")
}

export function edad(fechaNacimiento){
    if (!fechaNacimiento || fechaNacimiento === '' || fechaNacimiento === '1899-11-30') return null

    let fechaNac;
    if (fechaNacimiento.includes("-")){
        fechaNac = dayjs(fechaNacimiento, 'YYYY-MM-DD')
    }else{  
        fechaNac = dayjs(fechaNacimiento, 'DD/MM/YYYY')
    }
    const fechaActual = dayjs();
    const edad = fechaActual.diff(fechaNac, 'year');

    return edad;
}

export function obtenerHoraDate(fecha){
    fecha = new Date(fecha)
    
    const horas = fecha.getHours();
    const minutos = fecha.getMinutes();

    const horasFormateadas = horas < 10 ? '0' + horas : horas;
    const minutosFormateados = minutos < 10 ? '0' + minutos : minutos;

    return `${horasFormateadas}:${minutosFormateados}`;
}

// Función para agregar los separadores de miles
export function agregaSeparadorDeMiles(number) {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function formatoDocumento(nroDocumento, formatoCuit){
    if (formatoCuit){
        if (isNaN(parseInt(nroDocumento))) return nroDocumento
        return nroDocumento.replace(/^(\d{2})(\d{8})(\d{1})$/, '$1-$2-$3');
    }else{
        return agregaSeparadorDeMiles(nroDocumento);
    }
};

export function diaActual(){
    return dayjs().format("YYYY-MM-DD")
}

export function decimales (value, decimales=2){
    return value.toFixed(decimales).toLocaleString();
};

export function cerosIzquierda(number, width){
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */ 
    var zero = "0"; /* String de cero */  
    
    if (width <= length) {
        if (number < 0) {
             return ("-" + numberOutput.toString()); 
        } else {
             return numberOutput.toString(); 
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString()); 
        }
    }
}

export function createPDFWithImage(callback) {
    
    var getImageFromUrl = function(url, callback) {
        var img = new Image();
        img.onError = function() {
            alert('Cannot load image: "' + url + '"');
        };
        img.onload = function() {
            callback(img);
        };
        img.src = url;
    }

    getImageFromUrl(store.state.empresasModule.logobase64,callback);
}