<template> 
  <div class="flex-container"> 
    <v-img v-show="empresa.nrodocumento"
      :src="require(`../assets/${pathLogo}`)"
      max-width="800"/>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {

    computed: {
      ...mapState({
        empresa: state => state.empresasModule,
      }), 
      
      pathLogo() {
        return this.empresa.nrodocumento === "30-67385081-9" ? 'LogoM.svg' : 'LogoPampasoft.svg'
      },
    }

  }
</script>

<style>
  .flex-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ajusta la altura según tus necesidades */
  }
</style>