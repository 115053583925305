import { parametrosApi } from "../../api/ParametrosApi";

const state = {
    multiempresa: false,
};

const actions = { 
    async setParametros({commit}){
        const {data} = await parametrosApi.buscar()
        if (data){
            commit("setParametros",data) 
        }
    },
};

const mutations = {
    setParametros:(state,data)=>(
        state.multiempresa = data.multiempresa
    ),
};

export default {
    state,
    actions,
    mutations,
}