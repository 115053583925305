<template>
  <FormABM
    :show="show"
    titulo="Auditoría"
    :endPointBuscarVarios="endPointBuscarVarios"
    :headers="headers"
    :filtro="filtro"
    sortBy="fecha"      
    :permiteAgregar="false"
    :permiteEliminar="false"
    :multiple="false">
      
    <template v-slot:CompFiltros>
        <div class="d-flex">
            <v-col cols="4" class="pa-0"> 
                <InputFechaRango v-model="filtro.rangoFecha" />  
            </v-col>
            <v-col cols="4" class="pa-0 pl-2"> 
                <SelectTiposOperacion v-model="filtro.idsTiposOperacion" multiple/>
            </v-col>
            <v-col cols="4" class="pa-0 pl-2"> 
                <SelectUsuarios v-model="filtro.idsUsuarios" multiple incluirExterno/>
            </v-col>
        </div>
    </template>  

  </FormABM>    
</template>

<script>

    import {FormABMMixin} from '@/components/mixins/FormABMMixin';
    import {auditoriaApi} from '@/api/AuditoriaApi'
    import FormABM from '@/components/base/form/FormABM.vue';
    import InputFechaRango from '@/components/input/InputFechaRango.vue';
    import SelectTiposOperacion from '@/components/select/SelectTiposOperacion.vue';
    import SelectUsuarios from '@/components/select/SelectUsuarios.vue';

    export default {
    
        mixins: [FormABMMixin],

        components: {
            FormABM, 
            InputFechaRango,
            SelectTiposOperacion,
            SelectUsuarios,
        },

        data: () => ({
            endPointBuscarVarios: auditoriaApi.buscarVarios,
            headers: [
                {text: "Tabla", value: "tabla"},
                {text: "Operación", value: "operacion"},
                {text: "Fecha y hora", value: "fechahora", tipo:"fechahoraminuto"},
                {text: "Usuario", value: "usuario"},
                {text: "Dato", value: "campo"},
                {text: "Valor antiguo", value: "oldvalue", groupable: false},
                {text: "Valor nuevo", value: "newvalue", groupable: false},
            ],
            filtro:{
                rangoFecha: [],
                idsUsuarios: null,
                idsTiposOperacion: null,
            }
        }),

        created() {
            this.filtro.rangoFecha[0] = this.$diaActual()
            this.filtro.rangoFecha[1] = this.$diaActual()
        }
    }
</script>