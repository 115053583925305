<template>
     <FormEdicion
        v-model="show"
        titulo="Actividad Económica"
        :tabla="tabla"
        :modoAlta="modoAlta"
        :modoModificacion="modoModificacion"
        :tablaValoresPorDefecto="tablaValoresPorDefecto"
        @actualizoFormEdicion="actualizoFormEdicion"
        @eliminar="eliminar">
        <template v-slot:Campos>
                    
            <SelectActividadesEconomicas v-model="tabla.actividadeconomica.id" 
                :autofocus="modoAlta"
                habilitaDetalle
                @valorSecundario="valorSecundarioActividad"/>
         
            <Input v-model="tabla.direccion"
                label="Dirección"
                obligatorio
                class="soloMayusculas"/>

            <SelectLocalidades v-model="tabla.localidad.id" 
                obligatorio
                habilitaDetalle
                @valorSecundario="valorSecundarioLocalidad"/>
            
            <div class="d-flex">
                <SelectEstadosActividades v-model="tabla.estadoactividad.id" 
                    obligatorio 
                    @valorSecundario="valorSecundarioEstado"/>
                <div class="px-2"> 
                    <InputFecha v-model="tabla.emision" label="Fecha Habilitación" obligatorio/>
                </div>
                <SwitchCustom v-model="tabla.principal" label="Principal"/> 
            </div>

            <Input v-model="tabla.observaciones"
                label="Observaciones"/>

        </template>    
    </FormEdicion>
</template>

<script>

    import {FormEdicionMixin} from '@/components/mixins/FormEdicionMixin';
    import FormEdicion from '@/components/base/form/FormEdicion.vue';
    import Input from '@/components/input/Input';
    import SelectActividadesEconomicas from '../../components/select/SelectActividadesEconomicas.vue';
    import SelectLocalidades from '../../components/select/SelectLocalidades.vue';
    import SelectEstadosActividades from '../../components/select/SelectEstadosActividades.vue';
    import InputFecha from '../../components/input/InputFecha.vue';
    import SwitchCustom from '../../components/base/Switch.vue';

    export default {

        mixins: [FormEdicionMixin],

        components: {
            FormEdicion,
            Input,
            SelectActividadesEconomicas,
            SelectLocalidades,
            SelectEstadosActividades,
            InputFecha,
            SwitchCustom,
        },

        data: () => ({
            tabla: {
                actividadeconomica:{
                    id: 0,
                    codigo: '',
                    nombre: ''
                },
                direccion: "",
                localidad:{
                    id: 0,
                    nombre: ''
                },
                estadoactividad:{
                    id: 0,
                    nombre: ''
                },
                emision: null,
                principal: false,
                observaciones: "",
            },
        }),


        methods: {
            valorSecundarioActividad(val){
                this.tabla.actividadeconomica.codigo = val.codigo
                this.tabla.actividadeconomica.nombre = val.descripcion
            },

            valorSecundarioEstado(val){
                this.tabla.estadoactividad.nombre = val.nombre
            },

            valorSecundarioLocalidad(val){
                this.tabla.localidad.nombre = val.nombre
            },
        }
    }    
</script>

<style>
    .soloMayusculas input{
        text-transform: uppercase
    }
</style>