import axios from 'axios'
import store from '../store/index'
const controller = "usuarios/"

class UsuariosApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){
        return axios.get(controller,{params:{
            activo: parametros.activo,
            administrador: parametros.administrador,
            autorizador: parametros.autorizador,
            idsSectores: parametros.idsSectores,
            idsModulos: parametros.idsModulos,
            idsEstados: parametros.idsEstados}    
        });
    }

    select(parametros){
        return axios.get(controller + "select",{params:{
            ids: parametros.ids,
            soloActivos: parametros.soloActivos,
            soloAutorizadores: parametros.soloAutorizadores,
            incluirExterno: parametros.incluirExterno,
            idSector: parametros.idSector}   
        });
    }

    contrasenia(datos){
        datos.email = store.state.usuarioModule.email
        return axios.put(controller + "contrasenia",datos);
    }

    auditoria(id,rangoFecha){
        return axios.get("auditoria",{params:{
            desde: rangoFecha ? rangoFecha[0] : null,
            hasta: rangoFecha ? rangoFecha[1] : null,
            idTabla: id,
            tabla: "usuarios"
        }})
    }
}

export const usuariosApi = new UsuariosApi();