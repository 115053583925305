<template>
  <FormABM
    :show="show"
    titulo="Calendarios"
    :endPointBuscarVarios="endPointBuscarVarios"
    :endPointEliminarVarios="endPointEliminarVarios"
    :permiteAgregar="permiteAgregar"
    :permiteModificar="permiteModificar"
    :permiteEliminar="permiteEliminar"
    :headers="headers"
    :actualizarBuscar="actualizarBuscar"       
    :modoSeleccion="modoSeleccion"
    :multiple="multiple"
    :filtro="filtro"
    @actualizar="actualizar"
    @cerrar="cerrar"
    @seleccionar="seleccionar">

    <template v-slot:CompFiltros>
      <SelectSectores v-model="filtro.idsSectores" multiple/>
    </template>  

    <template v-slot:FormEdicion>
        <Calendario v-model="showFormEdicion" :id="id" @actualizoFormEdicion="actualizoFormEdicion"/> 
    </template>    
  </FormABM>    
</template>

<script>

  import {mapState} from 'vuex';
  import {FormABMMixin} from '@/components/mixins/FormABMMixin';
  import {calendariosApi} from '@/api/CalendariosApi'
  import FormABM from '@/components/base/form/FormABM.vue';
  import SelectSectores from '@/components/select/SelectSectores.vue'
  import Calendario from './Calendario.vue'

  export default {

    mixins: [FormABMMixin],

    components: {
      FormABM, 
      SelectSectores,
      Calendario,
    },

    data: () => ({
      endPointBuscarVarios: calendariosApi.buscarVarios,
      endPointEliminarVarios: calendariosApi.eliminarVarios,
      headers: [
        { text: "Opciones", value: "opciones", sortable: false, filterable: false, groupable: false},
        { text: "Nombre", value: "nombre", groupable: false},
      ],
      filtro:{idsSectores: null}
    }),

    computed: {
      ...mapState({
        permiteAgregar: state => state.usuarioModule.permisos.calendario.agregar,
        permiteModificar: state => state.usuarioModule.permisos.calendario.modificar,
        permiteEliminar: state => state.usuarioModule.permisos.calendario.eliminar,
      }),       
    },

  }
</script>