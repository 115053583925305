import axios from 'axios'
const controller = "tipostipocomprobante/"

class TiposTiposComprobantesApi {

    guardar(datos){
        if (datos.id){
            return axios.put(controller,datos);
        }else{
            return axios.post(controller,datos);
        }
    }

    buscar(id){
        return axios.get(controller + id);
    }

    eliminarVarios(ids){
        return axios.delete(controller,{params:{
            ids: ids
        }});
    }

    buscarVarios(parametros){ 
        return axios.get(controller,{params: {
            activo: parametros.activo,
        }})
    }

    select(){
        return axios.get(controller + "select");
    }

}

export const tiposTiposComprobantesApi = new TiposTiposComprobantesApi();